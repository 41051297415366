import React, { useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import Banner from '../../../images/rules-banner.png';
import instance from '../../Communal/instance';
import history from '../../Communal/history';
import RulesResource from './RulesResources';
import RulesClassify from './RulesClassify';
import ExternalRules from './ExternalRules';
import OperationLog from './OperationLog';

const { TabPane } = Tabs;

const RuleResources = () => {
    const [activeType, setActiveType] = useState('1');
    const [search, setsSearch] = useState({
        show: false,
        value: ""
    });
    const [initData, setInitData] = useState({
        hotKeyword: [],
        resourceNumber: {}
    });
    const [filter, setFilter] = useState({
        search: "",
    });
    const [userType, setUserType] = useState(0);
    const [userIssue, setUserIssue] = useState(false);

    useEffect(() => {
        resourceNumber();

    }, [])

    useEffect(() => {
        instance.get("/ctf/user/type/?mode=rules").then(data => {
            setUserType(data.user_type);
            setUserIssue(data.issue);
        })
    }, [])

    let resourceNumber = () => {
        instance.get("/rules/static/").then(data => {
            initData.resourceNumber = data;
            setInitData({ ...initData })
        })
        instance.get("/ctf/hot_word/", { params: { type: 'rules', size: 5 } }).then(data => {
            initData.hotKeyword = data;
            setInitData({ ...initData })
        })
    }
    let onClicks = () => {
        search.show = false;
        setsSearch({ ...search });
    }
    let handelSearch = () => {
        if (search.value !== filter.search) {
            filter.search = search.value;
            setFilter({ ...filter })
        }
        search.show = true;
        setsSearch({ ...search });
    }
    //搜索回车事件
    let calAge = (e) => {
        var evt = window.event || e;
        if (evt.keyCode === 13) {
            handelSearch();
        }
    }
    const handleToggle = (key) => {
        setActiveType(key);
        filter.search = '';
        search.value = '';
        setsSearch({ ...search })
        setFilter({ ...filter });
    }
    return (
        <div className="ctf-resource-list" onClick={onClicks}>
            <div className="banner" style={{ backgroundImage: `url(${Banner})` }}></div>
            <div className="ctf-resource-search">
                <div className="top" onClick={(e) => { e.stopPropagation() }}>
                    {
                        search.show ?
                            <div className="search-input">
                                <input
                                    className="input-fouce"
                                    type="text"
                                    placeholder="请输入关键字进行搜索"
                                    value={search.value}
                                    onKeyDown={(e) => calAge(e)}
                                    onChange={(e) => {
                                        search.value = e.target.value;
                                        setsSearch({ ...search })
                                    }}
                                />
                            </div>
                            : <div className="statistics">
                                <div>
                                    <span>{initData.resourceNumber?.month_add}</span>
                                    <span>本月新增</span>
                                </div>
                                <div>
                                    <span>{initData.resourceNumber?.outside_add}</span>
                                    <span>外部新增</span>
                                </div>
                                <div>
                                    <span>{initData.resourceNumber?.available}</span>
                                    <span>可用规则</span>
                                </div>
                            </div>
                    }
                    <div className="search-btn" onClick={() => handelSearch()}>
                        <SearchOutlined />
                    </div>
                </div>
                <div className="bottom">
                    今天热点搜索：
                    {
                        initData.hotKeyword.map((val, key) => {
                            return <span key={"key" + key} onClick={() => {
                                filter.page = 1;
                                filter.search = val;
                                setFilter({ ...filter })
                            }}>{val}</span>
                        })
                    }
                    {
                        initData.hotKeyword.length === 0 &&
                        "暂无热搜关键字"
                    }
                </div>
            </div>
            <div className="ctf-resource-table content-container">
                <Tabs
                    activeKey={activeType}
                    onChange={(key) => {
                        handleToggle(key)
                    }}
                    style={{ 'overflow': 'visible', 'marginBottom': '10px' }}
                >
                    <TabPane
                        tab='规则资源'
                        key='1'
                    >
                        {activeType === "1" && <RulesResource
                            keyword={filter.search}
                            key={filter.search}
                            userType={userType}
                            userIssue={userIssue}
                        />
                        }
                    </TabPane>
                    <TabPane
                        tab='规则分类'
                        key='2'
                    >
                        {activeType === "2" && <RulesClassify
                            keyword={filter.search}
                            key={filter.search}
                            userType={userType}
                            userIssue={userIssue}
                        />
                        }
                    </TabPane>
                    <TabPane
                        tab='外部规则'
                        key='3'
                    >
                        {activeType === "3" && <ExternalRules
                            keyword={filter.search}
                            key={filter.search}
                            userType={userType}
                            userIssue={userIssue}
                        />}
                    </TabPane>
                    {
                        userType > 1 ? <TabPane
                            tab='操作日志'
                            key='4'
                        >
                            {activeType === "4" && <OperationLog
                                keyword={filter.search}
                                key={filter.search}
                                userType={userType}
                                userIssue={userIssue}
                            />}
                        </TabPane> : undefined
                    }

                </Tabs>
            </div>
        </div>
    )
}
export default RuleResources;