import React, { useState, useEffect } from 'react';
import { Button, Table, Pagination, Divider, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import { Alert } from '../../../Communal/utility';
import instance from '../../../Communal/instance';
import DeleteBox from '../../deteleBox';
import { levelList } from '../../../../utils/method';
import '../index.less'

const { Column } = Table
const { Search } = Input;

const App = ({
    keyword,
    userType,
    userIssue
}) => {
    const [filter, setFilter] = useState({
        page: 1,
        size: 10,
        search: keyword,
        username: "",
        vulntype: ""
    });
    const [deleteTip, setDeleteTip] = useState(
        {
            show: false,
            list: []
        }
    )
    const [initData, setInitData] = useState(
        {
            data: [],
            allCount: 0,
        }
    );
    const [checkList, setCheckList] = useState([]);

    useEffect(() => {
        return () => {
            setFilter({
                page: 1,
                size: 10,
                search: keyword,
                username: "",
            })
        }
    }, [])

    useEffect(() => {
        getVulnInfoList()
    }, [filter]);

    //获取漏洞信息列表
    let getVulnInfoList = () => {
        instance.get("/vulninte/api/all/", { params: filter }).then(data => {
            initData.data = data.results;
            initData.allCount = data.count;
            setInitData({ ...initData })
        })
    }
    //单个删除或者批量删除操作
    let resourceDelete = (list) => {
        if (list.length === 0) {
            Alert("warn", "请选择需要删除的漏洞信息");
            return
        }
        instance.post("/vulninte/api/deletes/", { id: list }).then(data => {
            if (filter.page > 1 && (initData.data.length === list.length)) {
                filter.page -= 1;
                setFilter({ ...filter })
            } else {
                getVulnInfoList();
            }
            Alert("success", "删除成功");
            setCheckList([]);
            deleteTip.list = [];
            deleteTip.show = false;
            setDeleteTip({ ...deleteTip })
        })
    }

    let handleTableChange = (pagination, filters, sorter) => {
        if (filters.severity) {
            filter.severity = filters.severity;
        } else {
            filter.severity = [];
        }
        if (filters.resource_level) {
            filter.resource_level = filters.resource_level;
        } else {
            filter.resource_level = [];
        }
        if (filters.vulnstatus) {
            filter.plug = []
            filter.vulexp = []
            filter.vulpoc = []
            filter.vulnset = []
            filter.day=[]
            filters.vulnstatus.map((x, i) => {
                filter[x] = 1
            })
        } else {
            filter.plug = []
            filter.vulexp = []
            filter.vulpoc = []
            filter.vulnset = []
            filter.day = []
        }
        if (sorter.order) {
            if (sorter.order === "ascend") {
                filter.ordering = "is_env"
            } else {
                filter.ordering = "-is_env"
            }
        } else {
            filter.ordering = ""
        }
        filter.page = 1;
        setFilter({ ...filter });
    }

    return (
        <div className="ctf-resource-list vulnInfo-list" style={{ padding: 0 }}>
            <div className="ctf-resource-table content-container" style={{ marginTop: 0 }}>
                <div className="handle">
                    {
                        userType > 1 &&
                        <div style={{ display: 'inline-block' }}>
                            <Button
                                type="primary"
                                danger
                                disabled={checkList.length === 0}
                                onClick={() => {
                                    if (checkList.length > 0) {
                                        deleteTip.show = true;
                                        deleteTip.list = checkList;
                                        setDeleteTip({ ...deleteTip })
                                    } else {
                                        Alert("warn", "请选择需要删除的漏洞信息");
                                    }
                                }}
                            >
                                批量删除
                            </Button>
                        </div>
                    }
                    <div className='right'>
                        <Search
                            placeholder="请输入搜索内容"
                            style={{ width: 250 }}
                            value={filter.search}
                            onChange={(e) => {
                                filter.page = 1
                                filter.search = e.target.value
                                setFilter({ ...filter });
                            }}
                        />
                        <Divider type='vertical' />
                        <Button
                            type="primary"
                            onClick={() => window.open("/#/activity/vulninfo/0/0")}
                        >
                            提交漏洞信息
                        </Button>
                    </div>
                </div>
                <Table
                    dataSource={initData.data}
                    rowKey={record => record.id}
                    bordered
                    pagination={false}
                    rowSelection={userType > 1 && {
                        type: "checkbox",
                        selectedRowKeys: checkList,
                        preserveSelectedRowKeys: true,
                        onChange: ((selectedRowKeys) => {
                            setCheckList(selectedRowKeys)
                        })
                    }}
                    onChange={handleTableChange}
                >
                    <Column
                        title="序号"
                        key="serial"
                        align="center"
                        width='80px'
                        render={(text, record, index) => {
                            return <span
                                className="click-href"
                                onClick={() => {
                                    window.location.href = "/#/activity/vulninfodetail/" + record.id
                                }}
                            >
                                {filter.size * (filter.page - 1) + index + 1}
                            </span>
                        }}
                    />
                    <Column
                        title="漏洞编号"
                        key="number"
                        align="center"
                        ellipsis="true"
                        dataIndex="number"
                        width="170px"
                        render={(text, record) => {
                            return text ? <span
                                className="click-href"
                                onClick={() => {
                                    window.location.href = "/#/activity/vulninfodetail/" + record.id
                                }}
                            >
                                {text}
                            </span>
                                : "暂无编号"
                        }}
                    />
                    <Column
                        title="漏洞名称"
                        key="name"
                        align="center"
                        dataIndex="name"
                        ellipsis="true"
                        width='300px'
                        render={(text, record) => {
                            return <a
                                className="click-href"
                                href={"/#/activity/vulninfodetail/" + record.id}
                            >
                                {text}
                            </a>
                        }}
                    />
                    {userType > 1 && <Column
                        title="等级"
                        key="resource_level"
                        align="center"
                        dataIndex="resource_level"
                        width='80px'
                        filters={levelList}
                    />}
                    <Column
                        title="危害级别"
                        key="severity"
                        align="center"
                        dataIndex="severity"
                        render={(record) => {
                            return <span style={record === 0 ? { color: "#ff4747" } : record === 1 ? { color: "#ff6600" } : record === 2 ? { color: "#ffc000" } : record === 3 ? { color: "#00a8ff" } : {}}>
                                {
                                    {
                                        0: "超危",
                                        1: "高危",
                                        2: "中危",
                                        3: "低危",
                                        4: "未定义"
                                    }[record]
                                }
                            </span>
                        }}
                        width="105px"
                        filters={
                            [
                                { value: 0, text: "超危" },
                                { value: 1, text: "高危" },
                                { value: 2, text: "中危" },
                                { value: 3, text: "低危" },
                                { value: 4, text: "未定义" }
                            ]
                        }
                    />
                    <Column
                        title="漏洞类型"
                        key="vulntype"
                        align="center"
                        dataIndex="vulntype"
                        ellipsis="true"
                        width="150px"
                        render={(text) => {
                            return text ? text : '--'
                        }}
                        filterDropdown={({ clearFilters }) => {
                            return <div className="table-search">
                                <Input
                                    placeholder="请输入漏洞类型"
                                    value={filter.vulntype}
                                    onChange={(e) => {
                                        filter.vulntype = e.target.value;
                                        filter.page = 1;
                                        setFilter({ ...filter })
                                    }}
                                />
                                <Button type="primary" onClick={() => { clearFilters() }}>确定</Button>
                                <Button onClick={() => {
                                    clearFilters();
                                    filter.vulntype = "";
                                    filter.page = 1;
                                    setFilter({ ...filter })
                                }}>取消</Button>
                            </div>
                        }}
                        filterIcon={<SearchOutlined />}
                    />
                    <Column
                        title="漏洞状态"
                        key="vulnstatus"
                        align="center"
                        dataIndex="vulnstatus"
                        width="150px"
                        render={(text, record) => {
                            let isVulnInfo = record?.vuln_status?.VulnIntelligence
                            let isPoc = record?.vuln_status?.POC
                            let isExp = record?.vuln_status?.EXP
                            let isPlug = record?.vuln_status?.Plug
                            return <div>
                                <span
                                    title={(isVulnInfo ? '有' : '无') + '情报信息'}
                                    className={isVulnInfo ? 'color-blue vuln-status verify ' : 'vuln-status  verify'}
                                ></span>
                                <span
                                    title={(isPoc ? '有' : '无') + '漏洞POC'}
                                    className={isPoc ? 'color-blue  vuln-status poc' : 'vuln-status poc'}
                                ></span>
                                <span
                                    title={(isExp ? '有' : '无') + '漏洞EXP'}
                                    className={isExp ? 'color-blue  vuln-status vulninfo' : 'vuln-status vulninfo'}></span>
                                <span
                                    title={(isPlug ? '有' : '无') + '检测插件'}
                                    className={isPlug ? ' color-blue vuln-status repair-suggest' : 'vuln-status repair-suggest'}></span>
                            </div>
                        }}
                        filters={[
                            { text: '有情报信息', value: 'vulnset' },
                            { text: '有漏洞POC', value: 'vulpoc' },
                            { text: '有漏洞EXP', value: 'vulexp' },
                            { text: '有检测插件', value: 'plug' },
                            { text: '是0day', value: 'day' },
                        ]}
                    />
                    <Column
                        title="漏洞更新时间"
                        key="modified"
                        align="center"
                        dataIndex="modified"
                        width="150px"
                        render={(text) => {
                            return text ? text : '--'
                        }}
                    />
                    <Column
                        title="操作"
                        key="operate"
                        align="center"
                        // width={userIssue ? 210 : 160}
                        width={80}
                        render={(record) => {
                            return <div className="table-operation">
                                <div>
                                    <span>
                                        <b></b>
                                        <b></b>
                                        <b></b>
                                    </span>
                                    <div>
                                        <Button
                                            type="link"
                                            style={{ padding: 0 }}
                                            onClick={() => {
                                                window.location.href = "/#/activity/vulninfodetail/" + record.id
                                            }}
                                        >
                                            详情
                                        </Button>
                                        <Button
                                            style={{ padding: 0 }}
                                            // disabled={!record.on_delete}
                                            type="link"
                                            onClick={() => {
                                                window.location.href = "/#/activity/vulninfo/1/" + record.id
                                            }}
                                        >
                                            编辑
                                        </Button>
                                        <Button
                                            type="link"
                                            style={{ padding: 0 }}
                                            disabled={!record.on_delete}
                                            danger
                                            onClick={() => {
                                                deleteTip.show = true;
                                                deleteTip.list = [record.id];
                                                setDeleteTip({ ...deleteTip })
                                            }}
                                        >删除</Button>
                                    </div>
                                </div>
                            </div>
                        }}
                    />
                </Table>
                <Pagination
                    showQuickJumper={true}
                    current={filter.page}
                    pageSize={filter.size}
                    total={initData.allCount}
                    hideOnSinglePage={initData.allCount === 0}
                    pageSizeOptions={['10', '20', '50', '100']}
                    showSizeChanger={true}
                    className="flip-over"
                    style={{ margin: "30px 0" }}
                    onChange={
                        ((page, pageSize) => {
                            let data = { ...filter }
                            data.page = page;
                            data.size = pageSize;
                            setFilter(data)
                        })
                    }
                />
            </div>
            {
                deleteTip.show &&
                <DeleteBox
                    title='漏洞信息'
                    close={() => {
                        deleteTip.show = false;
                        deleteTip.list = [];
                        setDeleteTip({ ...deleteTip })
                    }}
                    submit={() => resourceDelete(deleteTip.list)}
                />
            }
        </div>
    )
}
export default App;