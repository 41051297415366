import React, { useEffect, useState } from 'react';
import { Drawer, Anchor } from 'antd';
import MarkNav from 'markdown-navbar';
import ReactMarkdown from 'react-markdown';
// 划线、表、任务列表和直接url等的语法扩展
import remarkGfm from 'remark-gfm';
// 解析标签，支持html语法
import rehypeRaw from 'rehype-raw'
// 代码高亮
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism'
// 内容样式
import 'github-markdown-css';
import 'markdown-navbar/dist/navbar.css';
import '../../css/comfirm.less';

const { Link } = Anchor;

const App = ({
    boxProperty,
    close,
    title,
    url,
}) => {
    const [mdContent, setMdContent] = useState('')
    const [titles, setTitles] = useState([])

    useEffect(() => {
        if (boxProperty.show) {
            fetch(url)
                .then(res => res.text())
                .then(text => setMdContent(text));
        }
        if (boxProperty.show) {
            setMdContent('')
        }
    }, [boxProperty.show]);

    useEffect(() => {
        if (boxProperty.show) {
            let title = addAnchor()
            setTitles(title)
        }
    }, [mdContent, boxProperty.show])

    useEffect(() => {
        // window.addEventListener('scroll', setLocations, true)
        // return () => {
        //     window.removeEventListener("scroll", setLocations);
        // };
    }, [])

    const handleCancel = () => {
        close()
    }

    const addAnchor = () => {
        const ele = document.getElementsByClassName('markdown-body')[0];
        let eid = 0;
        let titles = [];
        for (const e of ele.childNodes) {
            if (e.nodeName === 'H1' || e.nodeName === 'H2' || e.nodeName === 'H3' || e.nodeName === 'H4' || e.nodeName === 'H5' || e.nodeName === 'H6') {
                let a = document.createElement('a');
                a.setAttribute('id', '#' + eid);
                a.setAttribute('class', 'anchor-title');
                a.setAttribute('href', '#' + eid);
                a.innerText = ' '
                let title = {
                    type: e.nodeName,
                    id: eid,
                    name: e.innerText
                };
                titles.push(title);
                e.appendChild(a);
                eid++;
            }
        }
        return titles;
    }

    const handleClickFun = (e, link) => {
        e.preventDefault();
        if (link.href) {
            // 找到锚点对应得的节点
            let element = document.getElementById(link.href);
            // 如果对应id的锚点存在，就跳滚动到锚点顶部
            element && element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    }

    const setLocations = () => {
        const hashtags = document.getElementsByClassName('anchor-title');
        let locations = [];
        for (const h of hashtags) {
            let top = h.offsetTop + 64;
            let name = '#' + h.getAttribute('id');
            let location = { top: top, name: name };
            locations.push(location);
        }
        let inner = document.querySelector('h1,h2,h3,h4,h5,h6')
    }

    return (
        <Drawer
            className="form-modal submit-ctf markdown-modal"
            id="markdown-modal"
            style={{ backgroundColor: 'transparent' }}
            visible={boxProperty.show}
            onClose={handleCancel}
            destroyOnClose
            title={title}
            footer={null}
        >
            <div className='nav-menu'>
                {/* <MarkNav
                    className="article-menu"
                    source={mdContent}
                    headingTopOffset={80}
                    ordered={false}
                // declarative={true}
                // updateHashAuto={false}
                /> */}
                <Anchor
                    className='markdown-nav'
                    affix={false}
                    onClick={handleClickFun}
                    getContainer={() => document.getElementsByClassName('content-box')}
                >
                    {
                        titles.map(t => (
                            <Link href={'#' + t.id} title={t.name} className={'title-' + t.type} key={t.id} />
                        ))
                    }
                </Anchor>
            </div>
            <div className='content-box' >
                <ReactMarkdown
                    className='markdown-body'
                    children={mdContent}
                    remarkPlugins={[remarkGfm, { singleTilde: false }]}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                        code({ node, inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '')
                            return !inline && match ? (
                                <SyntaxHighlighter
                                    children={String(children).replace(/\n$/, '')}
                                    style={tomorrow}
                                    language={match[1]}
                                    PreTag="div"
                                    {...props}
                                />
                            ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                            )
                        }
                    }}
                />
            </div>
        </Drawer>
    )

}
export default App;