/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '../../../css/ctfResource.css';
import Banner from '../../../images/vuln-info-banner.png';
import { SearchOutlined } from '@ant-design/icons';
import { Tabs } from 'antd'
import instance from '../../Communal/instance';
import history from '../../Communal/history';
import Vuln from './Vuln';
import Components from './Components';
import PlugIn from './PlugIn';
import Fingerprints from './Fingerprints';

const { TabPane } = Tabs;

function List() {
    const [search, setsSearch] = useState({
        show: false,
        value: ""
    });
    const [initData, setInitData] = useState(
        {
            data: [],
            allCount: 0,
            hotKeyword: [],
            resourceNumber: {}
        }
    );
    const [filter, setFilter] = useState(
        {
            page: 1,
            size: 10,
            search: "",
            severity: [],
            ordering: "",
        }
    );
    const [userType, setUserType] = useState(0);
    const [userIssue, setIssue] = useState(false);
    const [activeType, setActiveType] = useState('1');
    const [classify, setClassify] = useState({ mark: [], plug: [] })

    useEffect(() => {
        if (history.location.state) {
            if (history.location.state.tabKey) {
                setActiveType(history.location.state.tabKey)
            }
        }
    }, [history.location.state])

    let handelSearch = () => {
        if (search.value !== filter.search) {
            filter.search = search.value;
            filter.page = 1;
            setFilter({ ...filter })
        }
        search.show = true;
        setsSearch({ ...search });
    }
    let onClicks = (ev) => {
        var e = ev || window.event; //浏览器兼容性
        var elem = e.target || e.srcElement;
        while (elem) {
            //循环判断至跟节点，防止点击的是div子元素
            if (elem.className === "top") {
                return;
            } else {
                elem = elem.parentNode;
            }
        }
        search.show = false;
        setsSearch({ ...search });
    }

    //获取热搜词
    useEffect(() => {
        // 获取指纹和漏洞类型
        getClassify()
        //获取资源数
        resourceNumber();
        instance.get("/ctf/hot_word/", { params: { type: "vuln_intelligence", size: 5 } }).then(data => {
            initData.hotKeyword = data;
            setInitData({ ...initData })
        })
        instance.get("/ctf/user/type/?mode=vulnintelligence").then(data => {
            setUserType(data.user_type);
            setIssue(data.issue);
        })
    }, [])
    let resourceNumber = () => {
        // instance.get("/ctf/sum/", { params: { type: "vuln_intelligence" } }).then(data => {
        //     initData.resourceNumber = data;
        //     setInitData({ ...initData })
        // })
        instance.get("/vulninte/api/count/").then(data => {
            initData.resourceNumber = data;
            setInitData({ ...initData })
        })
    }

    let getClassify = () => {
        instance.get("/vulninte/api/static/make/").then(data => {
            if (Array.isArray(data?.mark)) {
                for (let i in data.mark[0]) {
                    classify.mark.push({
                        value: i,
                        text: data.mark[0][i]
                    })
                }
            }
            if (Array.isArray(data?.plug)) {
                for (let i in data.plug[0]) {
                    classify.plug.push({
                        value: i,
                        text: data.plug[0][i]
                    })
                }
            }
            setClassify({ ...classify })
        })
    }

    //搜索回车事件
    let calAge = (e) => {
        var evt = window.event || e;
        if (evt.keyCode === 13) {
            handelSearch();
        }
    }

    const handleToggle = (key) => {
        setActiveType(key);
        filter.search = '';
        search.value = '';
        setsSearch({ ...search })
        setFilter({ ...filter });
    }

    return (
        <div className="ctf-resource-list vuln-resource-list" onClick={onClicks}>
            <div className="banner" style={{ backgroundImage: `url(${Banner})` }}></div>
            <div className="ctf-resource-search">
                <div className="top">
                    {
                        search.show ?
                            <div className="search-input">
                                <input
                                    className="input-fouce"
                                    type="text"
                                    placeholder="请输入关键字进行搜索"
                                    value={search.value}
                                    onKeyDown={(e) => calAge(e)}
                                    onChange={(e) => {
                                        search.value = e.target.value;
                                        setsSearch({ ...search })
                                    }}
                                />
                            </div>
                            :
                            <div
                                className="statistics w-20-div"
                                style={{ width: '100%' }}
                            >
                                <div>
                                    <span>{initData.resourceNumber?.moth}</span>
                                    <span>本月新增漏洞</span>
                                </div>
                                <div>
                                    <span>{initData.resourceNumber?.vuln}</span>
                                    <span>漏洞总数</span>
                                </div>
                                <div>
                                    <span>{initData.resourceNumber?.mark}</span>
                                    <span>指纹总数</span>
                                </div>
                                <div>
                                    <span>{initData.resourceNumber?.module}</span>
                                    <span>组件总数</span>
                                </div>
                                <div>
                                    <span>{initData.resourceNumber?.plug}</span>
                                    <span>插件总数</span>
                                </div>
                            </div>
                    }
                    {/* <div className="search-btn" onClick={() => handelSearch()}>
                        <SearchOutlined />
                    </div> */}
                </div>
                <div className="bottom">
                    今天热点搜索：
                    {
                        initData.hotKeyword.map((val, key) => {
                            return <span key={"key" + key} onClick={() => {
                                filter.page = 1;
                                filter.search = val;
                                setFilter({ ...filter })
                            }}>{val}</span>
                        })
                    }
                    {
                        initData.hotKeyword.length === 0 &&
                        "暂无热搜关键字"
                    }
                </div>
            </div>
            <div className="ctf-resource-table content-container">
                <Tabs
                    activeKey={activeType}
                    onChange={(key) => {
                        handleToggle(key)
                    }}
                    style={{ 'overflow': 'visible', 'marginBottom': '10px' }}
                >
                    <TabPane
                        tab='漏洞情报'
                        key='1'
                    >
                        {activeType === "1" && <Vuln
                            keyword={filter.search}
                            key={filter.search}
                            userType={userType}
                            userIssue={userIssue}
                            classify={classify.plug}
                        />
                        }
                    </TabPane>
                    <TabPane
                        tab='指纹库'
                        key='2'
                    >
                        {activeType === "2" && <Fingerprints
                            keyword={filter.search}
                            key={filter.search}
                            userType={userType}
                            userIssue={userIssue}
                            classify={classify.mark}
                        />
                        }
                    </TabPane>
                    <TabPane
                        tab='组件库'
                        key='3'
                    >
                        {activeType === "3" && <Components
                            keyword={filter.search}
                            key={filter.search}
                            userType={userType}
                            userIssue={userIssue}
                        />}
                    </TabPane>
                    <TabPane
                        tab='插件库'
                        key='4'
                    >
                        {activeType === "4" && <PlugIn
                            keyword={filter.search}
                            key={filter.search}
                            userType={userType}
                            userIssue={userIssue}
                        />}
                    </TabPane>
                </Tabs>
            </div>

        </div>
    )
}
export default List;