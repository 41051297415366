import React, { useEffect, useState } from 'react';
import { Button, Input, Row, Col, Drawer, Select } from 'antd';
import '../../../../css/comfirm.less';
import { Alert } from '../../../Communal/utility';
import instance from '../../../Communal/instance';

const { Option } = Select

const App = ({
    submitTip,
    close,
    getData
}) => {
    const [submitLoading, setSubmitLoading] = useState(false)

    const [submit, setSubmit] = useState({
        name: "",
        company: []
    })
    const [errMsg, setErrMsg] = useState({
        name: "",
        company: ""
    })
    const [componyList, setComponyList] = useState([])

    useEffect(() => {
        if (!submitTip.show) {
            setErrMsg({
                name: "",
                company: ""
            })
            setSubmit({
                name: "",
                company: []
            })
            setComponyList([])
        }
        if (submitTip.show && submitTip.type !== "0") {
            getDetail(submitTip.id)
        }
        if (submitTip.show && submitTip.type !== "3") {
            instance.get(`/vulninte/api/static/module/`).then(data => {
                if (Array.isArray(data)) {
                    setComponyList(data)
                }
            })
        }
    }, [submitTip.show])

    const getDetail = (id) => {
        instance.get(`/vulninte/api/module/${id}/`).then(data => {
            let list = data
            list.company = [data?.company]
            setSubmit({ ...list })
        })
    }

    const handleChange = (key, value) => {
        submit[key] = value
        if (key === 'company') {
            if (submit.company.length > 1) {
                submit.company = submit.company.slice(-1)
            }
        }
        setSubmit({ ...submit })
    }

    const clearErr = (key) => {
        let err = { ...errMsg }
        err[key] = ""
        setErrMsg({ ...err })
    }

    const handleCancel = () => {
        close()
    }

    const handleOk = (type) => {
        let err = { ...errMsg }
        const list = {
            name: submit.name,
            company: submit.company[0],
        }
        if (submit.name === '') {
            err.name = '请输入组件名称'
        }
        if (submit.company.length <= 0) {
            err.company = '请选择或输入组件厂商'
        }
        if (submit.name === '' || submit.company.length <= 0) {
            setErrMsg({ ...err })
            return
        }
        setSubmitLoading(true)
        if (submitTip.type === "0") {
            // 添加
            instance.post("/vulninte/api/module/", list).then(data => {
                setSubmitLoading(false);
                Alert("success", "添加成功");
                handleCancel()
                getData()
            }).catch(err => {
                setSubmitLoading(false);
                Alert("error", err.error)
            })
        }
        if (submitTip.type === "1" || type === "1") {
            // 编辑
            instance.patch(`/vulninte/api/module/${submitTip.id}/`, list).then(data => {
                setSubmitLoading(false);
                Alert("success", "编辑成功");
                handleCancel()
                getData()
            }).catch(err => {
                setSubmitLoading(false);
                Alert("error", err.error)
            })
        }
    }

    return (
        <Drawer
            className="form-modal submit-ctf component-form"
            style={{ backgroundColor: 'transparent' }}
            visible={submitTip.show}
            onClose={handleCancel}
            width={800}
            destroyOnClose
            title={'组件' + (submitTip.type === "0" ? '提交' : submitTip.type === "1" ? "编辑" : "详情")}
            footer={null}
        >
            <Row className="line">
                <Col span={4} className='left'><b>*</b>组件名称：</Col>
                <Col span={20} className='right'>
                    <Input
                        placeholder='请输入组件名称'
                        onChange={(e) => { handleChange('name', e.target.value) }}
                        value={submit.name}
                        disabled={submitTip.type === "3"}
                        maxLength={50}
                        onFocus={() => { clearErr('name') }}
                    />
                </Col>
            </Row>
            <Row className="error">
                <Col span={4}></Col>
                <Col span={20}>{errMsg?.name} </Col>
            </Row>

            <Row className="line">
                <Col span={4} className='left'><b>*</b>组件厂商：</Col>
                <Col span={20} className='right'>
                    <Select
                        mode="tags"
                        className='radio-tag'
                        style={{
                            width: '100%',
                        }}
                        maxTagCount={1}
                        placeholder='请下拉选择或输入组件厂商'
                        disabled={submitTip.type === "3"}
                        value={submit.company}
                        onChange={(value) => { handleChange('company', value) }}
                        onFocus={() => { clearErr('company') }}
                    >
                        {
                            (componyList || []).map((value, key) => {
                                if (value !== submit.company[0])
                                    return <Option key={value}>{value}</Option>
                            })
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="error">
                <Col span={4}></Col>
                <Col span={20}>{errMsg?.company} </Col>
            </Row>
            {
                submitTip.type !== "3" ?
                    <div
                        style={{ textAlign: 'center' }}
                        className="my-modal-footer submit-btn" >
                        <Button className="cancel-btn" onClick={handleCancel}>取消</Button>
                        <Button type="primary" loading={submitLoading} onClick={handleOk}>确认</Button>
                    </div>
                    :
                    null
            }
        </Drawer>
    )
}

export default App;
