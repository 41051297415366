/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '../../../css/ctfResource.css';
import { Tabs } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import instance from '../../Communal/instance';
import history from '../../Communal/history';
import StationTable from './stationTable';
import EnterproseTable from './enterpriseTable';
const { TabPane } = Tabs;
function List(param) {
    const [search, setsSearch] = useState({
        show: false,
        value: ""
    })
    const [initData, setInitData] = useState(
        {
            hotKeyword: [],//热搜关键词
            resourceNumber: {}//资源数
        }
    )
    const [filter, setFilter] = useState({
        search: "",
    });
    const [tabKey, setTabKey] = useState('');
    const [data, setData] = useState({});
    useEffect(() => {
        instance.get("/ctf/resource/type/").then(data => {
            if (
                history.location.state && history.location.state.tabKey === 2 ||
                !data?.station?.is_access && data?.enterprise?.is_access
            ) {
                setTabKey("2")
            } else {
                setTabKey("1")
            }
            setData(data)
        })
    }, [history.location.state])
    // 获取热搜词
    useEffect(() => {
        let reType = "Station"
        if (tabKey === "2") {
            reType = "enterprise"
        }
        instance.get("/ctf/hot_word/", { params: { type: reType, size: 5 } }).then(data => {
            initData.hotKeyword = data;
            setInitData({ ...initData })
        })
    }, [tabKey])
    
    // 获取资源数
    useEffect(() => {
        resourceNumber()
    }, [tabKey])
    // 获取资源数
    const resourceNumber = () => {
        if (tabKey === 0 || tabKey === "1") {
            instance.get("/ctf/sum/", { params: { type: "station" } }).then(data => {
                initData.resourceNumber = data;
                setInitData({ ...initData })
            })
        }
        if (tabKey === "2") {
            instance.get("/ctf/sum/", { params: { type: "enterprise" } }).then(data => {
                initData.resourceNumber = data;
                setInitData({ ...initData })
            })
        }

    }
    // 点击搜索框外切换为资源数
    const onClicks = (ev) => {
        var e = ev || window.event; //浏览器兼容性
        var elem = e.target || e.srcElement;
        while (elem) {
            //循环判断至跟节点，防止点击的是div子元素
            if (elem.className === "top") {
                return;
            } else {
                elem = elem.parentNode;
            }
        }
        search.show = false;
        setsSearch({ ...search });
    }
    //搜索回车事件
    const calAge = (e) => {
        var evt = window.event || e;
        if (evt.keyCode === 13) {
            handelSearch();
        }
    }
    // 点击搜索
    const handelSearch = () => {
        if (search.value !== filter.search) {
            filter.search = search.value;
            setFilter({ ...filter })
        }
        search.show = true;
        setsSearch({ ...search });
    }
    // tab切换
    const tabChange = (key) => {
        filter.search = '';
        search.value = '';
        setsSearch({ ...search })
        setFilter({ ...filter });
        setTabKey(key)
    }
    return (
        <div
            className='ctf-resource-list recruit-resource-list'
            onClick={onClicks}
        >
            <div className="banner"></div>
            <div className="ctf-resource-search">
                <div className="top">
                    {
                        search.show ?
                            <div className="search-input">
                                <input
                                    className="input-fouce"
                                    type="text"
                                    placeholder="请输入关键字进行搜索"
                                    value={search.value}
                                    onKeyDown={(e) => calAge(e)}
                                    onChange={(e) => {
                                        search.value = e.target.value;
                                        setsSearch({ ...search })
                                    }}
                                />
                            </div>
                            : <div className="statistics">
                                <div>
                                    <span>{initData.resourceNumber.day}</span>
                                    <span>今日新增</span>
                                </div>
                                <div>
                                    <span>{initData.resourceNumber.month}</span>
                                    <span>本月新增</span>
                                </div>
                                <div>
                                    <span>{initData.resourceNumber.sum}</span>
                                    <span>总资源数</span>
                                </div>
                            </div>
                    }
                    <div className="search-btn" onClick={() => handelSearch()}>
                        <SearchOutlined />
                    </div>
                </div>
                <div className="bottom">
                    今天热点搜索：
                    {
                        initData.hotKeyword.map((val, key) => {
                            return <span key={"key" + key} onClick={() => {
                                filter.page = 1;
                                filter.search = val;
                                setFilter({ ...filter })
                            }}>{val}</span>
                        })
                    }
                    {
                        initData.hotKeyword.length === 0 &&
                        "暂无热搜关键字"
                    }
                </div>
            </div>

            <div className="ctf-resource-table content-container">
                <Tabs
                    activeKey={tabKey}
                    onChange={(key) => { tabChange(key) }}
                >
                    {
                        data?.station?.is_access ?
                            <TabPane tab="招聘岗位" key='1'>
                                {
                                    tabKey === '1' &&
                                    <StationTable
                                        keyword={filter.search}
                                        key={filter.search}
                                    />
                                }
                            </TabPane> : undefined
                    }
                    {
                        data?.enterprise?.is_access ?
                            <TabPane tab="招聘企业" key="2">
                                {
                                    tabKey === '2' &&
                                    <EnterproseTable
                                        keyword={filter.search}
                                        key={filter.search}
                                    />
                                }
                            </TabPane> : undefined
                    }
                </Tabs>
            </div>
        </div>
    )
}
export default List;