import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './js/App';
import * as serviceWorker from './serviceWorker';
import { StoreContext } from 'redux-react-hook';
import { makeStore } from './js/redux/Store';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
const store = makeStore();
ReactDOM.render(
    
    <StoreContext.Provider value={store}> 
        <ConfigProvider locale={zhCN}>
                <App />
        </ConfigProvider>
    </StoreContext.Provider>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
