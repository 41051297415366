import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import CodeHighlighter from 'braft-extensions/dist/code-highlighter';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import styled from 'styled-components';
import history from '../../../Communal/history';
import Added from '../../../../images/added.png';
import AuditStatus from '../../../../images/audit-status.png';
import Denied from '../../../../images/denied.png';
import Pass from '../../../../images/pass.png';
import instance from '../../../Communal/instance';
const { TabPane } = Tabs;
const options = {
    syntaxs: [
        {
            name: 'JavaScript',
            syntax: 'javascript'
        }, {
            name: 'HTML',
            syntax: 'html'
        }, {
            name: 'CSS',
            syntax: 'css'
        }, {
            name: 'Java',
            syntax: 'java',
        }, {
            name: 'PHP',
            syntax: 'php'
        }, {
            name: "Python",
            syntax: 'python'
        }
    ],
}
BraftEditor.use(CodeHighlighter(options))
const Div = styled.div`
    width:100%!important;
    color:#333;
    .bf-content {
        height:auto
    }
    .bf-container .public-DraftEditor-content .braft-code-block-wrapper .braft-code-block{
        max-height:10000px
    }
`
const SceneTaskDetail = ({ param }) => {
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(null));
    const [detail, setDetail] = useState({});
    const [reminderShow, setReminderShow] = useState(false);
    const [activeType, setActiveType] = useState("2");
    const [changeTest, setChangeTest] = useState(0);
    useEffect(() => {
        getDetail()
    }, [])
    const getDetail = () => {
        instance.get(`/scene/api/all/${param.match.params.id}/`).then(res => {
            setEditorState(BraftEditor.createEditorState(res.guidebook))
            setDetail(res);
        })
    }
    return (
        <div className="activity-ctf-detail submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-name submit-ctf-div">
                    <div style={{ display: "inline-block" }} className='ctf-name'>
                        <span onClick={() => history.push('/resource')}>资源中心</span> ＞ <span onClick={() => history.push({ pathname: '/resource/scene', state: { tabKey: 2 } })}>场景任务</span> ＞ {detail.name}
                    </div>
                    {
                        detail.is_show_opin &&
                        <div className="status">
                            {
                                detail.status === 0 &&
                                <img src={AuditStatus} alt="" />
                            }
                            {
                                detail.status === 1 &&
                                <img src={Pass} alt="" />
                            }
                            {
                                detail.status === 2 &&
                                <img src={Added} alt="" />
                            }
                            {
                                detail.status === 3 &&
                                <img src={Denied} alt="" />
                            }
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    detail.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">
                                                <pre style={{ whiteSpace: "pre-line" }}>{val.msg}</pre>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div">

                    <div className="content">
                        {
                            detail.status === 1 &&
                            <div>
                                <span>场景任务编号</span>
                                <div>{detail.number ? detail.number : "暂无编号"}</div>
                            </div>
                        }
                        <div>
                            <span>所属场景</span>
                            <div>{detail.scenename}</div>
                        </div>
                        <div>
                            <span>任务名称</span>
                            <div>{detail.name}</div>
                        </div>
                        <div>
                            <span>用途备注</span>
                            <div>{detail.remarks ? detail.remarks : '暂无备注'}</div>
                        </div>
                        <div>
                            <span>任务描述</span>
                            <div>{detail.taskdesc}</div>
                        </div>
                        <div>
                            <span>任务附件</span>
                            <div>{detail.attached_name ? detail.attached_name : '暂无附件'}</div>
                        </div>
                        <div className="retrieval-div">
                            <Tabs
                                activeKey={activeType}
                                onChange={(key) => {
                                    setActiveType(key);
                                }}
                            >
                                <TabPane
                                    tab='任务指导书'
                                    key='1'
                                >
                                    <Div className="container">
                                        <BraftEditor
                                            value={editorState}
                                            readOnly
                                            controls={[]}
                                        />
                                    </Div>
                                </TabPane>
                                <TabPane
                                    tab='任务测试题'
                                    key='2'
                                >
                                    <div className='content'>
                                        {
                                            (detail.testquestions || []).map((val, key) => {
                                                // if (val.type===1) {
                                                //     return '自测题'
                                                // } else {
                                                //     return 'flag'
                                                // }
                                                return <div className='test-yourself' key={key}>

                                                    {val.type === 1 ?
                                                        <>
                                                            <div className='title'>
                                                                自测题{key + 1}、
                                                                【
                                                                {
                                                                    {
                                                                        0: "单选",
                                                                        1: "多选",
                                                                        2: "判断"
                                                                    }[val.theorytype]
                                                                }
                                                                】
                                                                {val.title}
                                                            </div>
                                                            <div className='option'>
                                                                {
                                                                    Object.keys(val.option).map(k => {
                                                                        return <div key={k}>
                                                                            <span className="circle">
                                                                                {
                                                                                    val.answer.indexOf(k) !== -1 &&
                                                                                    <span></span>
                                                                                }
                                                                            </span>
                                                                            <div>
                                                                                {k}、{val.option[k]}
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                            <div>
                                                                解析：{val.analysis}
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className='title'>
                                                                自测题{key + 1}、【flag测试】
                                                            </div>
                                                            <div className='flag'>
                                                                <div>
                                                                    <span className='title'>flag</span>
                                                                    {val.flag}
                                                                </div>
                                                                <div>
                                                                    <span
                                                                        className='title'>flag说明</span>
                                                                    {val.desc}
                                                                </div>
                                                                <div>
                                                                    <span
                                                                        className='title'>flag分值</span>
                                                                    {val.score}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            })
                                        }
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

}
export default SceneTaskDetail;