/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '../../../css/ctfResource.css';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Table, Input, Pagination, Divider, Dropdown, Menu } from 'antd'
import instance from '../../Communal/instance';
import { Alert } from '../../Communal/utility';
import DeteleBox from '../deteleBox';
import Comfirm from '../comfirm';
import { levelList } from '../../../utils/method';
const { Column } = Table;

function List() {
    const [search, setsSearch] = useState({
        show: false,
        value: ""
    });
    const [initData, setInitData] = useState(
        {
            data: [],
            allCount: 0,
            hotKeyword: [],
            resourceNumber: {}
        }
    );
    const [filter, setFilter] = useState(
        {
            page: 1,
            size: 10,
            search: "",
            classify: [],
            status: [],
            ordering: "",
            username: "",
            level: []
        }
    );
    const [classify, setClassify] = useState([]);
    const [checkList, setCheckList] = useState([]);
    const [sortedInfo, setSortedInfo] = useState({});
    const [userType, setUserType] = useState(0);
    const [userIssue, setIssue] = useState(false);
    const [deleteTip, setDeteleTip] = useState(
        {
            show: false,
            list: []
        }
    )
    const [comfirmShow, setComfirmShow] = useState({
        visible: false,
        mode: 'vuln',
        issue: '',
    })
    let handleTableChange = (pagination, filters, sorter) => {
        if (filters.classify) {
            filter.classify = filters.classify;
        } else {
            filter.classify = [];
        }
        if (filters.level) {
            filter.level = filters.level;
        } else {
            filter.level = [];
        }
        if (filters.status) {
            filter.status = filters.status;
        } else {
            filter.status = [];
        }
        if (filters.resource_level) {
            filter.resource_level = filters.resource_level;
        } else {
            filter.resource_level = [];
        }
        if (filters.issue) {
            filter.issue = filters.issue;
        } else {
            filter.issue = [];
        }
        if (sorter.order) {
            if (sorter.order === "ascend") {
                filter.ordering = "is_env"
            } else {
                filter.ordering = "-is_env"
            }
        } else {
            filter.ordering = ""
        }
        filter.page = 1;
        setFilter({ ...filter });
        setSortedInfo(sorter);
    }
    let handelSearch = () => {
        if (search.value !== filter.search) {
            filter.search = search.value;
            filter.page = 1;
            setFilter({ ...filter })
        }
        search.show = true;
        setsSearch({ ...search });
    }
    let onClicks = (ev) => {
        var e = ev || window.event; //浏览器兼容性
        var elem = e.target || e.srcElement;
        while (elem) {
            //循环判断至跟节点，防止点击的是div子元素
            if (elem.className === "top") {
                return;
            } else {
                elem = elem.parentNode;
            }
        }
        search.show = false;
        setsSearch({ ...search });
    }
    useEffect(() => {
        getCtf()
    }, [filter]);
    //获取热搜词
    useEffect(() => {
        //获取资源数
        resourceNumber();
        instance.get("/ctf/hot_word/", { params: { type: "vuln", size: 5 } }).then(data => {
            initData.hotKeyword = data;
            setInitData({ ...initData })
        })
        instance.get("/ctf/classify/?type=3").then(data => {
            if (data.length > 0) {
                for (var i = 0; i < data.length; i++) {
                    data[i].text = data[i].name;
                    data[i].value = data[i].id
                }
            }
            setClassify(data)
        })
        instance.get("/ctf/user/type/?mode=vuln").then(data => {
            setUserType(data.user_type);
            setIssue(data.issue);
        })
    }, [])
    let resourceNumber = () => {
        instance.get("/ctf/sum/", { params: { type: "vuln" } }).then(data => {
            initData.resourceNumber = data;
            setInitData({ ...initData })
        })
    }
    //获取ctf列表
    let getCtf = () => {
        instance.get("/vuln/", { params: filter }).then(data => {
            initData.data = data.results;
            initData.allCount = data.count;
            setInitData({ ...initData })
        })
    }
    //单个删除或者批量删除操作
    let resourceDelete = (list) => {
        if (list.length === 0) {
            Alert("warn", "请选择需要删除的漏洞");
            return
        }
        instance.post("/vuln/deletion/", { id: list }).then(data => {
            if (filter.page > 1 && (initData.data.length === list.length)) {
                filter.page -= 1;
                setFilter({ ...filter })
            } else {
                getCtf();
            }
            Alert("success", "删除成功");
            setCheckList([]);
            deleteTip.list = [];
            deleteTip.show = false;
            setDeteleTip({ ...deleteTip })
            resourceNumber();
        })
    }
    //搜索回车事件
    let calAge = (e) => {
        var evt = window.event || e;
        if (evt.keyCode === 13) {
            handelSearch();
        }
    }
    let exportVuln = (type) => {
        instance.post(`/vuln/export/?type=${type}`, { id_list: checkList }).then(res => {
            if (res.status === 1) {
                const link = document.createElement("a"); // 创建a标签
                link.href = res.file_path;
                link.download = "导出漏洞.zip"
                link.click();
            }
            if (res.status === 2) {
                Alert('error', res.message)
            }
        }).catch(err => {
            if (err.error) {
                Alert('error', err.error)
            }
        })
    }
    const menu = (
        <Menu onClick={({ key }) => { changeStatus(key) }}>
            <Menu.Item key={1}>回收</Menu.Item>
            <Menu.Item key={2}>发布</Menu.Item>
        </Menu>
    );
    const changeStatus = (key) => {
        setComfirmShow({ ...comfirmShow, visible: true, issue: Number(key) });
    }
    const handleIssue = () => {
        setCheckList([]);
        setFilter({ ...filter });
    }
    return (
        <div className="ctf-resource-list vuln-resource-list" onClick={onClicks}>
            <div className="banner"></div>
            <div className="ctf-resource-search">
                <div className="top">
                    {
                        search.show ?
                            <div className="search-input">
                                <input
                                    className="input-fouce"
                                    type="text"
                                    placeholder="请输入关键字进行搜索"
                                    value={search.value}
                                    onKeyDown={(e) => calAge(e)}
                                    onChange={(e) => {
                                        search.value = e.target.value;
                                        setsSearch({ ...search })
                                    }}
                                />
                            </div>
                            : <div className="statistics">
                                <div>
                                    <span>{initData.resourceNumber.day}</span>
                                    <span>今日新增</span>
                                </div>
                                <div>
                                    <span>{initData.resourceNumber.month}</span>
                                    <span>本月新增</span>
                                </div>
                                <div>
                                    <span>{initData.resourceNumber.sum}</span>
                                    <span>总资源数</span>
                                </div>
                            </div>
                    }
                    <div className="search-btn" onClick={() => handelSearch()}>
                        <SearchOutlined />
                    </div>
                </div>
                <div className="bottom">
                    今天热点搜索：
                    {
                        initData.hotKeyword.map((val, key) => {
                            return <span key={"key" + key} onClick={() => {
                                filter.page = 1;
                                filter.search = val;
                                setFilter({ ...filter })
                            }}>{val}</span>
                        })
                    }
                    {
                        initData.hotKeyword.length === 0 &&
                        "暂无热搜关键字"
                    }
                </div>
            </div>
            <div className="ctf-resource-table content-container">
                <div className="handle">
                    {
                        userType > 1 ?
                            <div style={{ display: 'inline-block' }}>
                                <Button
                                    type='ghost'
                                    disabled={checkList.length === 0 ? true : false}
                                    danger
                                    onClick={() => {
                                        if (checkList.length > 0) {
                                            deleteTip.show = true;
                                            deleteTip.list = checkList;
                                            setDeteleTip({ ...deleteTip })
                                        } else {
                                            Alert("warn", "请选择需要删除的漏洞");
                                        }
                                    }}
                                >
                                    批量删除
                                </Button>
                                {
                                    userIssue ?
                                        <Dropdown
                                            overlay={menu}
                                            disabled={checkList.length === 0}
                                        >
                                            <Button type="primary" style={{ marginLeft: '15px' }}>批量修改发布状态<DownOutlined /></Button>
                                        </Dropdown> : undefined
                                }
                                <Button
                                    type='primary'
                                    style={{ marginLeft: '15px' }}
                                    disabled={checkList.length === 0 ? true : false}
                                    onClick={() => exportVuln(1)}
                                >
                                    批量导出
                                </Button>
                                <Button
                                    type="primary"
                                    style={{ marginLeft: '15px' }}
                                    onClick={() => exportVuln(2)}
                                >
                                    全部导出
                                </Button>
                            </div> : undefined
                    }
                    {
                        userType > 0 ?
                            <Button
                                type="primary"
                                onClick={() => window.open("/#/activity/submitvuln/0/0/0")}
                            >
                                提交漏洞
                            </Button> : undefined
                    }
                </div>
                <Table
                    dataSource={initData.data}
                    rowKey={record => record.id}
                    bordered
                    pagination={false}
                    scroll={{ x: 1200 }}
                    rowSelection={userType > 1 && {
                        type: "checkbox",
                        selectedRowKeys: checkList,
                        preserveSelectedRowKeys:true,
                        onChange: ((selectedRowKeys) => {
                            let select = [...checkList];
                            (initData.data || []).forEach(x => {
                                select = select.filter(m => m !== x.id)
                            })
                            setCheckList(select.concat(selectedRowKeys))
                        })
                    }}
                    onChange={handleTableChange}
                >
                    <Column
                        title="序号"
                        key="serial"
                        align="center"
                        width={80}
                        fixed='left'
                        render={(text, record, index) => {
                            return <span
                                className="click-href"
                                onClick={() => {
                                    window.location.href = "/#/activity/vulndetail/0/" + record.id
                                }}
                            >
                                {filter.size * (filter.page - 1) + index + 1}
                            </span>
                        }}
                    />
                    <Column
                        title="漏洞编号"
                        key="number"
                        align="center"
                        dataIndex="number"
                        width={150}
                        fixed='left'
                        render={(text, record) => {
                            return text ? <span
                                className="click-href"
                                onClick={() => {
                                    window.location.href = "/#/activity/vulndetail/0/" + record.id
                                }}
                            >
                                {text}
                            </span>
                                : "暂无编号"
                        }}
                    />
                    <Column
                        title="漏洞名称"
                        key="name"
                        align="center"
                        dataIndex="name"
                        width={250}
                        fixed='left'
                        render={(text, record) => {
                            return <a
                                className="click-href"
                                href={"/#/activity/vulndetail/0/" + record.id}
                            >
                                {text}
                            </a>
                        }}
                    />
                    <Column
                        title="漏洞等级"
                        key="level"
                        align="center"
                        dataIndex="level"
                        render={(record) => {
                            return <span style={record === 1 ? { color: "#ff4747" } : record === 2 ? { color: "#ff6600" } : record === 3 ? { color: "#ffc000" } : { color: "#00a8ff" }}>
                                {
                                    {
                                        1: "高危",
                                        2: "中危",
                                        3: "低危",
                                        4: "信息"
                                    }[record]
                                }
                            </span>
                        }}
                        width={150}
                        filters={
                            [
                                { value: 1, text: "高危" },
                                { value: 2, text: "中危" },
                                { value: 3, text: "低危" },
                                { value: 4, text: "信息" }
                            ]
                        }
                    />
                    <Column
                        title="漏洞分类"
                        key="classify"
                        align="center"
                        dataIndex="classify"
                        filters={classify}
                        width={150}
                    />
                    <Column
                        title="资源等级"
                        key="resource_level"
                        align="center"
                        dataIndex="resource_level"
                        width={150}
                        filters={levelList}
                    />
                    <Column
                        title="环境"
                        key="is_env"
                        align="center"
                        dataIndex="is_env"
                        sorter="true"
                        sortOrder={sortedInfo.columnKey === 'is_env' && sortedInfo.order}
                        render={(record) => {
                            return record ? "有" : "否"
                        }}
                        width={150}
                    />
                    <Column
                        title="审核状态"
                        key="status"
                        align="center"
                        dataIndex="status"
                        width={150}
                        filters={
                            [
                                { text: "待审核", value: "0" },
                                { text: "通过", value: "1" },
                                { text: "待补充", value: "2" },
                                { text: "拒绝", value: "3" }
                            ]
                        }
                        render={(record) => {
                            return <span className="audit-status">
                                {
                                    record === 0 && "待审核"
                                }
                                {
                                    record === 1 && <span className="pass">通过</span>
                                }
                                {
                                    record === 2 && <span className="added">待补充</span>
                                }
                                {
                                    record === 3 && <span className="denied">拒绝</span>
                                }
                            </span>
                        }}
                    />
                    <Column
                        title="发布状态"
                        key="issue"
                        align="center"
                        dataIndex="issue"
                        filterMultiple={false}
                        width={150}
                        filters={
                            [
                                { text: "未发布", value: 1 },
                                { text: "已发布", value: 2 },
                            ]
                        }
                        render={(record) => {
                            return <span className="audit-status">
                                {
                                    record === 1 && <span className="added">未发布</span>
                                }
                                {
                                    record === 2 && <span className="pass">已发布</span>
                                }
                            </span>
                        }}
                    />
                    <Column
                        title="漏洞关键字"
                        key="keyword"
                        align="center"
                        dataIndex="keyword"
                        width={200}
                        render={(record) => {
                            return <div className="table-keyword" title={record}>
                                {record.map((val, key) => {
                                    return <span className="keyword" key={key + "key"}>{val}</span>
                                })}
                            </div>
                        }}
                    />
                    <Column
                        title="提交者"
                        key="username"
                        align="center"
                        dataIndex="username"
                        width={150}
                        filterDropdown={({ clearFilters }) => {
                            return <div className="table-search">
                                <Input
                                    placeholder="请输入提交者名称"
                                    value={filter.username}
                                    onChange={(e) => {
                                        filter.username = e.target.value;
                                        filter.page = 1;
                                        setFilter({ ...filter })
                                    }}
                                />
                                <Button type="primary" onClick={() => { clearFilters() }}>确定</Button>
                                <Button onClick={() => {
                                    clearFilters();
                                    filter.username = "";
                                    filter.page = 1;
                                    setFilter({ ...filter })
                                }}>取消</Button>
                            </div>
                        }}
                        filterIcon={<SearchOutlined />}
                    />
                    <Column
                        title="提交时间"
                        key="sub_time"
                        align="center"
                        dataIndex="sub_time"
                        width={250}
                    />
                    <Column
                        title="操作"
                        key="operate"
                        align="center"
                        width={userType > 1 ? userIssue ? 260 : 210 : 160}
                        fixed="right"
                        render={(record) => {
                            return <div>
                                <Button
                                    style={{ padding: 0 }}
                                    type="link"
                                    onClick={() => {
                                        window.location.href = "/#/activity/vulndetail/0/" + record.id
                                    }}
                                >
                                    详情
                                </Button>
                                <Divider type='vertical' />
                                <Button
                                    style={{ padding: 0 }}
                                    disabled={!record.change}
                                    type="link"
                                    onClick={() => {
                                        window.location.href = "/#/activity/submitvuln/0/1/" + record.id
                                    }}
                                >
                                    编辑
                                </Button>
                                <Divider type='vertical' />
                                {
                                    userType > 1 ? <>
                                        <Button
                                            style={{ padding: 0 }}
                                            type="link"
                                            onClick={() => {
                                                window.open("/#/activity/submitvuln/0/2/" + record.id)
                                            }}
                                        >
                                            审核
                                        </Button>
                                        <Divider type='vertical' />
                                    </> : undefined
                                }
                                {
                                    userIssue ? <>
                                        {
                                            record.issue === 1 ?
                                                <Button
                                                    style={{ padding: 0 }}
                                                    disabled={record.status !== 1}
                                                    type="link"
                                                    onClick={() => {
                                                        setComfirmShow({ ...comfirmShow, visible: true, issue: 2 });
                                                        setCheckList([record.id])
                                                    }}
                                                >
                                                    发布
                                                </Button> :
                                                <Button
                                                    style={{ padding: 0 }}
                                                    type="link"
                                                    danger
                                                    onClick={() => {
                                                        setComfirmShow({ ...comfirmShow, visible: true, issue: 1 });
                                                        setCheckList([record.id])
                                                    }}
                                                >
                                                    回收
                                                </Button>
                                        }
                                        <Divider type='vertical' />
                                    </> : undefined
                                }
                                <Button
                                    type="link"
                                    disabled={!record.change}
                                    style={{ padding: 0 }}
                                    danger
                                    onClick={() => {
                                        deleteTip.show = true;
                                        deleteTip.list = [record.id];
                                        setDeteleTip({ ...deleteTip })
                                    }}
                                >删除</Button>
                            </div>
                        }}
                    />
                </Table>
                <Pagination
                    showQuickJumper={true}
                    current={filter.page}
                    pageSize={filter.size}
                    total={initData.allCount}
                    hideOnSinglePage={initData.allCount === 0}
                    pageSizeOptions={['10', '20', '50', '100']}
                    showSizeChanger={true}
                    className="flip-over"
                    style={{ margin: "30px 0" }}
                    onChange={
                        ((page,pageSize) => {
                            let data = { ...filter }
                            data.page = page;
                            data.size = pageSize;
                            setFilter(data)
                        })
                    }
                />
            </div>
            {
                deleteTip.show &&
                <DeteleBox
                    close={() => {
                        deleteTip.show = false;
                        deleteTip.list = [];
                        setDeteleTip({ ...deleteTip })
                    }}
                    submit={() => resourceDelete(deleteTip.list)}
                />
            }
            <Comfirm
                boxProperty={comfirmShow}
                onClose={() => {
                    setComfirmShow({ ...comfirmShow, visible: false })
                }}
                onOk={handleIssue}
                ids={checkList}
            />
        </div>
    )
}
export default List;