/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import '../../../css/submitCtf.css';
import '../../../css/box.css';
import { Button, Input, Progress, Select, Tag } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import instance from '../../Communal/instance';
import Retrieval from '../../Activity/retrieval';
import { Alert, srcUrl } from '../../Communal/utility';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import PassBox from '../../Activity/PassBox';
import DeclineBox from '../../Activity/DeclineBox';
import AddedBox from '../../Activity/AddedBox';
import Denied from '../../../images/denied.png';
import { ContentUtils } from 'braft-utils'
import $ from 'jquery';
const { Option } = Select;
function SubmitCTF(param) {
    //id===0管理员 type: 0 添加 1 编辑 2 审核
    const dateRef = useRef()
    const [type, setType] = useState("0")
    const [detail, setDetail] = useState({})
    const [submit, setSubmit] = useState(
        {
            name: '',
            desc: '',
            tags: [],
            classify: null,
            source: '',
            content: BraftEditor.createEditorState(null),
            attached: null,
            attached_name: '',
            resource_level: 4,
        }
    )
    const [error, setError] = useState(
        {
            name: [],
            desc: [],
            tags: [],
            classify: [],
            source: [],
            attached: [],
            content: [],
            resource_level: []
        }
    )
    const [imagesType, setImagesType] = useState(0);
    const [isSubmit, setIsSubmit] = useState(true);
    const [retrievalBox, setRetrievalBox] = useState({
        show: false,
        count: 0
    });
    const [tags, setTags] = useState('')
    const [knowClassify, setKnowClassify] = useState([])
    const [attachedShow, setAttachedShow] = useState(false)
    const [attachedPercent, setAttachedPercent] = useState(0)
    const [initBox, setInitBox] = useState(
        {
            passBox: false,
            declineBox: false,
            addedBox: false
        }
    )
    useEffect(() => {
        setType(param.param.match.params.type);
    }, [param.param.match.params.type])
    useEffect(() => {
        if (param.param.match.params.type !== "0") {
            // 知识详情
            instance.get("/knowledge/" + param.param.match.params.id + "/").then(data => {
                submit.name = data.name;
                submit.desc = data.intro;
                submit.tags = data.tags;
                submit.classify = data.classify;
                submit.source = data.source;
                submit.content = BraftEditor.createEditorState(data.content);
                submit.attached_name = data.attached_name;
                submit.resource_level = data.resource_level;
                setSubmit({ ...submit });
                getClassify();
                setDetail(data);
            })
        } else {
            getClassify();
            instance.get("/media/resource/WPTemplate/knowledge/template.txt").then(data => {
                submit.content = BraftEditor.createEditorState(data);
                setSubmit({ ...submit });
            })
        }
    }, [])
    let myUploadFn = (param) => {
        const fd = new FormData();
        fd.append('image', param.file);
        fd.append("model", "experiment");
        instance.post("/ctf/upload_image/", fd).then(data => {
            param.success({
                url: data.url,
            })
        })
    }
    // 获取知识类型
    let getClassify = () => {
        instance.get("/ctf/classify/?type=10").then(data => {
            submit.classify = data[0]?.id
            setKnowClassify(data)
        })
    }
    // 表单元素改变
    const inputChange = (value, name) => {
        submit[name] = value;
        setSubmit({ ...submit })
    }

    // 查重
    const retrievalClick = (text) => {
        if (submit.name.trim() === '') {
            Alert('warn', '请输入知识名称进行查重')
            return
        }
        let list = {
            type: 'knowledge',
            flag: text,
            search_type: type
        }
        instance.get(`/knowledge/check/?name=${submit.name}`).then(data => {
            retrievalBox.count = data.length
            retrievalBox.show = true
            setRetrievalBox({ ...retrievalBox })
        })

    }
    // 回车添加知识标签
    const onPressEnter = (e) => {
        if (e.target.value.replace(/(^\s*)|(\s*$)/g, "") === "") {
            return
        }
        var count = 0;
        if (submit.tags.length < 5) {
            if (submit.tags.length > 0) {
                for (var i = 0; i < submit.tags.length; i++) {
                    if (submit.tags[i] !== e.target.value) {
                        count++
                    }
                }
                if (count === submit.tags.length) {
                    submit.tags.push(e.target.value)
                }
            } else {
                submit.tags.push(e.target.value)
            }
            setTags("");
            setSubmit({ ...submit })
            handleFocus("tags")
        }
    }
    const tagsChange = (e) => {
        setTags(e.target.value.replace(",", ""));
    }
    // 删除标签
    const deleteChange = (e, key) => {
        e.preventDefault();
        submit.tags.splice(key, 1)
        setSubmit({ ...submit })
    }
    // 插入图片到媒体库
    const addMediaItem = () => {
        document.getElementsByClassName("images")[0].click();
        document.getElementsByClassName("images")[0].value = "";
        setImagesType(0);
    }
    // 插入图片到编辑器
    const insertMediItem = () => {
        document.getElementsByClassName("images")[0].click();
        document.getElementsByClassName("images")[0].value = "";
        setImagesType(1);
    }
    // 上传图片
    const changeImage = () => {
        const file = document.getElementsByClassName("images")[0].files[0];
        if (file !== undefined) {
            if (file.size > 5 * 1024 * 1024) {
                Alert("error", "上传图片不能大于5M")
                return
            }
            const form = document.getElementById('file-image');
            const formData = new FormData(form);
            formData.append("model", "knowledge");
            instance.post("/ctf/upload_image/", formData).then(data => {
                if (imagesType === 0) {
                    if (dateRef.current !== undefined) {
                        let braftFinder = dateRef.current.getFinderInstance();
                        braftFinder.addItems([
                            {
                                id: new Date().getTime(),
                                type: 'IMAGE',
                                url: data.url
                            }
                        ])
                    }
                } else if (imagesType === 1) {
                    const editorState = ContentUtils.insertMedias(submit.content, [
                        {
                            type: 'IMAGE',
                            url: data.url
                        }
                    ])
                    submit.content = editorState;
                    setSubmit({ ...submit })
                }
                Alert("success", "上传成功")
            }).catch(err => {
                if (err.image) {
                    Alert("error", err.image)
                }
            })
        }
    }
    // 点击选择触发上传
    const uploadChange = () => {
        if (attachedShow === true) {
            Alert('warn', '有文件正在上传中，请稍候')
            return
        }
        document.getElementsByClassName("upload-file-one")[0].click();
        document.getElementsByClassName("upload-file-one")[0].value = "";
        submit.attached = null;
        submit.attached_name = '';
        error.attached = [];
        setSubmit({ ...submit })
        setError({ ...error })
    }
    // 上传附件
    const changeFile = () => {
        let file = document.getElementsByClassName("upload-file-one")[0].files[0];
        if (file !== undefined) {
            if (file.size > 1024 * 1024 * 500) {
                Alert("error", "上传附件不能大于500M");
                return
            }
            let form = document.getElementById('formImg1');
            let formData = new FormData(form);
            formData.append("model", "knowledge")
            setAttachedShow(true)
            $.ajax({
                url: "/ctf/upload_file/",
                type: "POST",
                data: formData,
                contentType: false,
                processData: false,
                xhr: () => {
                    var myXhr = $.ajaxSettings.xhr()
                    if (myXhr.upload) {
                        myXhr.upload.addEventListener('progress', (e) => {
                            var per = Math.floor(100 * e.loaded / e.total);  //已经上传的百分比
                            setAttachedPercent(per)
                            if (myXhr.readyState === 1 && per === 100) {
                                setAttachedPercent(99)
                            }
                        }, false);
                    }
                    return myXhr;
                },
                success: (data) => {
                    setAttachedShow(false)
                    setAttachedPercent(0)
                    submit.attached_name = data.origin_file_name;
                    submit.attached = data.url
                    setSubmit({ ...submit })
                },
                error: (err) => {
                    setAttachedShow(false)
                    setAttachedPercent(0)
                    Alert('warn', err.responseJSON.non_field_errors[0])
                }
            })
        }
    }
    // 清除错误提示
    const handleFocus = (name) => {
        error[name] = []
        setError({ ...error })
    }
    // 提交
    const commitResources = (actionType) => {
        if (srcUrl(submit.content.toHTML())) {
            Alert('warn', '知识内容中图片存在外部链接，请检查！！！')
            return
        }
        if (!isSubmit) {
            Alert("warn", "正在提交数据，请稍后！")
            return
        }
        // 传入接口的数据
        const list = {
            name: submit.name,
            intro: submit.desc,
            tags: submit.tags,
            classify: submit.classify,
            source: submit.source,
            content: submit.content.toHTML(),
            attached: submit.attached,
            attached_name: submit.attached_name,
            resource_level: submit.resource_level
        }
        if (submit.name === '') {
            error.name = ["请输入题目标题"]
        }
        if (submit.desc.trim() === '') {
            error.desc = ['请填写知识简介']
        }
        if (submit.tags.length === 0) {
            error.tags = ['请填写知识标签']
        }
        if (submit.classify === undefined) {
            error.classify = ['请选择知识分类']
        }
        if (submit.content.toHTML() === '<p></p>') {
            error.content = ['请填写知识内容']
        }
        if (submit.resource_level !== 0 && !submit.resource_level) {
            error.resource_level = ['请填写资源等级']
        }
        if (
            submit.name.trim() === "" ||
            submit.desc.trim() === "" ||
            submit.classify === undefined ||
            submit.content.toHTML() === '<p></p>' ||
            submit.tags.length === 0 ||
            submit.resource_level !== 0 && !submit.resource_level
        ) {
            Alert('warn', '提交内容有误，请检查！')
            setError({ ...error })
            return
        }
        if (attachedShow) {
            Alert("warn", "有文件正在上传中，请稍候");
            return
        }
        setIsSubmit(false)
        // 提交
        if (actionType === 1) {
            instance.post("/knowledge/add/", list).then(data => {
                Alert("success", "提交成功");
                setIsSubmit(true);
                instance.get("/media/resource/WPTemplate/knowledge/template.txt").then(data => {
                    setSubmit(
                        {
                            name: '',
                            desc: '',
                            tags: [],
                            classify: knowClassify[0].id || undefined,
                            source: '',
                            content: BraftEditor.createEditorState(data),
                            attached: null,
                            attached_name: '',
                            resource_level: 4
                        }
                    )
                })

            }).catch(err => {
                errorInfo(err);
                setIsSubmit(true);
            })
        }
        // 编辑 审核保存修改
        else if (actionType === 2) {
            instance.patch("/knowledge/" + param.param.match.params.id + "/", list).then(data => {
                Alert("success", "提交成功");
                setIsSubmit(true);
            }).catch((err) => {
                errorInfo(err);
                setIsSubmit(true);
            })
        }
    }
    // 接口错误提示
    const errorInfo = (err) => {
        if (err.name) {
            error.name = err.name
        }
        if (err.content) {
            error.content = err.content
        }
        if (err.source) {
            error.source = err.source
        }
        if (err.classify) {
            error.classify = err.classify
        }
        if (err.resource_level) {
            error.resource_level = err.resource_level
        }
        if (err.tags) {
            error.tags = err.tags
        }
        if (err.desc) {
            error.desc = err.desc
        }
        Alert('warn', '提交内容有误，请检查！')
        setError({ ...error })
    }
    // 点击审核
    const handleAudit = (data) => {
        if (data.status === 1) {
            if (data.data === "") {
                Alert("error", "请输入奖励数");
                return
            }
            // if(data.data<0){
            //     Alert("error","奖励数不可超过1000")
            //     return
            // }
        }
        if ((data.status === 2 || data.status === 3) && data.data === "") {
            Alert("error", "请输入审核意见");
            return
        }
        instance.post("/knowledge/" + param.param.match.params.id + "/audit/", data).then(data => {
            Alert("success", "操作成功");
            window.location.href = "/#/resource/knowledge"
        }).catch(err => {
            Alert("error", err.error)
        })
    }
    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className='submit-ctf-div submit-ctf-name'>
                    {
                        type === "0" && "提交知识"
                    }
                    {
                        type === "1" && "编辑知识"
                    }
                    {
                        type === "2" && "审核知识"
                    }
                    {
                        detail.status === 3 &&
                        <div className="status">
                            <img src={Denied} alt="" />
                        </div>
                    }
                </div>
                <div className="submit-ctf-div subject-info">
                    <div className="title">
                        知识信息
                    </div>
                    <div className="content">
                        <div className="retrieval-div">
                            <span><b>*</b>知识名称</span>
                            <Input
                                value={submit.name}
                                className="content-input"
                                placeholder="请输入知识名称"
                                onChange={(e) => inputChange(e.target.value, "name")}
                                maxLength={100}
                                onFocus={() => handleFocus("name")}
                            />
                            <span className="number">
                                {100 - submit.name.length}
                            </span>
                            <Button
                                type="primary"
                                onClick={() => retrievalClick(submit.name)}
                            >
                                查重
                            </Button>
                            <div className="error-message">{error.name[0]}</div>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>知识简述</span>
                            <Input
                                value={submit.desc}
                                className="content-input"
                                placeholder="对知识进行简单描述"
                                onChange={(e) => inputChange(e.target.value, "desc")}
                                maxLength={180}
                                onFocus={() => handleFocus("desc")}
                            />
                            <span className="number">
                                {180 - submit.desc.length}
                            </span>
                            <div className="error-message">{error.desc[0]}</div>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>知识标签</span>
                            <Input
                                value={tags}
                                className="content-input tags-input"
                                placeholder="题目关键字，回车添加关键字"
                                onPressEnter={onPressEnter}
                                onChange={tagsChange}
                                maxLength={16}
                            />
                            <span className="tags-number number">
                                {16 - tags.length}
                            </span>
                            <span className="prompt-words number">
                                添加标签（{5 - submit.tags.length}）
                            </span>
                            <div className="error-message">{error.tags[0]}</div>
                        </div>
                        {
                            submit.tags.map((value, key) => {
                                return <Tag
                                    key={"key" + key}
                                    className={'tags' + key}
                                    closable={true}
                                    onClose={(e) => deleteChange(e, key)}
                                >
                                    {value}
                                </Tag>
                            })
                        }
                        <div className='retrieval-div'>
                            <span><b>*</b>知识分类</span>
                            <Select
                                style={{ width: 187 }}
                                value={submit.classify}
                                placeholder='请选择知识分类'
                                onChange={(value) => inputChange(value, 'classify')}
                                onFocus={() => handleFocus("classify")}
                            >
                                {
                                    knowClassify.map((val, key) => {
                                        return <Option value={val.id} key={key}>
                                            {val.name}
                                        </Option>
                                    })
                                }
                            </Select>
                            <div className="error-message">{error.classify[0]}</div>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>资源等级</span>
                            <Input
                                value={submit.resource_level}
                                className="content-input"
                                placeholder="请输入资源等级"
                                onChange={(e) => inputChange(e.target.value, "resource_level")}
                                onFocus={() => handleFocus("resource_level")}
                                maxLength={200}
                            />
                            <div className="error-message">{error.resource_level[0]}</div>
                        </div>

                        <div className="retrieval-div">
                            <span>知识来源</span>
                            <Input
                                value={submit.source}
                                className="content-input"
                                placeholder="请输入知识来源域名(www.example.com)"
                                onChange={(e) => inputChange(e.target.value, "source")}
                                onFocus={() => handleFocus("source")}
                                maxLength={200}
                            />
                            <div className="error-message">{error.source[0]}</div>
                        </div>
                    </div>

                </div>
                <div className="submit-ctf-div topic-answer">
                    <div className="title">
                        知识内容
                    </div>
                    <div>
                        <span><b>*</b>知识内容</span>
                        <div className="my-component">
                            <BraftEditor
                                value={submit.content}
                                onChange={(editorState) => inputChange(editorState, 'content')}
                                onFocus={() => handleFocus("content")}
                                ref={dateRef}
                                media={{
                                    uploadFn: myUploadFn,
                                    image: false
                                }}
                                excludeControls={["superscript", "subscript", "emoji", "remove-styles"]}
                                extendControls={[
                                    'separator',
                                    {
                                        key: 'add-media',
                                        type: 'button',
                                        text: '插入图片到媒体库',
                                        onClick: () => addMediaItem()
                                    },
                                    {
                                        key: 'insert-media',
                                        type: 'button',
                                        text: '插入图片到编辑器',
                                        onClick: () => insertMediItem()
                                    }
                                ]}
                            />
                            <div
                                className="error-message"
                                style={{ margin: "0" }}
                            >
                                {error.content[0]}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="submit-ctf-div subject-upload">
                    <div className="title">
                        上传附件
                    </div>
                    <div className="content">
                        <div>
                            <span>上传附件</span>
                            <form id="formImg1">
                                <div>
                                    <span className="upload" onClick={uploadChange}>
                                        选择
                                        <input
                                            type="file"
                                            name="file"
                                            className="upload-file-one"
                                            accept=".zip,"
                                            onChange={changeFile}
                                        />
                                    </span>
                                    （仅支持ZIP格式）
                                    {
                                        submit.attached && submit.attached !== "" && [
                                            <span className="file-name" key="1">
                                                <a
                                                    href={submit.attached}
                                                    download
                                                >
                                                    <PaperClipOutlined />
                                                    {submit.attached_name}
                                                </a>
                                            </span>,
                                            <Button
                                                type="link"
                                                key="2"
                                                danger
                                                onClick={() => {
                                                    submit.attached_name = "";
                                                    submit.attached = "";
                                                    setSubmit({ ...submit })
                                                }}>
                                                删除
                                            </Button>
                                        ]
                                    }
                                    {
                                        attachedShow &&
                                        <>
                                            <Progress percent={attachedPercent} />
                                            <span> （上传中，请稍候）</span>
                                        </>
                                    }
                                </div>
                            </form>
                            <div
                                style={{ marginLeft: "140px" }}
                                className="error-message">
                                {error.attached[0]}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    // 提交
                    type === "0" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => commitResources(1)}
                            loading={!isSubmit}
                        >
                            提交
                        </Button>
                    </div>
                }
                {
                    // 编辑
                    type === "1" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => commitResources(2)}
                        >
                            提交
                        </Button>
                    </div>
                }
                {
                    // 审核
                    type === "2" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => {
                                initBox.passBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            通过
                        </Button>
                        <Button
                            style={{ backgroundColor: "transparent", color: "#266cff" }}
                            type="primary"
                            onClick={() => window.location.href = "/#/resource/knowledge"}
                        >
                            取消
                        </Button>
                        <Button
                            className="modify"
                            onClick={() => commitResources(2)}
                        >
                            保存修改
                        </Button>
                        <Button
                            className="decline"
                            onClick={() => {
                                initBox.declineBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            拒绝
                        </Button>
                        <Button
                            className="added"
                            onClick={() => {
                                initBox.addedBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            待补充
                        </Button>
                        <Button
                            className="added"
                            onClick={() => handleAudit({ status: 0 })}
                        >
                            待审核
                        </Button>
                    </div>
                }
            </div>
            {
                retrievalBox.show &&
                <Retrieval
                    close={() => {
                        retrievalBox.show = false;
                        setRetrievalBox({ ...retrievalBox })
                    }}
                    count={retrievalBox.count}
                    name={"知识名称"}
                />
            }
            {
                initBox.passBox &&
                <PassBox
                    close={() => {
                        initBox.passBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.declineBox &&
                <DeclineBox
                    close={() => {
                        initBox.declineBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.addedBox &&
                <AddedBox
                    close={() => {
                        initBox.addedBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            <form id="file-image">
                <input
                    type="file"
                    name="image"
                    accept=".jpg,.png,.gif"
                    style={{ display: "none" }}
                    className="images"
                    onChange={changeImage}
                />
            </form>
        </div>
    )
}
export default SubmitCTF;