import React, { useState, useEffect } from 'react';
import history from '../../../Communal/history';
import Reminder from '../../../Communal/reminder';
import Added from '../../../../images/added.png';
import AuditStatus from '../../../../images/audit-status.png';
import Denied from '../../../../images/denied.png';
import Pass from '../../../../images/pass.png';
import instance from '../../../Communal/instance';
import { Tag } from 'antd';

const ThreatenDetail = ({ param }) => {
    const [detail, setDetail] = useState({});
    const [reminderShow, setReminderShow] = useState(false);
    useEffect(() => {
        getDetail(param.match.params.id)
    }, [param])
    const getDetail = (id) => {
        instance.get(`/threat_point/${id}/`).then(res => {
            setDetail(res);
        })
    }
    return (
        <div className="activity-ctf-detail submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-name submit-ctf-div">
                    <div style={{ display: "inline-block" }} className='ctf-name'>
                        <span onClick={() => history.push('/resource')}>资源中心</span> ＞ <span onClick={() => history.push({ pathname: '/resource/sceneelement', state: { tabKey: 2 } })}>威胁点资源</span> ＞ {detail.name}
                    </div>
                    {
                        detail.is_show_opin &&
                        <div className="status">
                            {
                                detail.status === 0 &&
                                <img src={AuditStatus} alt="" />
                            }
                            {
                                detail.status === 1 &&
                                <img src={Pass} alt="" />
                            }
                            {
                                detail.status === 2 &&
                                <img src={Added} alt="" />
                            }
                            {
                                detail.status === 3 &&
                                <img src={Denied} alt="" />
                            }
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    detail.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">
                                                <pre style={{ whiteSpace: "pre-line" }}>{val.msg}</pre>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div">
                    <div className="award">
                        <div>
                            <span>{detail.coin}</span>
                            <span>奖励</span>
                        </div>
                        <p onClick={() => setReminderShow(true)}>赚取更多奖励＞</p>
                    </div>
                    <div className="title">威胁点信息</div>
                    <div className="content">
                        {
                            detail.status === 1 &&
                            <div>
                                <span>威胁点编号</span>
                                <div>{detail.number ? detail.number : "暂无编号"}</div>
                            </div>
                        }
                        <div>
                            <span>威胁点名称</span>
                            <div>{detail.name}</div>
                        </div>
                        <div>
                            <span>威胁点分类</span>
                            <div>{detail.classify_name}</div>
                        </div>
                        <div>
                            <span>威胁点等级</span>
                            <div>
                                {
                                    {
                                        0: '低危',
                                        1: '中危',
                                        2: '高危'
                                    }[detail.level]
                                }
                            </div>
                        </div>
                        <div>
                            <span>威胁点标签</span>
                            <div>
                                {
                                    (detail.tags_name || []).map((val, key) => {
                                        return <Tag key={key} color="green">
                                            {val}
                                        </Tag>
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            <span>关联场景对象</span>
                            <div>
                                {
                                    (detail.scene_objs_list || []).map((val, key) => {
                                        return <Tag key={key} color="green">
                                            {val.name}
                                        </Tag>
                                    })
                                }
                                {
                                    (detail.scene_objs_list && detail.scene_objs_list.length === 0) && '未关联场景对象'
                                }
                            </div>
                        </div>
                        <div>
                            <span>威胁点描述</span>
                            <div style={{ paddingTop: "9px", lineHeight: "25px" }}>
                                <pre style={{ whiteSpace: "pre-wrap" }}>{detail.desc}</pre>
                            </div>
                        </div>
                        {
                            detail.status === 1 && [
                                <div key="2">
                                    <span>提交用户</span>
                                    <div>
                                        {detail.username}
                                    </div>
                                </div>,
                                <div key="3">
                                    <span>提交时间</span>
                                    <div>
                                        {detail.sub_time}
                                    </div>
                                </div>
                            ]
                        }
                    </div>
                </div>
            </div>
            {
                reminderShow &&
                <Reminder
                    close={() => setReminderShow(false)}
                />
            }
        </div>
    )
}
export default ThreatenDetail;