//资源中心
import React from 'react';
import { Route } from 'react-router-dom';
import Home from './home';
import CtfList from './CTF/CtfList';
import ExpList from './Exp/ExpList';
import VulnList from './Vuln/VulnList';
import ToolsList from './Tool/ToolsList';
import TestList from './Test/TestList';
import AWDList from './Awd/AWDList';
import MethodResource from './MethodResource';
import Knowledge from './Knowledge/KnowledgeList'
import RecruitList from './Recruit'
import ThreatResources from './ThreatResources';
import VulnInfo from './VulnInfo';
import Scene from './Scene';
import Rule from './Rule';
import '../../css/resource.css';

function Index() {
	return (
		<div>
			<Route exact path="/resource" component={(param) => {
				return <Home />
			}} />
			<Route path="/resource/ctf" component={(param) => {
				return <CtfList />
			}} />
			<Route path="/resource/exp" component={(param) => {
				return <ExpList />
			}} />
			<Route path="/resource/vuln" component={(param) => {
				return <VulnList />
			}} />
			<Route path="/resource/tool" component={(param) => {
				return <ToolsList />
			}} />
			<Route path="/resource/test" component={(param) => {
				return <TestList />
			}} />
			<Route path="/resource/awd" component={(param) => {
				return <AWDList />
			}} />
			<Route path="/resource/sceneelement" component={(param) => {
				return <ThreatResources />
			}} />
			<Route path="/resource/methods" component={(param) => {
				return <MethodResource />
			}} />
			<Route path="/resource/knowledge" component={(param) => {
				return <Knowledge />
			}} />
			<Route path="/resource/recruit" component={(param) => {
				return <RecruitList />
			}} />
			<Route path="/resource/vulninfo" component={(param) => {
				return <VulnInfo />
			}} />
			<Route path="/resource/scene" component={(param) => {
				return <Scene />
			}} />
			<Route path="/resource/rule" component={(param) => {
				return <Rule />
			}} />
		</div>
	);
}

export default Index;
