//头部
/* eslint-disable react-hooks/exhaustive-deps */
import React ,{useEffect,useState,useCallback}from 'react';
import history from '../Communal/history';
import Logo from '../../images/collage-logo-big.png';
import realmName from '../../json/realmName.json';
import {goLogin, Alert} from '../Communal/utility';
import {useMappedState,useDispatch } from "redux-react-hook";
import {Avatar} from 'antd';
import instance from '../Communal/instance';
import Config from '../../json/config.json';
function Header() {
	const [pathname,setPathname]=useState("");
	// 拿到公共的值---实时变化
    const mapstate = useCallback((state) => ({
		login:state.login,
		userInfo:state.userInfo
	}),[]);
	//获取数据并订阅存储
	const loginInfo= useMappedState(mapstate);
	const dispatch = useDispatch();
	useEffect(()=>{
		window.addEventListener('load', refresh);
		window.addEventListener('hashchange', refresh);
		instance.get("/users/status/").then(data=>{
			dispatch({
				type: "SET_LOGIN",
				login: data.status
			})
			if(data.status){
				instance.get("/users/user_info/").then(detail=>{
					dispatch({
						type: "SET_USERINFO",
						userInfo: detail
					})
					if(!Config.login){
						instance.get(realmName.sso + "/server_app/custom/is_login/",{params:{user_uuid:detail.user_uuid}}).then(res=>{
							if(res.code===2){
								loginout(); 
							}
						})	
					}
				})
			}
		})
	},[])
	let refresh=()=>{
		setPathname(history.location.pathname);
	}
	//sso退出
	let handleLogout=()=>{
		if(!Config.login){
			instance.get(realmName.sso + "/server_app/logout/",{params:{uuid:loginInfo.userInfo.user_uuid}}).then(data=>{
				loginout();
			})
		}else{
			loginout();
		}
	}
	//当前平台退出
	let loginout=()=>{
		instance.get("/users/logout/").then(data=>{
			Alert("success","登出成功");
			window.location.href="/#/";
			dispatch({
				type: "SET_LOGIN",
				login: false
			})
			dispatch({
				type: "SET_USERINFO",
				userInfo: {}
			})
		})
	}
	return (
		<div className="platform-header">
            <div className="logo">
				<img src={Logo} alt=""/>
			</div>
			<div className="nav">
				<a
					href="/#/"
					className={pathname==="/"?"active":""}
				>
					首页
					<b></b>
				</a>
				<a
					href="/#/activity"
					className={pathname==="/activity"?"active":""}
				>
					活动中心
					<b></b>
				</a>
				<a
					href="/#/resource"
					className={pathname==="/resource"?"active":""}
				>
					资源中心
					<b></b>
				</a>
				<a
					href="https://www.doosec.com/#/shop"
					target="_blank"
					rel="noopener noreferrer"
				>
					兑换商城
					<b></b>
				</a>
			</div>
			<div className="login-btn">
				{
					loginInfo.login?
					<div className="has-login">
						<Avatar src={loginInfo.userInfo.photo}/>	
						<div className="drop-down">
							<span onClick={()=>window.location.href="/#/personal/1"}>个人中心</span>
							<span onClick={handleLogout}>退出</span>
						</div>			
					</div>
					:<div className="not-login">
						<span onClick={()=>goLogin()}>登录</span>
						<b></b>
						<span onClick={()=>window.open(realmName.sso + "/server_app/#/other/first/0")}>注册</span>
					</div>
				}
			</div>
		</div>
	);
}

export default Header;
