/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import BraftEditor from 'braft-editor';
import Cao from '../../../images/cao.png';
import HuiCao from '../../../images/huicao.png';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import CodeHighlighter from 'braft-extensions/dist/code-highlighter';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import instance from '../../Communal/instance';
import Added from '../../../images/added.png';
import AuditStatus from '../../../images/audit-status.png';
import Denied from '../../../images/denied.png';
import Pass from '../../../images/pass.png';
import BuyCtf from '../../Activity/buyCtf';
import { Button } from 'antd';
import Reminder from '../../Communal/reminder';
import styled from 'styled-components';
import { useMappedState } from "redux-react-hook";

const Div = styled.div`
    width:100%!important;
    color:#333;
    .bf-content {
        height:auto
    }
    .bf-container .public-DraftEditor-content .braft-code-block-wrapper .braft-code-block{
        max-height:10000px
    }
`

const options = {
    syntaxs: [
        {
            name: 'JavaScript',
            syntax: 'javascript'
        }, {
            name: 'HTML',
            syntax: 'html'
        }, {
            name: 'CSS',
            syntax: 'css'
        }, {
            name: 'Java',
            syntax: 'java',
        }, {
            name: 'PHP',
            syntax: 'php'
        }, {
            name: "Python",
            syntax: 'python'
        }
    ],
}
BraftEditor.use(CodeHighlighter(options))
function CtfDetail(param) {
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(null));
    const [detail, setDetail] = useState({});
    const [buyCtf, setBuyCtf] = useState(false);
    const [reminderShow, setReminderShow] = useState(false)
    const [userType, setUserType] = useState(0);
    useEffect(() => {
        getDetail();
        instance.get("/ctf/user/type/?mode=ctf").then(data => {
            setUserType(data.user_type)
        })
    }, []);
    let getDetail = () => {
        instance.get("/ctf/" + param.param.match.params.id + "/").then(data => {
            setDetail(data)
            setEditorState(BraftEditor.createEditorState(data.answer))
        })
    }
    // 拿到公共的值---实时变化
    const mapstate = useCallback((state) => ({
        userInfo: state.userInfo
    }), []);
    //获取数据并订阅存储
    const loginInfo = useMappedState(mapstate);
    let Level = (level) => {
        let str = ``;
        for (var i = 0; i < level; i++) {
            str += `<img src=${Cao} alt=""/>`
        }
        for (var j = level; j < 5; j++) {
            str += `<img src=${HuiCao} alt=""/>`
        }
        return str
    }
    return (
        <div className="activity-ctf-detail submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-name submit-ctf-div">
                    {
                        param.param.match.params.where === "0" &&
                        <div style={{ display: "inline-block" }} className='ctf-name'>
                            <span onClick={() => window.location.href = "/#/resource"}>资源中心</span> ＞ <span onClick={() => window.location.href = "/#/resource/ctf"}>CTF题目资源库</span> ＞ {detail.name}
                        </div>
                    }
                    {
                        param.param.match.params.where === "1" &&
                        <div style={{ display: "inline-block" }} className='ctf-name'>
                            <span onClick={() => window.location.href = "/#/activity"}>活动中心</span> ＞ {detail.name}
                        </div>
                    }
                    {
                        param.param.match.params.where === "2" &&
                        <div style={{ display: "inline-block" }} className='ctf-name'>
                            <span onClick={() => window.location.href = "/#/personal/7"}>个人中心</span> ＞ 已购买CTF资源 ＞ {detail.name}
                        </div>
                    }
                    {
                        detail.is_show_opin &&
                        <div className="status">
                            {
                                detail.status === 0 &&
                                <img src={AuditStatus} alt="" />
                            }
                            {
                                detail.status === 1 &&
                                <img src={Pass} alt="" />
                            }
                            {
                                detail.status === 2 &&
                                <img src={Added} alt="" />
                            }
                            {
                                detail.status === 3 &&
                                <img src={Denied} alt="" />
                            }
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    detail.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">
                                                <pre style={{ whiteSpace: "pre-line" }}>{val.msg}</pre>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div">
                    <div className="award">
                        <div>
                            <span>{detail.coin}</span>
                            <span>奖励</span>
                        </div>
                        <p onClick={() => setReminderShow(true)}>赚取更多奖励＞</p>
                    </div>
                    <div className="title">题目信息</div>
                    <div className="content">
                        {
                            detail.status === 1 &&
                            <div>
                                <span>题目编号</span>
                                <div>{detail.number ? detail.number : "暂无编号"}</div>
                            </div>
                        }
                        <div>
                            <span>题目标题</span>
                            <div>{detail.name}</div>
                        </div>
                        <div>
                            <span>题目内容</span>
                            <div style={{ paddingTop: "9px", lineHeight: "25px" }}>
                                <pre style={{ whiteSpace: "pre-wrap" }}>{detail.desc}</pre>
                            </div>
                        </div>
                        <div>
                            <span>题目分类</span>
                            <div>{detail.classify_name}</div>
                        </div>
                        <div>
                            <span>题目标签</span>
                            <div className="tag">
                                {
                                    detail.keyword_list &&
                                    detail.keyword_list.map((val, key) => {
                                        return <span key={"key" + key}>{val}</span>
                                    })
                                }

                            </div>
                        </div>
                        <div>
                            <span>资源等级</span>
                            <div>{detail.resource_level}</div>
                        </div>
                        <div>
                            <span>题目备注</span>
                            <div>{detail.remark || '暂无'}</div>
                        </div>
                        {
                            detail.is_buy && [
                                <div key="1">
                                    <span>Flag类型</span>
                                    <div>
                                        {detail.flag_type === 1 ? "静态Flag" : "动态Flag"}
                                    </div>
                                </div>,
                                <div key="2">
                                    <span>题目Flag</span>
                                    <div>
                                        {detail.flag}
                                    </div>
                                </div>
                            ]
                        }
                        {
                            detail.is_buy && detail.flag_type === 2 &&
                            <div>
                                <span>Flag脚本</span>
                                <div>
                                    {detail.flag_script}
                                </div>
                            </div>
                        }
                        {
                            detail.is_buy && [
                                <div key="1">
                                    <span>题目分值</span>
                                    <div>
                                        {detail.score}
                                    </div>
                                </div>,
                                <div key="2">
                                    <span>题目等级</span>
                                    <div className="grade" dangerouslySetInnerHTML={{ __html: Level(detail.level) }}></div>
                                </div>
                            ]
                        }
                        {
                            (detail.status !== 1 && detail.is_buy) ?
                                <div key="2">
                                    <span>题目类型</span>
                                    <div>
                                        {detail.test_type === 1 ? "环境型" : "附件型"}
                                    </div>
                                </div> :
                                <div key="2">
                                    <span>{detail.test_type === 1 ? "环境编号" : "题目类型"}</span>
                                    <div>
                                        {detail.test_type === 1 ? detail.number : "附件型"}
                                    </div>
                                </div>
                        }
                        {
                            detail.status === 1 && [
                                <div key="2">
                                    <span>提交用户</span>
                                    <div>
                                        {detail.username}
                                    </div>
                                </div>,
                                <div key="3">
                                    <span>提交时间</span>
                                    <div>
                                        {detail.sub_time}
                                    </div>
                                </div>
                            ]
                        }
                    </div>
                    <div className="title">
                        题目来源
                        {
                            !detail.is_buy &&
                            <span>购买后可查看</span>
                        }
                    </div>
                    {
                        detail.is_buy &&
                        <div className="content">
                            <div>
                                <span>题目来源</span>
                                <div>
                                    {/* <a href={detail.source} target="_blank" rel="noopener noreferrer">{detail.source}</a> */}
                                    {detail.source}
                                </div>
                            </div>
                        </div>
                    }
                    <div className="title">
                        题目解答
                        {
                            !detail.is_buy &&
                            <span>购买后可查看</span>
                        }
                        {
                            userType > 1 &&
                            <a
                                download
                                href={"/ctf/" + param.param.match.params.id + "/export/?type=1"}
                            >
                                导出
                            </a>
                        }
                    </div>
                    {
                        detail.is_buy &&
                        <div className="content">
                            <div>
                                <Div className="container">
                                    <BraftEditor
                                        value={editorState}
                                        readOnly
                                        controls={[]}
                                    />
                                </Div>
                            </div>
                        </div>
                    }
                    {
                        detail.is_buy &&
                        <div className="title">
                            题目附件
                        </div>
                    }
                    {
                        !detail.is_buy &&
                        <div className="title">
                            题目附件
                            {
                                !detail.is_buy &&
                                <span>购买后可查看</span>
                            }
                        </div>
                    }
                    {
                        detail.is_buy &&
                        <div className="content">
                            <div>
                                <span>题目附件</span>
                                <div>
                                    {detail.attached ? <a href={detail.attached} download>{detail.attached_name}</a> : "暂无附件"}
                                </div>
                            </div>
                            <div>
                                <span>其他附件</span>
                                <div>
                                    {detail.other_attached ? <a href={detail.other_attached} download>{detail.other_attached_name}</a> : "暂无附件"}
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {
                    !detail.is_buy &&
                    <div className="buy-button">
                        <Button type="primary" onClick={() => setBuyCtf(true)}>购买</Button>
                    </div>
                }
            </div>
            {
                buyCtf &&
                <BuyCtf
                    coin={detail.coin}
                    url={"/ctf/" + detail.id + "/buy/"}
                    close={() => setBuyCtf(false)}
                    getDetail={getDetail}
                />
            }
            {
                reminderShow &&
                <Reminder
                    close={() => setReminderShow(false)}
                />
            }
        </div>
    )
}
export default CtfDetail;