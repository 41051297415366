import React, { useEffect, useState } from 'react';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import instance from '../../../Communal/instance';
import BuyCtf from '../../../Activity/buyCtf';
import { Row, Col, Tabs, Empty, Button } from 'antd';
import styled from 'styled-components';
import '../index.less';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism'

const { TabPane } = Tabs;
const Div = styled.div`
    width:100%!important;
    color:#333;
    .bf-content {
        height:auto
    }
    .bf-container .public-DraftEditor-content .braft-code-block-wrapper .braft-code-block{
        max-height:10000px
    }
`
const Detail = ({ param }) => {
    const [detail, setDetail] = useState({});
    const [recommend, setRecommend] = useState({});
    const [buyCtf, setBuyCtf] = useState(false);
    useEffect(() => {
        if (param.match.params.id !== '0') {
            getDetail(param.match.params.id)
        }
    }, [param]);

    const getDetail = (id) => {
        instance.get(`/vulninte/api/all/${id}/`).then(res => {
            res.vulexp = (res.vulexp === '<p></p>' || !res.vulexp) ? null : BraftEditor.createEditorState(res.vulexp);
            res.vulpoc = (res.vulpoc === '<p></p>' || !res.vulpoc) ? null : BraftEditor.createEditorState(res.vulpoc);
            res.analysis = (res.analysis === '<p></p>' || !res.analysis) ? null : BraftEditor.createEditorState(res.analysis);
            setDetail(res);
        })
        instance.get(`/vulninte/api/vulninte/${id}/`).then(res => {
            setRecommend(res)
        })
    }
    return (
        <div className="activity-ctf-detail submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <Row>
                    <Col span={18}>
                        <div className="submit-ctf-name submit-ctf-div">
                            <div style={{ display: "inline-block" }} className='ctf-name'>
                                <span
                                    onClick={() => window.location.href = "/#/resource"}
                                >
                                    资源中心
                                </span>
                                &nbsp;＞&nbsp;
                                <span
                                    onClick={() => window.location.href = "/#/resource/vulninfo"}
                                >
                                    漏洞情报库
                                </span>
                                &nbsp;＞&nbsp;
                                {detail.name}
                            </div>
                        </div>
                        <div className="submit-ctf-div">
                            <div className="title">漏洞情报信息</div>
                            <div className="content">
                                <div>
                                    <span>漏洞编号</span>
                                    <div>{detail.number}</div>
                                </div>
                                <div>
                                    <span>漏洞名称</span>
                                    <div>{detail.name}</div>
                                </div>
                                <Row>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>CVE编号</span>
                                            <div className='detail'>{detail.cve ? detail.cve : '--'}</div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>CNVD编号</span>
                                            <div className='detail'>{detail.cnvd ? detail.cnvd : '--'}</div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>CNNVD编号</span>
                                            <div className='detail'> {detail.cnnvd ? detail.cnnvd : '--'}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <div>
                                    <span>漏洞简介</span>
                                    <div style={{ paddingTop: "9px", lineHeight: "25px" }}>
                                        <pre style={{ whiteSpace: "pre-wrap" }}>
                                            {detail.vulnbrief}
                                        </pre>
                                    </div>
                                </div>
                                <Row>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>漏洞披露时间</span>
                                            <div className='detail'>{detail.published}</div>
                                        </div>

                                    </Col>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>漏洞更新时间</span>
                                            <div className='detail'>{detail.modified ? detail.modified : '--'}</div>
                                        </div>

                                    </Col>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>漏洞级别</span>
                                            <div className='detail'>
                                                {
                                                    {
                                                        0: '超危',
                                                        1: '高危',
                                                        2: '中危',
                                                        3: '低危',
                                                        4: '未定义'
                                                    }[detail.severity]
                                                }
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <div>
                                            <span className='name'>CVSS2.0评分</span>
                                            <div className='detail'>{detail.cvss2 ? detail.cvss2 : '--'}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <div>
                                            <span className='name'>CVSS3.0评分</span>
                                            <div className='detail'>{detail.cvss3 ? detail.cvss3 : '--'}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>2.0基本评分</span>
                                            <div className='detail'>{detail.cvss20 ? detail.cvss20 : '--'}</div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>3.0基本评分</span>
                                            <div className='detail'>{detail.cvss30 ? detail.cvss30 : '--'}</div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>漏洞类型</span>
                                            <div className='detail'>{detail.vulntype ? detail.vulntype : '--'}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>组件厂商</span>
                                            <div className='detail'>{detail?.module?.company ? detail?.module?.company : '--'}</div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>影响组件</span>
                                            <div className='detail'>{detail?.module?.module_name ? detail?.module?.module_name : '--'}</div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>是否0day</span>
                                            <div className='detail'>{detail?.is_day
                                                ? '是' : '否'}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>提交时间</span>
                                            <div className='detail'>{detail?.sub_time || '--'}</div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>提交用户</span>
                                            <div className='detail'>{detail?.username}</div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>更新用户</span>
                                            <div className='detail'>{detail?.update_user || '--'}</div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={8}>
                                        <div>
                                            <span className='name'>更新时间</span>
                                            <div className='detail'>{detail?.update_time || '--'}</div>
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <div>
                                            <span className='name'>查看等级</span>
                                            <div className='detail'>{detail?.resource_level}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <div>
                                            <span className='name'>情报附件</span>
                                            <div className='detail'>  {detail.attached ? <a href={detail.attached} download>{detail.attached_name}</a> : "暂无附件"}</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div style={{ padding: '0px 15px', background: '#fff', marginTop: '20px' }}>
                            <Tabs>
                                <TabPane tab='情报信息' key='1'>
                                    <div className='submit-ctf-div subject-info'>
                                        <div className='content'>
                                            <div>
                                                <span>影响产品</span>
                                                <div style={{ paddingTop: "9px", lineHeight: "25px" }}>
                                                    <pre style={{ whiteSpace: "pre-wrap" }}>
                                                        {detail.ref ? detail.ref : '--'}
                                                    </pre>
                                                </div>
                                            </div>
                                            <div>
                                                <span>漏洞CPE</span>
                                                <div style={{ paddingTop: "9px", lineHeight: "25px" }}>
                                                    <pre style={{ whiteSpace: "pre-wrap" }}>
                                                        {detail.cpe ? detail.cpe : '--'}
                                                    </pre>
                                                </div>
                                            </div>
                                            <div>
                                                <span>漏洞解决方案</span>
                                                <div style={{ paddingTop: "9px", lineHeight: "25px" }}>
                                                    <pre style={{ whiteSpace: "pre-wrap" }}>
                                                        {detail.vulnsolution ? detail.vulnsolution : '--'}
                                                    </pre>
                                                </div>
                                            </div>
                                            <div>
                                                <span>漏洞参考链接</span>
                                                <div style={{ paddingTop: "9px", lineHeight: "25px" }}>
                                                    <pre style={{ whiteSpace: "pre-wrap" }}>
                                                        {detail.refurls ? detail.refurls : '--'}
                                                    </pre>
                                                </div>
                                            </div>
                                            <div>
                                                <span>漏洞验证地址</span>
                                                <div style={{ paddingTop: "9px", lineHeight: "25px" }}>
                                                    <pre style={{ whiteSpace: "pre-wrap" }}>
                                                        {detail.verify ? detail.verify : '--'}
                                                    </pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                {
                                    detail?.vulpoc &&
                                    <TabPane tab='漏洞POC' key='2'>
                                        <div className='submit-ctf-div subject-info'>
                                            <div className='content'>
                                                <div>
                                                    <Div className="container">
                                                        <BraftEditor
                                                            value={detail.vulpoc}
                                                            readOnly
                                                            controls={[]}
                                                        />
                                                    </Div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                }
                                {
                                    detail?.vulexp &&
                                    <TabPane tab='漏洞EXP' key='3'>
                                        <div className='submit-ctf-div subject-info'>
                                            <div className='content'>
                                                <div>
                                                    <Div className="container">
                                                        <BraftEditor
                                                            value={detail.vulexp}
                                                            readOnly
                                                            controls={[]}
                                                        />
                                                    </Div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                }
                                {
                                    detail?.analysis &&
                                    <TabPane tab='分析过程' key='4'>
                                        <div className='submit-ctf-div subject-info'>
                                            <div className='content'>
                                                <div>
                                                    <Div className="container">
                                                        <BraftEditor
                                                            value={detail.analysis}
                                                            readOnly
                                                            controls={[]}
                                                        />
                                                    </Div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                }
                                {
                                    (detail?.plug === false || detail?.plug === '')
                                        ?
                                        undefined
                                        :
                                        <TabPane tab='检测插件' key='5'>
                                            <div className='submit-ctf-div subject-info'>
                                                <div className='content'>
                                                    <div className='plug-content'>
                                                        {!detail.is_buy ? <span style={{ color: 'red' }}>购买后可查看</span> : <SyntaxHighlighter
                                                            children={String(detail?.plug).replace(/\n$/, '')}
                                                            style={tomorrow}
                                                            language="javascript"
                                                            showLineNumbers
                                                        />}
                                                    </div>

                                                </div>
                                                {
                                                    !detail.is_buy &&
                                                    <div className="buy-button">
                                                        <Button type="primary" onClick={() => setBuyCtf(true)}>购买</Button>
                                                    </div>
                                                }
                                            </div>
                                        </TabPane>
                                }
                                {
                                    JSON.stringify(detail?.rules) !== '{}' &&
                                    <TabPane tab='检测规则' key='6'>
                                        <div className='submit-ctf-div subject-info'>
                                            <div className='content'>
                                                {
                                                    Object.keys(detail?.rules || {}).map((item, key) => {
                                                        return <div key={key}>
                                                            <span>规则{key + 1}</span>
                                                            <div style={{ paddingTop: "9px", lineHeight: "25px" }}>
                                                                <pre style={{ whiteSpace: "pre-wrap" }}>
                                                                    {detail?.rules[item]}
                                                                </pre>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </TabPane>
                                }
                            </Tabs>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className='related-vulnerability'>
                            <div className='related-title'>
                                相关漏洞
                            </div>
                            <div className='related-content'>
                                {
                                    (recommend?.name || []).map((val, key) => {
                                        return <div className='vuln-info' key={key}>
                                            <div className='vuln-name' onClick={() => {
                                                window.location.href = "/#/activity/vulninfodetail/" + val.id
                                            }}>{val.name}</div>
                                            <div className='vuln-time'>
                                                {
                                                    val.published ?
                                                        <span>披露时间：{val.published}</span> : undefined
                                                }
                                                {
                                                    val.modified ? <span className='right'>更新时间：{val.modified}</span> : undefined
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                                {
                                    (recommend?.name || []).length === 0 &&
                                    <Empty />
                                }
                            </div>
                        </div>
                        <div className='related-vulnerability'>
                            <div className='related-title'>
                                同类型漏洞
                            </div>
                            <div className='related-content'>
                                {
                                    (recommend?.vulntype || []).map((val, key) => {
                                        return <div className='vuln-info' key={key}>
                                            <div className='vuln-name' onClick={() => {
                                                window.location.href = "/#/activity/vulninfodetail/" + val.id
                                            }}>{val.name}</div>
                                            <div className='vuln-time'>
                                                {
                                                    val.published ?
                                                        <span>披露时间：{val.published}</span> : undefined
                                                }
                                                {
                                                    val.modified ? <span className='right'>更新时间：{val.modified}</span> : undefined
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                                {
                                    (recommend?.vulntype || []).length === 0 &&
                                    <Empty />
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {
                buyCtf &&
                <BuyCtf
                    coin={detail.plug_coin}
                    url={`/vulninte/api/buy/${detail.plug_id}/`}
                    close={() => setBuyCtf(false)}
                    getDetail={() => getDetail(param.match.params.id)}
                />
            }
        </div>
    )
}
export default Detail