import React, { useState, useEffect } from 'react';
import { Button, Table, Pagination } from 'antd'
import { Alert } from '../../../Communal/utility';
import instance from '../../../Communal/instance';
import DeteleBox from '../../deteleBox';
import DetailBox from './operationLog';

const OperationLog = ({ keyword }) => {
    const [initData, setInitData] = useState({
        data: [],
        allCount: 0
    });
    const [checkList, setCheckList] = useState([]);
    const [filter, setFilter] = useState({
        page: 1,
        size: 10,
        search: keyword,
        username: "",
    });
    const [deleteTip, setDeleteTip] = useState({
        show: false,
        list: []
    })
    const [detailTip, setDetailTip] = useState({
        show: false,
        detail: {}
    })
    const [allDelete, setAllDelete] = useState(false)

    useEffect(() => {
        tableData()
    }, [filter])

    useEffect(() => {
        return () => {
            setFilter({
                page: 1,
                size: 10,
                search: keyword,
                username: "",
            })
        }
    }, [])

    const getDetail = (id) => {
        instance.get(`/rules/thelog/${id}/`).then(data => {
            detailTip.detail = data;
            detailTip.show = true
            setDetailTip({ ...detailTip })
        })
    }


    const tableData = () => {
        instance.get('/rules/thelog/', { params: filter }).then(data => {
            if (Array.isArray(data.results)) {
                initData.data = data.results;
            }
            initData.allCount = data.count;
            setInitData({ ...initData })
        })
    }

    const resourceDelete = (list) => {
        let param = {}
        if (!allDelete) {
            param = { ids: list }
        }
        instance.post("/rules/thelog/", param).then(data => {
            if (data.status === 1) {
                Alert("success", data.msg)
            }
            if (initData.allCount === list.length || allDelete) {
                filter.page = 1;
                setFilter({ ...filter })
            }
            else if (filter.page > 1 && (initData.data.length === param?.ids.length)) {
                filter.page -= 1;
                setFilter({ ...filter })
            }
            tableData();
            setCheckList([]);
            deleteTip.list = [];
            deleteTip.show = false;
            setDeleteTip({ ...deleteTip })
        })
    }

    const handleTableChange = () => {

    }

    const rowSelection = {
        selectedRowKeys: checkList,
        preserveSelectedRowKeys:true,
        onChange: (selectedRowKeys, selectedRows) => {
            deleteTip.list = selectedRowKeys
            setDeleteTip({ ...deleteTip })
            setCheckList(selectedRowKeys)
        },
        getCheckboxProps: (record) => {
            if (record.on_delete === false) {
                return { disabled: true }
            } else {
                return null;
            }
        }
    }
    const columns = [
        {
            title: '序号',
            key: 'id',
            width: 70,
            fixed: 'left',
            align: 'center',
            // 递增序号
            render: (text, record, index) => (
                <span
                    className="click-href"
                // onClick={() => {
                //     window.location.href = "/#/activity/scenetaskdetail/" + record.id
                // }}
                >
                    {filter.size * (filter.page - 1) + index + 1}
                </span>
            )
        }, {
            title: '类型',
            align: 'center',
            key: 'type',
            dataIndex: 'type',
            width: 100,
            fixed: 'left',
            render: (text) => {
                return {
                    0: "分类模块",
                    1: "外部规则",
                    2: "内部规则",
                }[text]
            }
        }, {
            title: '动作',
            align: 'center',
            key: 'operation',
            dataIndex: 'operation',
            width: 100,
            fixed: 'left',
            render: (text) => {
                return {
                    1: "添加",
                    2: "编辑",
                    3: "删除",
                    4: "审核",
                    5: "归档",
                }[text]
            }
        }, {
            title: '修改内容',
            key: 'content',
            dataIndex: 'content',
            width: 300,
            align: 'center',
            ellipsis: true,
        }, {
            title: '操作人',
            key: 'username',
            dataIndex: 'username',
            width: 100,
            align: 'center',
            ellipsis: true,
        }, {
            title: '操作时间',
            key: 'sub_time',
            dataIndex: 'sub_time',
            width: 150,
            align: 'center',
            fixed: 'right'
        }, {
            title: '操作',
            key: 'operate',
            dataIndex: 'operate',
            align: 'center',
            width: 100,
            fixed: 'right',
            render: (text, record) => {
                return <div className="table-operation">
                    <Button
                        type="link"
                        onClick={() => {
                            getDetail(record.id)
                        }}
                    >
                        详情
                    </Button>
                </div>
            }
        }
    ]
    return (
        <div className="ctf-resource-list" style={{ padding: 0 }}>
            <div className="ctf-resource-table content-container" style={{ marginTop: 0 }}>
                {
                    // loginInfo.userInfo.role_type === 1 &&
                    <div className="handle">
                        <div style={{ display: 'inline-block' }}>
                            <Button
                                type='ghost'
                                disabled={checkList.length === 0 ? true : false}
                                danger
                                onClick={() => {
                                    setAllDelete(false)
                                    deleteTip.show = true;
                                    deleteTip.list = checkList;
                                    setDeleteTip({ ...deleteTip })
                                }}
                            >
                                批量删除
                            </Button>
                            <Button
                                type='primary'
                                style={{ marginLeft: '15px' }}
                                danger
                                disabled={initData.allCount === 0}
                                onClick={() => {
                                    setAllDelete(true)
                                    deleteTip.show = true;
                                    deleteTip.list = [];
                                    setDeleteTip({ ...deleteTip })
                                }}
                            >
                                全部清空
                            </Button>
                        </div>
                    </div>
                }
                <Table
                    dataSource={initData.data}
                    rowKey={record => record.id}
                    bordered
                    rowSelection={rowSelection}
                    onChange={handleTableChange}
                    columns={columns}
                    pagination={false}
                />
                <Pagination
                    showQuickJumper={true}
                    current={filter.page}
                    pageSize={filter.size}
                    total={initData.allCount}
                    hideOnSinglePage={initData.allCount === 0}
                    pageSizeOptions={['10', '20', '50', '100']}
                    showSizeChanger={true}
                    className="flip-over"
                    style={{ margin: "30px 0" }}
                    onChange={
                        ((page,pageSize) => {
                            let data = { ...filter }
                            data.page = page;
                            data.size = pageSize;
                            setFilter(data)
                        })
                    }
                />
            </div>
            {
                deleteTip.show &&
                <DeteleBox
                    close={() => {
                        deleteTip.show = false;
                        deleteTip.list = [];
                        setDeleteTip({ ...deleteTip })
                    }}
                    title='操作日志'
                    submit={() => resourceDelete(deleteTip.list)}
                />
            }
            <DetailBox
                close={() => {
                    detailTip.show = false;
                    deleteTip.detail = {};
                    setDetailTip({ ...deleteTip })
                }}
                detailTip={detailTip}
            />
        </div>
    )
}
export default OperationLog