//个人中心\
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from 'react';
import '../../css/personal.css';
import { Avatar } from 'antd'
import { useMappedState, useDispatch } from "redux-react-hook";
import Info from './info';
import TextArea from 'antd/lib/input/TextArea';
import CtfSubmit from './ctfSubmit';
import VulnSubmit from './vulnSubmit';
import TestSubmit from './testSubmit';
import ToolSubmit from './toolSubmit';
import PlugSubmit from './plugSubmit';
import HaveBought from './haveBought';
import TestTopicSubmit from './testTopicSubmit';
import AWDSubmit from './awdSubmit';
import instance from '../Communal/instance';
import { Alert } from '../Communal/utility';
function Index(param) {
	useEffect(() => {
		document.body.style.backgroundColor = "#f4f4f4";
		getRank();
		return () => {
			document.body.style.backgroundColor = "#fff"
		}
	}, [])
	// 拿到公共的值---实时变化
	const mapstate = useCallback((state) => ({
		login: state.login,
		userInfo: state.userInfo
	}), []);
	//获取数据并订阅存储
	const loginInfo = useMappedState(mapstate);
	const [type, setType] = useState(1);
	const [sign, setSign] = useState(
		{
			name: "",
			show: false
		}
	)
	useEffect(() => {
		let type = Number(param.param.match.params.type);
		if (type !== 0) {
			setType(type)
		}
	}, [param])
	useEffect(() => {
		sign.name = loginInfo.userInfo.signature;
		setSign({ ...sign })
	}, [loginInfo])
	const [initData, setInitData] = useState(
		{
			coin: 0,
			rank: 0,
			resource_count: 0,
			ctf_count: 0,
			vuln_count: 0,
			experiment_count: 0,
			tool_count: 0,
			plugin_count: 0,
			test_count: 0,
		}
	)
	const dispatch = useDispatch();
	//获取个人排名
	let getRank = () => {
		instance.get("/users/rank/").then(data => {
			setInitData({ ...data })
		})
	}
	//编辑个性签名
	let editSign = (param) => {
		instance.patch("/users/change/", param).then(data => {
			Alert("success", "修改成功");
			sign.show = false;
			setSign({ ...sign });
			instance.get("/users/user_info/").then(detail => {
				dispatch({
					type: "SET_USERINFO",
					userInfo: detail
				})
			})
		}).catch(err => {
			if (err.signature) {
				Alert("error", err.signature[0])
			}
			if (err.photo) {
				Alert("error", err.photo[0])
			}
			if (err.realname) {
				Alert("error", err.realname[0])
			} if (err.error) {
				Alert("error", err.error)
			}
		})
	}
	return (
		<div className="platform-personal">
			<div className="banner"></div>
			<div className="content-container personal-content">
				<div className="personal-content-left">
					<div className="personal-information">
						<div>
							<Avatar src={loginInfo.userInfo.photo} size={80} />
						</div>
						<div>{loginInfo.userInfo.username}</div>
						<div>
							{
								!sign.show ?
									<div onDoubleClick={() => {
										sign.show = true;
										setSign({ ...sign })
									}}>
										{sign.name ? sign.name : "这个人比较懒，什么都没留下！"}
									</div> :
									<TextArea
										value={sign.name}
										onChange={(e) => {
											sign.name = e.target.value;
											setSign({ ...sign })
										}}
										onPressEnter={() => editSign({ signature: sign.name })}
									/>
							}

						</div>
						<div>
							<div>
								<b>{initData.coin}</b>
								<span>奖励</span>
							</div>
							<b></b>
							<div>
								<b>{initData.rank}</b>
								<span>富人榜</span>
							</div>
							<b></b>
							<div>
								<b>{initData.resource_count}</b>
								<span>提交数</span>
							</div>
						</div>
					</div>
					<div className="platform-personal-nav">
						<div
							className={type === 1 ? "single-nav active" : "single-nav"}
							style={{ borderBottom: "1px solid #f4f4f4" }}
							onClick={() => {
								window.location.href = "/#/personal/1"
							}}
						>
							<b></b>
							资料与账号
						</div>
						<div
							className={type === 2 ? "active" : ""}
							onClick={() => {
								window.location.href = "/#/personal/2"
							}}
						>
							<b></b>
							提交CTF题目
							<span>{initData.ctf_count}</span>
						</div>
						<div
							className={type === 3 ? "active" : ""}
							onClick={() => {
								window.location.href = "/#/personal/3"
							}}
						>
							<b></b>
							提交漏洞
							<span>{initData.vuln_count}</span>
						</div>
						<div
							className={type === 4 ? "active" : ""}
							onClick={() => {
								window.location.href = "/#/personal/4"
							}}
						>
							<b></b>
							提交实验
							<span>{initData.experiment_count}</span>
						</div>
						<div
							className={type === 5 ? "active" : ""}
							onClick={() => {
								window.location.href = "/#/personal/5"
							}}
						>
							<b></b>
							提交工具
							<span>{initData.tool_count}</span>
						</div>
						<div
							className={type === 8 ? "active" : ""}
							onClick={() => {
								window.location.href = "/#/personal/8"
							}}
						>
							<b></b>
							提交理论题目
							<span>{initData.test_count || 0}</span>
						</div>
						<div
							className={type === 9 ? "active" : ""}
							onClick={() => {
								window.location.href = "/#/personal/9"
							}}
						>
							<b></b>
							提交AWD题目
							<span>{initData.awd_count || 0}</span>
						</div>
						{/* <div
							className={type===6?"active":""}
							onClick={()=>{
								setType(6)
							}}
						>
							<b></b>
							提交插件
							<span>{initData.plugin_count}</span>
						</div> */}
						<div
							className={type === 7 ? "single-nav active" : "single-nav"}
							style={{ borderTop: "1px solid #f4f4f4" }}
							onClick={() => {
								window.location.href = "/#/personal/7"
							}}
						>
							<b></b>
							已购买资源
						</div>
					</div>
				</div>
				<div className="personal-content-right">
					{
						type === 1 &&
						<Info
							editSign={editSign}
						/>
					}
					{
						type === 2 &&
						<CtfSubmit />
					}
					{
						type === 3 &&
						<VulnSubmit />
					}
					{
						type === 4 &&
						<TestSubmit />
					}
					{
						type === 5 &&
						<ToolSubmit />
					}
					{
						type === 6 &&
						<PlugSubmit />
					}
					{
						type === 7 &&
						<HaveBought />
					}
					{
						type === 8 &&
						<TestTopicSubmit />
					}
					{
						type === 9 &&
						<AWDSubmit />
					}
				</div>
			</div>
		</div>
	);
}

export default Index;
