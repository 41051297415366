import React, { useEffect, useState, useRef } from 'react';
import { Button, Input, Select } from 'antd';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import CodeHighlighter from 'braft-extensions/dist/code-highlighter';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import { ContentUtils } from 'braft-utils'
import Denied from '../../../../images/denied.png';
import PassBox from '../../../Activity/PassBox';
import DeclineBox from '../../../Activity/DeclineBox';
import AddedBox from '../../../Activity/AddedBox';
import history from '../../../Communal/history';
import { Alert, srcUrl } from '../../../Communal/utility';
import Retrieval from '../../../Activity/retrieval';
import instance from '../../../Communal/instance';
const { Option } = Select;
const options = {
    syntaxs: [
        {
            name: 'JavaScript',
            syntax: 'javascript'
        }, {
            name: 'HTML',
            syntax: 'html'
        }, {
            name: 'CSS',
            syntax: 'css'
        }, {
            name: 'Java',
            syntax: 'java',
        }, {
            name: 'PHP',
            syntax: 'php'
        }, {
            name: "Python",
            syntax: 'python'
        }
    ],
}
BraftEditor.use(CodeHighlighter(options))

const MethodsSceneForm = ({ param }) => {
    const [type, setType] = useState("0"); //type: 0 添加 1 编辑 2 审核
    const dateRef = useRef();
    const [imagesType, setImagesType] = useState(0);
    const [tags, setTags] = useState([]);
    const [classify, setClassify] = useState([]);
    const [submit, setSubmit] = useState({
        name: '',
        desc: BraftEditor.createEditorState(null),
        classify: undefined,
        tags: [],
    });
    const [error, setError] = useState({
        name: [],
        desc: [],
        classify: [],
        tags: [],
    });
    const [initBox, setInitBox] = useState({
        passBox: false,
        declineBox: false,
        addedBox: false
    })
    const [retrievalBox, setRetrievalBox] = useState({
        show: false,
        type: 0,
        count: 0
    });
    const [submitLoading, setSubmitLoading] = useState(false);
    useEffect(() => {
        setType(param.match.params.type);
        getClassData();
        getTag();
        if (param.match.params.id !== '0') {
            getDetail(param.match.params.id)
        } else {
            instance.get("/media/resource/WPTemplate/method/template.json").then(data => {
                submit.desc = BraftEditor.createEditorState(data?.methodScene);
                setSubmit({ ...submit });
            })
        }
        document.getElementsByClassName('submit-ctf')[0].style.minHeight = 'calc(100vh - 40px)';
    }, [param]);
    const getDetail = (id) => {
        instance.get(`/method_scene/${id}/`).then(res => {
            res.desc = BraftEditor.createEditorState(res.desc);
            res.tags = res.tags_name;
            setSubmit(res);
        })
    }
    const getTag = () => {
        instance.get('/threat_point/tag/').then(res => {
            setTags(res)
        })
    }
    const getClassData = () => {
        instance.get('/ctf/classify/?type=7').then(res => {
            setClassify(res);
        })
    }
    let inputChange = (e, name) => {
        submit[name] = e.target.value;
        setSubmit({ ...submit })
    }
    let handleFouce = (name) => {
        error[name] = []
        setError({ ...error })
    }
    let myUploadFn = (param) => {
        const fd = new FormData();
        fd.append('image', param.file);
        fd.append("model", "method_scene");
        instance.post("/ctf/upload_image/", fd).then(data => {
            param.success({
                url: data.url,
            })
        })
    }
    //点击上传到媒体库
    let addMediaItem = () => {
        document.getElementsByClassName("images")[0].click();
        document.getElementsByClassName("images")[0].value = "";
        setImagesType(0)

    }
    //点击插入到编辑器
    let insertMediItem = () => {
        document.getElementsByClassName("images")[0].click();
        document.getElementsByClassName("images")[0].value = "";
        setImagesType(1)
    }
    //点击检索按钮进行检索，根据类型判断 检索的字段
    let retrievalClick = (type, text) => {
        if (text.replace(/(^\s*)|(\s*$)/g, "") === "") {
            Alert("warn", "请输入检索内容");
            return
        }
        let list = {
            type: "method_scene",
            flag: text,
            search_type: type
        }
        instance.get("/ctf/search/", { params: list }).then(data => {
            retrievalBox.show = true;
            retrievalBox.count = data.count;
            retrievalBox.type = type;
            setRetrievalBox({ ...retrievalBox })
        })
    }
    let handleChangeTag = (value) => {
        submit.tags = value;
        setSubmit({ ...submit })
    }
    let handleChangeClass = (key) => {
        submit.classify = key;
        setSubmit({ ...submit })
    }
    let commitResources = (type) => {
        if (srcUrl(submit.desc.toHTML())) {
            Alert('warn', '手法场景描述中图片存在外部链接，请检查！！！')
            return
        }
        setSubmitLoading(true);
        if (submit.name === '') {
            error.name = ['请输入手法场景名称']
        }
        if (submit.tags.length === 0) {
            error.tags = ['请选择手法场景标签']
        }
        if (!submit.classify) {
            error.classify = ['请选择手法场景分类']
        }
        if (submit.desc.toHTML() === "<p></p>") {
            Alert("error", "请输入手法场景描述");
        }
        if (submit.name === '' || submit.tags.length === 0 || !submit.classify || submit.desc.toHTML() === "<p></p>") {
            setError({ ...error });
            setSubmitLoading(false);
            Alert('warn', '提交内容有误，请检查！')
            return
        }
        let list = {
            name: submit.name,
            desc: submit.desc.toHTML(),
            classify: submit.classify,
            tags: submit.tags,
        }
        if (type === 1) {
            instance.post('/method_scene/add/', list).then(res => {
                Alert("success", "提交成功");
                history.push({ pathname: '/resource/methods', state: { tabKey: 1 } })
                setSubmitLoading(false)
            }).catch(err => {
                setSubmitLoading(false);
                errorInfo(err);
            })
        } else if (type === 2) {
            instance.patch(`/method_scene/${param.match.params.id}/`, list).then(res => {
                Alert("success", "提交成功");
                history.push({ pathname: '/resource/methods', state: { tabKey: 1 } });
                setSubmitLoading(false)
            }).catch(err => {
                setSubmitLoading(false);
                errorInfo(err);
            })
        }
    }
    const errorInfo = (err) => {
        if (err.error) {
            Alert('error', err.error)
        }
        if (err.name) {
            error.name = err.name
        }
        if (err.desc) {
            Alert('error', err.desc)
        }
        if (err.classify) {
            error.classify = err.classify
        }
        if (err.tags) {
            error.tags = err.tags
        }
        Alert('warn', '提交内容有误，请检查！')
        setError({ ...error })
    }
    let handleAudit = (data) => {
        if (data.status === 1) {
            if (data.data === "") {
                Alert("error", "请输入奖励奖励数");
                return
            }
        }
        if ((data.status === 2 || data.status === 3) && data.data === "") {
            Alert("error", "请输入审核意见");
            return
        }
        instance.post("/method_scene/" + param.match.params.id + "/audit/", data).then(data => {
            Alert("success", "操作成功");
            history.push({ pathname: '/resource/methods', state: { tabKey: 1 } })
        }).catch(err => {
            Alert("error", err.error)
        })
    }
    let handleEditorChange = (editorState) => {
        submit.desc = editorState;
        setSubmit({ ...submit })
    }
    //点击上传文件
    let changeImage = () => {
        let file = document.getElementsByClassName("images")[0].files[0];
        if (file !== undefined) {
            if (file.size > 5 * 1024 * 1024) {
                Alert("error", "上传图片不能大于5M")
                return
            }
            let form = document.getElementById('file-image');
            let formdData = new FormData(form);
            formdData.append("model", "method_scene");
            instance.post("/ctf/upload_image/", formdData).then(data => {
                if (imagesType === 0) {
                    if (dateRef.current !== undefined) {
                        let braftFinder = dateRef.current.getFinderInstance();
                        braftFinder.addItems([
                            {
                                id: new Date().getTime(),
                                type: 'IMAGE',
                                url: data.url
                            }
                        ])
                    }
                } else if (imagesType === 1) {
                    let editorState = ContentUtils.insertMedias(submit.desc, [
                        {
                            type: 'IMAGE',
                            url: data.url
                        }
                    ])
                    submit.desc = editorState;
                    setSubmit({ ...submit })
                }
                Alert("success", "上传成功")
            }).catch(err => {
                if (err.image) {
                    Alert("error", err.image)
                }
            })
        }
    }
    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-div submit-ctf-name">
                    {
                        type === "0" && "提交手法场景"
                    }
                    {
                        type === "1" && "编辑手法场景"
                    }
                    {
                        type === "2" && "审核手法场景"
                    }
                    {
                        submit.status === 3 &&
                        <div className="status">
                            <img src={Denied} alt="" />
                        </div>
                    }
                </div>
                {
                    submit.is_show_opin && submit.opin && submit.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    submit.opin &&
                                    submit.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">{val.msg}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div subject-info">
                    <div className="title">
                        手法场景信息
                    </div>
                    <div className="content">
                        <div className="retrieval-div">
                            <span style={{ width: '120px' }}><b>*</b>手法场景名称</span>
                            <Input
                                value={submit.name}
                                className="content-input"
                                style={{ width: 'calc(100% - 200px)' }}
                                placeholder="请输入手法场景名称"
                                onChange={(e) => inputChange(e, "name")}
                                maxLength="250"
                                onFocus={() => handleFouce("name")}
                            />
                            <span className="number">{250 - submit.name.length}</span>
                            <Button type="primary" onClick={() => retrievalClick(1, submit.name)}>检索</Button>
                            <div style={{ marginLeft: '120px' }} className="error-message">{error.name[0]}</div>
                        </div>
                        <div>
                            <span style={{ width: '120px' }}><b>*</b>手法场景标签</span>
                            <Select
                                mode="tags"
                                style={{ width: 'calc(100% - 200px)' }}
                                placeholder="请选择手法场景标签"
                                value={submit.tags}
                                onChange={handleChangeTag}
                                onClick={() => handleFouce("tags")}
                            >
                                {
                                    tags.map((val, key) => {
                                        return <Option key={key} value={val.name}>
                                            {val.name}
                                        </Option>
                                    })

                                }
                            </Select>
                            <div style={{ marginLeft: '120px' }} className="error-message">{error.tags[0]}</div>
                        </div>
                        <div>
                            <span style={{ width: '120px' }}><b>*</b>手法场景类型</span>
                            <Select
                                value={submit.classify}
                                placeholder='请选择场景类型'
                                style={{ width: 180 }}
                                onChange={handleChangeClass}
                                onClick={() => handleFouce("classify")}
                            >
                                {
                                    classify.map((val, key) => {
                                        return <Option
                                            key={key + "key"}
                                            value={val.id}
                                        >
                                            {val.name}
                                        </Option>
                                    })
                                }
                            </Select>
                            <div style={{ marginLeft: '120px' }} className="error-message">{error.classify[0]}</div>
                        </div>
                    </div>
                </div>
                <div className="submit-ctf-div topic-answer">
                    <div className="title">
                        手法场景描述
                    </div>
                    <div>
                        <span style={{ width: '120px' }}><b>*</b>手法场景描述</span>
                        <div className="my-component">
                            <BraftEditor
                                value={submit.desc}
                                onChange={handleEditorChange}
                                ref={dateRef}
                                media={{
                                    uploadFn: myUploadFn,
                                    image: false
                                }}
                                excludeControls={["superscript", "subscript", "emoji", "remove-styles"]}
                                extendControls={[
                                    'separator',
                                    {
                                        key: 'add-media',
                                        type: 'button',
                                        text: '插入图片到媒体库',
                                        onClick: () => addMediaItem()
                                    },
                                    {
                                        key: 'insert-media',
                                        type: 'button',
                                        text: '插入图片到编辑器',
                                        onClick: () => insertMediItem()
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
                {
                    type === "0" &&
                    <div className="submit-btn">
                        <Button type="primary" loading={submitLoading} onClick={() => commitResources(1)}>提交</Button>
                    </div>
                }
                {
                    type === "1" &&
                    <div className="submit-btn">
                        <Button type="primary" loading={submitLoading} onClick={() => commitResources(2)}>提交</Button>
                    </div>
                }
                {
                    type === "2" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => {
                                initBox.passBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            通过
                        </Button>
                        <Button
                            style={{ backgroundColor: "transparent", color: "#266cff" }}
                            type="primary"
                            onClick={() => history.push({ pathname: '/resource/methods', state: { tabKey: 1 } })}
                        >
                            取消
                        </Button>
                        <Button className="modify" loading={submitLoading} onClick={() => commitResources(2)}>保存修改</Button>
                        <Button
                            className="decline"
                            onClick={() => {
                                initBox.declineBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            拒绝
                        </Button>
                        <Button
                            className="added"
                            onClick={() => {
                                initBox.addedBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            待补充
                        </Button>
                        <Button
                            className="added"
                            onClick={() => handleAudit({ status: 0 })}
                        >
                            待审核
                        </Button>
                    </div>
                }
            </div>
            <form id="file-image">
                <input
                    type="file"
                    name="image"
                    accept=".jpg,.png,.gif"
                    style={{ display: "none" }}
                    className="images"
                    onChange={changeImage}
                />
            </form>
            {
                initBox.passBox &&
                <PassBox
                    close={() => {
                        initBox.passBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.declineBox &&
                <DeclineBox
                    close={() => {
                        initBox.declineBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.addedBox &&
                <AddedBox
                    close={() => {
                        initBox.addedBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                retrievalBox.show &&
                <Retrieval
                    close={() => {
                        retrievalBox.show = false;
                        setRetrievalBox({ ...retrievalBox })
                    }}
                    count={retrievalBox.count}
                    name={
                        {
                            1: "手法场景名称"
                        }[retrievalBox.type]
                    }
                />
            }
        </div>
    )
}
export default MethodsSceneForm;