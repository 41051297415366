import React, { useEffect, useState } from 'react';
import { Button, Input, Select, InputNumber, Row, Col } from 'antd';
import Denied from '../../../../images/denied.png';
import DeclineBox from '../../../Activity/DeclineBox';
import AddedBox from '../../../Activity/AddedBox';
import PassBox from '../../../Activity/PassBox';
import history from '../../../Communal/history';
import { Alert } from '../../../Communal/utility';
import instance from '../../../Communal/instance';
import MarkDownModal from '../../MarkDownModal';
import SubmitBox from '../../submitBox';
import '../index.less';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import 'ace-builds/webpack-resolver'

const { Option } = Select;
const { TextArea } = Input;

const App = ({ param }) => {
    const plugTypeList = [
        { value: 0, name: '漏洞插件' },
        { value: 1, name: '基础插件' },
    ]
    const [type, setType] = useState("0"); //type: 0 添加 1 编辑 2 审核
    const [submit, setSubmit] = useState({
        name: '',
        pulg_type: null,
        resource_level: 4,
        content: ""
    });
    const [error, setError] = useState({
        name: [],
        vulntype: [],
        resource_level: [],
        content: [],
    });
    const [initBox, setInitBox] = useState({
        passBox: false,
        declineBox: false,
        addedBox: false
    })
    const [checkLoading, setCheckLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [submitBox, setSubmitBox] = useState({
        show: false
    })
    const [writeBox, setWriteBox] = useState({
        show: false
    })

    useEffect(() => {
        setType(param.match.params.type);
        if (param.match.params.id !== '0') {
            getDetail(param.match.params.id)
        }
        document.getElementsByClassName('submit-ctf')[0].style.minHeight = 'calc(100vh - 40px)';
    }, [param]);

    useEffect(() => {
        return () => {
            setSubmit({
                name: '',
                pulg_type: null,
                resource_level: 4,
                content: ""
            })
        }
    }, [])

    const getDetail = (id) => {
        instance.get(`/vulninte/api/plug/${param.match.params.id}/`).then(data => {
            submit.name = data.name
            submit.pulg_type = data.pulg_type
            submit.resource_level = data.resource_level
            submit.content = data.content_read
            setSubmit({ ...submit });
        })
    }

    const inputChange = (value, name) => {
        submit[name] = value;
        setSubmit({ ...submit })
    }

    const handleFocus = (name) => {
        error[name] = []
        setError({ ...error })
    }

    // 审核
    let handleAudit = (data) => {
        let list = {
            ids: param.match.params.id,
            status: data.status,
            data: data.data
        }
        if (data.status === 1) {
            if (data.data === "") {
                Alert("error", "请输入奖励数");
                return
            }
        }
        if ((data.status === 2 || data.status === 3) && data.data === "") {
            Alert("error", "请输入审核意见");
            return
        }
        instance.post("/vulninte/api/audit/plug/", list).then(data => {
            Alert("success", data.msg);
            history.push({ pathname: '/resource/vulninfo', state: { tabKey: "4" } })
            initBox.declineBox = false;
            initBox.addedBox = false;
            setInitBox({ ...initBox })
        }).catch(err => {
            Alert("error", err.error)
        })
    }

    const handleOk = () => {
        let list = {}
        list.name = submit.name
        list.content = submit.content
        list.pulg_type = submit.pulg_type
        list.resource_level = submit.resource_level
        setSubmitLoading(true)
        if (type === "0") {
            instance.post("/vulninte/api/plug/", list).then(data => {
                setSubmitLoading(false);
                Alert("success", "添加成功");
                history.push({ pathname: '/resource/vulninfo', state: { tabKey: "4" } })
                submitBox.show = false
                setSubmit({ ...submitBox })
            }).catch((err) => {
                setSubmitLoading(false);
                submitBox.show = false
                setSubmitBox({ ...submitBox })
                setError({ ...error, ...err })
            })
        }
        if (type === "1" || type === "2") {
            if (param.match.params.id) {
                instance.patch(`vulninte/api/plug/${param.match.params.id}/`, list).then(data => {
                    setSubmitLoading(false);
                    Alert("success", "编辑成功");
                    history.push({ pathname: '/resource/vulninfo', state: { tabKey: "4" } })
                }).catch((err) => {
                    setSubmitLoading(false);
                    setError({ ...error, ...err })
                    submitBox.show = false
                    setSubmitBox({ ...submitBox })
                })
            }

        }
    }

    const handleCommit = () => {
        let err = { ...error }
        if (submit.name === '') {
            err.name = ['请输入插件名称']
        }
        if (submit.pulg_type === null) {
            err.vulntype = ['请选择插件类型']
        }
        if (submit.resource_level === null) {
            err.resource_level = ['请填写插件等级']
        }
        if (submit.content === '') {
            err.content = ['请输入插件内容']
        }
        if (submit.name === '' ||
            submit.pulg_type === null ||
            submit.resource_level === null ||
            submit.content === ''
        ) {
            setError({ ...err })
            return
        }
        submitBox.show = true
        setSubmitBox({ ...submitBox })
    }

    const handleCheckPlug = () => {
        if (submit.content === '') {
            Alert('error', '请输入要校验的插件内容')
            return false
        }
        setCheckLoading(true)
        instance.post("/vulninte/api/check/", { plug: submit.content }).then(data => {
            setCheckLoading(false)
            if (data?.content) {
                error.content = data.content
                setError({ ...error })
            }
        }).catch((err) => {
            setCheckLoading(false)
            if (err?.content) {
                error.content = err.content
                setError({ ...error })
            }
        })
    }

    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-div submit-ctf-name">
                    {
                        type === "0" && "提交插件"
                    }
                    {
                        type === "1" && "编辑插件"
                    }
                    {
                        type === "2" && "审核插件"
                    }
                    {
                        submit.status === 3 &&
                        <div className="status">
                            <img src={Denied} alt="" />
                        </div>
                    }
                </div>
                {
                    submit.is_show_opin && submit.opin && submit.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    submit.opin &&
                                    submit.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">{val.msg}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div subject-info">
                    <div className="title">
                        插件信息
                    </div>
                    <div className="content">
                        <Row>
                            <Col span={12} className="retrieval-div">
                                <span className='name'><b>*</b>插件名称</span>
                                <Input
                                    value={submit.name}
                                    className="content-input"
                                    style={{ width: '80%' }}
                                    placeholder="请输入插件名称"
                                    onChange={(e) => inputChange(e.target.value, "name")}
                                    maxLength="200"
                                    onFocus={() => handleFocus("name")}
                                />
                                <div className="error-message">{error?.name[0]}</div>
                            </Col>
                            <Col span={6} className="retrieval-div">
                                <span className='name'><b>*</b>插件类型</span>
                                <Select
                                    className='width-select'
                                    value={submit.pulg_type}
                                    placeholder='请选择插件类型'
                                    style={{ width: 180 }}
                                    onChange={(value) => {
                                        inputChange(value, 'pulg_type')
                                    }
                                    }
                                    onClick={() => handleFocus("vulntype")}
                                >
                                    {
                                        plugTypeList.map((val, key) => {
                                            return <Option
                                                key={key}
                                                value={val.value}
                                            >
                                                {val.name}
                                            </Option>
                                        })
                                    }
                                </Select>
                                <div className="error-message">{error?.vulntype[0]}</div>
                            </Col>
                            <Col span={6} className="retrieval-div">
                                <span className='name'><b>*</b>插件等级</span>
                                <InputNumber
                                    style={{ width: 180 }}
                                    precision={0}
                                    min={0}
                                    value={submit.resource_level}
                                    className="content-input"
                                    onChange={(value) => inputChange(value, "resource_level")}
                                    onFocus={() => handleFocus("resource_level")}
                                />
                                <div className="error-message">{error?.resource_level[0]}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={18} className="retrieval-div my-component">
                                <span className='name' style={{ minWidth: '80px' }}><b>*</b>插件内容</span>
                                {/* <TextArea
                                    rows={13}
                                    className="content-input"
                                    style={{ width: '80%' }}
                                    placeholder='请填写插件内容'
                                    value={submit.content}
                                    onChange={(e) => {
                                        inputChange(e.target.value, 'content')
                                    }}
                                    maxLength={20000}
                                    onClick={() => handleFocus("content")}
                                /> */}
                                <AceEditor
                                    mode="javascript"
                                    theme="github"
                                    fontSize={14}
                                    maxLength={20000}
                                    onChange={(e) => {
                                        inputChange(e, 'content')
                                    }}
                                    placeholder='请填写插件内容'
                                    value={submit.content}
                                    name="UNIQUE_ID_OF_DIV"
                                    editorProps={{ $blockScrolling: true }}
                                    showGutter={true}
                                    enableBasicAutocompletion
                                    enableLiveAutocompletion
                                    showPrintMargin={false}
                                    setOptions={{
                                        useWorker: false,
                                        // 自动提词此项必须设置为true
                                        enableSnippets: true,
                                        tabSize: 2,
                                    }}
                                />
                                <div className="error-message" style={{ whiteSpace: 'pre', color: error?.content[0] == '格式正确!' ? '#47c447' : '#FC7057' }}>{error?.content[0]}</div>
                            </Col>
                            <Col span={6} className="retrieval-div">
                                <div
                                    className='name'
                                    style={{
                                        width: '110px'
                                    }}
                                >
                                    插件编写说明：
                                </div>
                                <br />
                                <div
                                    style={{ marginLeft: '10px', color: '#bbb' }}
                                >
                                    1、yml文件以缩进代表层级关系
                                    <br />
                                    2、缩进不允许使用tab只能使用空格
                                    <br />
                                    3、空格的个数不重要，只要相同层级的元素左对齐即可
                                    <br />
                                    4、大小写敏感
                                    <br />
                                    5、数据格式为，名称:(空格)值,冒号后面有值
                                    <br />
                                    6、冒号后面必须有空格
                                    <br />
                                    7、-后面也必须要有空格
                                    <br />
                                    8、＃ 表示注释， 从这个字符一直到行尾，都会被解析器忽略
                                    <br />
                                    9、--- 表示分段，在同一个yaml文件中，可以用 --- 来分段，这样可以将多个文档写在一个文件中
                                </div>
                                <Button
                                    type='primary'
                                    style={{ marginRight: '10px' }}
                                    loading={checkLoading}
                                    onClick={() => handleCheckPlug()}
                                >
                                    插件格式校验
                                </Button>
                                <Button
                                    type='primary'
                                    // style={{ float: 'right' }}
                                    onClick={() => {
                                        writeBox.show = true
                                        setWriteBox({ ...writeBox })
                                    }}
                                >
                                    插件编写说明
                                </Button>

                            </Col>
                        </Row>
                    </div>
                </div>
                {
                    //新增 //编辑
                    (type === "0" || type === "1") &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            loading={submitLoading}
                            onClick={() => {
                                handleCommit()
                            }}
                        >
                            提交
                        </Button>
                    </div>
                }
                {
                    type === "2" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => {
                                initBox.passBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            通过
                        </Button>
                        <Button
                            style={{ backgroundColor: "transparent", color: "#266cff" }}
                            type="primary"
                            onClick={() => history.push({ pathname: '/resource/vulninfo', state: { tabKey: "4" } })}
                        >
                            取消
                        </Button>
                        <Button className="modify" loading={submitLoading} onClick={() => {
                            handleCommit()
                        }}>保存修改</Button>
                        <Button
                            className="decline"
                            onClick={() => {
                                initBox.declineBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            拒绝
                        </Button>
                        <Button
                            className="added"
                            onClick={() => {
                                initBox.addedBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            待补充
                        </Button>
                        <Button
                            className="added"
                            onClick={() => handleAudit({ status: 0 })}
                        >
                            待审核
                        </Button>
                    </div>
                }
            </div>
            {
                initBox.passBox &&
                <PassBox
                    close={() => {
                        initBox.passBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.declineBox &&
                <DeclineBox
                    close={() => {
                        initBox.declineBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.addedBox &&
                <AddedBox
                    close={() => {
                        initBox.addedBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            <MarkDownModal
                boxProperty={writeBox}
                title={'POC 编写指南'}
                url={'/media/resource/vulnInfo/poc.md'}
                close={() => {
                    writeBox.show = false
                    setWriteBox({ ...writeBox })
                }}
            />
            <SubmitBox
                submit={submitBox}
                resourceName={'插件'}
                onOK={() => { handleOk() }}
                onClose={() => {
                    submitBox.show = false
                    setSubmitBox({ ...submitBox })
                }}
                loading={submitLoading}
            />
        </div>
    )
}
export default App;