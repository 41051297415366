import React, { useEffect, useState } from "react";
import { Button,Table, Input } from "antd";
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import CodeHighlighter from 'braft-extensions/dist/code-highlighter';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import styled from 'styled-components';
import instance from "../../../Communal/instance";

const { Column } = Table;
const options = {
    syntaxs: [
        {
            name: 'JavaScript',
            syntax: 'javascript'
        }, {
            name: 'HTML',
            syntax: 'html'
        }, {
            name: 'CSS',
            syntax: 'css'
        }, {
            name: 'Java',
            syntax: 'java',
        }, {
            name: 'PHP',
            syntax: 'php'
        },{
            name:"Python",
            syntax: 'python'
        }
    ],
}
BraftEditor.use(CodeHighlighter(options));
const Div=styled.div`
    width:100%!important;
    color:#333;
    .bf-content {
        height:auto;
    }
    .bf-content img{
        max-width:100%!important;
    }
    .bf-container .public-DraftEditor-content .braft-code-block-wrapper .braft-code-block{
        max-height:10000px
    }
    .bf-container .public-DraftEditor-content{
        padding: 5px 0;
    }
`
const { Search } = Input;
const MethodSceneBox=({onClose,onEnter,method_scene})=>{
    const [initData,setInitData]=useState({
        data:[],
        allCount:0
    });
    const [editorState,setEditorState] = useState(BraftEditor.createEditorState(null));
    const [classify,setClassify] = useState([]);
    const [loading,setLoading] = useState(false);
    const [detail,setDetail]=useState({});
    const [checkList, setCheckList] = useState([]);
    const [checkRow,setCheckRow] = useState(method_scene);
    const [changeId,setChangeId] = useState('');
    const [filter,setFilter]=useState({
        page:1,
        size:5,
        search:'',
        classify:[],
        status:1,
    })
    useEffect(()=>{
        document.body.style.overflow='hidden';
        let arrNew = []
        method_scene.forEach(item => {
            arrNew.push(item.id)
        })
        setCheckList(arrNew);
        getClassData();
        return ()=>{
            document.body.style.overflow='auto';
        }
    },[])
    useEffect(()=>{
        getData();
    },[filter]);
    useEffect(()=>{
        if(changeId!==''){
            getDetail();
        }
    },[changeId])
    let getData=()=>{
        setLoading(true);
        instance.get("/method_scene/", { params: filter }).then(data => {
            if(data.results.length>0){
                setChangeId(data.results[0].id)
            }
            initData.data = data.results;
            initData.allCount = data.count;
            setInitData({ ...initData });
            setLoading(false);
        })
    };
    const getDetail=()=>{
        instance.get(`/method_scene/${changeId}/`).then(res=>{
            setEditorState(BraftEditor.createEditorState(res.desc))
            setDetail(res);
        })
    }
    const getClassData=()=>{
        instance.get('/ctf/classify/?type=7').then(res=>{
            if (res.length > 0) {
                for (var i = 0; i < res.length; i++) {
                    res[i].text = res[i].name;
                    res[i].value = res[i].id
                }
            }
            setClassify(res);
        })
    }
    let handleTableChange = (pagination, filters, sorter) => {
        if (filters.classify) {
            filter.classify = filters.classify;
        } else {
            filter.classify = [];
        }
        filter.page = 1;
        setFilter({ ...filter });
    }
    const handleChangeRow=(selectedRowKeys,selectedRows)=>{
        if(checkList.length>0){
            for(let j=0;j<initData.data.length;j++){
                let index=checkList.indexOf(initData.data[j].id);
                if(index!==-1){
                    checkList.splice(index,1);
                    checkRow.splice(index,1);
                }
            }
            if(selectedRowKeys.length>0){
                for(let i=0;i<selectedRowKeys.length;i++){
                    if(selectedRows[i]){
                        checkList.push(selectedRowKeys[i]);
                        checkRow.push(selectedRows[i])
                    }
                }
            }
            setCheckRow([...checkRow])
            setCheckList([...checkList])
        }else{
            setCheckRow(selectedRows)
            setCheckList(selectedRowKeys)
        }
    }
    return (
        <div className='audit-bounced'>
            <div className='choice-method-scene'>
                <div className='header'>
                    选择手法场景
                </div>
                <div className='content-left'>
                    <div className='select-tags'>
                        已选手法场景：
                        {
                            checkRow.map((val,key)=>{
                                return <span key={key}>
                                    {val.name}
                                    {checkRow.length!==(key+1)&&'、'}
                                </span>
                            })
                        }
                    </div>
                    <div className="search-operate">
                        <Search 
                            enterButton 
                            placeholder='请输入搜索内容'
                            value={filter.search}
                            style={{width:250}}
                            onChange={(e)=>{
                                filter.search=e.target.value;
                                filter.page=1;
                                setFilter({...filter})
                            }}
                        />
                    </div>
                    <Table
                        className="admin-table"
                        tableLayout={'fixed'}
                        dataSource={initData.data}
                        rowKey={record => record.id}
                        loading={loading}
                        bordered
                        rowClassName={(record)=>{
                            return record.id===changeId?'selectRow':''
                        }}
                        pagination={{
                            showQuickJumper: true,
                            current: filter.page,
                            pageSize: filter.size,
                            total: initData.allCount,
                            hideOnSinglePage: true,
                            className: "flip-over",
                            style: { margin: "30px 0" },
                            onChange: ((page) => {
                                filter.page = page
                                setFilter({ ...filter })
                            })
                        }}
                        rowSelection={{
                            type: "checkbox",
                            selectedRowKeys: checkList,
                            onChange:handleChangeRow
                        }}
                        onChange={handleTableChange}
                    >
                        <Column
                            title="手法场景编号"
                            key="number"
                            className='overflow'
                            align="center"
                            dataIndex="number"
                            width="25%"
                            render={(text, record) => {
                                return text ? <span
                                    className="click-href"
                                    title={text}
                                    onClick={() => {
                                        setChangeId(record.id)
                                    }}
                                >
                                    {text}
                                </span>
                                    : "暂无编号"
                            }}
                        />
                        <Column
                            title="手法场景名称"
                            key="name"
                            align="center"
                            className='overflow'
                            dataIndex="name"
                            width={"25%"}
                            render={(text, record) => {
                                return <span
                                    className="click-href"
                                    title={text}
                                    onClick={()=>{setChangeId(record.id)}}
                                >
                                    {text}
                                </span>
                            }}
                           
                        />
                        <Column
                            title="手法场景类型"
                            key="classify"
                            align="center"
                            className='overflow'
                            dataIndex="classify"
                            width="25%"
                            filters={classify}
                            render={(text, record) => {
                                return <span title={text}>
                                     {text}
                                </span>
                            }}
                        />
                        <Column
                            title="添加时间"
                            key="sub_time"
                            className='overflow'
                            align="center"
                            dataIndex="sub_time"
                            width="25%"
                            render={(text, record) => {
                                return <span title={text}>
                                     {text}
                                </span>
                            }}
                        />
                    </Table>
                </div>
                <div className='content-right'>
                    <div className="name">
                        <span>手法场景名称：</span>
                    </div>
                    <div className="detail">{detail.name}</div>
                    <div className="name">
                        <span>手法场景编号：</span>
                    </div>
                    <div className="detail">{detail.number}</div>
                    <div className="name">
                        <span>手法场景描述：</span>
                    </div>
                    <div className="detail">
                        <Div className="container">
                            <BraftEditor
                                value={editorState}
                                readOnly
                                controls={[]}
                            />
                        </Div>
                    </div>
                </div>
                <div className='bottom'>
                    <Button
                        type='primary'
                        className='cancel'
                        onClick={onClose}
                    >
                        取消
                    </Button>
                    <Button
                        type='primary'
                        onClick={()=>{
                            onEnter(checkRow)
                        }}
                    >
                        保存
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default MethodSceneBox;