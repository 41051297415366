//页脚
import React from 'react';
import Icon from '../../images/icon-police.png';
function Footer() {
	return (
		<div className="platform-footer">
				<a target="blank" href="http://www.beian.miit.gov.cn">
					陕ICP备14009494号-5 
				</a> 
				<a target="blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019002000754">
					<img src={Icon} alt=""/>
					陕公网安备 61019002000754号
				</a> 
				版权所有 ©{new Date().getFullYear()} 西安四叶草信息技术有限公司 保留所有权
		</div>
	);
}

export default Footer;
