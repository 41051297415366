//活动中心
import React from 'react';
import '../../css/activity.css';
import { Route } from 'react-router-dom';
import List from './list';
import Detail from './detail';
import SubmitCtf from '../Resource/CTF/submitCTF';
import CtfDetail from '../Resource/CTF/ctfDetail';
import SubmitExp from '../Resource/Exp/submitExp';
import ExpDetail from '../Resource/Exp/expDetail';
import SubmitVuln from '../Resource/Vuln/submitVuln';
import VulnDetail from '../Resource/Vuln/vulnDetail';
import SubmitTool from '../Resource/Tool/submitTool';
import ToolDetail from '../Resource/Tool/toolDetail';
import SubmitTest from '../Resource/Test/submitTest';
import TestDetail from '../Resource/Test/testDetail';
import SubmitAWD from '../Resource/Awd/submitAWD';
import AWDDetail from '../Resource/Awd/awdDetail';
import ElementForm from '../Resource/ThreatResources/SceneElement/ElementForm';
import ElementDetail from '../Resource/ThreatResources/SceneElement/ElementDetail';
import ThreatenForm from '../Resource/ThreatResources/Threaten/ThreatenForm';
import ThreatenDetail from '../Resource/ThreatResources/Threaten/ThreatenDetail';
import MethodsSceneForm from '../Resource/MethodResource/MethodsScene/MethodsSceneForm';
import MethodsSceneDetail from '../Resource/MethodResource/MethodsScene/MethodsSceneDetail';
import MethodForm from '../Resource/MethodResource/Methods/MethodForm';
import MethodDetail from '../Resource/MethodResource/Methods/MethodDetail';
import SubmitKnowledge from '../Resource/Knowledge/submitKnowledge';
import KnowledgeDetail from '../Resource/Knowledge/knowledgeDetail';
import SubmitRecruit from '../Resource/Recruit/submitRecruit';
import RecruitDetail from '../Resource/Recruit/recuritDetail';
import SubmitEnterprise from '../Resource/Recruit/submitEnterprise';
import EnterpriseDetail from '../Resource/Recruit/enterpriseDetail';
import AddVulnInfo from '../Resource/VulnInfo/Vuln/add';
import VulnInfoDetail from '../Resource/VulnInfo/Vuln/detail';
import PlugInForm from '../Resource/VulnInfo/PlugIn/plugInForm';
import PlugInDetail from '../Resource/VulnInfo/PlugIn/plugInDetail';
import FingerprintForm from '../Resource/VulnInfo/Fingerprints/fingerprintForm';
import FingerprintDetail from '../Resource/VulnInfo/Fingerprints/fingerprintDetail';
import SceneTaskDetail from '../Resource/Scene/SceneTask/SceneTaskDetail';
import SceneTaskForm from '../Resource/Scene/SceneTask/SceneTaskForm';
import RuleResourceForm from '../Resource/Rule/RulesResources/rulesResourceForm';
import RulesResourceDetail from '../Resource/Rule/RulesResources/rulesResourceDetail';
import ExternalRulesForm from '../Resource/Rule/ExternalRules/externalRulesForm';
function Index() {
	return (
		<div>
			<Route exact path="/activity" component={(param) => {
				return <List />
			}} />
			<Route path="/activity/detail/:id" component={(param) => {
				return <Detail param={param} />
			}} />
			<Route path="/activity/submitctf/:where/:type/:id" component={(param) => { //where 判断是否从活动详情进入 type判断提交、编辑、审核、详情
				return <SubmitCtf param={param} />
			}} />
			<Route path="/activity/ctfdetail/:where/:id" component={(param) => { //where 判断是否从活动详情进入
				return <CtfDetail param={param} />
			}} />
			<Route path="/activity/submitexp/:where/:type/:id" component={(param) => { //where 判断是否从活动详情进入 type判断提交、编辑、审核、详情
				return <SubmitExp param={param} />
			}} />
			<Route path="/activity/expdetail/:where/:id" component={(param) => { //where 判断是否从活动详情进入 
				return <ExpDetail param={param} />
			}} />
			<Route path="/activity/submitvuln/:where/:type/:id" component={(param) => { //where 判断是否从活动详情进入 type判断提交、编辑、审核、详情
				return <SubmitVuln param={param} />
			}} />
			<Route path="/activity/vulndetail/:where/:id" component={(param) => { //where 判断是否从活动详情进入 
				return <VulnDetail param={param} />
			}} />
			<Route path="/activity/submittool/:where/:type/:id" component={(param) => { //where 判断是否从活动详情进入 type判断提交、编辑、审核、详情
				return <SubmitTool param={param} />
			}} />
			<Route path="/activity/tooldetail/:where/:id" component={(param) => { //where 判断是否从活动详情进入
				return <ToolDetail param={param} />
			}} />
			<Route path="/activity/submittest/:where/:type/:id" component={(param) => { //where 判断是否从活动详情进入 type判断提交、编辑、审核、详情
				return <SubmitTest param={param} />
			}} />
			<Route path="/activity/testdetail/:where/:id" component={(param) => { //where 判断是否从活动详情进入
				return <TestDetail param={param} />
			}} />
			<Route path="/activity/submitawd/:where/:type/:id" component={(param) => { //where 判断是否从活动详情进入 type判断提交、编辑、审核、详情
				return <SubmitAWD param={param} />
			}} />
			<Route path="/activity/awddetail/:where/:id" component={(param) => { //where 判断是否从活动详情进入
				return <AWDDetail param={param} />
			}} />
			<Route path="/activity/elementform/:type/:id" component={(param) => { // type判断提交、编辑、审核、详情
				return <ElementForm param={param} />
			}} />
			<Route path="/activity/elementdetail/:id" component={(param) => {
				return <ElementDetail param={param} />
			}} />
			<Route path="/activity/threatenform/:type/:id" component={(param) => { // type判断提交、编辑、审核、详情
				return <ThreatenForm param={param} />
			}} />
			<Route path="/activity/Threatendetail/:id" component={(param) => {
				return <ThreatenDetail param={param} />
			}} />
			<Route path="/activity/methodsceneform/:type/:id" component={(param) => { // type判断提交、编辑、审核、详情
				return <MethodsSceneForm param={param} />
			}} />
			<Route path="/activity/methodscenedetail/:id" component={(param) => {
				return <MethodsSceneDetail param={param} />
			}} />
			<Route path="/activity/methodform/:type/:id" component={(param) => { // type判断提交、编辑、审核、详情
				return <MethodForm param={param} />
			}} />
			<Route path="/activity/methoddetail/:id" component={(param) => {
				return <MethodDetail param={param} />
			}} />
			<Route path="/activity/submitknowledge/:where/:type/:id" component={(param) => {
				return <SubmitKnowledge param={param} />
			}} />
			<Route path="/activity/knowledgedetail/:id" component={(param) => {
				return <KnowledgeDetail param={param} />
			}} />
			<Route path="/activity/SubmitRecruit/:where/:type/:id" component={(param) => {
				return <SubmitRecruit param={param} />
			}} />
			<Route path="/activity/recruitdetail/:id" component={(param) => {
				return <RecruitDetail param={param} />
			}} />
			<Route path="/activity/submitenterprise/:where/:type/:id" component={(param) => {
				return <SubmitEnterprise param={param} />
			}} />
			<Route path="/activity/enterprisedetail/:id" component={(param) => {
				return <EnterpriseDetail param={param} />
			}} />
			<Route path="/activity/vulninfo/:type/:id" component={(param) => {
				return <AddVulnInfo param={param} />
			}} />
			<Route path="/activity/vulninfodetail/:id" component={(param) => {
				return <VulnInfoDetail param={param} />
			}} />
			<Route path="/activity/pluginform/:type/:id" component={(param) => {
				return <PlugInForm param={param} />
			}} />
			<Route path="/activity/plugIndetail/:where/:id" component={(param) => {
				return <PlugInDetail param={param} />
			}} />
			<Route path="/activity/fingerprintform/:type/:id" component={(param) => {
				return <FingerprintForm param={param} />
			}} />
			<Route path="/activity/fingerprintdetail/:where/:id" component={(param) => {
				return <FingerprintDetail param={param} />
			}} />
			<Route path="/activity/scenetaskdetail/:id" component={(param) => {
				return <SceneTaskDetail param={param} />
			}} />
			<Route path="/activity/scenetaskform/:type/:id" component={(param) => { // type判断提交、编辑、审核、详情
				return <SceneTaskForm param={param} />
			}} />
			<Route path="/activity/ruleresourceform/:type/:id" component={(param) => {
				return <RuleResourceForm param={param} />
			}} />
			<Route path="/activity/ruleresourcedetail/:where/:id" component={(param) => {
				return <RulesResourceDetail param={param} />
			}} />
			<Route path="/activity/externalrulesform/:type/:id" component={(param) => {
				return <ExternalRulesForm param={param} />
			}} />
		</div>
	);
}

export default Index;
