import React,{useEffect,useState} from 'react';
import CloseBox from '../../images/close-box.png';
import { Input } from 'antd';
const { TextArea } = Input;
function DeclineBox(param){
    useEffect(()=>{
        document.body.style.overflow="hidden"
    },[])
    useEffect(()=>{
        return ()=>{
            document.body.style.overflow="auto"
        }
    },[]);
    const [text,setText]=useState("")
    return (
        <div className="audit-bounced">
            <div className="content decline-box">
                <div className="header">
                    <img src={CloseBox} onClick={param.close} alt=""/>
                </div>
                <div className="detail">
                    <div>拒绝提交人提交资源</div>
                    <div>请填写审核意见</div>
                    <div>
                        <b>*</b>
                        <TextArea
                            value={text}
                            rows={5}
                            onChange={(e)=>setText(e.target.value)}
                        />
                    </div>
                    <div>
                        <button
                            onClick={()=>{
                                param.click({status:3,data:text})
                            }}
                        >
                            拒绝
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DeclineBox;