/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Pagination, Avatar } from 'antd';
import RankingIcon from '../../images/ranging-icon.png';
import Rank1 from '../../images/rank1.png';
import Rank2 from '../../images/rank2.png';
import Rank3 from '../../images/rank3.png';
import Rank from '../../images/rank.png';
import instance from '../Communal/instance';
function Ranking(param) {
    const [filter, setFilter] = useState(
        {
            page: 1,
            size: 10,
            activity_id: param.id
        }
    );
    const [rank, setRank] = useState(
        {
            resourceCount: "",
            coinCount: "",
            rank: "",
            sub_time: ""
        }
    )
    const [initData, setInitData] = useState({
        data: [],
        allCount: 0
    })
    useEffect(() => {
        instance.get("/activity/" + param.id + "/my/rank/").then(data => {
            setRank(data)
        })
    }, []);
    useEffect(() => {
        instance.get("/activity/rank", { params: filter }).then(data => {
            initData.data = data.results;
            initData.allCount = data.count;
            setInitData({ ...initData })
        })
    }, [filter]);
    return (
        <div className="activity-ranking">
            <div className="activity-ranking-heder">
                <div>
                    <img src={RankingIcon} alt="" />
                </div>
                <div>我的排名：{rank.rank}</div>
                <div>提交资源数：{rank.resourceCount}</div>
                <div>获取奖励数：{rank.coinCount}</div>
                <div style={{ width: "28%" }}>最后更新时间：{rank.sub_time && rank.sub_time !== "" ? rank.sub_time : "暂无提交"}</div>
            </div>
            <div className="activity-ranking-table-header">
                <span>排名</span>
                <span>头像</span>
                <span>用户名</span>
                <span>提交资源数</span>
                <span>奖励</span>
                <span>最后更新时间</span>
            </div>
            <table className="activity-ranking-table">
                <tbody>
                    {
                        initData.data.map((val, key) => {
                            return <tr key={"key" + key}>
                                <td style={{ width: "16.6%" }}>
                                    {
                                        filter.size * (filter.page - 1) + key + 1 === 1 &&
                                        <span>
                                            <img src={Rank1} alt="" />
                                        </span>
                                    }
                                    {
                                        filter.size * (filter.page - 1) + key + 1 === 2 &&
                                        <span>
                                            <img src={Rank2} alt="" />
                                        </span>
                                    }
                                    {
                                        filter.size * (filter.page - 1) + key + 1 === 3 &&
                                        <span>
                                            <img src={Rank3} alt="" />
                                        </span>
                                    }
                                    {
                                        filter.size * (filter.page - 1) + key + 1 > 3 &&
                                        <span>
                                            <b>{filter.size * (filter.page - 1) + key + 1}</b>
                                            <img src={Rank} alt="" />
                                        </span>
                                    }
                                </td>
                                <td style={{ width: "16.6%" }}>
                                    <Avatar src={val.user__photo} size={45} />
                                </td>
                                <td style={{ width: "16.6%" }} title={val.user__username}>{val.user__username}</td>
                                <td style={{ width: "16.6%" }}>{val.resource_count}</td>
                                <td style={{ width: "16.6%" }} className="gold">{val.coin_count}</td>
                                <td style={{ width: "16.6%" }}>{val.sub_time && val.sub_time !== "" ? val.sub_time : "暂未提交"}</td>
                            </tr>
                        })
                    }
                    {
                        initData.data.length === 0 &&
                        <tr>
                            <td colSpan="6">暂无排名</td>
                        </tr>
                    }
                </tbody>
            </table>
            <div className="flip-over">
                <Pagination
                    showQuickJumper
                    pageSize={filter.size}
                    current={filter.page}
                    total={initData.allCount}
                    hideOnSinglePage={true}
                    showSizeChanger={false}
                    onChange={(page) => {
                        filter.page = page;
                        setFilter({ ...filter })
                    }}
                />
            </div>
        </div>
    )
}
export default Ranking;