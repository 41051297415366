/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import Editor from '@toast-ui/editor'
import '@toast-ui/editor/dist/toastui-editor.css'
import '@toast-ui/editor/dist/i18n/zh-cn';
import '../../../css/submitCtf.css';
import '../../../css/box.css';
import { Button, Input, Cascader, Upload, Progress, Select, Radio, message } from 'antd';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import CodeHighlighter from 'braft-extensions/dist/code-highlighter';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import Retrieval from '../../Activity/retrieval';
import instance from '../../Communal/instance';
import { Alert, srcUrl } from '../../Communal/utility';
import Denied from '../../../images/denied.png';
import PassBox from '../../Activity/PassBox';
import DeclineBox from '../../Activity/DeclineBox';
import AddedBox from '../../Activity/AddedBox';
import $ from 'jquery';
import { ContentUtils } from 'braft-utils'
import history from '../../Communal/history';
const { Option } = Select;
const options = {
    syntaxs: [
        {
            name: 'JavaScript',
            syntax: 'javascript'
        }, {
            name: 'HTML',
            syntax: 'html'
        }, {
            name: 'CSS',
            syntax: 'css'
        }, {
            name: 'Java',
            syntax: 'java',
        }, {
            name: 'PHP',
            syntax: 'php'
        }, {
            name: "Python",
            syntax: 'python'
        }
    ],
}

BraftEditor.use(CodeHighlighter(options))
const { TextArea } = Input;
function SubmitCTF(param) {
    //id===0管理员 type: 0 添加 1 编辑 2 审核
    const [type, setType] = useState("0")
    const [submit, setSubmit] = useState(
        {
            editorState: BraftEditor.createEditorState(null),
            name: '', //工具名称
            classify: undefined, //一级分类id
            sec_classify: undefined, //二级分类id
            keyword: [], //关键字
            activity_id: "", //管理员提交时 取消该参数
            tool_book: '', //工具手册
            desc: '', //工具描述
            system: '',//工具体系
            tool_book_type: 1,
        }
    );
    const [systemList, setSystemList] = useState([]);
    const [percent, setPercent] = useState(0);
    const [isUpload, setIsUpload] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [imagesType, setImagesType] = useState(0);
    const dateRef = useRef();
    const editorDom = useRef();
    const editorIns = useRef();
    const [detail, setDetail] = useState({})
    const [keyword, setKeyword] = useState("");
    const [retrievalBox, setRetrievalBox] = useState({
        show: false,
        type: 0,
        count: 0
    });
    const [classify, setClassify] = useState([]); //选择分类 选项
    const [initBox, setInitBox] = useState({
        passBox: false,
        declineBox: false,
        addedBox: false
    })
    useEffect(() => {
        setType(param.param.match.params.type);
    }, [param.param.match.params.type]);
    useEffect(() => {
        submit.activity_id = param.param.match.params.where;
        setSubmit({ ...submit });
        if (param.param.match.params.type !== "0") {
            //工具详情
            instance.get("/tool/" + param.param.match.params.id + "/").then(data => {
                submit.name = data.name;
                submit.desc = data.desc;
                submit.keyword = data.keyword_list;
                submit.editorState = BraftEditor.createEditorState(data.tool_book);
                submit.classify = data.classify;
                submit.sec_classify = data.sec_classify;
                submit.system = data.system;
                submit.tool_book_md = data.tool_book_md;
                submit.tool_book_type = data.tool_book_type
                setFileList([{
                    uid: '1',
                    name: data.attached_name,
                    status: 'done',
                }])
                setSubmit({ ...submit });
                getClassify();
                setDetail(data)
            })
        } else {
            getClassify();
            instance.get("/media/resource/WPTemplate/tool/template.json").then(data => {
                submit.editorState = BraftEditor.createEditorState(data.template);
                setSubmit({ ...submit });
            })
        }
        getSystem();
    }, []);
    useEffect(() => {
        if (submit.tool_book_type === 2) {
            editorIns.current = new Editor({
                el: editorDom.current,
                language: 'zh-CN',
                height: '600px',
                initialEditType: 'markdown',
                placeholder: '请输入工具手册',
                initialValue: submit.tool_book_md,
                hooks: {
                    addImageBlobHook: addImageBlobHook
                },
                toolbarItems: [
                    ['heading', 'bold', 'italic', 'strike'],
                    ['hr', 'quote'],
                    ['ul', 'ol', 'task', 'indent', 'outdent'],
                    ['table', 'link'],
                    ['code', 'codeblock'],
                ],
            });
        }
    }, [submit.tool_book_type]);
    //获取工具类型
    let getClassify = () => {
        instance.get("/tool/classify/").then(data => {
            setClassify(data)
        })
    }
    const addImageBlobHook = (file, callback) => {
        const fd = new FormData();
        fd.append('image', file);
        fd.append("model", "ctf");
        instance.post("/ctf/upload_image/", fd).then(data => {
            if (data.success === 1) {
                callback(data.url);
            }
            if (data.success === 2) {
                message.error(data.message);
            }
        })
    }
    const getSystem = () => {
        instance.get('/tool/system/').then(res => {
            if (param.param.match.params.type === "0") {
                if (res.length > 0) {
                    submit.system = res[0].id;
                    setSubmit({ ...submit })
                }
            }
            setSystemList(res)
        })
    }
    //添加关键字回车事件
    let onPressEnter = (e) => {
        if (e.target.value.replace(/(^\s*)|(\s*$)/g, "") === "") {
            return
        }
        var count = 0;
        if (submit.keyword.length < 3) {
            if (submit.keyword.length > 0) {
                for (var i = 0; i < submit.keyword.length; i++) {
                    if (submit.keyword[i] !== e.target.value) {
                        count++
                    }
                }
                if (count === submit.keyword.length) {
                    submit.keyword.push(e.target.value);
                }
            } else {
                submit.keyword.push(e.target.value);
            }
            setKeyword("");
            setSubmit({ ...submit })
        }
    }
    //关键字change事件
    let keywordChange = (e) => {
        setKeyword(e.target.value.replace(",", ""));
    }
    //删除关键字事件
    let deleteChange = (key) => {
        submit.keyword.splice(key, 1)
        setSubmit({ ...submit })
    }
    //input change 事件
    let inputChange = (e, name) => {
        submit[name] = e.target.value;
        setSubmit({ ...submit })
    }
    let handleEditorChange = (editorState) => {
        submit.editorState = editorState;
        setSubmit({ ...submit })
    }
    const addTool = () => {
        let markText = editorIns.current?.getMarkdown();
        if (srcUrl(submit.editorState.toHTML())) {
            Alert('warn', '工具手册中图片存在外部链接，请检查！！！')
            return
        }
        if (submit.name.trim() === '') {
            Alert('error', '请填写工具名称')
            return
        }
        if (submit.desc.trim() === '') {
            Alert('error', '请填写工具简介')
            return
        }
        if (submit.classify === undefined) {
            Alert('error', '请选择一级分类')
            return
        }
        if (submit.sec_classify === undefined) {
            Alert('error', '请选择二级分类')
        }
        if (submit.editorState.toHTML() === '<p></p>' && !markText) {
            Alert('error', '请填写工具手册')
            return
        }
        if (submit.system === '') {
            Alert('error', '请选择工具体系')
        }
        if (submit.keyword.length === 0) {
            Alert('error', '请填写关键字')
            return
        }
        if (fileList.length === 0) {
            Alert('error', '请上传工具附件')
            return
        }
        let formData = new FormData();
        setUploadLoading(true);
        formData.append('name', submit.name);
        formData.append('desc', submit.desc);
        if (submit.tool_book_type === 1) {
            formData.append('tool_book', submit.editorState.toHTML());
        }
        if (submit.tool_book_type === 2) {
            formData.append('tool_book_md', markText);
        }
        if (submit.classify !== undefined)
            formData.append('classify', submit.classify);
        if (submit.sec_classify)
            formData.append('sec_classify', submit.sec_classify);
        formData.append('tool_file', fileList[0]);
        formData.append('attached_name', submit.attached_name);
        formData.append('tool_book_type', submit.tool_book_type);
        formData.append('keyword', JSON.stringify(submit.keyword));
        formData.append('system', submit.system);
        if (submit.activity_id !== "" && submit.activity_id !== "0" && submit.activity_id !== "-1") {
            formData.append('activity_id', submit.activity_id);
        }
        $.ajax({
            url: `/tool/`,
            type: 'POST',
            enctype: 'multipart/form-data',
            data: formData,
            // 告诉jQuery不要去处理发送的数据
            processData: false,
            // 告诉jQuery不要去设置Content-Type请求头
            contentType: false,
            timeout: 3000000,//设置超时时间
            beforeSend: function () {
                setIsUpload(true);
            },
            xhr: function () {
                let myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) {
                    myXhr.upload.addEventListener('progress', function (e) {
                        if (e.lengthComputable) {
                            let percent = Math.floor((e.loaded / e.total) * 100);
                            setPercent(percent);
                            if (myXhr.readyState === 1 && percent === 100) {
                                setPercent(99)
                            }
                        }
                    }, false);
                    myXhr.upload.addEventListener('load', function (e) { }, false);
                }
                return myXhr;
            },
            success: function (res) {
                setIsUpload(false);
                Alert('success', '提交成功')
                instance.get("/media/resource/WPTemplate/tool/template.json").then(data => {
                    setSubmit({
                        editorState: BraftEditor.createEditorState(data.template),
                        name: '', //工具名称
                        classify: undefined, //一级分类id
                        sec_classify: undefined, //二级分类id
                        keyword: [], //关键字
                        activity_id: "", //管理员提交时 取消该参数
                        tool_file: '', //工具文件
                        tool_book: '', //工具手册
                        desc: '', //工具描述
                    });
                })

                setFileList([]);
                setPercent(0)
                setUploadLoading(false);
            },
            error: function (res) {
                errMessage(res.responseJSON)
                setIsUpload(false);
                setUploadLoading(false);
            }
        });
    }
    const editTool = () => {
        let markText = editorIns.current.getMarkdown();
        if (srcUrl(submit.editorState.toHTML())) {
            Alert('warn', '工具手册中图片存在外部链接，请检查！！！')
            return
        }
        if (submit.name.trim() === '') {
            Alert('error', '请填写工具名称')
            return
        }
        if (submit.desc.trim() === '') {
            Alert('error', '请填写工具简介')
            return
        }
        if (submit.classify === undefined) {
            Alert('error', '请选择一级分类')
            return
        }
        if (submit.sec_classify === undefined) {
            Alert('error', '请选择二级分类')
        }
        if (submit.system === '') {
            Alert('error', '请选择工具体系')
        }
        if (submit.editorState.toHTML() === '<p></p>' && !markText) {
            Alert('error', '请填写工具手册')
            return
        }
        if (submit.keyword.length === 0) {
            Alert('error', '请填写关键字')
            return
        }
        let formData = new FormData();
        if (submit.tool_file !== undefined) {
            if (fileList.length === 0) {
                Alert('error', '请上传工具附件');
                return
            } else {
                formData.append('tool_file', fileList[0]);
                formData.append('attached_name', submit.attached_name);
            }
        }
        setUploadLoading(true);
        formData.append('name', submit.name);
        formData.append('desc', submit.desc);
        if (submit.tool_book_type === 1) {
            formData.append('tool_book', submit.editorState.toHTML());
        }
        if (submit.tool_book_type === 2) {
            formData.append('tool_book_md', markText);
        }
        formData.append('tool_book_type', submit.tool_book_type);
        formData.append('classify', submit.classify);
        formData.append('sec_classify', submit.sec_classify);
        formData.append('keyword', JSON.stringify(submit.keyword));
        formData.append('system', submit.system);
        if (submit.activity_id !== "" && submit.activity_id !== "0" && submit.activity_id !== "-1") {
            formData.append('activity_id', submit.activity_id);
        }
        $.ajax({
            url: `/tool/${param.param.match.params.id}/`,
            type: 'PUT',
            enctype: 'multipart/form-data',
            data: formData,
            // 告诉jQuery不要去处理发送的数据
            processData: false,
            // 告诉jQuery不要去设置Content-Type请求头
            contentType: false,
            timeout: 3000000,//设置超时时间
            beforeSend: function () {
                setIsUpload(true);
            },
            xhr: function () {
                let myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) {
                    myXhr.upload.addEventListener('progress', function (e) {
                        if (e.lengthComputable) {
                            let percent = Math.floor((e.loaded / e.total) * 100);
                            setPercent(percent);
                            if (myXhr.readyState === 1 && percent === 100) {
                                setPercent(99)
                            }
                        }
                    }, false);
                    myXhr.upload.addEventListener('load', function (e) { }, false);
                }
                return myXhr;
            },
            success: function (res) {
                setIsUpload(false);
                Alert('success', '编辑成功')
                setPercent(0)
                history.go(-1);
            },
            error: function (res) {
                errMessage(res.responseJSON);
                setIsUpload(false);
                setUploadLoading(false);
            }
        });
    }
    let errMessage = (err) => {
        for (let key in err) {
            Alert('error', err[key])
        }
    }
    //点击审核操作
    let handleAudit = (data) => {
        if (data.status === 1) {
            if (data.data === "") {
                Alert("error", "请输入奖励奖励数");
                return
            }
        }
        if ((data.status === 2 || data.status === 3) && data.data === "") {
            Alert("error", "请输入审核意见");
            return
        }
        instance.post("/tool/" + param.param.match.params.id + "/audit/", data).then(data => {
            Alert("success", "操作成功");
            window.location.href = "/#/resource/tool"
        }).catch(err => {
            Alert("error", err.error)
        })
    }
    //点击上传到媒体库
    let addMediaItem = () => {
        document.getElementsByClassName("images")[0].click();
        document.getElementsByClassName("images")[0].value = "";
        setImagesType(0)
    }
    //点击插入到编辑器
    let insertMediItem = () => {
        document.getElementsByClassName("images")[0].click();
        document.getElementsByClassName("images")[0].value = "";
        setImagesType(1);
    }
    //点击上传文件
    let changeImage = () => {
        let file = document.getElementsByClassName("images")[0].files[0];
        if (file !== undefined) {
            if (file.size > 5 * 1024 * 1024) {
                Alert("error", "上传图片不能大于5M")
                return
            }
            let form = document.getElementById('file-image');
            let formdData = new FormData(form);
            formdData.append("model", "ctf");
            instance.post("/ctf/upload_image/", formdData).then(data => {
                if (imagesType === 0) {
                    if (dateRef.current !== undefined) {
                        let braftFinder = dateRef.current.getFinderInstance();
                        braftFinder.addItems([
                            {
                                id: new Date().getTime(),
                                type: 'IMAGE',
                                url: data.url
                            }
                        ])
                    }
                } else if (imagesType === 1) {
                    let editorState = ContentUtils.insertMedias(submit.editorState, [
                        {
                            type: 'IMAGE',
                            url: data.url
                        }
                    ])
                    submit.editorState = editorState;
                    setSubmit({ ...submit })
                }
                Alert("success", "上传成功")
            }).catch(err => {
                if (err.image) {
                    Alert("error", err.image)
                }
            })
        }
    }
    //点击检索按钮进行检索，根据类型判断 检索的字段
    let retrievalClick = (type, text) => {
        if (text.replace(/(^\s*)|(\s*$)/g, "") === "") {
            Alert("warn", "请输入检索内容");
            return
        }
        let list = {
            type: "tool",
            flag: text,
            search_type: type
        }
        instance.get("/ctf/search/", { params: list }).then(data => {
            retrievalBox.show = true;
            retrievalBox.count = data.count;
            retrievalBox.type = type;
            setRetrievalBox({ ...retrievalBox })
        })
    }
    let myUploadFn = (param) => {
        const fd = new FormData();
        fd.append('image', param.file);
        fd.append("model", "ctf");
        instance.post("/ctf/upload_image/", fd).then(data => {
            param.success({
                url: data.url,
            })
        })
    }
    const uploadProps = {
        // action: `/tools/test/`,
        fileList: fileList,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setSubmit({ ...submit, tool_file: '' })
        },
        beforeUpload: (file) => {
            // return new Promise((resolve, reject) => {
            //     setAddData({ ...addData, tool_file: '' });
            //     setFileList([file]);
            //     return resolve(false);
            // })
            setSubmit({ ...submit, tool_file: '', attached_name: file.name });
            setFileList([file]);
            return false
        },
    };
    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-div submit-ctf-name">
                    {
                        type === "0" && "提交工具"
                    }
                    {
                        type === "1" && "编辑工具"
                    }
                    {
                        type === "2" && "审核工具"
                    }
                    {
                        detail.status === 3 &&
                        <div className="status">
                            <img src={Denied} alt="" />
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    (detail.opin || []).map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">{val.msg}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div subject-info">
                    <div className="title">
                        工具信息
                    </div>
                    <div className="content">
                        <div className="retrieval-div">
                            <span><b>*</b>工具名称</span>
                            <Input
                                value={submit.name}
                                className="content-input"
                                placeholder="请输入工具名称"
                                onChange={(e) => inputChange(e, "name")}
                                maxLength="200"
                            />
                            <span className="number">{200 - submit.name.length}</span>
                            <Button type="primary" onClick={() => retrievalClick(0, submit.name)}>检索</Button>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>工具简介</span>
                            <TextArea
                                value={submit.desc}
                                className="content-input"
                                placeholder="请输入工具简介"
                                onChange={(e) => inputChange(e, "desc")}
                            // autoSize
                            // maxLength="180"
                            />
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>工具分类</span>
                            <Cascader
                                changeOnSelect
                                fieldNames={{ label: 'name', value: 'id', children: 'sec_classify', disabled: 'is_self' }}
                                options={classify}
                                onChange={([classify, sec_classify]) => { setSubmit({ ...submit, classify, sec_classify }) }}
                                placeholder="请选择工具分类"
                                value={(submit.classify === undefined && submit.sec_classify === undefined) ? undefined : [submit.classify, submit.sec_classify]}
                            />
                        </div>
                        <div className='retrieval-div'>
                            <span><b>*</b>工具体系</span>
                            <Select
                                style={{ width: 187 }}
                                value={submit.system}
                                onChange={e => {
                                    submit.system = e;
                                    setSubmit({ ...submit })
                                }}
                            >
                                {
                                    systemList.map((val, key) => {
                                        return <Option value={val.id} key={key}>
                                            {val.name}
                                        </Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div className='retrieval-div'>
                            <span><b>*</b>手册类型</span>
                            <Radio.Group
                                value={submit.tool_book_type}
                                onChange={(e) => inputChange(e, "tool_book_type")}
                            >
                                <Radio value={1}>富文本编辑器</Radio>
                                <Radio value={2}>MarkDown编辑器</Radio>
                            </Radio.Group>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>工具手册</span>
                            {
                                submit.tool_book_type === 1 &&
                                <div className="my-component tool-book-div">
                                    <BraftEditor
                                        className="braft-style"
                                        value={submit.editorState}
                                        onChange={handleEditorChange}
                                        ref={dateRef}
                                        media={{
                                            uploadFn: myUploadFn,
                                            image: false
                                        }}
                                        excludeControls={["superscript", "subscript", "emoji", "remove-styles"]}
                                        extendControls={[
                                            'separator',
                                            {
                                                key: 'add-media',
                                                type: 'button',
                                                text: '插入图片到媒体库',
                                                onClick: () => addMediaItem()
                                            },
                                            {
                                                key: 'insert-media',
                                                type: 'button',
                                                text: '插入图片到编辑器',
                                                onClick: () => insertMediItem()
                                            }
                                        ]}
                                    />
                                </div>
                            }
                            {
                                submit.tool_book_type === 2 &&
                                <div className="my-component tool-book-div" ref={editorDom}></div>
                            }
                        </div>
                    </div>
                </div>
                <div className="submit-ctf-div subject-keyword">
                    <div className="title">
                        添加关键字
                    </div>
                    <div className="content">
                        <div>
                            <b>*</b>
                            关键字
                        </div>
                        <div>
                            <div>
                                <Input
                                    value={keyword}
                                    placeholder="题目关键字，回车添加关键字"
                                    onPressEnter={onPressEnter}
                                    onChange={keywordChange}
                                    maxLength={16}
                                />
                                <span className="number">{16 - keyword.length}</span>
                                添加关键字（{3 - submit.keyword.length}）
                            </div>
                            <div>
                                {
                                    submit.keyword.map((val, key) => {
                                        return <span key={"key" + key}>
                                            {val}
                                            <CloseOutlined
                                                onClick={() => deleteChange(key)}
                                            />
                                        </span>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="submit-ctf-div subject-upload-tool">
                    <div className="title">
                        上传工具
                        {/* <span>提供题目的离线附件或者Web题目的源码压缩包，或者题目环境，将能得到更多奖励</span> */}
                    </div>
                    <div className="content">
                        <div className="upload-tool-div">
                            <div className="upload-tool-left"><b>*</b>上传工具</div>
                            <Upload {...uploadProps} className="upload-tool-opt" disabled={isUpload}>
                                <Button type="primary" className="upload-btn"><UploadOutlined />选择工具</Button>
                            </Upload>
                        </div>
                        {
                            isUpload &&
                            <>
                                <Progress
                                    // strokeColor={{
                                    //     from: '#108ee9',
                                    //     to: '#87d068',
                                    // }}
                                    percent={percent}
                                    status="active"
                                />
                                <span style={{ color: '#bbb' }}>（上传中，请稍候）</span>
                            </>
                        }
                    </div>
                </div>
                {
                    //新增
                    type === "0" &&
                    <div className="submit-btn">
                        <Button type="primary" onClick={() => addTool()} loading={uploadLoading}>提交</Button>
                    </div>
                }
                {
                    //编辑
                    type === "1" &&
                    <div className="submit-btn">
                        <Button type="primary" onClick={() => editTool()}>提交</Button>
                    </div>
                }
                {
                    type === "2" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => {
                                initBox.passBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            通过
                        </Button>
                        <Button
                            style={{ backgroundColor: "transparent", color: "#266cff" }}
                            type="primary"
                            onClick={() => window.location.href = "/#/resource/tool"}
                        >
                            取消
                        </Button>
                        <Button className="modify" onClick={() => editTool()}>保存修改</Button>
                        <Button
                            className="decline"
                            onClick={() => {
                                initBox.declineBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            拒绝
                        </Button>
                        <Button
                            className="added"
                            onClick={() => {
                                initBox.addedBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            待补充
                        </Button>
                        <Button
                            className="added"
                            onClick={() => handleAudit({ status: 0 })}
                        >
                            待审核
                        </Button>
                    </div>
                }
            </div>
            {
                retrievalBox.show &&
                <Retrieval
                    close={() => {
                        retrievalBox.show = false;
                        setRetrievalBox({ ...retrievalBox })
                    }}
                    count={retrievalBox.count}
                    name={
                        {
                            0: "工具名称",
                            1: "题目标题",
                            2: "题目内容"
                        }[retrievalBox.type]
                    }
                />
            }
            {
                initBox.passBox &&
                <PassBox
                    close={() => {
                        initBox.passBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.declineBox &&
                <DeclineBox
                    close={() => {
                        initBox.declineBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.addedBox &&
                <AddedBox
                    close={() => {
                        initBox.addedBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            <form id="file-image">
                <input
                    type="file"
                    name="image"
                    accept=".jpg,.png,.gif"
                    style={{ display: "none" }}
                    className="images"
                    onChange={changeImage}
                />
            </form>
        </div>
    )
}
export default SubmitCTF;