import React, { useEffect, useState } from 'react';
import { Input, Button, Radio, Row, Col, DatePicker, Select, Tabs, InputNumber, Progress, message, } from 'antd';
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import instance from '../../../Communal/instance';
import moment from 'moment';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import { Alert, srcUrl } from '../../../Communal/utility';
import history from '../../../Communal/history';
import $ from 'jquery';
import Retrieval from '../../../Activity/retrieval';
import MarkDownModal from '../../MarkDownModal';
import SubmitBox from '../../submitBox';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import 'ace-builds/webpack-resolver'

const { Option } = Select;
const { TextArea } = Input
const { TabPane } = Tabs;
const Add = ({ param }) => {
    const [type, setType] = useState("0"); //type: 0 添加 1 编辑
    const [error, setError] = useState({});
    const [retrievalBox, setRetrievalBox] = useState({
        show: false,
        type: 0,
        count: 0
    });
    const [companyList, setCompanyList] = useState([])
    const [modelList, setModelList] = useState([])
    const [writeBox, setWriteBox] = useState({
        show: false
    })
    const [submitBox, setSubmitBox] = useState({
        show: false
    })
    const [attachedShow, setAttachedShow] = useState(false)
    const [attachedPercent, setAttachedPercent] = useState(0)
    const [submitLoading, setSubmitLoading] = useState(false);
    const [checkLoading, setCheckLoading] = useState(false);
    const [submit, setSubmit] = useState({
        name: '',//名称
        vulnbrief: '',//简介
        severity: 4,//漏洞级别 0 超危 1 高危 2 中危 3 低危 4 未定义
        cve: '',//cve编号
        cnnvd: '',//cnnvd编号
        cnvd: '',//cnvd编号
        vulntype: '',//漏洞类型 
        vulnstatus: 0,//漏洞状态  0 情报 1 POC 2 EXP
        published: null,//漏洞披露时间
        modified: moment().format("YYYY-MM-DD"),//漏洞更新时间
        thrtype: 0,//情报类型 0 本地 1 远程
        cvss2: '',//漏洞cvss2评分
        cvss20: null,//漏洞cvss2评分分值
        cvss3: '',//漏洞cvss3评分
        cvss30: null,//漏洞cvss3评分分值
        cpe: '',//漏洞cpe
        ref: '',//影响产品
        // vulndescript: '',//漏洞描述,
        vulnsolution: '',//漏洞修复方案
        refurls: '',//漏洞参考链接
        verify: '',//验证地址
        company: null,//组件厂商
        model_name: null,//影响组件
        resource_level: 4,//查看等级
        is_day: true,//是否0day
        vulpoc: BraftEditor.createEditorState(null),//poc
        vulexp: BraftEditor.createEditorState(null),//exp
        analysis: BraftEditor.createEditorState(null),//分析过程
        plug: '',//插件
        attached: null,//附件
        attached_name: '',//附件名称
        rules: {},
    })
    const [userType, setUserType] = useState(0);

    useEffect(() => {
        setType(param.match.params.type);
        if (param.match.params.id !== '0') {
            getDetail(param.match.params.id)
        }
        document.getElementsByClassName('submit-ctf')[0].style.minHeight = 'calc(100vh - 40px)';
    }, [param]);

    useEffect(() => {
        instance.get(`/vulninte/api/static/module/`).then(data => {
            if (Array.isArray(data)) {
                setCompanyList(data)
            }
        })
        instance.get("/ctf/user/type/?mode=vulnintelligence").then(data => {
            setUserType(data.user_type);
        })
    }, [])

    const getModelName = (value) => {
        // 获取当前厂商的组件
        instance.post(`/vulninte/api/static/module/`, { company: value }).then(data => {
            if (Array.isArray(data)) {
                setModelList(data)
            }
        })
    }
    const retrievalClick = (text) => {
        if (text.replace(/(^\s*)|(\s*$)/g, "") === "") {
            Alert("warn", "请输入检索内容");
            return
        }
        let list = {
            type: "vulninte",
            flag: text,
            search_type: 0
        }
        instance.get("/ctf/search/", { params: list }).then(data => {
            retrievalBox.show = true;
            retrievalBox.count = data.count;
            setRetrievalBox({ ...retrievalBox })
        })
    }
    const getDetail = (id) => {
        instance.get(`/vulninte/api/all/${id}/`).then(res => {
            getModelName(res?.module?.company)
            res.vulpoc = BraftEditor.createEditorState(res.vulpoc);
            res.vulexp = BraftEditor.createEditorState(res.vulexp);
            res.analysis = BraftEditor.createEditorState(res.analysis);
            res.company = res?.module?.company
            res.model_name = res?.module?.module_name;
            res.modified=moment().format("YYYY-MM-DD");
            setSubmit(res);
        })
    }
    let inputChange = (value, name) => {
        submit[name] = value;
        setSubmit({ ...submit })
        if (name === 'company') {
            getModelName(value)
            submit.model_name = null
            setSubmit({ ...submit })
        }
    }
    let handleFouce = (name) => {
        error[name] = null
        setError({ ...error })
    }

    const commitResources = (type) => {
        let data = { ...submit };
        data.vulpoc = data.vulpoc.toHTML();
        data.vulexp = data.vulexp.toHTML();
        data.analysis = data.analysis.toHTML();
        let ruleEmpty = 0;
        if (srcUrl(data.vulpoc)) {
            Alert('warn', '漏洞POC中图片存在外部链接，请检查！！！')
            return
        }
        if (srcUrl(data.vulexp)) {
            Alert('warn', '漏洞EXP中图片存在外部链接，请检查！！！')
            return
        }
        if (srcUrl(data.analysis)) {
            Alert('warn', '分析过程中图片存在外部链接，请检查！！！')
            return
        }
        if (data.name === '') {
            error.name = '请输入漏洞名称'
        }
        if (data.vulnbrief === '') {
            error.vulnbrief = '请输入漏洞简介'
        }
        if (!data.published) {
            error.published = '请选择漏洞披露时间'
        }
        if (!data.modified) {
            error.modified = '请选择漏洞更新时间'
        }
        if (data.name === '' || data.vulnbrief === '' || !data.published || !data.modified) {
            setError({ ...error })
            return
        }
        Object.keys(data.rules).map(item => {
            if (data.rules[item] === '') {
                ruleEmpty += 1;
            }
        })
        if (ruleEmpty > 0) {
            Alert('warn', '请检查检测规则，检测规则不能为空！')
            return
        }
        submitBox.show = true
        setSubmitBox({ ...submitBox })
    }

    const handleOk = () => {
        let data = { ...submit };
        data.vulpoc = data.vulpoc.toHTML();
        data.vulexp = data.vulexp.toHTML();
        data.analysis = data.analysis.toHTML();
        setSubmitLoading(true)
        if (type === "0") {
            instance.post('/vulninte/api/all/', data).then(res => {
                Alert("success", "提交成功");
                history.push({ pathname: '/resource/vulninfo' })
                setSubmitLoading(false)
            }).catch(err => {
                setSubmitLoading(false);
                setError(err);
                submitBox.show = false
                setSubmitBox({ ...submitBox })
                if (err?.content) {
                    error.plug = err.content[0]
                    setError({ ...error })
                }
            })
        }
        if (type === "1" || type === "2") {
            instance.patch(`/vulninte/api/all/${data.id}/`, data).then(res => {
                Alert("success", "提交成功");
                history.push({ pathname: '/resource/vulninfo' })
                setSubmitLoading(false)
            }).catch(err => {
                setSubmitLoading(false);
                setError(err);
                submitBox.show = false
                setSubmitBox({ ...submitBox })
                if (err?.content) {
                    error.plug = err.content[0]
                    setError({ ...error })
                }
            })
        }
    }
    let myUploadFn = (param) => {
        const fd = new FormData();
        fd.append('image', param.file);
        fd.append("model", "vuln");
        instance.post("/ctf/upload_image/", fd).then(data => {
            param.success({
                url: data.url,
            })
        })
    }
    // 点击选择触发上传
    const uploadChange = () => {
        if (attachedShow === true) {
            Alert('warn', '有文件正在上传中，请稍候')
            return
        }
        document.getElementsByClassName("upload-file-one")[0].click();
        document.getElementsByClassName("upload-file-one")[0].value = "";
        submit.attached = null;
        submit.attached_name = '';
        error.attached = [];
        setSubmit({ ...submit })
        setError({ ...error })
    }
    // 上传附件
    const changeFile = () => {
        let file = document.getElementsByClassName("upload-file-one")[0].files[0];
        if (file !== undefined) {
            if (file.size > 1024 * 1024 * 500) {
                Alert("error", "上传附件不能大于500M");
                return
            }
            let form = document.getElementById('formImg1');
            let formData = new FormData(form);
            formData.append("model", "vuln")
            setAttachedShow(true)
            $.ajax({
                url: "/ctf/upload_file/",
                type: "POST",
                data: formData,
                contentType: false,
                processData: false,
                xhr: () => {
                    var myXhr = $.ajaxSettings.xhr()
                    if (myXhr.upload) {
                        myXhr.upload.addEventListener('progress', (e) => {
                            var per = Math.floor(100 * e.loaded / e.total);  //已经上传的百分比
                            setAttachedPercent(per)
                            if (myXhr.readyState === 1 && per === 100) {
                                setAttachedPercent(99)
                            }
                        }, false);
                    }
                    return myXhr;
                },
                success: (data) => {
                    setAttachedShow(false)
                    setAttachedPercent(0)
                    submit.attached_name = data.origin_file_name;
                    submit.attached = data.url
                    setSubmit({ ...submit })
                },
                error: (err) => {
                    setAttachedShow(false)
                    setAttachedPercent(0)
                    Alert('warn', err.responseJSON.non_field_errors[0])
                }
            })
        }
    }
    let handleEditorChange = (e, name) => {
        submit[name] = e;
        setSubmit({ ...submit })
    }
    const handleCheckPlug = () => {
        if (submit.plug === '') {
            Alert('error', '请输入要校验的插件内容')
            return false
        }
        setCheckLoading(true)
        instance.post("/vulninte/api/check/", { plug: submit.plug }).then(data => {
            setCheckLoading(false)
            if (data?.content) {
                error.plug = data.content[0]
                setError({ ...error })
            }
        }).catch((err) => {
            setCheckLoading(false)
            if (err?.content) {
                error.plug = err.content[0]
                setError({ ...error })
            }
        })
    }
    const changeRule = (item, value) => {
        submit.rules[item] = value;
        setSubmit({ ...submit })
    }
    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-div submit-ctf-name">
                    {
                        type === "0" && "提交漏洞情报"
                    }
                    {
                        type === "1" && "编辑漏洞情报"
                    }
                </div>
                <div className="submit-ctf-div subject-info">
                    <div className="title">
                        漏洞情报信息
                    </div>
                    <div className="content">
                        <div className="retrieval-div">
                            <span style={{ width: '100px' }}><b>*</b>漏洞名称</span>
                            <Input
                                value={submit.name}
                                className="content-input"
                                style={{ width: 'calc(100% - 180px)' }}
                                placeholder="请输入漏洞名称"
                                onChange={(e) => inputChange(e.target.value, "name")}
                                maxLength="255"
                                onFocus={() => handleFouce("name")}
                            />
                            <span className="number">{255 - submit.name.length}</span>
                            <Button type="primary" onClick={() => retrievalClick(submit?.name)}>检索</Button>
                            <div className="error-message" style={{ marginLeft: '100px' }}>{error?.name}</div>
                        </div>
                        <div className="retrieval-div">
                            <span style={{ width: '100px' }}><b>*</b>漏洞简介</span>
                            <Input.TextArea
                                value={submit.vulnbrief}
                                className="content-input"
                                style={{ width: 'calc(100% - 180px)' }}
                                placeholder="请输入漏洞简介"
                                onChange={(e) => inputChange(e.target.value, "vulnbrief")}
                                maxLength="500"
                                onFocus={() => handleFouce("vulnbrief")}
                            />
                            <span className="number">{500 - submit.vulnbrief.length}</span>
                            {/* <Button type="primary" onClick={() => retrievalClick(2, submit.vulnbrief)}>检索</Button> */}
                            <div className="error-message" style={{ marginLeft: '100px' }}>{error?.vulnbrief}</div>
                        </div>
                        <Row>
                            <Col span={8}>
                                <div>
                                    <span className='name' style={{ width: '120px' }}><b>*</b>漏洞披露时间</span>
                                    <DatePicker
                                        value={submit.published ? moment(submit.published, 'YYYY-MM-DD') : null}
                                        onChange={(_, dateString) => {
                                            inputChange(dateString, "published");
                                            handleFouce("published")
                                        }}
                                    />
                                    <div className="error-message" style={{ marginLeft: '100px' }}>{error?.published}</div>
                                </div>
                            </Col>
                            {/* <Col span={8}>
                                <div>
                                    <span className='name' style={{ width: '120px' }}><b>*</b>漏洞更新时间</span>
                                    <DatePicker
                                        value={submit.modified ? moment(submit.modified, 'YYYY-MM-DD') : null}
                                        onChange={(_, dateString) => {
                                            inputChange(dateString, "modified");
                                            handleFouce("modified");
                                        }}
                                    />
                                    <div className="error-message" style={{ marginLeft: '100px' }}>{error?.modified}</div>
                                </div>
                            </Col> */}
                            <Col span={8}>
                                <div>
                                    <span className='name' style={{ width: '100px' }}>漏洞等级</span>
                                    <Select
                                        value={submit.severity}
                                        style={{ width: '120px' }}
                                        onChange={(e) => {
                                            inputChange(e, "severity")
                                        }}
                                    >
                                        <Option value={0}>
                                            超危
                                        </Option>
                                        <Option value={1}>
                                            高危
                                        </Option>
                                        <Option value={2}>
                                            中危
                                        </Option>
                                        <Option value={3}>
                                            低危
                                        </Option>
                                        <Option value={4}>
                                            未定义
                                        </Option>
                                    </Select>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div>
                                    <span className='name' style={{ width: '100px' }}>CVSS2.0评分</span>
                                    <Input
                                        value={submit.cvss2}
                                        className="content-input"
                                        style={{ width: 'calc(100% - 180px)' }}
                                        placeholder="请输入CVSS2.0评分"
                                        onChange={(e) => inputChange(e.target.value, "cvss2")}
                                        onFocus={() => handleFouce("cvss2")}
                                    />
                                    <div className="error-message" style={{ marginLeft: '100px' }}>{error?.cvss2}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div>
                                    <span className='name' style={{ width: '100px' }}>CVSS3.0评分</span>
                                    <Input
                                        value={submit.cvss3}
                                        className="content-input"
                                        style={{ width: 'calc(100% - 180px)' }}
                                        placeholder="请输入CVSS3.0评分"
                                        onChange={(e) => inputChange(e.target.value, "cvss3")}
                                        onFocus={() => handleFouce("cvss3")}
                                    />
                                    <div className="error-message" style={{ marginLeft: '100px' }}>{error?.cvss3}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <div>
                                    <span className='name' style={{ width: '100px' }}>2.0基本评分</span>
                                    <InputNumber
                                        value={submit.cvss20}
                                        // precision={0}
                                        className="content-input"
                                        style={{ width: 'calc(100% - 180px)' }}
                                        placeholder="请输入2.0基本评分"
                                        onChange={(e) => inputChange(e, "cvss20")}
                                        onFocus={() => handleFouce("cvss20")}
                                    />
                                    <div className="error-message" style={{ marginLeft: '100px' }}>{error?.cvss20}</div>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div>
                                    <span className='name' style={{ width: '100px' }}>3.0基本评分</span>
                                    <InputNumber
                                        value={submit.cvss30}
                                        // precision={0}
                                        className="content-input"
                                        style={{ width: 'calc(100% - 180px)' }}
                                        placeholder="请输入3.0基本评分"
                                        onChange={(e) => inputChange(e, "cvss30")}
                                        onFocus={() => handleFouce("cvss30")}
                                    />
                                    <div className="error-message" style={{ marginLeft: '100px' }}>{error?.cvss30}</div>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div>
                                    <span className='name' style={{ width: '100px' }}>漏洞类型</span>
                                    <Input
                                        value={submit.vulntype}
                                        className="content-input"
                                        style={{ width: 'calc(100% - 180px)' }}
                                        placeholder="请输入漏洞类型"
                                        onChange={(e) => inputChange(e.target.value, "vulntype")}
                                        maxLength="50"
                                        onFocus={() => handleFouce("vulntype")}
                                    />
                                    <div className="error-message" style={{ marginLeft: '100px' }}>{error?.vulntype}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <div>
                                    <span className='name' style={{ width: '100px' }}>CNVD编号</span>
                                    <Input
                                        value={submit.cnvd}
                                        className="content-input"
                                        style={{ width: 'calc(100% - 180px)' }}
                                        placeholder="请输入CNVD编号"
                                        onChange={(e) => inputChange(e.target.value, "cnvd")}
                                        maxLength="50"
                                        onFocus={() => handleFouce("cnvd")}
                                    />
                                    <div className="error-message" style={{ marginLeft: '100px' }}>{error?.cnvd}</div>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div>
                                    <span className='name' style={{ width: '100px' }}>CNNVD编号</span>
                                    <Input
                                        value={submit.cnnvd}
                                        className="content-input"
                                        style={{ width: 'calc(100% - 180px)' }}
                                        placeholder="请输入CNNVD编号"
                                        onChange={(e) => inputChange(e.target.value, "cnnvd")}
                                        maxLength="50"
                                        onFocus={() => handleFouce("cnnvd")}
                                    />
                                    <div className="error-message" style={{ marginLeft: '100px' }}>{error?.cnnvd}</div>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div>
                                    <span className='name' style={{ width: '100px' }}>CVE编号</span>
                                    <Input
                                        value={submit.cve}
                                        className="content-input"
                                        style={{ width: 'calc(100% - 180px)' }}
                                        placeholder="请输入CVE编号"
                                        onChange={(e) => inputChange(e.target.value, "cve")}
                                        maxLength="50"
                                        onFocus={() => handleFouce("cve")}
                                    />
                                    <div className="error-message" style={{ marginLeft: '100px' }}>{error?.cve}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <div>
                                    <span className='name' style={{ width: '100px' }}>组件厂商</span>
                                    <Select
                                        showSearch
                                        value={submit.company}
                                        style={{ width: 'calc(100% - 180px)' }}
                                        onChange={(e) => {
                                            inputChange(e, "company")
                                        }}
                                    >
                                        {
                                            companyList.map((val, key) => {
                                                return <Option
                                                    key={key}
                                                    value={val}
                                                >
                                                    {val}
                                                </Option>
                                            })
                                        }
                                    </Select>
                                    <div className="error-message" style={{ marginLeft: '100px' }}>{error?.company}</div>
                                </div>
                            </Col>
                            <Col span={8}>
                                <span className='name' style={{ width: '100px' }}>影响组件</span>
                                <Select
                                    showSearch
                                    value={submit.model_name}
                                    style={{ width: 'calc(100% - 180px)' }}
                                    disabled={submit.company === null}
                                    onChange={(e) => {
                                        inputChange(e, "model_name")
                                    }}
                                >
                                    {
                                        modelList.map((val, key) => {
                                            return <Option
                                                key={key}
                                                value={val}
                                            >
                                                {val}
                                            </Option>
                                        })
                                    }
                                </Select>
                                <div className="error-message" style={{ marginLeft: '100px' }}>{error?.model_name}</div>
                            </Col>
                            <Col span={8}>
                                {userType > 1 &&
                                    <>
                                        <span className='name' style={{ width: '100px' }}>查看等级</span>
                                        <InputNumber
                                            value={submit.resource_level}
                                            precision={0}
                                            className="content-input"
                                            style={{ width: 'calc(100% - 180px)' }}
                                            placeholder="请输入等级"
                                            onChange={(e) => inputChange(e, "resource_level")}
                                            onFocus={() => handleFouce("resource_level")}
                                        />
                                        <div className="error-message" style={{ marginLeft: '100px' }}>{error?.resource_level}</div>
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <div>
                                    <span className='name' style={{ width: '100px' }}>情报类型</span>
                                    <Radio.Group
                                        value={submit.thrtype}
                                        onChange={(e) => {
                                            inputChange(e.target.value, "thrtype")
                                        }}
                                    >
                                        <Radio value={0}>
                                            本地
                                        </Radio>
                                        <Radio value={1}>
                                            远程
                                        </Radio>
                                    </Radio.Group>
                                </div>
                            </Col>
                            <Col span={16}>
                                <div>
                                    <span className='name' style={{ width: '100px' }}>是否0day</span>
                                    <Radio.Group
                                        value={submit.is_day}
                                        onChange={(e) => {
                                            inputChange(e.target.value, "is_day")
                                        }}
                                    >
                                        <Radio value={true}>
                                            是
                                        </Radio>
                                        <Radio value={false}>
                                            否
                                        </Radio>
                                    </Radio.Group>
                                </div>
                            </Col>
                        </Row>
                        <Row className='subject-upload'>
                            <Col span={24} className='content' style={{ padding: 0 }}>
                                <div style={{ lineHeight: '40px' }}>
                                    <span className='name' style={{ width: '100px' }}>情报附件</span>
                                    <form id="formImg1" style={{ marginLeft: '20px' }}>
                                        <div>
                                            <span className="upload" onClick={uploadChange}>
                                                选择
                                                <input
                                                    type="file"
                                                    name="file"
                                                    className="upload-file-one"
                                                    accept=".zip,"
                                                    onChange={changeFile}
                                                />
                                            </span>
                                            （提示：附件可上传源代码、利用工具等内容的压缩包，500M以内）
                                            {
                                                submit.attached && submit.attached !== "" && [
                                                    <span className="file-name" key="1">
                                                        <a
                                                            href={submit.attached}
                                                            download
                                                        >
                                                            <PaperClipOutlined />
                                                            {submit?.attached_name}
                                                        </a>
                                                    </span>,
                                                    <Button
                                                        type="link"
                                                        key="2"
                                                        danger
                                                        onClick={() => {
                                                            submit.attached_name = "";
                                                            submit.attached = "";
                                                            setSubmit({ ...submit })
                                                        }}>
                                                        删除
                                                    </Button>
                                                ]
                                            }
                                            {
                                                attachedShow &&
                                                <>
                                                    <Progress percent={attachedPercent} />
                                                    <span> （上传中，请稍候）</span>
                                                </>
                                            }
                                        </div>
                                    </form>
                                    <div className="error-message" style={{ marginLeft: '100px' }}>
                                        {error?.attached}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style={{ padding: '0px 15px', background: '#fff', marginTop: '20px' }}>
                    <Tabs>
                        <TabPane tab='情报信息' key='1'>
                            <div className='submit-ctf-div subject-info'>
                                <div className='content'>
                                    <div>
                                        <span className='name' style={{ width: '100px' }}>影响产品</span>
                                        <Input.TextArea
                                            value={submit.ref}
                                            className="content-input"
                                            style={{ width: 'calc(100% - 180px)' }}
                                            placeholder="请输入影响产品"
                                            onChange={(e) => inputChange(e.target.value, "ref")}
                                            maxLength="150"
                                            onFocus={() => handleFouce("ref")}
                                        />
                                        <div className="error-message" style={{ marginLeft: '100px' }}>{error?.ref}</div>
                                    </div>
                                    <div>
                                        <span style={{ width: '100px' }} className='name'>漏洞CPE</span>
                                        <Input.TextArea
                                            value={submit.cpe}
                                            className="content-input"
                                            style={{ width: 'calc(100% - 180px)' }}
                                            placeholder="请输入漏洞CPE"
                                            onChange={(e) => inputChange(e.target.value, "cpe")}
                                            maxLength="250"
                                            onFocus={() => handleFouce("cpe")}
                                        />
                                        <div className="error-message" style={{ marginLeft: '100px' }}>{error?.cpe}</div>
                                    </div>
                                    <div>
                                        <span style={{ width: '100px' }} className='name'>漏洞修复方案</span>
                                        <Input.TextArea
                                            value={submit.vulnsolution}
                                            className="content-input"
                                            style={{ width: 'calc(100% - 180px)' }}
                                            placeholder="请输入漏洞修复方案"
                                            onChange={(e) => inputChange(e.target.value, "vulnsolution")}
                                            maxLength="250"
                                            onFocus={() => handleFouce("vulnsolution")}
                                        />
                                        <div className="error-message" style={{ marginLeft: '100px' }}>{error?.vulnsolution}</div>
                                    </div>
                                    <div>
                                        <span style={{ width: '100px' }} className='name'>参考链接</span>
                                        <Input.TextArea
                                            value={submit.refurls}
                                            className="content-input"
                                            style={{ width: 'calc(100% - 180px)' }}
                                            placeholder="请输入参考链接"
                                            onChange={(e) => inputChange(e.target.value, "refurls")}
                                            maxLength="250"
                                            onFocus={() => handleFouce("refurls")}
                                        />
                                        <div className="error-message" style={{ marginLeft: '100px' }}>{error?.refurls}</div>
                                    </div>
                                    <div>
                                        <span style={{ width: '100px' }} className='name'>验证地址</span>
                                        <Input.TextArea
                                            value={submit.verify}
                                            className="content-input"
                                            style={{ width: 'calc(100% - 180px)' }}
                                            placeholder="请输入参考链接"
                                            onChange={(e) => inputChange(e.target.value, "verify")}
                                            maxLength="250"
                                            onFocus={() => handleFouce("verify")}
                                        />
                                        <div className="error-message" style={{ marginLeft: '100px' }}>{error?.verify}</div>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tab='漏洞POC' key='2'>
                            <div className="my-component" style={{ marginBottom: '40px' }}>
                                <BraftEditor
                                    value={submit.vulpoc}
                                    onChange={(e) => handleEditorChange(e, 'vulpoc')}
                                    media={{
                                        uploadFn: myUploadFn,
                                        image: false
                                    }}
                                    excludeControls={["superscript", "subscript", "emoji", "remove-styles", 'add-media', 'insert-media']}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab='漏洞EXP' key='3'>
                            <div className="my-component" style={{ marginBottom: '40px' }}>
                                <BraftEditor
                                    value={submit.vulexp}
                                    onChange={(e) => handleEditorChange(e, 'vulexp')}
                                    media={{
                                        uploadFn: myUploadFn,
                                        image: false
                                    }}
                                    excludeControls={["superscript", "subscript", "emoji", "remove-styles", 'add-media', 'insert-media']}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab='分析过程' key='4'>
                            <div className="my-component" style={{ marginBottom: '40px' }}>
                                <BraftEditor
                                    value={submit.analysis}
                                    onChange={(e) => handleEditorChange(e, 'analysis')}
                                    media={{
                                        uploadFn: myUploadFn,
                                        image: false
                                    }}
                                    excludeControls={["superscript", "subscript", "emoji", "remove-styles", 'add-media', 'insert-media']}
                                />
                            </div>
                        </TabPane>
                        {
                            submit?.plug === false ? undefined :
                                <TabPane tab='检测插件' key='5'>
                                    <div className="my-component vlun-plug" style={{ marginBottom: '40px' }}>
                                        {
                                            submit?.plug === null ? <span style={{ color: 'red' }}>购买后可查看，请进入资源详情进行购买</span> :
                                                <AceEditor
                                                    style={{ width: '100%' }}
                                                    mode="javascript"
                                                    theme="github"
                                                    fontSize={14}
                                                    maxLength={20000}
                                                    onChange={(e) => handleEditorChange(e, 'plug')}
                                                    placeholder='请填写插件内容'
                                                    value={submit.plug}
                                                    name="UNIQUE_ID_OF_DIV"
                                                    editorProps={{ $blockScrolling: true }}
                                                    showGutter={true}
                                                    enableBasicAutocompletion
                                                    enableLiveAutocompletion
                                                    showPrintMargin={false}
                                                    setOptions={{
                                                        useWorker: false,
                                                        // 自动提词此项必须设置为true
                                                        enableSnippets: true,
                                                        tabSize: 2,
                                                    }}
                                                />
                                        }
                                        <div className="error-message" style={{ whiteSpace: 'pre', color: error?.plug == '格式正确!' ? '#47c447' : '#FC7057' }}>{error?.plug}</div>
                                        <div
                                            style={{ float: 'right', margin: '10px 0' }}>
                                            <Button
                                                type='primary'
                                                style={{ marginRight: '10px' }}
                                                loading={checkLoading}
                                                onClick={() => handleCheckPlug()}
                                            >
                                                插件格式校验
                                            </Button>
                                            <Button
                                                type='primary'
                                                onClick={() => {
                                                    writeBox.show = true
                                                    setWriteBox({ ...writeBox })
                                                }}
                                            >
                                                插件编写说明
                                            </Button>
                                        </div>
                                    </div>
                                </TabPane>
                        }
                        <TabPane tab='检测规则' key='6'>
                            <div className='vuln-rule subject-info submit-ctf-div' style={{ marginBottom: '40px' }}>
                                <div className='content'>
                                    {
                                        Object.keys(submit.rules).map((item, key) => {
                                            return <div key={key}>
                                                <span style={{ width: '100px' }} className='name'>规则{key + 1}</span>
                                                <Input.TextArea
                                                    value={submit.rules[item]}
                                                    className="content-input"
                                                    style={{ width: 'calc(100% - 180px)' }}
                                                    placeholder="请输入规则内容"
                                                    onChange={(e) => changeRule(item, e.target.value)}
                                                />
                                                <DeleteOutlined
                                                    style={{ color: 'red', cursor: 'pointer', marginLeft: '5px', verticalAlign: 'middle' }}
                                                    onClick={() => {
                                                        delete submit.rules[item];
                                                        setSubmit({ ...submit })
                                                    }}
                                                />
                                            </div>
                                        })
                                    }
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <Button
                                        type='primary'
                                        onClick={() => {
                                            let times = new Date().getTime();
                                            submit.rules[times] = '';
                                            setSubmit({ ...submit })
                                        }}
                                    >
                                        添加规则
                                    </Button>
                                </div>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
                {
                    type === "0" &&
                    <div className="submit-btn">
                        <Button type="primary" loading={submitLoading} onClick={() => commitResources(1)}>提交</Button>
                    </div>
                }
                {
                    type === "1" &&
                    <div className="submit-btn">
                        <Button type="primary" loading={submitLoading} onClick={() => commitResources(2)}>提交</Button>
                    </div>
                }
            </div>
            {
                retrievalBox.show &&
                <Retrieval
                    close={() => {
                        retrievalBox.show = false;
                        setRetrievalBox({ ...retrievalBox })
                    }}
                    count={retrievalBox.count}
                    name='漏洞情报名称'
                />
            }
            <MarkDownModal
                boxProperty={writeBox}
                title={'POC 编写指南'}
                url={'/media/resource/vulnInfo/poc.md'}
                close={() => {
                    writeBox.show = false
                    setWriteBox({ ...writeBox })
                }}
            />
            <SubmitBox
                submit={submitBox}
                resourceName={'漏洞情报'}
                onOK={() => { handleOk() }}
                onClose={() => {
                    submitBox.show = false
                    setSubmitBox({ ...submitBox })
                }}
                loading={submitLoading}
            />
        </div>
    )
}
export default Add