/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import '../../../css/submitCtf.css';
import '../../../css/box.css';
import { Button, Input, Radio, Select } from 'antd';
import { CloseOutlined, CheckCircleFilled } from '@ant-design/icons';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import Retrieval from '../../Activity/retrieval';
import instance from '../../Communal/instance';
import { Alert } from '../../Communal/utility';
import Denied from '../../../images/denied.png';
import PassBox from '../../Activity/PassBox';
import DeclineBox from '../../Activity/DeclineBox';
import AddedBox from '../../Activity/AddedBox';
import DeteleIcon from '../../../images/detele-icon.png';
import history from '../../Communal/history';
import Cao from '../../../images/cao.png';
import HuiCao from '../../../images/huicao.png';
const { Option } = Select;
let letter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
function SubmitCTF(param) {
    //id===0管理员 type: 0 添加 1 编辑 2 审核
    const [type, setType] = useState("0")
    const [submit, setSubmit] = useState(
        {
            name: '', //题目名称
            classify: undefined, //题目分类id
            alias: '', //题目别名
            option_type: 1, //题目类型 1单选 2多选 3判断 4填空
            content: ['', ''], //题目内容
            option: [], //答案
            parse: '', //解析
            keyword: [], //关键字
            level: 1,//题目等级 1-3
        }
    );
    const [submitLoading, setSubmitLoading] = useState(false);
    const [detail, setDetail] = useState({})
    const [keyword, setKeyword] = useState("")
    const [retrievalBox, setRetrievalBox] = useState({
        show: false,
        type: 0,
        count: 0
    });
    const [classify, setClassify] = useState([]); //选择分类 选项
    const [initBox, setInitBox] = useState(
        {
            passBox: false,
            declineBox: false,
            addedBox: false
        }
    )
    useEffect(() => {
        setType(param.param.match.params.type);
    }, [param.param.match.params.type]);
    useEffect(() => {
        setSubmit({ ...submit });
        if (param.param.match.params.type !== "0") {
            //题目详情
            instance.get("/test/" + param.param.match.params.id + "/").then(data => {
                setSubmit({
                    name: data.name,
                    classify: data.classify_id,
                    alias: data.alias,
                    option_type: data.option_type,
                    option: data.option,
                    parse: data.parse,
                    keyword: data.keyword_list,
                    level: data.level,
                    content: Object.entries(data.content).map(x => { return x[1] })
                });
                getClassify();
                setDetail(data)
            })
        } else {
            getClassify();
        }
    }, []);
    //获取工具类型
    let getClassify = () => {
        instance.get("/ctf/classify/?type=5").then(data => {
            setClassify(data)
        })
    }
    //添加关键字回车事件
    let onPressEnter = (e) => {
        if (e.target.value.replace(/(^\s*)|(\s*$)/g, "") === "") {
            return
        }
        var count = 0;
        if (submit.keyword.length < 3) {
            if (submit.keyword.length > 0) {
                for (var i = 0; i < submit.keyword.length; i++) {
                    if (submit.keyword[i] !== e.target.value) {
                        count++
                    }
                }
                if (count === submit.keyword.length) {
                    submit.keyword.push(e.target.value);
                }
            } else {
                submit.keyword.push(e.target.value);
            }
            setKeyword("");
            setSubmit({ ...submit })
        }
    }
    //关键字change事件
    let keywordChange = (e) => {
        setKeyword(e.target.value.replace(",", ""));
    }
    //删除关键字事件
    let deleteChange = (key) => {
        submit.keyword.splice(key, 1)
        setSubmit({ ...submit })
    }
    //input change 事件
    let inputChange = (e, name) => {
        submit[name] = e.target.value;
        setSubmit({ ...submit })
    }
    //点击审核操作
    let handleAudit = (data) => {
        if (data.status === 1) {
            if (data.data === "") {
                Alert("error", "请输入奖励奖励数");
                return
            }
        }
        if ((data.status === 2 || data.status === 3) && data.data === "") {
            Alert("error", "请输入审核意见");
            return
        }
        instance.post("/test/" + param.param.match.params.id + "/audit/", data).then(data => {
            Alert("success", "操作成功");
            window.location.href = "/#/resource/test"
        }).catch(err => {
            Alert("error", err.error)
        })
    }
    //点击检索按钮进行检索，根据类型判断 检索的字段
    let retrievalClick = (type, text) => {
        if (text.replace(/(^\s*)|(\s*$)/g, "") === "") {
            Alert("warn", "请输入检索内容");
            return
        }
        let list = {
            type: "test",
            flag: text,
            search_type: type
        }
        instance.get("/ctf/search/", { params: list }).then(data => {
            retrievalBox.show = true;
            retrievalBox.count = data.count;
            retrievalBox.type = type;
            setRetrievalBox({ ...retrievalBox })
        })
    }

    const submitTest = (key) => {
        if (submit.name.trim() === '') {
            Alert('error', '请填写题目名称');
            return
        }
        if (submit.classify === undefined) {
            Alert('error', '请选择题目分类');
            return
        }
        if (submit.alias.trim() === '') {
            Alert('error', '请填写题目别名');
            return
        }
        if (submit.content.findIndex(x => String(x).trim() === '') !== -1) {
            Alert('error', '请输入选项描述');
            return
        }
        if (submit.option_type !== 4 && submit.option.length === 0) {
            Alert('error', '请选择答案');
            return
        }
        if (submit.parse.trim() === '') {
            Alert('error', '请填写题目解析');
            return
        }
        if (submit.keyword.length === 0) {
            Alert('error', '请填写关键字')
            return
        }
        let list = {
            name: submit.name,
            classify: submit.classify,
            alias: submit.alias,
            option_type: submit.option_type,
            option: submit.option,
            parse: submit.parse,
            keyword: JSON.stringify(submit.keyword),
            level: submit.level
        };
        let content_copy = {};
        for (let i = 0; i < submit.content.length; i++) {
            content_copy[letter[i]] = submit.content[i];
        }
        list.content = content_copy;
        setSubmitLoading(true)
        if (key === 1) {
            //新增
            instance.post("/test/", list).then(data => {
                Alert('success', '添加成功')
                setSubmit({
                    name: '', //题目名称
                    classify: undefined, //题目分类id
                    alias: '', //题目别名
                    option_type: 1, //题目类型 1单选 2多选 3判断 4填空
                    content: ['', ''], //题目内容
                    option: [], //答案
                    parse: '', //解析
                    keyword: [], //关键字
                    level: 1,
                });
                setSubmitLoading(false);
            }).catch(err => {
                if (err.name) {
                    Alert("error", err.name[0])
                }
                if (err.non_field_errors) {
                    Alert("error", err.non_field_errors[0])
                }
                setSubmitLoading(false);
                // Alert("error", err.error)
            })
        } else {
            instance.put(`/test/${param.param.match.params.id}/`, list).then(data => {
                Alert('success', '编辑成功')
                setSubmitLoading(false);
                history.go(-1);
            }).catch(err => {
                if (err.name) {
                    Alert("error", err.name[0])
                }
                if (err.non_field_errors) {
                    Alert("error", err.non_field_errors[0])
                }
                setSubmitLoading(false);
                // Alert("error", err.error)
            })
        }
    }
    //删除选项
    const deleteOption = (key) => {
        if (submit.content.length > 2) {
            submit.content.splice(key, 1);
            submit.option = [];
        } else {
            Alert('warn', '最少存在两个选项！！！')
        }
        setSubmit({ ...submit })
    }
    //选择答案
    let handleChangeOption = (key) => {
        let type = submit.option_type;
        if (type === 2) {
            if (submit.option.indexOf(letter[key]) !== -1) {
                submit.option.splice(submit.option.indexOf(letter[key]), 1)
            } else {
                submit.option.push(letter[key]);
            }
        } else {
            submit.option = [letter[key]]
        }
        setSubmit({ ...submit })
    }
    //增加选项
    let moreOptions = () => {
        submit.content.push("");
        setSubmit({ ...submit })
    }
    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-div submit-ctf-name">
                    {
                        type === "0" && "提交题目"
                    }
                    {
                        type === "1" && "编辑题目"
                    }
                    {
                        type === "2" && "审核题目"
                    }
                    {
                        detail.status === 3 &&
                        <div className="status">
                            <img src={Denied} alt="" />
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    detail.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">{val.msg}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div subject-info subject-test-div">
                    <div className="title">
                        题目信息
                    </div>
                    <div className="content">
                        <div className="retrieval-div">
                            <span><b>*</b>题目名称</span>
                            <Input
                                value={submit.name}
                                className="content-input"
                                placeholder="请输入题目名称"
                                onChange={(e) => inputChange(e, "name")}
                            />
                            <Button type="primary" onClick={() => retrievalClick(0, submit.name)}>检索</Button>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>题目分类</span>
                            <Select
                                value={submit.classify}
                                style={{ width: 180 }}
                                placeholder="请选择题目分类"
                                onChange={(e) => { setSubmit({ ...submit, classify: e }) }}
                            >
                                {classify.map((x, i) => {
                                    return <Option key={i} value={x.id}>{x.name}</Option>
                                })}
                            </Select>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>题目别名</span>
                            <Input
                                maxLength={50}
                                value={submit.alias}
                                className="content-input"
                                placeholder="请输入题目别名"
                                onChange={(e) => inputChange(e, "alias")}
                            />
                            <span style={{ marginLeft: 10 }}>{submit.alias.length}/{50}</span>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>题目类型</span>
                            <Radio.Group value={submit.option_type} onChange={e => {
                                setSubmit({ ...submit, option_type: e.target.value, option: e.target.value === 4 ? ['A'] : [] })
                            }}>
                                <Radio value={1}>单选</Radio>
                                <Radio value={2}>多选</Radio>
                                <Radio value={3}>判断</Radio>
                                <Radio value={4}>填空</Radio>
                            </Radio.Group>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>选项</span>
                            <div className="option">
                                {
                                    submit.content.map((x, i) => {
                                        return <div key={i}>
                                            <img src={DeteleIcon} alt="" onClick={() => deleteOption(i)} />
                                            {submit.option_type === 4 ? `空${i + 1}：` : `选项${letter[i]}：`}
                                            <Input
                                                value={x}
                                                onChange={(e) => {
                                                    submit.content[i] = e.target.value;
                                                    setSubmit({ ...submit })
                                                }}
                                                className="content-input"
                                                placeholder="请输入选项描述"
                                            />
                                            {submit.option_type !== 4 && <span
                                                onClick={() => handleChangeOption(i)}
                                                className={submit.option.indexOf(letter[i]) !== -1 ? "active" : ""}
                                            >
                                                <CheckCircleFilled />
                                            </span>}
                                        </div>
                                    })
                                }
                                <div className="add-btn">
                                    <span onClick={moreOptions}>+增加选项</span>
                                </div>
                            </div>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>题目解析</span>
                            <Input
                                value={submit.parse}
                                className="content-input"
                                placeholder="请输入题目解析"
                                onChange={(e) => inputChange(e, "parse")}
                            />
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>题目等级</span>
                            <div className="test-level">
                                {
                                    Array(3).fill("").map((val, key) => {
                                        return <img
                                            key={key}
                                            src={(key + 1) > submit.level ? HuiCao : Cao}
                                            alt=""
                                            onClick={() => {
                                                submit.level = key + 1;
                                                setSubmit({ ...submit })
                                            }}
                                        />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="submit-ctf-div subject-keyword">
                    <div className="title">
                        添加知识点
                    </div>
                    <div className="content">
                        <div>
                            <b>*</b>
                            知识点
                        </div>
                        <div>
                            <div>
                                <Input
                                    value={keyword}
                                    placeholder="题目知识点，回车添加知识点"
                                    onPressEnter={onPressEnter}
                                    onChange={keywordChange}
                                    maxLength={16}
                                />
                                <span className="number">{16 - keyword.length}</span>
                                添加知识点（{3 - submit.keyword.length}）
                             </div>
                            <div>
                                {
                                    submit.keyword.map((val, key) => {
                                        return <span key={"key" + key}>
                                            {val}
                                            <CloseOutlined
                                                onClick={() => deleteChange(key)}
                                            />
                                        </span>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    //新增
                    type === "0" &&
                    <div className="submit-btn">
                        <Button type="primary" onClick={() => submitTest(1)} loading={submitLoading}>提交</Button>
                    </div>
                }
                {
                    //编辑
                    type === "1" &&
                    <div className="submit-btn">
                        <Button type="primary" onClick={() => submitTest(2)}>提交</Button>
                    </div>
                }
                {
                    type === "2" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => {
                                initBox.passBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            通过
                        </Button>
                        <Button
                            style={{ backgroundColor: "transparent", color: "#266cff" }}
                            type="primary"
                            onClick={() => window.location.href = "/#/resource/test"}
                        >
                            取消
                        </Button>
                        <Button className="modify" onClick={() => submitTest(2)}>保存修改</Button>
                        <Button
                            className="decline"
                            onClick={() => {
                                initBox.declineBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            拒绝
                        </Button>
                        <Button
                            className="added"
                            onClick={() => {
                                initBox.addedBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            待补充
                        </Button>
                        <Button
                            className="added"
                            onClick={() => handleAudit({ status: 0 })}
                        >
                            待审核
                        </Button>
                    </div>
                }
            </div>
            {
                retrievalBox.show &&
                <Retrieval
                    close={() => {
                        retrievalBox.show = false;
                        setRetrievalBox({ ...retrievalBox })
                    }}
                    count={retrievalBox.count}
                    name={
                        {
                            0: "题目名称",
                            1: "题目标题",
                            2: "题目内容"
                        }[retrievalBox.type]
                    }
                />
            }
            {
                initBox.passBox &&
                <PassBox
                    hideReward={true}
                    close={() => {
                        initBox.passBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.declineBox &&
                <DeclineBox
                    close={() => {
                        initBox.declineBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.addedBox &&
                <AddedBox
                    close={() => {
                        initBox.addedBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
        </div>
    )
}
export default SubmitCTF;