import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import CodeHighlighter from 'braft-extensions/dist/code-highlighter';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import styled from 'styled-components';
import history from '../../../Communal/history';
import Reminder from '../../../Communal/reminder';
import Added from '../../../../images/added.png';
import AuditStatus from '../../../../images/audit-status.png';
import Denied from '../../../../images/denied.png';
import Pass from '../../../../images/pass.png';
import instance from '../../../Communal/instance';

const options = {
    syntaxs: [
        {
            name: 'JavaScript',
            syntax: 'javascript'
        }, {
            name: 'HTML',
            syntax: 'html'
        }, {
            name: 'CSS',
            syntax: 'css'
        }, {
            name: 'Java',
            syntax: 'java',
        }, {
            name: 'PHP',
            syntax: 'php'
        }, {
            name: "Python",
            syntax: 'python'
        }
    ],
}
BraftEditor.use(CodeHighlighter(options))
const Div = styled.div`
    width:100%!important;
    color:#333;
    .bf-content {
        height:auto
    }
    .bf-container .public-DraftEditor-content .braft-code-block-wrapper .braft-code-block{
        max-height:10000px
    }
`
const MethodDetail = ({ param }) => {
    const [detail, setDetail] = useState({});
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(null));
    const [reminderShow, setReminderShow] = useState(false);
    useEffect(() => {
        getDetail(param.match.params.id)
    }, [param])
    const getDetail = (id) => {
        instance.get(`/method/${id}/`).then(res => {
            setEditorState(BraftEditor.createEditorState(res.detail));
            setDetail(res);
        })
    }
    const handleHrefScene = (id) => {
        window.open(`/#/activity/methodscenedetail/${id}/`)
    }
    return (
        <div className="activity-ctf-detail submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-name submit-ctf-div">
                    <div style={{ display: "inline-block" }} className='ctf-name'>
                        <span onClick={() => history.push('/resource')}>资源中心</span> ＞ <span onClick={() => history.push({ pathname: '/resource/methods', state: { tabKey: 2 } })}>对抗手法</span> ＞ {detail.name}
                    </div>
                    {
                        detail.is_show_opin &&
                        <div className="status">
                            {
                                detail.status === 0 &&
                                <img src={AuditStatus} alt="" />
                            }
                            {
                                detail.status === 1 &&
                                <img src={Pass} alt="" />
                            }
                            {
                                detail.status === 2 &&
                                <img src={Added} alt="" />
                            }
                            {
                                detail.status === 3 &&
                                <img src={Denied} alt="" />
                            }
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    detail.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">
                                                <pre style={{ whiteSpace: "pre-line" }}>{val.msg}</pre>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div">
                    <div className="award">
                        <div>
                            <span>{detail.coin}</span>
                            <span>奖励</span>
                        </div>
                        <p onClick={() => setReminderShow(true)}>赚取更多奖励＞</p>
                    </div>
                    <div className="title">手法信息</div>
                    <div className="content">
                        {
                            detail.status === 1 &&
                            <div>
                                <span>手法编号</span>
                                <div>{detail.number ? detail.number : "暂无编号"}</div>
                            </div>
                        }
                        <div>
                            <span>手法名称</span>
                            <div>{detail.name}</div>
                        </div>
                        <div>
                            <span>手法背景</span>
                            <div style={{ paddingTop: "9px", lineHeight: "25px" }}>
                                <pre style={{ whiteSpace: "pre-wrap" }}>{detail.back}</pre>
                            </div>
                        </div>
                        <div>
                            <span>手法场景</span>
                            <div>
                                {
                                    (detail.method_scenes_list || []).map((val, key) => {
                                        return <Tag key={key}
                                            color="green"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleHrefScene(val.id)}
                                        >
                                            {val.number}：{val.name}
                                        </Tag>
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            <span>手法分类</span>
                            <div>{detail.classify_name}</div>
                        </div>
                        <div>
                            <span>手法阶段</span>
                            <div>{detail.stage_name}</div>
                        </div>
                        {
                            detail.status === 1 && [
                                <div key="2">
                                    <span>提交用户</span>
                                    <div>
                                        {detail.username}
                                    </div>
                                </div>,
                                <div key="3">
                                    <span>提交时间</span>
                                    <div>
                                        {detail.sub_time}
                                    </div>
                                </div>
                            ]
                        }
                    </div>
                    <div className="title">手法详情</div>
                    <div className="content">
                        <div>
                            <Div className="container">
                                <BraftEditor
                                    value={editorState}
                                    readOnly
                                    controls={[]}
                                />
                            </Div>
                        </div>
                    </div>
                    <div className="title">手法标签</div>
                    <div className="content">
                        <div>
                            <span>手法标签</span>
                            <div>
                                {
                                    (detail.tags_name || []).map((val, key) => {
                                        return <Tag key={key} color="green">
                                            {val}
                                        </Tag>
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            <span>输入标签</span>
                            <div>
                                {
                                    (detail.input_tags_name || []).map((val, key) => {
                                        return <Tag key={key} color="green">
                                            {val}
                                        </Tag>
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            <span>输出标签</span>
                            <div>
                                {
                                    (detail.output_tags_name || []).map((val, key) => {
                                        return <Tag key={key} color="green">
                                            {val}
                                        </Tag>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="title">手法附件</div>
                    <div className="content">
                        <div>
                            <span>手法附件</span>
                            <div>
                                {
                                    detail.attached === '' ? '暂未上传附件' :
                                        <a href={detail.attached} download={detail.attached_name}>{detail.attached_name}</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                reminderShow &&
                <Reminder
                    close={() => setReminderShow(false)}
                />
            }
        </div>
    )
}
export default MethodDetail