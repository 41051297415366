/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import CodeHighlighter from 'braft-extensions/dist/code-highlighter';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import instance from '../../Communal/instance';
import Added from '../../../images/added.png';
import AuditStatus from '../../../images/audit-status.png';
import Denied from '../../../images/denied.png';
import Pass from '../../../images/pass.png';
import BuyCtf from '../../Activity/buyCtf';
import { Button } from 'antd';
import Reminder from '../../Communal/reminder';
import styled from 'styled-components';
const options = {
    syntaxs: [
        {
            name: 'JavaScript',
            syntax: 'javascript'
        }, {
            name: 'HTML',
            syntax: 'html'
        }, {
            name: 'CSS',
            syntax: 'css'
        }, {
            name: 'Java',
            syntax: 'java',
        }, {
            name: 'PHP',
            syntax: 'php'
        }, {
            name: "Python",
            syntax: 'python'
        }
    ],
}
BraftEditor.use(CodeHighlighter(options))
const Div = styled.div`
    width:100%!important;
    color:#333;
    .bf-content {
        height:auto
    }
    .bf-container .public-DraftEditor-content .braft-code-block-wrapper .braft-code-block{
        max-height:10000px
    }
`
function CtfDetail(param) {
    const [detail, setDetail] = useState({});
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(null));
    const [reminderShow, setReminderShow] = useState(false)
    const [buyCtf, setBuyCtf] = useState(false)
    // 获取详情
    useEffect(() => {
        getDetail()
    }, [])
    const getDetail = () => {
        instance.get("/knowledge/" + param.param.match.params.id + "/").then(data => {
            setDetail(data)
            setEditorState(BraftEditor.createEditorState(data.content))
        })
    }

    return (
        <div className="activity-ctf-detail submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-name submit-ctf-div">
                    <div style={{ display: "inline-block" }} className='ctf-name'>
                        <span
                            onClick={() => window.location.href = "/#/resource"}
                        >
                            资源中心
                        </span>
                        &nbsp;＞&nbsp;
                        <span
                            onClick={() => window.location.href = "/#/resource/knowledge"}
                        >
                            知识资源库
                        </span>
                        &nbsp;＞&nbsp;
                        {detail.name}
                    </div>
                    {
                        detail.is_show_opin &&
                        <div className="status">
                            {
                                detail.status === 0 &&
                                <img src={AuditStatus} alt="" />
                            }
                            {
                                detail.status === 1 &&
                                <img src={Pass} alt="" />
                            }
                            {
                                detail.status === 2 &&
                                <img src={Added} alt="" />
                            }
                            {
                                detail.status === 3 &&
                                <img src={Denied} alt="" />
                            }
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div
                            className="title"
                            style={{ borderBottom: "1px solid #f4f4f4" }}
                        >
                            审核意见
                        </div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    detail.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">
                                                [{val.time}]
                                            </div>
                                            <div className="details">
                                                <pre
                                                    style={{ whiteSpace: "pre-line" }}
                                                >{val.msg}
                                                </pre>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div">
                    <div className="award">
                        <div>
                            <span>{detail.coin}</span>
                            <span>金币</span>
                        </div>
                        <p onClick={() => setReminderShow(true)}>赚取更多金币＞</p>
                    </div>
                    <div className="title">知识信息</div>
                    <div className="content">
                        <div>
                            <span>知识名称</span>
                            <div>{detail.name}</div>
                        </div>
                        <div>
                            <span>知识简介</span>
                            <div style={{ paddingTop: "9px", lineHeight: "25px" }}>
                                <pre style={{ whiteSpace: "pre-wrap" }}>
                                    {detail.intro}
                                </pre>
                            </div>
                        </div>
                        <div>
                            <span>知识标签</span>
                            <div className="tag">
                                {
                                    detail.tags &&
                                    detail.tags.map((value, key) => {
                                        return <span key={"key" + key}>
                                            {value}
                                        </span>
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            <span>知识分类</span>
                            <div>{detail.classify_name}</div>
                        </div>
                        <div>
                            <span>资源等级</span>
                            <div>{detail.resource_level}</div>
                        </div>
                        <div>
                            <span>知识来源</span>
                            <div>{detail.source}</div>
                        </div>
                        <div>
                            <span>提交用户</span>
                            <div>{detail.username}</div>
                        </div>
                        <div>
                            <span>提交时间</span>
                            <div>{detail.sub_time}</div>
                        </div>
                    </div>
                    {
                        detail.is_buy ?
                            <>
                                <div className="title">知识内容</div>
                                <div className="content">
                                    <div>
                                        <Div className="container">
                                            <BraftEditor
                                                value={editorState}
                                                readOnly
                                                controls={[]}
                                            />
                                        </Div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="title">
                                    知识内容
                                    <span>
                                        购买后可查看
                                    </span>
                                </div>

                            </>
                    }
                    {
                        detail.is_buy ?
                            <>
                                <div className="title">知识附件</div>
                                <div className="content">
                                    <div>
                                        <span>知识附件</span>
                                        <div>
                                            {
                                                detail.attached ?
                                                    <a
                                                        href={detail.attached}
                                                        download={detail.attached_name}
                                                    >
                                                        {detail.attached_name}
                                                    </a>
                                                    :
                                                    '暂无附件'
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="title">
                                    知识附件
                                    <span>购买后可查看</span>
                                </div>
                            </>
                    }
                </div>
                {
                    !detail.is_buy &&
                    <div className="buy-button">
                        <Button
                            type="primary"
                            onClick={() => setBuyCtf(true)}
                        >
                            购买
                        </Button>
                    </div>
                }
            </div>
            {
                buyCtf &&
                <BuyCtf
                    coin={detail.coin}
                    url={"/knowledge/" + detail.id + "/buy/"}
                    close={() => setBuyCtf(false)}
                    getDetail={getDetail}
                />
            }
            {
                reminderShow &&
                <Reminder
                    close={() => setReminderShow(false)}
                />
            }
        </div>
    )
}
export default CtfDetail;