import axios from 'axios';
import qs from 'qs';
import {goLogin} from './utility'
function getCookie(name) {
    if (!document.cookie) {
        return null;
    }
    const csrfCookies = document.cookie.split(';')
        .map(c => c.trim())
        .filter(c => c.startsWith(name + '='));
    if (csrfCookies.length === 0) {
        return null;
    }
    return decodeURIComponent(csrfCookies[0].split('=')[1]);
}
const instance = axios.create({
    //当创建实例的时候配置默认配置
    timeout: 1000 * 600
});
 
 
//添加请求拦截器
instance.interceptors.request.use(function (config) {
    if (config.method === "get") {
        config.paramsSerializer=params => {
            return qs.stringify(params, { indices: false })
        }
    }
    if (config.method === "post") {
        if (config.body instanceof FormData) {
            config.headers = {
                'X-CSRFToken': getCookie('csrftoken')
            }
        } else {
            config.headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            }
        }
    }
    if (config.method === "put") {
        if (config.body instanceof FormData) {
            config.headers = {
                'X-CSRFToken': getCookie('csrftoken')
            }
        } else {
            config.headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            }
        }
    }
    if (config.method === "patch") {
        config.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken')
        }
    }
    if (config.method === "delete") {
        config.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken')
        };
        config.paramsSerializer = params => {
            return qs.stringify(params, { indices: false })
        }
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
 
//添加一个响应拦截器
instance.interceptors.response.use(response=>{
    return Promise.resolve(response.data);
}, error=>{
    if (error?.response?.status === 403) {
        // 403处理
        goLogin();
    }
    return Promise.reject(error?.response?.data);
});
 
export default instance;