/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import instance from '../../../Communal/instance';
import Added from '../../../../images/added.png';
import AuditStatus from '../../../../images/audit-status.png';
import Denied from '../../../../images/denied.png';
import Pass from '../../../../images/pass.png';
import BuyCtf from '../../../Activity/buyCtf';
import { Button } from 'antd'; 
import Reminder from '../../../Communal/reminder';

function RulesResourceDetail(param) {
    const [detail, setDetail] = useState({});
    const [buyCtf, setBuyCtf] = useState(false);
    const [reminderShow, setReminderShow] = useState(false);
    useEffect(() => {
        getDetail();
    }, []);
    let getDetail = () => {
        instance.get(`/rules/${param.param.match.params.id}/`).then(data => {
            if (data.content_read.indexOf(';)') >= 0 && data?.new_sid !== '') {
                let index = data.content_read.indexOf(';)')
                data.content_read = data.content_read.substring(0, index) +';sid:' + data?.new_sid + data.content_read.substring(index)
            }
            setDetail(data);
        })
    }
    return (
        <div className="activity-ctf-detail submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-name submit-ctf-div">
                    {
                        param.param.match.params.where === "0" &&
                        <div style={{ display: "inline-block" }} className='ctf-name'>
                            <span onClick={() => window.location.href = "/#/resource"}>资源中心</span> ＞ <span onClick={() => window.location.href = "/#/resource/rule"}>威胁检测规则资源</span> ＞ {detail.new_sid}
                        </div>
                    }
                    {
                        param.param.match.params.where === "1" &&
                        <div style={{ display: "inline-block" }} className='ctf-name'>
                            <span onClick={() => window.location.href = "/#/activity"}>活动中心</span> ＞ {detail.name}
                        </div>
                    }
                    {
                        detail.is_show_opin &&
                        <div className="status">
                            {
                                detail.status === 0 &&
                                <img src={AuditStatus} alt="" />
                            }
                            {
                                detail.status === 1 &&
                                <img src={Pass} alt="" />
                            }
                            {
                                detail.status === 2 &&
                                <img src={Added} alt="" />
                            }
                            {
                                detail.status === 3 &&
                                <img src={Denied} alt="" />
                            }
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    detail.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">
                                                <pre style={{ whiteSpace: "pre-line" }}>{val.msg}</pre>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div">
                    <div className="award">
                        <div>
                            <span>{detail.coin}</span>
                            <span>奖励</span>
                        </div>
                        <p onClick={() => setReminderShow(true)}>赚取更多奖励＞</p>
                    </div>
                    <div className="title">资源信息</div>
                    <div className="content">
                        <div>
                            <span>规则分类</span>
                            <div>{detail?.rules_class_name}</div>
                        </div>
                        <div>
                            <span>规则sid</span>
                            <div>
                                {detail?.new_sid || '--'}
                            </div>
                        </div>
                        <div>
                            <span>原sid</span>
                            <div>
                                {detail?.source_sid || '无'}
                            </div>
                        </div>
                        <div>
                            <span>规则阶段</span>
                            <div className="tag">
                                {
                                    // detail?.stage.join('，')
                                    detail.stage &&
                                    detail.stage.map((val, key) => {
                                        return <span key={key}>{val}</span>
                                    })
                                }

                            </div>
                        </div>
                        <div>
                            <span>规则描述</span>
                            <div>{detail?.desc}</div>
                        </div>
                        <div>
                            <span>规则备注</span>
                            <div>{detail?.remarks}</div>
                        </div>
                        <div>
                            <span>参考资料</span>
                            <div>{detail?.reference || '--'}</div>
                        </div>
                        <div key="1">
                            <span>规则内容</span>
                            <div>
                                {!detail.is_buy ? <span style={{ color: 'red' }}>购买后可查看</span> : detail?.content_read}
                            </div>
                        </div>
                        {
                            detail.status === 1 &&
                            <>
                                <div >
                                    <span>提交用户</span>
                                    <div>
                                        {detail?.username}
                                    </div>
                                </div>
                                <div >
                                    <span>提交时间</span>
                                    <div>
                                        {detail?.sub_time}
                                    </div>
                                </div>
                                <div>
                                    <span>等级</span>
                                    <div>
                                        {detail?.level}
                                    </div>
                                </div>
                                <div>
                                    <span>更新用户</span>
                                    <div>
                                        {detail?.update_user}
                                    </div>
                                </div>
                                <div>
                                    <span>更新时间</span>
                                    <div>
                                        {detail?.update_time}
                                    </div>
                                </div>
                            </>
                        }
                        <div>
                            <span></span>
                            <div style={{ color: '#FC7057', margin: '10px 0' }}>
                                提示：sid在审核时会自动生成，sid命名规则是，年份+月份+四位数，为校验规则，规则内容中无需填写sid
                            </div>
                        </div>
                    </div>
                </div>
                {
                    !detail.is_buy &&
                    <div className="buy-button">
                        <Button type="primary" onClick={() => setBuyCtf(true)}>购买</Button>
                    </div>
                }
            </div>
            {
                buyCtf &&
                <BuyCtf
                    coin={detail.coin}
                    url={`/rules/${detail.id}/buy/`}
                    close={() => setBuyCtf(false)}
                    getDetail={getDetail}
                />
            }
            {
                reminderShow &&
                <Reminder
                    close={() => setReminderShow(false)}
                />
            }
        </div>
    )
}
export default RulesResourceDetail;