/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '../../../css/ctfResource.css';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Table, Input, Pagination, Divider, Dropdown, Menu } from 'antd'
import instance from '../../Communal/instance';
import { Alert } from '../../Communal/utility';
import DeteleBox from '../deteleBox';
import ImportToolBox from '../importToolBox';
import Comfirm from '../comfirm';
const { Column } = Table;

function List() {
    const [search, setsSearch] = useState({
        show: false,
        value: ""
    });
    const [initData, setInitData] = useState(
        {
            data: [],
            allCount: 0,
            hotKeyword: [],
            resourceNumber: {}
        }
    );
    const [boxShow, setBoxShow] = useState(false);
    const [systemList, setSystemList] = useState([]);
    const [filter, setFilter] = useState(
        {
            page: 1,
            size: 10,
            search: "",
            classify: [],
            status: [],
            username: "",
            test_type: "",
            system: []
        }
    );
    const [classify, setClassify] = useState([]);
    const [checkList, setCheckList] = useState([]);
    const [userType, setUserType] = useState(0);
    const [userIssue, setIssue] = useState(false);
    const [deleteTip, setDeteleTip] = useState(
        {
            show: false,
            list: []
        }
    )
    const [comfirmShow, setComfirmShow] = useState({
        visible: false,
        mode: 'tool',
        issue: '',
    })
    let handleTableChange = (pagination, filters, sorter) => {
        if (filters.classify) {
            filter.classify = filters.classify;
        } else {
            filter.classify = [];
        }
        if (filters.system_name) {
            filter.system = filters.system_name;
        } else {
            filter.system = [];
        }
        if (filters.test_type) {
            filter.test_type = filters.test_type[0];
        } else {
            filter.test_type = "";
        }
        if (filters.issue) {
            filter.issue = filters.issue;
        } else {
            filter.issue = [];
        }
        if (filters.status) {
            filter.status = filters.status;
        } else {
            filter.status = [];
        }
        filter.page = 1;
        setFilter({ ...filter });
    }
    let handelSearch = () => {
        if (search.value !== filter.search) {
            filter.search = search.value;
            filter.page = 1;
            setFilter({ ...filter })
        }
        search.show = true;
        setsSearch({ ...search });
    }
    let onClicks = (ev) => {
        var e = ev || window.event; //浏览器兼容性
        var elem = e.target || e.srcElement;
        while (elem) {
            //循环判断至跟节点，防止点击的是div子元素
            if (elem.className === "top") {
                return;
            } else {
                elem = elem.parentNode;
            }
        }
        search.show = false;
        setsSearch({ ...search });
    }
    useEffect(() => {
        getTool()
    }, [filter]);
    //获取热搜词
    useEffect(() => {
        //获取资源数
        resourceNumber();
        getSystem();
        instance.get("/ctf/hot_word/", { params: { type: "tool", size: 5 } }).then(data => {
            initData.hotKeyword = data;
            setInitData({ ...initData })
        })
        instance.get("/tool/classify/").then(data => {
            if (data.length > 0) {
                for (var i = 0; i < data.length; i++) {
                    data[i].text = data[i].name;
                    data[i].value = data[i].id
                }
            }
            setClassify(data)
        })
        instance.get("/ctf/user/type/?mode=tool").then(data => {
            setUserType(data.user_type);
            setIssue(data.issue);
        })
    }, [])
    let resourceNumber = () => {
        instance.get("/ctf/sum/", { params: { type: "tool" } }).then(data => {
            initData.resourceNumber = data;
            setInitData({ ...initData })
        })
    }
    const getSystem = () => {
        instance.get('/tool/system/').then(res => {
            if (res.length > 0) {
                for (var i = 0; i < res.length; i++) {
                    res[i].text = res[i].name;
                    res[i].value = res[i].id
                }
            }
            setSystemList(res)
        })
    }
    //获取工具列表
    let getTool = () => {
        instance.get("/tool/", { params: filter }).then(data => {
            initData.data = data.results;
            initData.allCount = data.count;
            setInitData({ ...initData })
        })
    }
    //单个删除或者批量删除操作
    let resourceDelete = (list) => {
        if (list.length === 0) {
            Alert("warn", "请选择需要删除的工具");
            return
        }
        instance.post("/tool/delete/", { id: list }).then(data => {
            if (filter.page > 1 && (initData.data.length === list.length)) {
                filter.page -= 1;
                setFilter({ ...filter })
            } else {
                getTool();
            }
            Alert("success", "删除成功");
            setCheckList([]);
            deleteTip.list = [];
            deleteTip.show = false;
            setDeteleTip({ ...deleteTip })
            resourceNumber();
        })
    }
    //搜索回车事件
    let calAge = (e) => {
        var evt = window.event || e;
        if (evt.keyCode === 13) {
            handelSearch();
        }
    }
    const exprotTool = () => {
        instance.post('/tool/export/?type=1', { id_list: checkList }).then(res => {
            if (res.status === 1) {
                const link = document.createElement("a"); // 创建a标签
                link.href = res.file_path;
                link.download = "导出工具.zip"
                link.click();
            }
        }).catch(err => {
            if (err.error) {
                Alert('error', err.error)
            }
        })
    }
    const menu = (
        <Menu onClick={({ key }) => { changeStatus(key) }}>
            <Menu.Item key={1}>回收</Menu.Item>
            <Menu.Item key={2}>发布</Menu.Item>
        </Menu>
    );
    const changeStatus = (key) => {
        setComfirmShow({ ...comfirmShow, visible: true, issue: Number(key) });
    }
    const handleIssue = () => {
        setCheckList([]);
        setFilter({ ...filter });
    }
    return (
        <div className="ctf-resource-list tool-resource-list" onClick={onClicks}>
            <div className="banner"></div>
            <div className="ctf-resource-search">
                <div className="top">
                    {
                        search.show ?
                            <div className="search-input">
                                <input
                                    className="input-fouce"
                                    type="text"
                                    placeholder="请输入关键字进行搜索"
                                    value={search.value}
                                    onKeyDown={(e) => calAge(e)}
                                    onChange={(e) => {
                                        search.value = e.target.value;
                                        setsSearch({ ...search })
                                    }}
                                />
                            </div>
                            : <div className="statistics">
                                <div>
                                    <span>{initData.resourceNumber.day}</span>
                                    <span>今日新增</span>
                                </div>
                                <div>
                                    <span>{initData.resourceNumber.month}</span>
                                    <span>本月新增</span>
                                </div>
                                <div>
                                    <span>{initData.resourceNumber.sum}</span>
                                    <span>总资源数</span>
                                </div>
                            </div>
                    }
                    <div className="search-btn" onClick={() => handelSearch()}>
                        <SearchOutlined />
                    </div>
                </div>
                <div className="bottom">
                    今天热点搜索：
                    {
                        initData.hotKeyword.map((val, key) => {
                            return <span key={"key" + key} onClick={() => {
                                filter.page = 1;
                                filter.search = val;
                                setFilter({ ...filter })
                            }}>{val}</span>
                        })
                    }
                    {
                        initData.hotKeyword.length === 0 &&
                        "暂无热搜关键字"
                    }
                </div>
            </div>
            <div className="ctf-resource-table content-container">
                <div className="handle">
                    {
                        userType > 1 ?
                            <div style={{ display: 'inline-block' }}>
                                <Button
                                    type='ghost'
                                    disabled={checkList.length === 0 ? true : false}
                                    danger
                                    onClick={() => {
                                        deleteTip.show = true;
                                        deleteTip.list = checkList;
                                        setDeteleTip({ ...deleteTip })
                                    }}
                                >
                                    批量删除
                                </Button>
                                {
                                    userIssue ?
                                        <Dropdown
                                            overlay={menu}
                                            disabled={checkList.length === 0}
                                        >
                                            <Button type="primary" style={{ marginLeft: '15px' }}>批量修改发布状态<DownOutlined /></Button>
                                        </Dropdown> : undefined
                                }
                                <Button
                                    type='primary'
                                    style={{ marginLeft: '15px' }}
                                    disabled={checkList.length === 0 ? true : false}
                                    onClick={exprotTool}
                                >
                                    批量导出
                                </Button>
                                <Button
                                    type='primary'
                                    disabled={initData.allCount === 0 ? true : false}
                                    style={{ marginLeft: '15px' }}
                                    onClick={() => setBoxShow(true)}
                                >
                                    按体系导出
                                </Button>
                            </div> : undefined
                    }
                    {
                        userType > 0 ?
                            <Button
                                type="primary"
                                onClick={() => window.open("/#/activity/submittool/0/0/0")}
                            >
                                提交工具
                            </Button> : undefined
                    }
                </div>
                <Table
                    dataSource={initData.data}
                    rowKey={record => record.id}
                    bordered
                    scroll={{ x: 1200 }}
                    pagination={false}
                    rowSelection={userType > 1 && {
                        type: "checkbox",
                        selectedRowKeys: checkList,
                        preserveSelectedRowKeys:true,
                        onChange: ((selectedRowKeys) => {
                            setCheckList(selectedRowKeys)
                        })
                    }}
                    onChange={handleTableChange}
                >
                    <Column
                        title="序号"
                        key="serial"
                        align="center"
                        width={80}
                        fixed='left'
                        render={(text, record, index) => {
                            return <span
                                className="click-href"
                                onClick={() => {
                                    window.location.href = "/#/activity/tooldetail/0/" + record.id
                                }}
                            >
                                {filter.size * (filter.page - 1) + index + 1}
                            </span>
                        }}
                    />
                    <Column
                        title="工具编号"
                        key="number"
                        align="center"
                        dataIndex="number"
                        width={150}
                        fixed='left'
                        render={(text, record) => {
                            return text ? <span
                                className="click-href"
                                onClick={() => {
                                    window.location.href = "/#/activity/tooldetail/0/" + record.id
                                }}
                            >
                                {text}
                            </span>
                                : "暂无编号"
                        }}
                    />
                    <Column
                        title="工具名称"
                        key="name"
                        align="center"
                        dataIndex="name"
                        width={250}
                        fixed='left'
                        render={(text, record) => {
                            return <a
                                className="click-href"
                                href={"/#/activity/tooldetail/0/" + record.id}
                            >
                                {text}
                            </a>
                        }}
                    />
                    <Column
                        title="工具体系"
                        key="system_name"
                        align="center"
                        dataIndex="system_name"
                        filters={systemList}
                        width={150}
                        render={(text, record) => {
                            return <div>
                                {text ? text : '暂无体系'}
                            </div>
                        }}
                    />
                    <Column
                        title="工具类型"
                        key="classify"
                        align="center"
                        dataIndex="classify"
                        filters={classify}
                        width={150}
                        render={(text, record) => {
                            return <div>
                                {record.classify ? record.classify : <span>暂无一级分类</span>}&nbsp;/&nbsp;
                                {record.sec_classify ? record.sec_classify : <span>暂无二级分类</span>}
                            </div>
                        }}
                    />
                    <Column
                        title="标签"
                        key="keyword"
                        align="center"
                        dataIndex="keyword"
                        width={200}
                        render={(record) => {
                            return <div className="table-keyword" title={record}>
                                {record.map((val, key) => {
                                    return <span className="keyword" key={key + "key"}>{val}</span>
                                })}
                            </div>
                        }}
                    />
                    <Column
                        title="审核状态"
                        key="status"
                        align="center"
                        dataIndex="status"
                        width={150}
                        filters={
                            [
                                { text: "待审核", value: "0" },
                                { text: "通过", value: "1" },
                                { text: "待补充", value: "2" },
                                { text: "拒绝", value: "3" }
                            ]
                        }
                        render={(record) => {
                            return <span className="audit-status">
                                {
                                    record === 0 && "待审核"
                                }
                                {
                                    record === 1 && <span className="pass">通过</span>
                                }
                                {
                                    record === 2 && <span className="added">待补充</span>
                                }
                                {
                                    record === 3 && <span className="denied">拒绝</span>
                                }
                            </span>
                        }}
                    />
                    <Column
                        title="发布状态"
                        key="issue"
                        align="center"
                        dataIndex="issue"
                        filterMultiple={false}
                        width={150}
                        filters={
                            [
                                { text: "未发布", value: 1 },
                                { text: "已发布", value: 2 },
                            ]
                        }
                        render={(record) => {
                            return <span className="audit-status">
                                {
                                    record === 1 && <span className="added">未发布</span>
                                }
                                {
                                    record === 2 && <span className="pass">已发布</span>
                                }
                            </span>
                        }}
                    />
                    <Column
                        title="提交者"
                        key="username"
                        align="center"
                        dataIndex="username"
                        width={150}
                        filterDropdown={({ clearFilters }) => {
                            return <div className="table-search">
                                <Input
                                    placeholder="请输入提交者名称"
                                    value={filter.username}
                                    onChange={(e) => {
                                        filter.username = e.target.value;
                                        filter.page = 1;
                                        setFilter({ ...filter })
                                    }}
                                />
                                <Button type="primary" onClick={() => { clearFilters() }}>确定</Button>
                                <Button onClick={() => {
                                    clearFilters();
                                    filter.username = "";
                                    filter.page = 1;
                                    setFilter({ ...filter })
                                }}>取消</Button>
                            </div>
                        }}
                        filterIcon={<SearchOutlined />}
                    />
                    <Column
                        title="提交时间"
                        key="sub_time"
                        align="center"
                        dataIndex="sub_time"
                        width={200}
                    />
                    <Column
                        title="操作"
                        key="operate"
                        align="center"
                        width={userType > 1 ? userIssue ? 260 : 210 : 160}
                        fixed='right'
                        render={(record) => {
                            return <div>
                                <Button
                                    style={{ padding: 0 }}
                                    type="link"
                                    onClick={() => {
                                        window.location.href = "/#/activity/tooldetail/0/" + record.id
                                    }}
                                >
                                    详情
                                </Button>
                                <Divider type='vertical' />
                                <Button
                                    style={{ padding: 0 }}
                                    disabled={!record.change}
                                    type="link"
                                    onClick={() => {
                                        window.location.href = "/#/activity/submittool/0/1/" + record.id
                                    }}
                                >
                                    编辑
                                </Button>
                                <Divider type='vertical' />
                                {
                                    userType > 1 ? <>
                                        <Button
                                            style={{ padding: 0 }}
                                            type="link"
                                            onClick={() => {
                                                window.open("/#/activity/submittool/0/2/" + record.id)
                                            }}
                                        >
                                            审核
                                        </Button>
                                        <Divider type='vertical' />
                                    </> : undefined
                                }
                                {
                                    userIssue ? <>
                                        {
                                            record.issue === 1 ?
                                                <Button
                                                    style={{ padding: 0 }}
                                                    disabled={record.status !== 1}
                                                    type="link"
                                                    onClick={() => {
                                                        setComfirmShow({ ...comfirmShow, visible: true, issue: 2 });
                                                        setCheckList([record.id])
                                                    }}
                                                >
                                                    发布
                                                </Button> :
                                                <Button
                                                    style={{ padding: 0 }}
                                                    type="link"
                                                    danger
                                                    onClick={() => {
                                                        setComfirmShow({ ...comfirmShow, visible: true, issue: 1 });
                                                        setCheckList([record.id])
                                                    }}
                                                >
                                                    回收
                                                </Button>
                                        }
                                        <Divider type='vertical' />
                                    </> : undefined
                                }
                                <Button
                                    type="link"
                                    disabled={!record.change}
                                    style={{ padding: 0 }}
                                    danger
                                    onClick={() => {
                                        deleteTip.show = true;
                                        deleteTip.list = [record.id];
                                        setDeteleTip({ ...deleteTip })
                                    }}
                                >删除</Button>
                            </div>
                        }}
                    />
                </Table>
                <Pagination
                    showQuickJumper={true}
                    current={filter.page}
                    pageSize={filter.size}
                    total={initData.allCount}
                    hideOnSinglePage={initData.allCount === 0}
                    pageSizeOptions={['10', '20', '50', '100']}
                    showSizeChanger={true}
                    className="flip-over"
                    style={{ margin: "30px 0" }}
                    onChange={
                        ((page,pageSize) => {
                            let data = { ...filter }
                            data.page = page;
                            data.size = pageSize;
                            setFilter(data)
                        })
                    }
                />
            </div>
            {
                deleteTip.show &&
                <DeteleBox
                    close={() => {
                        deleteTip.show = false;
                        deleteTip.list = [];
                        setDeteleTip({ ...deleteTip })
                    }}
                    submit={() => resourceDelete(deleteTip.list)}
                />
            }
            {
                boxShow &&
                <ImportToolBox
                    system={systemList}
                    onClose={() => setBoxShow(false)}
                />
            }
            <Comfirm
                boxProperty={comfirmShow}
                onClose={() => {
                    setComfirmShow({ ...comfirmShow, visible: false })
                }}
                onOk={handleIssue}
                ids={checkList}
            />
        </div>
    )
}
export default List;