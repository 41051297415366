import {createStore} from 'redux';
// 在这里定义的应该怎么干
function reducer(state, action) {
    switch (action.type) {
        case "SET_LOGIN": {
            return {
                ...state,
                login: action.login,
            };
        }
        case "SET_USERINFO": {
            return {
                ...state,
                userInfo: action.userInfo,
            };
        }
        case "SET_CTFFILTER": {
            return {
                ...state,
                ctfFilter: action.ctfFilter,
            };
        }
        default:
            return state;
    }
}
export function makeStore() {
    return createStore(reducer, {
        login:false,
        userInfo:{},
        ctfFilter:{
            page:1,
            size:10,
            search:"",
            classify:[],
            status:[],
            ordering:""
        }
    });
}
