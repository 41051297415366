//确认框
import { Modal, Button, Row, Radio, Col } from 'antd';
import React, { useEffect, useState } from 'react';

const App = ({ detailTip, close }) => {
    const type = {
        0: "分类模块",
        1: "外部规则",
        2: "内部规则",
    }[detailTip?.detail?.type]

    const operation = {
        1: "添加",
        2: "编辑",
        3: "删除",
        4: "审核",
        5: "归档",
    }[detailTip?.detail?.operation]

    useEffect(() => {

    }, [])

    const handleCancel = () => {
        close()
    }

    return (
        <Modal
            title="操作日志详情"
            className='issue-modal'
            visible={detailTip.show}
            centered={true}
            width={1000}
            destroyOnClose
            onCancel={handleCancel}
            footer={<div className="my-modal-footer">
            </div>}
        >
            <Row className="line" style={{ margin: '10px 0' }}>
                <Col span={12}>
                    <Row className="line">
                        <Col span={6}>
                            操作模块类型：
                        </Col>
                        <Col span={18}>
                            {type}
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row className="line">
                        <Col span={6}>
                            操作动作：
                        </Col>
                        <Col span={18}>
                            {operation}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="line" style={{ margin: '10px 0' }}>
                <Col span={12}>
                    <Row className="line">
                        <Col span={6}>
                            操作人：
                        </Col>
                        <Col span={18}>
                            {detailTip?.detail?.username}
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row className="line">
                        <Col span={6}>
                            操作时间：
                        </Col>
                        <Col span={18}>
                            {detailTip?.detail?.sub_time}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="line" style={{ margin: '10px 0' }}>
                <Col span={3}>
                    操作内容：
                </Col>
                <Col span={21}>
                    {detailTip?.detail?.content}
                </Col>
            </Row>

        </Modal>
    )
}

export default App