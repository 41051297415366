/* eslint-disable */
import { notification, Icon } from 'antd';
import React from 'react';
import './style.css';
import { InfoCircleOutlined, ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const openNotification = (title, icon, className) => {
    notification.open({
        message: title,
        //   description:description,
        icon: icon,
        className: className
    });
}
//提示信息
let Alert = (type, description) => {
    if (type === "success") {
        const successIcon = <CheckCircleOutlined style={{ fontSize: "25px", color: "#52c41a" }} />
        openNotification(description, successIcon, "success-alert");
    } else if (type === "error") {
        const errorIcon = <InfoCircleOutlined style={{ fontSize: "25px", color: "#f5222d" }} />
        openNotification(description, errorIcon, "error-alert");
    } else if (type === "warn") {
        const warnIcon = <ExclamationCircleOutlined style={{ fontSize: "25px", color: "#faad14" }} />
        openNotification(description, warnIcon, "warn-alert");
    } else if (type === "info") {
        const warnIcon = <InfoCircleOutlined style={{ fontSize: "25px", color: "#6EBBFF" }} />
        openNotification(description, warnIcon, "info-alert");
    }
}
let goLogin = () => {
    let href = window.location.href.replace("#", "@");
    window.location.href = window.location.protocol + "//" + window.location.host + "/client/?next=" + href;
}
let srcUrl = (str) => {
    const pa = []
    let externalUrl = false;
    // 匹配图片（g表示匹配所有结果i表示区分大小写）
    var imgReg = /<img.*?(?:>|\/>)/gi
    // 匹配src属性
    var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
    var arr = str.match(imgReg)
    // alert('所有已成功匹配图片的数组：' + arr)
    if (arr) {
        for (var i = 0; i < arr.length; i++) {
            var src = arr[i].match(srcReg)
            // 获取图片地址
            if (src[1] && (src[1].indexOf('https://') !== -1 || src[1].indexOf('http://') !== -1 || src[1].indexOf('file://') !== -1)) {
                // alert('已匹配的图片地址' + (i + 1) + '：' + src[1])
                pa.push(src[1])
            }
        }
    }
    if (pa.length > 0) {
        externalUrl = true
    } else {
        externalUrl = false
    }
    return externalUrl
}
export {
    Alert,
    goLogin,
    srcUrl
};