import React,{useEffect} from 'react';
import CloseIcon from '../../images/close-block1.png';
const FlagTip=(param)=>{
    useEffect(()=>{
        document.body.style.overflow="hidden";
        return ()=>{
            document.body.style.overflow="auto";
        }
    },[])
    return <div className="platform-pop-up">
        <div className="flag-script-box">
            <div className="script-title">
                Flag脚本格式如下
                <img src={CloseIcon} alt="" onClick={param.onClose}/>
            </div>
            <div className="script-content">
                <b>1、</b>文件型flag：（替换示例）<br/><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sed -i "s/$flag/$new_flag/g" /var/www/html/flag.php</b><br/>
                <b>2、</b>文件型flag：（覆盖示例）<br/><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;echo $new_flag > /flag.txt</b><br/>
                <b>3、</b>数据库型flag:<br/><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;mysql -e "USE supersqli; update flag_tab set flag='$new_flag' where id=1;" -uroot -proot</b><br/>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;温馨提示：</b>如果需要服务有优先级则使用&nbsp;&nbsp;<b>/usr/bin/wait-for-it.sh 127.0.0.1:3306 --</b> <b>执行的语句</b>，等待服务正常后在执行后面的语句，示例中为先等待MySQL服务OK后才执行对应的语句。<br/>
                {/* 需要做一个操作，启动时就将wait-for-it.sh脚本写入容器，/usr/bin/目录下<br/> */}
            </div>
        </div>
    </div>
}
export default FlagTip;