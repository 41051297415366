import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import CodeHighlighter from 'braft-extensions/dist/code-highlighter';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import styled from 'styled-components';
import history from '../../../Communal/history';
import Reminder from '../../../Communal/reminder';
import Added from '../../../../images/added.png';
import AuditStatus from '../../../../images/audit-status.png';
import Denied from '../../../../images/denied.png';
import Pass from '../../../../images/pass.png';
import instance from '../../../Communal/instance';

const options = {
    syntaxs: [
        {
            name: 'JavaScript',
            syntax: 'javascript'
        }, {
            name: 'HTML',
            syntax: 'html'
        }, {
            name: 'CSS',
            syntax: 'css'
        }, {
            name: 'Java',
            syntax: 'java',
        }, {
            name: 'PHP',
            syntax: 'php'
        }, {
            name: "Python",
            syntax: 'python'
        }
    ],
}
BraftEditor.use(CodeHighlighter(options))
const Div = styled.div`
    width:100%!important;
    color:#333;
    .bf-content {
        height:auto
    }
    .bf-container .public-DraftEditor-content .braft-code-block-wrapper .braft-code-block{
        max-height:10000px
    }
`

const MethodsSceneDetail = ({ param }) => {
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(null));
    const [detail, setDetail] = useState({});
    const [reminderShow, setReminderShow] = useState(false);
    useEffect(() => {
        getDetail()
    }, [])
    const getDetail = () => {
        instance.get(`/method_scene/${param.match.params.id}/`).then(res => {
            setEditorState(BraftEditor.createEditorState(res.desc))
            setDetail(res);
        })
    }
    return (
        <div className="activity-ctf-detail submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-name submit-ctf-div">
                    <div style={{ display: "inline-block" }} className='ctf-name'>
                        <span onClick={() => history.push('/resource')}>资源中心</span> ＞ <span onClick={() => history.push({ pathname: '/resource/methods', state: { tabKey: 1 } })}>手法场景资源</span> ＞ {detail.name}
                    </div>
                    {
                        detail.is_show_opin &&
                        <div className="status">
                            {
                                detail.status === 0 &&
                                <img src={AuditStatus} alt="" />
                            }
                            {
                                detail.status === 1 &&
                                <img src={Pass} alt="" />
                            }
                            {
                                detail.status === 2 &&
                                <img src={Added} alt="" />
                            }
                            {
                                detail.status === 3 &&
                                <img src={Denied} alt="" />
                            }
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    detail.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">
                                                <pre style={{ whiteSpace: "pre-line" }}>{val.msg}</pre>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div">
                    <div className="award">
                        <div>
                            <span>{detail.coin}</span>
                            <span>奖励</span>
                        </div>
                        <p onClick={() => setReminderShow(true)}>赚取更多奖励＞</p>
                    </div>
                    <div className="title">手法场景信息</div>
                    <div className="content">
                        {
                            detail.status === 1 &&
                            <div>
                                <span>手法场景编号</span>
                                <div>{detail.number ? detail.number : "暂无编号"}</div>
                            </div>
                        }
                        <div>
                            <span>手法场景名称</span>
                            <div>{detail.name}</div>
                        </div>
                        <div>
                            <span>手法场景标签</span>
                            <div>
                                {
                                    (detail.tags_name || []).map((val, key) => {
                                        return <Tag key={key} color="green">
                                            {val}
                                        </Tag>
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            <span>手法场景分类</span>
                            <div>{detail.classify_name}</div>
                        </div>
                        {
                            detail.status === 1 && [
                                <div key="2">
                                    <span>提交用户</span>
                                    <div>
                                        {detail.username}
                                    </div>
                                </div>,
                                <div key="3">
                                    <span>提交时间</span>
                                    <div>
                                        {detail.sub_time}
                                    </div>
                                </div>
                            ]
                        }
                    </div>
                    <div className='title'>手法场景描述</div>
                    <div className="content">
                        <div>
                            <Div className="container">
                                <BraftEditor
                                    value={editorState}
                                    readOnly
                                    controls={[]}
                                />
                            </Div>
                        </div>
                    </div>
                </div>
            </div>
            {
                reminderShow &&
                <Reminder
                    close={() => setReminderShow(false)}
                />
            }
        </div>
    )
}
export default MethodsSceneDetail;