import React, { useEffect, useState, useCallback } from 'react';
import CtfModel from '../../images/ctf-model.png';
import VulnModel from '../../images/vuln-model.png';
import TestModel from '../../images/test-model.png';
import ToolModel from '../../images/tool-model.png';
import TopicModel from '../../images/topic-model.png';
import AWDModel from '../../images/awd-model.png';
import SceneElementIcon from '../../images/scene-element-icon.png';
import MethodIcon from '../../images/method-icon.png';
import KnowledgeModel from '../../images/knowledge-model.png';
import RecruitIcon from '../../images/recruit-icon.png';
import VulnInfo from '../../images/vuln-info-icon.png';
import SceneIcon from '../../images/scene-icon.png';
import RuleIcon from '../../images/rule-icon.png';
import { Alert, goLogin } from '../Communal/utility';
import instance from '../Communal/instance';
import { useMappedState } from "redux-react-hook";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

function Home() {
    const [data, setData] = useState({});
    // 拿到公共的值---实时变化
    const mapstate = useCallback((state) => ({
        login: state.login,
        userInfo: state.userInfo
    }), []);
    //获取数据并订阅存储
    const loginInfo = useMappedState(mapstate);
    useEffect(() => {
        document.getElementsByClassName("resource-home")[0].style.minHeight = document.body.clientHeight - 40 + "px";
        instance.get("/ctf/resource/type/").then(data => {
            setData({ ...data })
        })
    }, [])
    let handleHref = (type, resource) => {
        if (loginInfo.login) {
            if (!type) {
                Alert("info", "您没有权限查看该类型资源,请联系管理员添加！");
                return
            }
            if (resource === 0) {
                window.location.href = "/#/resource/ctf"
            } else if (resource === 1) {
                window.location.href = "/#/resource/vuln"
            } else if (resource === 2) {
                window.location.href = "/#/resource/exp"
            } else if (resource === 3) {
                window.location.href = "/#/resource/tool"
            } else if (resource === 4) {
                window.location.href = "/#/resource/test"
            } else if (resource === 5) {
                window.location.href = "/#/resource/awd"
            } else if (resource === 6) {
                window.location.href = "/#/resource/sceneelement"
            } else if (resource === 7) {
                window.location.href = "/#/resource/methods"
            } else if (resource === 8) {
                window.location.href = "/#/resource/knowledge"
            } else if (resource === 9) {
                window.location.href = "/#/resource/recruit"
            } else if (resource === 10) {
                window.location.href = "/#/resource/vulninfo"
            } else if (resource === 11) {
                window.location.href = "/#/resource/scene"
            } else if (resource === 12) {
                window.location.href = "/#/resource/rule"
            }
        } else {
            goLogin();
        }
    }
    return (
        <div className="resource-home">
            <div className="content-container">
                <div className="resource-home-title">
                    <div>在线资源集中中心</div>
                    <div>
                        <span>各类CTF</span>
                        <b></b>
                        <span>安全实验</span>
                        <b></b>
                        <span>技术小贴士</span>
                    </div>
                </div>
                <div className="resource-home-model">
                    <div className="resource-model-line resource-model-line-1">
                        <div onClick={() => handleHref(data?.ctf?.is_access, 0)}>
                            <img src={CtfModel} alt="" />
                            <div className="name">{data.ctf ? data.ctf.name : "CTF题目资源库"}</div>
                            <div>
                                <span>资源数：{data.ctf ? (data?.ctf?.resource_sum >= 1000 ? '999+' : data?.ctf?.resource_sum) : '0'}
                                </span>
                                <span>访问数：{data.ctf ? (data?.ctf?.visits >= 1000 ? '999+' : data?.ctf?.visits) : '0'}</span>
                            </div>
                        </div>
                        <div onClick={() => handleHref(data?.vuln?.is_access, 1)}>
                            <img src={VulnModel} alt="" />
                            <div className="name">{data.vuln ? data.vuln.name : "漏洞资源库"}</div>
                            <div>
                                <span>资源数：{data.vuln ? (data?.vuln?.resource_sum >= 1000 ? '999+' : data?.vuln?.resource_sum) : '0'}</span>
                                <span>访问数：{data.vuln ? (data?.vuln?.visits >= 1000 ? '999+' : data?.vuln?.visits) : '0'}</span>
                            </div>
                        </div>
                        <div onClick={() => handleHref(data?.experiment?.is_access, 2)}>
                            <img src={TestModel} alt="" />
                            <div className="name">{data.experiment ? data.experiment.name : "安全实验资源库"}</div>
                            <div>
                                <span>资源数：{data.experiment ? (data?.experiment?.resource_sum >= 1000 ? '999+' : data?.experiment?.resource_sum) : '0'}</span>
                                <span>访问数：{data.experiment ? (data?.experiment?.visits >= 1000 ? '999+' : data?.experiment?.visits) : '0'}</span>
                            </div>
                        </div>
                        <div onClick={() => handleHref(data?.tool?.is_access, 3)}>
                            <img src={ToolModel} alt="" />
                            <div className="name">{data.tool ? data.tool.name : "工具资源库"}</div>
                            <div>
                                <span>资源数：{data.tool ? (data?.tool?.resource_sum >= 1000 ? '999+' : data?.tool?.resource_sum) : '0'}</span>
                                <span>访问数：{data.tool ? (data?.tool?.visits >= 1000 ? '999+' : data?.tool?.visits) : '0'}</span>
                            </div>
                        </div>
                        <div onClick={() => handleHref(data?.test?.is_access, 4)}>
                            <img src={TopicModel} alt="" />
                            <div className="name">{data.test ? data?.test?.name : "理论题目资源库"}</div>
                            <div>
                                <span>资源数：{data.test ? (data?.test?.resource_sum >= 1000 ? '999+' : data?.test?.resource_sum) : '0'}</span>
                                <span>访问数：{data.test ? (data?.test?.visits >= 1000 ? '999+' : data?.test?.visits) : '0'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="resource-model-line">
                        <div onClick={() => handleHref(data?.awd?.is_access, 5)}>
                            <img src={AWDModel} alt="" />
                            <div className="name">{data.awd ? data.awd.name : "AWD题目资源库"}</div>
                            <div>
                                <span>资源数：{data.awd ? (data?.awd?.resource_sum >= 1000 ? '999+' : data?.awd?.resource_sum) : '0'}</span>
                                <span>访问数：{data.awd ? (data?.awd?.visits >= 1000 ? '999+' : data?.awd?.visits) : '0'}</span>
                            </div>
                        </div>
                        <div onClick={() => handleHref(data?.sceneobj?.is_access || data?.threatpoint?.is_access, 6)}>
                            <img src={SceneElementIcon} alt="" />
                            <div className="name">威胁资源库</div>
                            <div>
                                <span>
                                    资源数：
                                    {
                                        (data.sceneobj || data?.threatpoint) ?
                                            ((data?.sceneobj?.resource_sum + data?.threatpoint?.resource_sum) >= 1000 ?
                                                '999+' : (data?.sceneobj?.resource_sum + data?.threatpoint?.resource_sum))
                                            : '0'
                                    }
                                </span>
                                <span>
                                    访问数：
                                    {
                                        (data.sceneobj || data?.threatpoint) ?
                                            ((data?.sceneobj?.visits + data?.threatpoint?.visits) >= 1000 ? '999+' :
                                                (data?.sceneobj?.visits + data?.threatpoint?.visits)) : '0'
                                    }
                                </span>
                            </div>
                        </div>
                        <div onClick={() => handleHref(data?.method?.is_access || data?.methodscene?.is_access, 7)}>
                            <img src={MethodIcon} alt="" />
                            <div className="name">手法资源库</div>
                            <div>
                                <span>
                                    资源数：
                                    {
                                        (data.method || data?.methodscene) ?
                                            (
                                                (data?.method?.resource_sum + data?.methodscene?.resource_sum) >= 1000 ? '999+' : (data?.method?.resource_sum + data?.methodscene?.resource_sum)
                                            ) :
                                            '0'
                                    }
                                </span>
                                <span>访问数：{(data.method || data?.methodscene) ? ((data?.method?.visits + data?.methodscene?.visits) >= 1000 ? '999+' : (data?.method?.visits + data?.methodscene?.visits)) : '0'}</span>
                            </div>
                        </div>
                        <div onClick={() => handleHref(data?.knowledge?.is_access, 8)}>
                            <img src={KnowledgeModel} alt="" />
                            <div className="name">知识资源库</div>
                            <div>
                                <span>资源数：{data.knowledge ? (data?.knowledge?.resource_sum >= 1000 ? '999+' : data?.knowledge?.resource_sum) : '0'}</span>
                                <span>访问数：{data.knowledge ? (data?.knowledge?.visits >= 1000 ? '999+' : data?.knowledge?.visits) : '0'}</span>
                            </div>
                        </div>
                        <div onClick={() => handleHref(data?.station?.is_access || data?.enterprise?.is_access, 9)}>
                            <img src={RecruitIcon} alt="" />
                            <div className="name">招聘信息</div>
                            <div>
                                <span>
                                    资源数：
                                    {
                                        (data.station || data?.enterprise) ?
                                            ((data?.station?.resource_sum + data?.enterprise?.resource_sum) >= 1000 ? '999+' :
                                                (data?.station?.resource_sum + data?.enterprise?.resource_sum)) :
                                            '0'
                                    }
                                </span>
                                <span>
                                    访问数：
                                    {(data.station || data?.enterprise) ? ((data?.station?.visits + data?.enterprise?.visits) >= 1000 ? '999+' : (data?.station?.visits + data?.enterprise?.visits)) : '0'}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="resource-model-line">
                        <div onClick={() => handleHref(data?.vulnintelligence?.is_access, 10)}>
                            <img src={VulnInfo} alt="" />
                            <div className="name">漏洞情报</div>
                            <div>
                                <span>资源数：{data.vulnintelligence ? (data?.vulnintelligence?.resource_sum >= 1000 ? '999+' : data?.vulnintelligence?.resource_sum) : '0'}</span>
                                <span>访问数：{data.vulnintelligence ? (data?.vulnintelligence?.visits >= 1000 ? '999+' : data?.vulnintelligence?.visits) : '0'}</span>
                            </div>
                        </div>
                        {/* 场景任务 暂无场景管理 */}
                        <div onClick={() => handleHref(data?.scenetask?.is_access, 11)}>
                            <img src={SceneIcon} alt="" />
                            <div className="name">场景库</div>
                            <div>
                                <span>资源数：{data.scenetask ? (data?.scenetask?.resource_sum >= 1000 ? '999+' : data?.scenetask?.resource_sum) : '0'}</span>
                                <span>访问数：{data.scenetask ? (data?.scenetask?.visits >= 1000 ? '999+' : data?.scenetask?.visits) : '0'}</span>
                            </div>
                        </div>
                        <div onClick={() => handleHref(data?.rules?.is_access, 12)}>
                            <img src={RuleIcon} alt="" />
                            <div className="name">规则库</div>
                            <div>
                                <span>
                                    资源数：
                                    {
                                        (data.rules) ?
                                            (data?.rules?.resource_sum >= 1000 ? '999+' :
                                                data?.rules?.resource_sum) :
                                            '0'
                                    }
                                </span>
                                <span>
                                    访问数：
                                    {data.rules ? (data?.rules?.visits >= 1000 ? '999+' : data?.rules?.visits) : '0'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default Home