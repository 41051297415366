import React, { useEffect, useState } from 'react';
import { Button, Input, Select, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Denied from '../../../../images/denied.png';
import DeclineBox from '../../../Activity/DeclineBox';
import AddedBox from '../../../Activity/AddedBox';
import PassBox from '../../../Activity/PassBox';
import history from '../../../Communal/history';
import { Alert } from '../../../Communal/utility';
import instance from '../../../Communal/instance';
import SubmitBox from '../../submitBox';
import { zoneList, wayList, engineList } from '../data.json';
import './index.less';

const { Option } = Select;

const App = ({ param }) => {
    const [type, setType] = useState("0"); //type: 0 添加 1 编辑 2 审核
    const [submit, setSubmit] = useState({
        name: '',
        vulntype: null,
        company: null,
        model_name: null,
        content: [{ url: '/', zone: null, way: null, trait: '' }],
        dork: [{ engine: null, quake: '' }],
        verify: ['']
    });
    const [error, setError] = useState({
        name: [],
        vulntype: [],
        company: [],
        model_name: [],
        content: [],
        dork: [],
        verify: [],
    });
    const [initBox, setInitBox] = useState({
        passBox: false,
        declineBox: false,
        addedBox: false
    })
    const [submitLoading, setSubmitLoading] = useState(false);
    const [submitBox, setSubmitBox] = useState({
        show: false
    })
    const [classify, setClassify] = useState([])
    const [companyList, setCompanyList] = useState([])
    const [modelList, setModelList] = useState([])

    useEffect(() => {
        return () => {
            setSubmit({
                name: '',
                vulntype: null,
                company: null,
                model_name: null,
                content: [],
                dork: [],
                verify: []
            })
        }
    }, [])

    useEffect(() => {
        getCompanyList()
        getClassifyList()
    }, [])

    useEffect(() => {
        setType(param.match.params.type);
        if (param.match.params.id !== '0') {
            getDetail(param.match.params.id)
        }
        document.getElementsByClassName('submit-ctf')[0].style.minHeight = 'calc(100vh - 40px)';
    }, [param]);

    const getCompanyList = () => {
        instance.get(`/vulninte/api/static/module/`).then(data => {
            if (Array.isArray(data)) {
                setCompanyList(data)
            }
        })
    }

    const getClassifyList = () => {
        instance.get("/vulninte/api/static/make/").then(data => {
            if (Array.isArray(data?.mark)) {
                let list = []
                for (let i in data.mark[0]) {
                    list.push({
                        value: i * 1,
                        name: data.mark[0][i]
                    })
                }
                setClassify(list)
            }
        })
    }

    const getDetail = (id) => {
        instance.get(`/vulninte/api/mark/${param.match.params.id}/`).then(data => {
            getModelName(data?.module?.company)
            submit.name = data.name
            submit.vulntype = data.vulntype
            submit.company = data?.module?.company
            submit.model_name = data?.module?.name
            submit.content = data.content
            submit.dork = data.dork
            submit.verify = data.verify
            setSubmit({ ...submit });
        })
    }

    const handleContentChange = (value, index, name, from) => {
        let data = submit[from]
        if (from === 'verify') {
            data[index] = value
        } else {
            data[index][name] = value
        }
        inputChange(data, from)
    }

    const handleAddList = (name) => {
        let data = submit[name]
        if (name === 'content') {
            data.push({ url: '/', zone: null, way: null, trait: '' })
        }
        if (name === 'dork') {
            data.push({ engine: null, quake: '' })
        }
        if (name === 'verify') {
            data.push('')
        }
        inputChange(data, name)
    }

    const handleDeleteList = (name, index) => {
        let data = submit[name]
        if (data.length <= 1) {
            Alert('error', '至少需要存在一条数据')
            return
        }
        data.splice(index, 1);
        inputChange(data, name)
        handleFocus(name)
    }

    const inputChange = (value, name) => {
        submit[name] = value;
        setSubmit({ ...submit })
        if (name === 'company') {
            getModelName(value)
            submit.model_name = null
            setSubmit({ ...submit })
        }
    }

    const getModelName = (value) => {
        // 获取当前厂商的组件
        instance.post(`/vulninte/api/static/module/`, { company: value }).then(data => {
            if (Array.isArray(data)) {
                setModelList(data)
            }
        })
    }

    const handleFocus = (name) => {
        if (name === 'model_name') {
            if (submit.company === null) {
                error.company = ['请选择所属厂商']
                setError({ ...error })
                return false
            }
        }
        error[name] = []
        setError({ ...error })
    }

    // 审核
    let handleAudit = (data) => {
        let list = {
            ids: param.match.params.id,
            status: data.status,
            data: data.data
        }
        if (data.status === 1) {
            if (data.data === "") {
                Alert("error", "请输入奖励数");
                return
            }
        }
        if ((data.status === 2 || data.status === 3) && data.data === "") {
            Alert("error", "请输入审核意见");
            return
        }
        instance.post("/vulninte/api/audit/make/", list).then(data => {
            Alert("success", data.msg);
            history.push({ pathname: '/resource/vulninfo', state: { tabKey: "2" } })
            initBox.declineBox = false;
            initBox.addedBox = false;
            setInitBox({ ...initBox })
        }).catch(err => {
            Alert("error", err.error)
        })
    }

    const handleOk = () => {
        let dork = []
        submit.dork.map((value, key) => {
            if (value.engine !== null || value.quake !== '') {
                dork.push(value)
            }
        })
        if (dork.length <= 0) {
            dork = [{ engine: null, quake: '' }]
        }
        let list = {}
        list.name = submit.name
        list.vulntype = submit.vulntype * 1
        list.company = submit.company
        list.model_name = submit.model_name
        list.content = submit.content
        list.dork = dork
        list.verify = submit.verify
        setSubmitLoading(true)
        console.log(list);
        if (type === "0") {
            instance.post("/vulninte/api/mark/", list).then(data => {
                setSubmitLoading(false);
                Alert("success", "添加成功");
                history.push({ pathname: '/resource/vulninfo', state: { tabKey: "2" } })
                submitBox.show = false
                setSubmit({ ...submitBox })
            }).catch((err) => {
                setSubmitLoading(false);
                submitBox.show = false
                setSubmitBox({ ...submitBox })
                setError({ ...error, ...err })
            })
        }
        if (type === "1" || type === "2") {
            if (param.match.params.id) {
                instance.patch(`vulninte/api/mark/${param.match.params.id}/`, list).then(data => {
                    setSubmitLoading(false);
                    Alert("success", "编辑成功");
                    history.push({ pathname: '/resource/vulninfo', state: { tabKey: "2" } })
                }).catch((err) => {
                    setSubmitLoading(false);
                    setError({ ...error, ...err })
                    submitBox.show = false
                    setSubmitBox({ ...submitBox })
                })
            }
        }
    }

    const handleCommit = () => {
        let err = { ...error }
        if (submit.name === '') {
            err.name = ['请输入指纹名称']
        }
        if (submit.vulntype === null) {
            err.vulntype = ['请选择指纹类型']
        }
        if (submit.company === null) {
            err.company = ['请选择所属厂商']
        }
        if (submit.model_name === null) {
            err.model_name = ['请选择所属组件']
        }
        const content = submit.content.map((value, key) => {
            if (value.url === '' || value.zone === null || value.way === null || value.trait === '') {
                return false
            }
            return true
        })
        // const dork = submit.dork.map((value, key) => {
        //     if (value.engine === null || value.quake === '') {
        //         return false
        //     }
        //     return true
        // })
        // const verify = submit.verify.map((value, key) => {
        //     if (value === '') {
        //         return false
        //     }
        //     return true
        // })
        if (!content[0]) {
            err.content = ['请完整填写所有指纹url内容']
        }
        // if (!dork[0]) {
        //     err.dork = ['请完整填写所有搜索引擎内容']
        // }
        // if (!verify[0]) {
        //     err.verify = ['请完整填写所有验证地址内容']
        // }
        if (submit.name === '' ||
            submit.vulntype === null ||
            submit.company === null ||
            submit.model_name === null ||
            !content[0]
        ) {
            setError({ ...err })
            return
        }
        submitBox.show = true
        setSubmitBox({ ...submitBox })
    }
    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-div submit-ctf-name">
                    {
                        type === "0" && "提交指纹"
                    }
                    {
                        type === "1" && "编辑指纹"
                    }
                    {
                        type === "2" && "审核指纹"
                    }
                    {
                        submit.status === 3 &&
                        <div className="status">
                            <img src={Denied} alt="" />
                        </div>
                    }
                </div>
                {
                    submit.is_show_opin && submit.opin && submit.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    submit.opin &&
                                    submit.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">{val.msg}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div subject-info">
                    <div className="title">
                        指纹信息
                    </div>
                    <div className="content">
                        <div className="retrieval-div">
                            <span className='name'><b>*</b>指纹名称</span>
                            <Input
                                value={submit.name}
                                className="content-input"
                                placeholder="请输入指纹名称"
                                onChange={(e) => inputChange(e.target.value, "name")}
                                maxLength="200"
                                onFocus={() => handleFocus("name")}
                            />
                            <div className="error-message">{error?.name[0]}</div>
                        </div>
                        <Row>
                            <Col span={8} className="retrieval-div">
                                <span className='name'><b>*</b>指纹类型</span>
                                <Select
                                    className='width-select'
                                    value={submit.vulntype}
                                    placeholder='请选择指纹类型'
                                    style={{ width: 180 }}
                                    onChange={(value) => {
                                        inputChange(value, 'vulntype')
                                    }
                                    }
                                    onClick={() => handleFocus("vulntype")}
                                >
                                    {
                                        classify.map((val, key) => {
                                            return <Option
                                                key={key}
                                                value={val.value}
                                            >
                                                {val.name}
                                            </Option>
                                        })
                                    }
                                </Select>
                                <div className="error-message">{error?.vulntype[0]}</div>
                            </Col>
                            <Col span={8} className="retrieval-div">
                                <span className='name'><b>*</b>所属厂商</span>
                                <Select
                                    showSearch
                                    className='width-select'
                                    value={submit.company}
                                    placeholder='请选择所属厂商'
                                    style={{ width: 180 }}
                                    onChange={(value) => {
                                        inputChange(value, 'company')
                                    }
                                    }
                                    onClick={() => handleFocus("company")}
                                >
                                    {
                                        companyList.map((val, key) => {
                                            return <Option
                                                key={key}
                                                value={val}
                                            >
                                                {val}
                                            </Option>
                                        })
                                    }
                                </Select>
                                <div className="error-message">{error?.company[0]}</div>
                            </Col>
                            <Col span={8} className="retrieval-div">
                                <span className='name'><b>*</b>所属组件</span>
                                <Select
                                    showSearch
                                    className='width-select'
                                    value={submit.model_name}
                                    placeholder='请选择组件'
                                    style={{ width: 180 }}
                                    disabled={submit.company === null}
                                    onChange={(value) => {
                                        inputChange(value, 'model_name')
                                    }
                                    }
                                    onClick={() => handleFocus("model_name")}
                                >
                                    {
                                        modelList.map((val, key) => {
                                            return <Option
                                                key={key}
                                                value={val}
                                            >
                                                {val}
                                            </Option>
                                        })
                                    }
                                </Select>
                                <div className="error-message">{error?.model_name[0]}</div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="submit-ctf-div subject-info flex-submit">
                    <div className="title">
                        指纹内容
                    </div>
                    <div className="content">
                        <Row>
                            <Col span={23} className='list-box'>
                                {
                                    (submit.content || []).map((x, i) => {
                                        return <Row key={i} className='list-line'>
                                            <Col span={6} className="retrieval-div flex-retrieval-div">
                                                <span
                                                    className='name'
                                                    style={{ width: '50px' }}
                                                >
                                                    <b>*</b>url
                                                </span>
                                                <Input
                                                    value={submit.content[i].url}
                                                    className=" flex-auto"
                                                    placeholder="请输入url"
                                                    onChange={(e) => handleContentChange(e.target.value, i, 'url', 'content')}
                                                    maxLength="200"
                                                    onFocus={() => handleFocus("content")}
                                                />
                                            </Col>
                                            <Col span={5} className="retrieval-div flex-retrieval-div">
                                                <span className='name'><b>*</b>识别区域</span>
                                                <Select
                                                    className='width-select-100 flex-auto'
                                                    value={submit.content[i].zone}
                                                    placeholder='请选择识别区域'
                                                    onChange={(e) => handleContentChange(e, i, 'zone', 'content')}
                                                    onClick={() => handleFocus("content")}
                                                >
                                                    {zoneList.map((item, index) => {
                                                        return <Option key={index} value={item.value}>
                                                            {item.name}
                                                        </Option>
                                                    })}
                                                </Select>
                                            </Col>
                                            <Col span={6} className="retrieval-div flex-retrieval-div">
                                                <span className='name'><b>*</b>识别方式</span>
                                                <Select
                                                    className='width-select-100 flex-auto'
                                                    value={submit.content[i].way}
                                                    placeholder='请选择识别方式'
                                                    onChange={(e) => handleContentChange(e, i, 'way', 'content')}
                                                    onClick={() => handleFocus("content")}
                                                >
                                                    {wayList.map((item, index) => {
                                                        return <Option key={index} value={item.value}>
                                                            {item.name}
                                                        </Option>
                                                    })}
                                                </Select>
                                            </Col>
                                            <Col span={6} className="retrieval-div flex-retrieval-div">
                                                <span className='name'><b>*</b>指纹特征</span>
                                                <Input
                                                    value={submit.content[i].trait}
                                                    className=" flex-auto"
                                                    placeholder="请输入指纹特征"
                                                    onChange={(e) => handleContentChange(e.target.value, i, 'trait', 'content')}
                                                    maxLength="500"
                                                    onFocus={() => handleFocus("content")}
                                                />
                                            </Col>
                                            {submit?.content?.length > 1 ? <Col span={1} className='operate-icon'>
                                                <MinusCircleOutlined
                                                    style={{ color: '#e76161' }}
                                                    onClick={() => { handleDeleteList('content', i) }}
                                                />
                                            </Col> : null}
                                        </Row>
                                    })
                                }
                                <div className="error-message">{error?.content[0]}</div>
                            </Col>
                            <Col span={1} className='operate-icon'>
                                <PlusCircleOutlined
                                    style={{ color: '#52c41a' }}
                                    onClick={() => { handleAddList('content') }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={23} className='list-box'>
                                {
                                    submit?.dork.map((x, i) => {
                                        return <Row key={i} className='list-line'>
                                            <Col span={7} className="retrieval-div flex-retrieval-div">
                                                <span className='name'>
                                                    搜索引擎
                                                </span>
                                                <Select
                                                    className='width-select-100 flex-auto'
                                                    value={submit.dork[i].engine}
                                                    placeholder='请选择搜索引擎'
                                                    onChange={(e) => handleContentChange(e, i, 'engine', 'dork')}
                                                    onClick={() => handleFocus("dork")}
                                                >
                                                    {engineList.map((item, index) => {
                                                        return <Option key={index} value={item.value}>
                                                            {item.name}
                                                        </Option>
                                                    })}
                                                </Select>
                                            </Col>
                                            <Col span={16} className="retrieval-div flex-retrieval-div">
                                                <span className='name'>Dork语法</span>
                                                <Input
                                                    value={submit.dork[i].quake}
                                                    className=" flex-auto"
                                                    placeholder="请输入Dork语法"
                                                    onChange={(e) => handleContentChange(e.target.value, i, 'quake', 'dork')}
                                                    maxLength="500"
                                                    onFocus={() => handleFocus("dork")}
                                                />
                                            </Col>
                                            {submit?.dork?.length > 1 ? <Col span={1} className='operate-icon'>
                                                <MinusCircleOutlined
                                                    style={{ color: '#e76161' }}
                                                    onClick={() => { handleDeleteList('dork', i) }}
                                                />
                                            </Col> : null}
                                        </Row>
                                    })
                                }
                                <div className="error-message">{error?.dork[0]}</div>
                            </Col>
                            <Col span={1} className='operate-icon'>
                                <PlusCircleOutlined
                                    style={{ color: '#52c41a' }}
                                    onClick={() => { handleAddList('dork') }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={23} className='list-box'>
                                {
                                    submit?.verify.map((x, i) => {
                                        return <Row key={i} className='list-line'>
                                            <Col span={23} className="retrieval-div flex-retrieval-div">
                                                <span className='name'>验证地址</span>
                                                <Input
                                                    value={submit.verify[i]}
                                                    className=" flex-auto"
                                                    placeholder="请输入验证地址"
                                                    onChange={(e) => handleContentChange(e.target.value, i, '', 'verify')}
                                                    maxLength="500"
                                                    onFocus={() => handleFocus("verify")}
                                                />
                                            </Col>
                                            {submit?.verify?.length > 1 ? <Col span={1} className='operate-icon'>
                                                <MinusCircleOutlined
                                                    style={{ color: '#e76161' }}
                                                    onClick={() => { handleDeleteList('verify', i) }}
                                                />
                                            </Col> : null}
                                        </Row>
                                    })
                                }
                                <div className="error-message">{error?.verify[0]}</div>
                            </Col>
                            <Col span={1} className='operate-icon'>
                                <PlusCircleOutlined
                                    style={{ color: '#52c41a' }}
                                    onClick={() => { handleAddList('verify') }}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                {
                    //新增 //编辑
                    (type === "0" || type === "1") &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            loading={submitLoading}
                            onClick={() => {
                                handleCommit()
                            }}
                        >
                            提交
                        </Button>
                    </div>
                }
                {
                    type === "2" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => {
                                initBox.passBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            通过
                        </Button>
                        <Button
                            style={{ backgroundColor: "transparent", color: "#266cff" }}
                            type="primary"
                            onClick={() => history.push({ pathname: '/resource/vulninfo', state: { tabKey: "2" } })}
                        >
                            取消
                        </Button>
                        <Button className="modify" loading={submitLoading} onClick={() => {
                            handleCommit()
                        }}>保存修改</Button>
                        <Button
                            className="decline"
                            onClick={() => {
                                initBox.declineBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            拒绝
                        </Button>
                        <Button
                            className="added"
                            onClick={() => {
                                initBox.addedBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            待补充
                        </Button>
                        <Button
                            className="added"
                            onClick={() => handleAudit({ status: 0 })}
                        >
                            待审核
                        </Button>
                    </div>
                }
            </div>
            {
                initBox.passBox &&
                <PassBox
                    close={() => {
                        initBox.passBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.declineBox &&
                <DeclineBox
                    close={() => {
                        initBox.declineBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.addedBox &&
                <AddedBox
                    close={() => {
                        initBox.addedBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            <SubmitBox
                submit={submitBox}
                resourceName={'指纹'}
                onOK={() => { handleOk() }}
                onClose={() => {
                    submitBox.show = false
                    setSubmitBox({ ...submitBox })
                }}
                loading={submitLoading}
            />
        </div >
    )

}

export default App;