import React, { useEffect, useState } from 'react';
import CloseBox from '../../images/close-box.png';
import { Input } from 'antd';
import { Alert } from '../Communal/utility';
function PassBox(param) {
    useEffect(() => {
        document.body.style.overflow = "hidden"
    }, [])
    useEffect(() => {
        return () => {
            document.body.style.overflow = "auto"
        }
    }, []);
    const [initData, setIninData] = useState(
        {
            gold: param.hideReward ? '0' : 1,
            grade: 1
        }
    )
    return (
        <div className="audit-bounced">
            <div className="content pass-box">
                <div className="header">
                    <img src={CloseBox} onClick={param.close} alt="" />
                </div>
                <div className="detail">
                    <div>通过提交人提交资源</div>
                    {!param.hideReward && <div>
                        <div className="name">请填写奖励奖励数</div>
                        <div className="input-dev" style={{ marginTop: "20px" }}>
                            <b>*</b>
                            <Input
                                value={initData.gold}
                                type="number"
                                placeholder="请输入奖励数"
                                maxLength={7}
                                onChange={(e) => {
                                    initData.gold = Number(e.target.value);
                                    setIninData({ ...initData })
                                }}
                            />
                        </div>
                    </div>}
                    <div className="btn" style={param.hideReward && { marginTop: 100 }}>
                        <button
                            onClick={() => {
                                if (!initData.gold) {
                                    Alert('warn', '请输入奖励数！')
                                    return
                                }
                                param.click({ status: 1, data: initData.gold })
                            }}
                        >
                            通过
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PassBox;