import React from 'react';
function DeleteBox(param){
    return (
        <div className="audit-bounced">
            <div className="content delete-box">
                <div>是否删除当前选择{param.title?param.title:'题目'}？</div>
                <div className="btn">
                    <span onClick={param.close}>取消</span>
                    <span onClick={param.submit}>确认</span>
                </div>
            </div>
        </div>
    )
}
export default DeleteBox;