/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {Button,Checkbox} from 'antd';
import instance from '../Communal/instance';
import { Alert } from '../Communal/utility';
import Loading  from '../Communal/loading';
const ImportToolBox=({system,onClose})=>{
    const [systemCheck,setSystemCheck]=useState([]);
    const [selectOption,setSelectOption]=useState([]);
    const [systemCount,setSystemCount]=useState('');
    const [loadingShow,setLoadingShow]=useState(false);
    useEffect(()=>{
        let list=[];
        for(var i =0;i<system.length;i++){
            list.push({
                label: system[i].name, value: system[i].id
            })
        }
        setSystemCheck(list)
    },[system]);
    useEffect(()=>{
        getCount();
    },[selectOption]);
    const getCount=()=>{
        instance.get("/tool/export/count/",{ params: {system:selectOption}}).then(res=>{
            setSystemCount(res.count)
        })
    }
    const exprotTool=()=>{
        setLoadingShow(true);
        instance.post('/tool/export/?type=2',{system:selectOption}).then(res=>{
            if(res.status===1){
                const link = document.createElement("a"); // 创建a标签
                link.href = res.file_path;
                link.download = "导出工具.zip" 
                link.click();
            }
            setLoadingShow(false);
        }).catch(err=>{
            if(err.error){
                Alert('error',err.error)
            }
            setLoadingShow(false);
        })
    }
    return (
        <div className='platform-pop-up'>
            <div className='import-tool-box'>
                <div className='title'>
                    选择体系导出工具
                </div>
                <div className='content'>
                    <p>默认导出全部已发布工具，选择体系则导出对应已发布工具</p>
                    <Checkbox.Group 
                        options={systemCheck}
                        onChange={e=>{
                            setSelectOption(e);
                        }}
                    />
                    <div className='tips'>
                        已选择导出<b>{systemCount}</b>个工具
                    </div>
                </div>
                <div className='bottom'>
                    <Button
                        onClick={onClose}
                    >
                        取消
                    </Button>
                    <Button
                        type='primary'
                        onClick={exprotTool}
                    >
                        导出
                    </Button>
                </div>
            </div>
            {
                loadingShow&&
                <Loading 
                    name='导出'
                />
            }
        </div>
    )
}
export default ImportToolBox;