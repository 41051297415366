//活动列表
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import CtfIcon from '../../images/ctf-icon.png';
// import TestIcon from '../../images/ctf-icon.png';
// import ToolIcon from '../../images/ctf-icon.png';
// import VulnIcon from '../../images/ctf-icon.png';
import { RightOutlined } from '@ant-design/icons';
import Underway from '../../images/underway-icon.png';
import { Pagination } from 'antd';
import instance from '../Communal/instance';
import { useMappedState } from "redux-react-hook";
import { goLogin, Alert } from '../Communal/utility'
import ApplyBox from './applyBox';
function List() {
    const [category, setCategory] = useState([]);
    const [status] = useState(
        [
            {
                id: "",
                name: "全部",
            },
            {
                id: 2,
                name: "即将开始",
            },
            {
                id: 0,
                name: "进行中",
            },
            {
                id: 1,
                name: "已结束",
            }
        ]
    );
    const [applyStatus] = useState(
        [
            {
                id: "",
                name: "全部",
            },
            {
                id: 3,
                name: "未报名",
            },
            {
                id: 0,
                name: "待审核",
            },
            {
                id: 1,
                name: "报名成功",
            },
            {
                id: 2,
                name: "已拒绝",
            }
        ]
    );
    const [initData, setInitData] = useState({
        data: [],
        allCount: 0
    });
    const [applyData, setApplyData] = useState(
        {
            show: false,
            qrCode: "",
            id: "",
        }
    )
    const [applyErr, setApplyErr] = useState({})
    const [filter, setFilter] = useState(
        {
            page: 1,
            size: 12,
            resource_type_id: "",
            activity_status: "",
            apply_status: ""
        }
    )
    // 拿到公共的值---实时变化
    const mapstate = useCallback((state) => ({
        login: state.login,
        userInfo: state.userInfo
    }), []);
    //获取数据并订阅存储
    const loginInfo = useMappedState(mapstate);
    //征集列表选择||活动状态选择||报名状态选择
    let handleStatus = (name, id) => {
        filter[name] = id;
        filter.page = 1;
        setFilter({ ...filter });
    }
    useEffect(() => {
        // 获取征集类别
        instance.get("/activity/resource/type/").then(data => {
            let category = data;
            category.unshift({
                name: "全部",
                id: ""
            });
            setCategory(category)
        })
    }, []);
    useEffect(() => {
        getActivity()
    }, [filter]);
    //获取活动列表
    let getActivity = () => {
        let list = {
            page: filter.page,
            size: filter.size
        }
        if (filter.resource_type_id !== "") {
            list.resource_type_id = filter.resource_type_id
        }
        if (filter.apply_status !== "") {
            list.apply_status = filter.apply_status
        }
        if (filter.activity_status !== "") {
            list.activity_status = filter.activity_status
        }
        instance.get("/activity/list/", { params: list }).then(data => {
            initData.data = data.results;
            initData.allCount = data.count;
            setInitData({ ...initData })
        })
    }
    //查看详情，判断是否报名 0为已报名 1为未报名
    let LookDetail = (apply, id, qr_code, status, is_detail, is_apply) => {
        if (loginInfo.login) {
            if (is_apply === 0) {
                if (is_detail) {
                    window.location.href = "/#/activity/detail/" + id
                } else {
                    Alert("warn", "当前活动不允许查看详情")
                }
                return
            }
            if (status === 0 || status === 2) {
                if (apply === 3) {
                    applyData.show = true;
                    applyData.id = id;
                    applyData.qrCode = qr_code;
                    setApplyData({ ...applyData })
                } else if (apply === 0) {
                    Alert("warn", "您已报名，正在等待审核")
                } else if (apply === 2) {
                    Alert("warn", "对不起，您已被拒绝参加此次活动")
                } else if (apply === 1) {
                    if (is_detail) {
                        window.location.href = "/#/activity/detail/" + id
                    } else {
                        Alert("warn", "当前活动不允许查看详情")
                    }
                }
            } else if (status === 1) {
                if (apply === 1) {
                    if (is_detail) {
                        window.location.href = "/#/activity/detail/" + id
                    } else {
                        Alert("warn", "当前活动不允许查看详情")
                    }
                } else {
                    Alert("warn", "活动已结束")
                }
            }
        } else {
            goLogin();
        }
    }
    // 报名
    let submitApply = (param) => {
        let list = {
            activity: applyData.id,
            class_name: param.grade,
            unit: param.unit,
            user_name: param.name
        }
        instance.post("/activity/apply/", list).then(data => {
            applyData.show = false;
            applyData.id = "";
            applyData.qrCode = "";
            setApplyData({ ...applyData });
            getActivity();
            Alert("success", "报名成功，等待审核中！")
        }).catch(err => {
            setApplyErr(err)
            // Alert("error", err.activity[0])
        })
    }
    let errChange = (type) => {
        let data = { ...applyErr }
        data[type] = null
        setApplyErr(data)
    }
    return (
        <div className="platform-activity-home">
            <div className="banner"></div>
            <div className="content-container">
                <div className="activity-screen ">
                    <div>
                        <span>征集类别：</span>
                        <div>
                            {
                                category.map((val, key) => {
                                    return <span
                                        key={key + "key"}
                                        className={filter.resource_type_id === val.id ? "active" : ""}
                                        onClick={() => handleStatus("resource_type_id", val.id)}
                                    >
                                        {val.name}
                                    </span>
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <span>活动状态：</span>
                        <div>
                            {
                                status.map((val, key) => {
                                    return <span
                                        key={key + "key"}
                                        className={filter.activity_status === val.id ? "active" : ""}
                                        onClick={() => handleStatus("activity_status", val.id)}
                                    >
                                        {val.name}
                                    </span>
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <span>报名状态：</span>
                        <div>
                            {
                                applyStatus.map((val, key) => {
                                    return <span
                                        key={key + "key"}
                                        className={filter.apply_status === val.id ? "active" : ""}
                                        onClick={() => handleStatus("apply_status", val.id)}
                                    >
                                        {val.name}
                                    </span>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div>
                    {
                        initData.data.map((val, key) => {
                            return <div
                                key={"key" + key}
                                className="single-hot-events"
                            >
                                <div>
                                    <img src={CtfIcon} alt="" />
                                    {/* {
										val.type===2&&
										<img src={TestIcon} alt=""/>
									}
									{
										val.type===3&&
										<img src={VulnIcon} alt=""/>
									}
									{
										val.type===4&&
										<img src={ToolIcon} alt=""/>
									} */}
                                </div>
                                <div>
                                    <div
                                        className="name"
                                        onClick={() => LookDetail(val.apply_status, val.id, val.qr_code, val.activity_status, val.is_detail, val.apply)}
                                        title={val.name}
                                    >
                                        {val.name}
                                    </div>
                                    <div className="intro" title={val.intro}>{val.intro}</div>
                                </div>
                                <div>
                                    <div>
                                        <span>活动截止日期：</span>
                                        <span>{val.end_time}</span>
                                    </div>
                                    <span onClick={() => LookDetail(val.apply_status, val.id, val.qr_code, val.activity_status, val.is_detail, val.apply)}>
                                        <RightOutlined />
                                    </span>
                                </div>
                                {
                                    val.activity_status === 0 &&
                                    <img src={Underway} alt="" />
                                }
                            </div>
                        })
                    }
                </div>
                {
                    initData.data.length === 0 &&
                    <div style={{ textAlign: "center", fontSize: "25px", lineHeight: "100px", color: "#999" }}>暂未筛选到数据</div>
                }
                <div className="flip-over">
                    <Pagination
                        showQuickJumper={true}
                        current={filter.page}
                        pageSize={filter.size}
                        total={initData.allCount}
                        hideOnSinglePage={true}
                        showSizeChanger={false}
                        onChange={(page) => {
                            filter.page = page;
                            setFilter({ ...filter })
                        }}
                    />
                </div>
            </div>
            {
                applyData.show &&
                <ApplyBox
                    close={() => {
                        applyData.show = false;
                        applyData.qrCode = "";
                        applyData.id = "";
                        setApplyData({ ...applyData })
                        setApplyErr({})
                    }}
                    applyData={applyData}
                    applyErr={applyErr}
                    submit={submitApply}
                    errChange={errChange}
                />
            }
        </div>
    );
}

export default List;