//首页
import React,{useState,useEffect,useCallback} from 'react';
import '../../css/home.css';
import CtfIcon from '../../images/ctf-icon.png';
// import TestIcon from '../../images/ctf-icon.png';
// import ToolIcon from '../../images/ctf-icon.png';
// import VulnIcon from '../../images/ctf-icon.png';
import { RightOutlined } from '@ant-design/icons';
import Underway from '../../images/underway-icon.png';
import Config from '../../json/config.json';
import {Input,Button} from 'antd';
import instance from '../Communal/instance';
import { Alert,goLogin } from '../Communal/utility';
import { useDispatch,useMappedState } from "redux-react-hook";
import ApplyBox from '../Activity/applyBox';
 
function Index() {
	const [ list,setList ] = useState([]);
	const [userName,setUserName]=useState("");
	const [applyErr, setApplyErr] = useState({})
	const [applyData,setApplyData]=useState(
		{
			show:false,
			qrCode:"",
			id:"",
		}
	)
	// 拿到公共的值---实时变化
    const mapstate = useCallback((state) => ({
		login:state.login,
		userInfo:state.userInfo
	}),[]);
	//获取数据并订阅存储
	const loginInfo= useMappedState(mapstate);
	const dispatch = useDispatch();
	useEffect(() => {
		getList();
	}, []);
	//获取热搜列表
	let getList=()=>{
		instance.get("/activity/hot/").then(data=>{
			setList(data)
		})
	}
	let gologin=()=>{
		instance.post("/users/login/",{username:userName}).then((data)=>{
			Alert("success","登录成功");
			setUserName("");
			instance.get("/users/status/").then(data=>{
				dispatch({
					type: "SET_LOGIN",
					login: data.status
				})
				if(data.status){
					instance.get("/users/user_info/").then(detail=>{
						dispatch({
							type: "SET_USERINFO",
							userInfo: detail
						})
					})
				}
			})
		}).catch(err=>{
			if(err.detail){
				Alert("error",err.detail)
			}
			if(err.username){
				Alert("error",err.username)
			}
		})
	}
	//查看详情，判断是否报名 0为已报名 1为未报名
	let LookDetail=(apply,id,qr_code,status,is_detail,is_apply)=>{
		if(loginInfo.login){
			if(is_apply===0){
				if(is_detail){
					window.location.href="/#/activity/detail/"+id
				}else{
					Alert("warn","当前活动不允许查看详情")
				}
				return 
			}
			if(status===0||status===2){
				if(apply===3){
					applyData.show=true;
					applyData.id=id;
					applyData.qrCode=qr_code;
					setApplyData({...applyData})
				}else if(apply===0){
					Alert("warn","您已报名，正在等待审核")
				}else if(apply===2){
					Alert("warn","对不起，您已被拒绝参加此次活动")
				}else if(apply===1){
					if(is_detail){
						window.location.href="/#/activity/detail/"+id
					}else{
						Alert("warn","当前活动不允许查看详情")
					}
				}
			}else if(status===1){
				if(apply===1){
					if(is_detail){
						window.location.href="/#/activity/detail/"+id
					}else{
						Alert("warn","当前活动不允许查看详情")
					}
				}else{
					Alert("warn","活动已结束")
				}
			}
		}else{
			goLogin();
		}
	}
	let submitApply=(param)=>{
		let list={
			activity:applyData.id,
            class_name:param.grade,
            unit:param.unit,
            user_name:param.name
		}
		instance.post("/activity/apply/",list).then(data=>{
			applyData.show=false;
			applyData.id="";
			applyData.qrCode="";
			setApplyData({...applyData});
			getList();
			Alert("success","报名成功，等待审核中！")
		}).catch(err=>{
			// Alert("error",err.activity[0])
			setApplyErr(err)
		})
	}
	let errChange = (type) => {
        let data = { ...applyErr }
        data[type] = null
        setApplyErr(data)
    }
	return (
		<div className="platform-home">
			<div className="banner">
				{
					Config.login&&
					<div className="temporary-login">
						<div className="content">	
							<Input 
								placeholder="请输入用户名"
								value={userName}
								onChange={(e)=>{
									setUserName(e.target.value)
								}}
							/>
							<Button
								type="primary"	
								onClick={gologin}
							>
								登录
							</Button>
						</div>
					</div>
				}
			</div>
			<div className="platform-home-hot-events">
				<p>热门活动</p>
				<div className="content-container">
					{
						list.map((val,key)=>{
							return <div 
								key={"key"+key}
								className="single-hot-events"
							>
								<div>
									
									<img src={CtfIcon} alt=""/>
									{/* 									
									{
										val.type===2&&
										<img src={TestIcon} alt=""/>
									}
									{
										val.type===3&&
										<img src={VulnIcon} alt=""/>
									}
									{
										val.type===4&&
										<img src={ToolIcon} alt=""/>
									} */}
								</div>
								<div>
									<div className="name" onClick={()=>LookDetail(val.apply_status,val.id,val.qr_code,val.activity_status,val.is_detail,val.apply)} title={val.name}>{val.name}</div>
									<div className="intro" title={val.intro}>{val.intro}</div>
								</div>
								<div>
									<div>
										<span>活动截止日期：</span>
										<span>{val.end_time}</span>
									</div>
									<span onClick={()=>LookDetail(val.apply_status,val.id,val.qr_code,val.activity_status,val.is_detail,val.apply)}>
										<RightOutlined />
									</span>
								</div>
								{
									Number(val.activity_status)===0&&
									<img src={Underway} alt=""/>
								}
							</div>
						})
					}
				</div>
			</div>
			<div className="events-process">
				<p>活动参与流程</p>
				<div></div>
			</div>
			{
				applyData.show&&
				<ApplyBox 
					close={()=>{
						applyData.show=false;
						applyData.qrCode="";
						applyData.id="";
						setApplyData({...applyData})
						setApplyErr({})
					}}
					applyErr={applyErr}
					applyData={applyData}
					submit={submitApply}
					errChange={errChange}
				/>
			}
		</div>
	);
}

export default Index;
