// 阶段
export const stage = [
    { value: 1, name: "信息收集", },
    { value: 2, name: "信息泄露", },
    { value: 3, name: "尝试登录", },
    { value: 4, name: "登录成功", },
    { value: 5, name: "网络扫描", },
    { value: 6, name: "漏洞探测", },
    { value: 7, name: "漏洞利用", },
    { value: 8, name: "上传木马", },
    { value: 9, name: "武器投递", },
    { value: 10, name: "远程控制", },
    { value: 11, name: "代理转发", },
    { value: 12, name: "横向移动", },
]
// 行为
export const actions = [
    { value: 1, name: 'pass' },
    { value: 2, name: 'drop' },
    { value: 3, name: 'reject' },
    { value: 4, name: 'alert' },
]
// 协议
export const deal = [
    { value: 1, name: 'udp' },
    { value: 2, name: 'tcp' },
    { value: 3, name: 'icmp' },
    { value: 4, name: 'ip' },
    { value: 5, name: 'http' },
    { value: 6, name: 'ftp' },
    { value: 7, name: 'tls' },
    { value: 8, name: 'smb' },
    { value: 9, name: 'dns' },
]
// 地址
export const Ip = [
    { value: 1, name: '$HOME_NET' },
    { value: 2, name: '$EXTERNAL_NET' },
    { value: 3, name: 'any' },
]
// 资源等级
export const levelList = [
    { text: 1, value: 1 },
    { text: 2, value: 2 },
    { text: 3, value: 3 },
    { text: 4, value: 4 },
    { text: 5, value: 5 },
    { text: 6, value: 6 },
    { text: 7, value: 7 },
    { text: 8, value: 8 },
    { text: 9, value: 9 },
    { text: 10, value: 10 },
    { text: 11, value: 11 },
]

// 完整规则内容切分
export const RulesHandling = (value) => {
    let list = {}
    const arr = ["action", "protocol", "sourceIp", "sourcePort", "destinationIp", "destinationPort"]
    if (value.indexOf("(") >= 0) {
        // 括号外内容
        let head = value.trim().split("(")[0].trim().split(" ")
        if (head.length > 0) {
            if (head.indexOf("->")) {
                head.splice(head.indexOf('->'), 1)
            }
            head.map((x, i) => {
                list[arr[i]] = x
            })
        }
        // 括号中内容
        value.trim().split("(").shift()
        let message = value.trim().split("(")
        message.shift()
        message.join()
        message.join('(').trim().split(";").filter((x) => {
            var reg1 = new RegExp('"', "g")
            var name = x.split(":")[0].replace(reg1, 'g').trim()
            if (name !== ')')
                list[name] = x.split(":")[1]
        })
    } else {
        let head = value.trim().split("(")[0].trim().split(" ")
        if (head.length > 0) {
            head.map((x, i) => {
                list[arr[i]] = x
            })
        }
    }
    return list
}

// 字段拼接规则内容
export const RuleSplicing = (value) => {
    let arr = []
    if (value.metadata && value.metadata.length > 0) {
        arr = value.metadata.map((value, key) => {
            return "stage" + " " + value
        })
    }
    let addClassType = value.classType ? ('classtype:' + value.classType + '; ') : ""
    let addMetadata = arr.length >= 1 ? ('metadata:' + arr.join(', ') + ';') : ""
    let addMsg = value.msg ? ('msg:"' + value.msg + '"; ') : ''
    let addRev = value.rev ? ('rev:' + value.rev + '; ') : ''
    let addReference = ''
    if (value.reference.indexOf(':') >= 0) {
        let str = value.reference.split(':').join(',')
        addReference = value.reference ? ('reference:' + str + '; ') : ''
    } else {
        addReference = value.reference ? ('reference:' + value.reference + '; ') : ''
    }

    let str = value.action + ` ` + value.protocol + ` ` + value.sourceIp + ` ` + value.sourcePort + ` ` + "->" + ` ` + value.destinationIp + ` ` + value.destinationPort + ` ` + '(' + addMsg + addReference + addClassType + addRev + addMetadata + ')'
    return str
}

// 生成规则内容(替换)
export const RulesGenerate = (content, headList, list, ruleField) => {
    const arr = ["action", "protocol", "sourceIp", "sourcePort", "->", "destinationIp", "destinationPort"]
    let head = content.trim().split("(")[0].trim().split(" ")
    arr.map((x, i) => {
        if (headList[x]) {
            head[i] = headList[x]
        }
    })
    let body = content.trim().split("(")
    body.splice(0, 1)
    if (body.length > 1) {
        body = body.join("(")
    } else {
        body = body.join("")
    }
    // if (body.charAt(body.length - 1) !== ')') {
    //     body = body.trim() + ')'
    // }
    for (let key in list) {
        if (body.indexOf(key) >= 0) {
            var resolves = body.substring(body.indexOf(key) + key.length + 1, body.length);//后
            // 需要替换的字符
            let value = resolves.substring(0, resolves.indexOf(';'))
            if (key === 'msg') {
                value = resolves.substring(1, resolves.indexOf('";'))
            }
            if (key === 'metadata') {
                let stage = list[key].map((value, key) => {
                    return "stage" + " " + value
                })
                if (ruleField.metadata) {
                    stage.push(ruleField.metadata);
                }
                if (value) {
                    body = body.split(value).join(stage);
                } else {
                    let index = body.indexOf('metadata:');
                    if (index !== -1) {
                        body = body.slice(0, index + 9) + stage.join(',') + body.slice(index + 9)
                    }
                }
            }
            else if (key === 'reference') {
                if (list[key].indexOf(':') >= 0) {
                    let referenceValue = list[key].split(':').join(',')
                    body = body.split(value).join(referenceValue)
                }
            }
            else {
                body = body.split(value).join(list[key])
            }
        }
        if (key === 'classtype' && body.indexOf('classtype') < 0 && list[key] !== '') {
            let classtype = 'classtype:' + list[key] + '; '
            body = body.slice(0, body.indexOf('sid')) + classtype + body.slice(body.indexOf('sid'))
        }
        if (key === 'reference' && body.indexOf('reference') < 0 && list[key] !== '') {
            let referenceValue = list[key].split(':').join(',')
            let index = null
            if (body.indexOf('sid') > 0) {
                index = body.indexOf('sid')
            }
            if (body.indexOf('rev') > 0) {
                index = body.indexOf('rev')
            }
            if (body.indexOf('classtype') > 0) {
                index = body.indexOf('classtype')
            }
            let reference = 'reference:' + referenceValue + '; '
            body = body.slice(0, index) + reference + body.slice(index)
        }
        if (key === 'metadata' && body.indexOf('metadata') < 0) {
            let arr = []
            if (list[key].length > 0) {
                arr = list[key].map((value, key) => {
                    return "stage" + " " + value
                })
            }
            let stage = arr.length >= 1 ? ('; ' + 'metadata:' + arr.join(', ')) : ""

            body = body.slice(0, body.indexOf(';)')) + stage + body.slice(body.indexOf(';)'))
        }
    }
    // reference字段为空，但内容中仍包含
    if (!list.reference && body.indexOf('reference') >= 0) {
        let value = body.slice(body.indexOf('reference'))
        value = value.slice(value.indexOf(';') + 1)
        body = body.slice(0, body.indexOf('reference')) + value.trim()
    }
    let str = head.join(' ') + ' (' + body
    return str
}
export const RuleMatching = (content) => {
    let msgReg = /(?<=msg:").*?(?=";)/;
    let metadataReg = /(?<=metadata:).*?(?=;)/
    let msg = null;
    if (msgReg.exec(content)) {
        msg = msgReg.exec(content)[0]
    }
    let metadata = null;
    if (metadataReg.exec(content)) {
        metadata = metadataReg.exec(content)[0].split(',')
        for (let i = metadata.length - 1; i >= 0; i--) {
            if (metadata[i].includes('stage')) {
                metadata.splice(i, 1)
            }
        }
    }
    return {
        msg,
        metadata: metadata?.join(',') || ''
    }
}