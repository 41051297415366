import React, { useState, useEffect, useCallback } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Table, Input, Pagination, Divider } from 'antd'
import { Alert } from '../../../Communal/utility';
import instance from '../../../Communal/instance';
import DeleteBox from '../../deteleBox';
import ComponentsForm from './componentsForm';
import { useMappedState } from "redux-react-hook";

const { Search } = Input;

const App = ({
    keyword,
    userType,
    userIssue
}) => {
    // 拿到公共的值---实时变化
    const mapstate = useCallback((state) => ({
        login: state.login,
        userInfo: state.userInfo
    }), []);
    //获取数据并订阅存储
    const loginInfo = useMappedState(mapstate);
    const [initData, setInitData] = useState({
        data: [],
        allCount: 0
    });
    const [checkList, setCheckList] = useState([]);
    const [filter, setFilter] = useState({
        page: 1,
        size: 10,
        search: keyword,
        stage: [],
        status: [],
        username: "",
    });
    const [deleteTip, setDeleteTip] = useState({
        show: false,
        list: []
    })
    const [submit, setSubmit] = useState({
        show: false,
        id: undefined,
        type: "0"
    })

    useEffect(() => {
        tableData()
    }, [filter])

    useEffect(() => {
        return () => {
            setFilter({
                page: 1,
                size: 10,
                search: keyword,
                stage: [],
                status: [],
                username: "",
            })
        }
    }, [])

    const tableData = () => {
        instance.get('/vulninte/api/module/', { params: filter }).then(data => {
            initData.data = data.results;
            initData.allCount = data.count;
            setInitData({ ...initData })
        })
    }

    const handleTableChange = (pagination, filters) => {
        if (filters.status) {
            filter.status = filters.status;
        } else {
            filter.status = [];
        }
        filter.page = 1;
        setFilter({ ...filter });
    }

    //单个删除或者批量删除操作
    const resourceDelete = (list) => {
        if (list.length === 0) {
            Alert("warn", "请选择需要删除的组件");
            return
        }
        instance.post("/vulninte/api/deletes/module/", { ids: list, type: 1 }).then(data => {
            if (filter.page > 1 && (initData.data.length === list.length)) {
                filter.page -= 1;
                setFilter({ ...filter })
            }
            if (initData.allCount === list.length) {
                filter.page = 1;
                setFilter({ ...filter })
            }
            tableData();
            Alert("success", "删除成功");
            setCheckList([]);
            deleteTip.list = [];
            deleteTip.show = false;
            setDeleteTip({ ...deleteTip })
        })
    }

    const rowSelection = {
        selectedRowKeys: checkList,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            deleteTip.list = selectedRowKeys
            setDeleteTip({ ...deleteTip })
            setCheckList(selectedRowKeys)
        },
    }

    const columns = [
        {
            title: '序号',
            key: 'id',
            width: 70,
            align: 'center',
            render: (text, record, index) => (
                <span
                    className="click-href"
                    onClick={() => {
                        let list = { ...submit }
                        list.show = true
                        list.type = "3"
                        list.id = record.id
                        setSubmit({ ...list })
                    }}
                >
                    {filter.size * (filter.page - 1) + index + 1}
                </span>
            )
        }, {
            title: '组件名称',
            key: 'name',
            dataIndex: 'name',
            align: 'center',
            width: 250,
            ellipsis: true,
            render: (text, record) => (
                <span
                    className="click-href"
                    onClick={() => {
                        let list = { ...submit }
                        list.show = true
                        list.type = "3"
                        list.id = record.id
                        setSubmit({ ...list })
                    }}
                >
                    {text}
                </span>
            )
        }, {
            title: '组件厂商',
            key: 'company',
            dataIndex: 'company',
            width: 250,
            align: 'center',
            ellipsis: true,
        }, {
            title: '提交者',
            key: 'username',
            dataIndex: 'username',
            width: 80,
            align: 'center',
            ellipsis: true,
            filterDropdown: ({ clearFilters }) => (
                <div className="table-search">
                    <Input
                        placeholder="请输入提交者名称"
                        value={filter.username}
                        onChange={(e) => {
                            filter.username = e.target.value;
                            filter.page = 1;
                            setFilter({ ...filter })
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => { clearFilters() }}
                    >
                        确定
                    </Button>
                    <Button onClick={() => {
                        clearFilters();
                        filter.username = "";
                        filter.page = 1;
                        setFilter({ ...filter })
                    }}>取消</Button>
                </div>
            ),
            filterIcon: <SearchOutlined />
        }, {
            title: '提交时间',
            key: 'sub_time',
            dataIndex: 'sub_time',
            width: 130,
            align: 'center',
        }, {
            title: '操作',
            key: 'operate',
            dataIndex: 'operate',
            align: 'center',
            width: 70,
            render: (text, record) => {
                return <div className="table-operation">
                    <div>
                        <span>
                            <b></b>
                            <b></b>
                            <b></b>
                        </span>
                        <div>
                            <Button
                                type="link"
                                onClick={() => {
                                    let list = { ...submit }
                                    list.show = true
                                    list.type = "3"
                                    list.id = record.id
                                    setSubmit({ ...list })
                                }}
                            >
                                详情
                            </Button>
                            <Button
                                key="2"
                                type="link"
                                // disabled={userType < 2 && record.username !== loginInfo.userInfo.username}
                                onClick={() => {
                                    let list = { ...submit }
                                    list.show = true
                                    list.type = "1"
                                    list.id = record.id
                                    setSubmit({ ...list })
                                }}
                            >
                                编辑
                            </Button>
                            <Button
                                key="6"
                                type="link"
                                danger
                                disabled={userType < 2 && record.username !== loginInfo.userInfo.username}
                                onClick={() => {
                                    deleteTip.show = true
                                    deleteTip.list = [record.id]
                                    setDeleteTip({ ...deleteTip })
                                }}
                            >
                                删除
                            </Button>
                        </div>
                    </div>
                </div>
            }
        }
    ]

    return (
        <div className="ctf-resource-list vulnInfo-list" style={{ padding: 0 }}>
            <div className="ctf-resource-table content-container" style={{ marginTop: 0 }}>
                <div className="handle">
                    {
                        userType > 1 ?
                            <div style={{ display: 'inline-block' }}>
                                <Button
                                    type='ghost'
                                    disabled={checkList.length === 0}
                                    danger
                                    onClick={() => {
                                        deleteTip.show = true;
                                        deleteTip.list = checkList;
                                        setDeleteTip({ ...deleteTip })
                                    }}
                                >
                                    批量删除
                                </Button>
                            </div>
                            : undefined
                    }
                    <div className='right'>
                        <Search
                            placeholder="请输入搜索内容"
                            style={{ width: 250 }}
                            value={filter.search}
                            onChange={(e) => {
                                filter.page = 1
                                filter.search = e.target.value
                                setFilter({ ...filter });
                            }}
                        />
                        <Divider type='vertical' />
                        <Button
                            type="primary"
                            onClick={() => {
                                let list = { ...submit }
                                list.show = true
                                list.type = "0"
                                setSubmit({ ...list })
                            }}
                        >
                            提交组件
                        </Button>
                    </div>
                </div>
                <Table
                    dataSource={initData.data}
                    rowKey={record => record.id}
                    bordered
                    rowSelection={userType > 1 && rowSelection}
                    onChange={handleTableChange}
                    columns={columns}
                    pagination={false}
                />
                <Pagination
                    showQuickJumper={true}
                    current={filter.page}
                    pageSize={filter.size}
                    total={initData.allCount}
                    hideOnSinglePage={initData.allCount === 0}
                    pageSizeOptions={['10', '20', '50', '100']}
                    showSizeChanger={true}
                    className="flip-over"
                    style={{ margin: "30px 0" }}
                    onChange={
                        ((page, pageSize) => {
                            let data = { ...filter }
                            data.page = page;
                            data.size = pageSize;
                            setFilter(data)
                        })
                    }
                />
            </div>
            {
                deleteTip.show &&
                <DeleteBox
                    close={() => {
                        deleteTip.show = false;
                        deleteTip.list = [];
                        setDeleteTip({ ...deleteTip })
                    }}
                    title='组件'
                    submit={() => resourceDelete(deleteTip.list)}
                />
            }
            <ComponentsForm
                submitTip={submit}
                close={() => {
                    submit.show = false;
                    submit.type = "0";
                    setSubmit({ ...submit })
                }}
                userType={userType}
                getData={tableData}
            />
        </div>
    )
}
export default App;