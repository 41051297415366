import React, { useState, useEffect } from 'react';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Table, Input, Pagination, Menu, Dropdown, Divider } from 'antd'
import { Alert } from '../../../Communal/utility';
import instance from '../../../Communal/instance';
import DeleteBox from '../../deteleBox';
import Confirm from '../../comfirm'
import { levelList } from '../../../../utils/method';

const { Search } = Input;

const PlugIn = ({ keyword, userType, userIssue }) => {
    const [initData, setInitData] = useState({
        data: [],
        allCount: 0
    });
    const [checkList, setCheckList] = useState([]);
    const [filter, setFilter] = useState({
        page: 1,
        size: 10,
        search: keyword,
        stage: [],
        status: [],
        username: "",
        resource_level: []
    });
    const [deleteTip, setDeleteTip] = useState({
        show: false,
        list: []
    })
    const [confirmShow, setConfirmShow] = useState({
        visible: false,
        mode: 'vlunplug',
        issue: '',
    })

    useEffect(() => {
        return () => {
            setFilter({
                page: 1,
                size: 10,
                search: keyword,
                classify: [],
                stage: [],
                status: [],
                username: "",
            })
        }
    }, [])

    useEffect(() => {
        tableData()
    }, [filter])

    const tableData = () => {
        instance.get('/vulninte/api/plug/', { params: filter }).then(data => {
            initData.data = data.results;
            initData.allCount = data.count;
            setInitData({ ...initData })
        })
    }

    const handleTableChange = (pagination, filters) => {
        if (filters.stage) {
            filter.stage = filters.stage;
        } else {
            filter.stage = [];
        }
        if (filters.resource_level) {
            filter.resource_level = filters.resource_level;
        } else {
            filter.resource_level = [];
        }
        if (filters.pulg_type) {
            filter.pulg_type = filters.pulg_type;
        } else {
            filter.pulg_type = [];
        }
        if (filters.issue) {
            filter.issue = filters.issue;
        } else {
            filter.issue = [];
        }
        if (filters.status) {
            filter.status = filters.status;
        } else {
            filter.status = [];
        }
        filter.page = 1;
        setFilter({ ...filter });
    }

    //单个删除或者批量删除操作
    const resourceDelete = (list) => {
        if (list.length === 0) {
            Alert("warn", "请选择需要删除的插件");
            return
        }
        instance.post("/vulninte/api/deletes/plug/", { ids: list, type: 3 }).then(data => {
            if (filter.page > 1 && (initData.data.length === list.length)) {
                filter.page -= 1;
                setFilter({ ...filter })
            }
            if (initData.allCount === list.length) {
                filter.page = 1;
                setFilter({ ...filter })
            }
            tableData();
            Alert("success", "删除成功");
            setCheckList([]);
            deleteTip.list = [];
            deleteTip.show = false;
            setDeleteTip({ ...deleteTip })
        })
    }

    const changeStatus = (key) => {
        setConfirmShow({ ...confirmShow, visible: true, issue: Number(key) });
    }

    const handleIssue = () => {
        setCheckList([]);
        setFilter({ ...filter });
    }

    const rowSelection = {
        selectedRowKeys: checkList,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            deleteTip.list = selectedRowKeys
            setDeleteTip({ ...deleteTip })
            setCheckList(selectedRowKeys)
        },
    }

    const columns = [
        {
            title: '序号',
            key: 'id',
            width: 70,
            align: 'center',
            // 递增序号
            render: (text, record, index) => (
                <span
                    className="click-href"
                    onClick={() => {
                        window.location.href = "/#/activity/plugIndetail/0/" + record.id
                    }}
                >
                    {filter.size * (filter.page - 1) + index + 1}
                </span>
            )
        }, {
            title: '插件编号',
            align: 'center',
            key: 'new_ids',
            dataIndex: 'new_ids',
            width: 100,
            ellipsis: true,
            render: (text, record) => (
                <>
                    {
                        text ? <span
                            className="click-href"
                            onClick={() => {
                                window.location.href = "/#/activity/plugIndetail/0/" + record.id
                            }}
                        >
                            {text}
                        </span> : '--'
                    }
                </>
            )
        }, {
            title: '插件名称',
            align: 'center',
            key: 'name',
            dataIndex: 'name',
            width: 160,
            ellipsis: true,
            render: (text, record) => {
                return text ? <span
                    className="click-href"
                    onClick={() => {
                        window.location.href = "/#/activity/plugIndetail/0/" + record.id
                    }
                    }
                >
                    {text}
                </span > : '--'

            }
        }, {
            title: '等级',
            key: 'resource_level',
            dataIndex: 'resource_level',
            width: 70,
            ellipsis: true,
            align: 'center',
            filters: levelList
        }, {
            title: '插件类型',
            key: 'pulg_type',
            dataIndex: 'pulg_type',
            width: 100,
            align: 'center',
            ellipsis: true,
            filterMultiple: false,
            filters: [
                { text: "漏洞插件", value: 0 },
                { text: "基础插件", value: 1 },
            ],
            render: (text) => {
                return {
                    0: "漏洞插件",
                    1: "基础插件"
                }[text]
            }
        }, {
            title: '审核状态',
            key: 'status',
            dataIndex: 'status',
            width: 100,
            align: 'center',
            filters: [
                { text: "待审核", value: "0" },
                { text: "通过", value: "1" },
                { text: "待补充", value: "2" },
                { text: "拒绝", value: "3" }
            ],
            render: (record) => {
                return <span className="audit-status">
                    {
                        record === 0 && "待审核"
                    }
                    {
                        record === 1 && <span className="pass">通过</span>
                    }
                    {
                        record === 2 && <span className="added">待补充</span>
                    }
                    {
                        record === 3 && <span className="denied">拒绝</span>
                    }
                </span>
            },
        }, {
            title: '发布状态',
            key: 'issue',
            dataIndex: 'issue',
            filterMultiple: false,
            width: 100,
            align: 'center',
            filters: [
                { text: "已发布", value: 2 },
                { text: "未发布", value: 1 },
            ],
            render: (record) => {
                return <span className="audit-status">
                    {
                        record === 2 ? <span className="pass">已发布</span> : <span className="added">未发布</span>
                    }

                </span>
            },
        }, {
            title: '提交者',
            key: 'username',
            dataIndex: 'username',
            width: 100,
            align: 'center',
            ellipsis: true,
            filterDropdown: ({ clearFilters }) => (
                <div className="table-search">
                    <Input
                        placeholder="请输入提交者名称"
                        value={filter.username}
                        onChange={(e) => {
                            filter.username = e.target.value;
                            filter.page = 1;
                            setFilter({ ...filter })
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => { clearFilters() }}
                    >
                        确定
                    </Button>
                    <Button onClick={() => {
                        clearFilters();
                        filter.username = "";
                        filter.page = 1;
                        setFilter({ ...filter })
                    }}>取消</Button>
                </div>
            ),
            filterIcon: <SearchOutlined />
        }, {
            title: '提交时间',
            key: 'sub_time',
            dataIndex: 'sub_time',
            width: 140,
            align: 'center',
        }, {
            title: '操作',
            key: 'operate',
            dataIndex: 'operate',
            align: 'center',
            // width: userType > 1 ? 300 : 200,
            width: 70,
            render: (text, record) => {
                return <div className="table-operation">
                    <div>
                        <span>
                            <b></b>
                            <b></b>
                            <b></b>
                        </span>
                        <div>
                            <Button
                                type="link"
                                onClick={() => {
                                    window.location.href = "/#/activity/plugindetail/0/" + record.id
                                }}
                            >
                                详情
                            </Button>
                            <Button
                                key="2"
                                type="link"
                                disabled={userType < 2 && record.status === 1}
                                onClick={() => {
                                    window.open("/#/activity/pluginform/1/" + record.id)
                                }}
                            >
                                编辑
                            </Button>
                            {
                                userType > 1 ? <>
                                    <Button
                                        key="4"
                                        type="link"
                                        onClick={() => {
                                            window.open("/#/activity/pluginform/2/" + record.id)
                                        }}
                                    >
                                        审核
                                    </Button>
                                </> : undefined
                            }
                            {
                                userIssue ? <>
                                    {
                                        record.issue !== 2 ?
                                            <Button
                                                disabled={record.status !== 1}
                                                type="link"
                                                onClick={() => {
                                                    setConfirmShow({ ...confirmShow, visible: true, issue: 2 });
                                                    setCheckList([record.id])
                                                }}
                                            >
                                                发布
                                            </Button> :
                                            <Button
                                                type="link"
                                                danger
                                                onClick={() => {
                                                    setConfirmShow({ ...confirmShow, visible: true, issue: 1 });
                                                    setCheckList([record.id])
                                                }}
                                            >
                                                回收
                                            </Button>
                                    }
                                </> : undefined
                            }
                            <Button
                                key="6"
                                type="link"
                                danger
                                disabled={userType < 2 && record.status === 1}
                                onClick={() => {
                                    deleteTip.show = true
                                    deleteTip.list = [record.id]
                                    setDeleteTip({ ...deleteTip })
                                }}
                            >删除</Button>
                        </div>
                    </div>
                </div>
            }
        }
    ]

    const menu = (
        <Menu onClick={({ key }) => { changeStatus(key) }}>
            <Menu.Item key={1}>回收</Menu.Item>
            <Menu.Item key={2}>发布</Menu.Item>
        </Menu>
    );

    return (
        <div className="ctf-resource-list vulnInfo-list" style={{ padding: 0 }}>
            <div className="ctf-resource-table content-container" style={{ marginTop: 0 }}>
                <div className="handle">
                    {
                        userType > 1 ?
                            <div style={{ display: 'inline-block' }}>
                                <Button
                                    type='ghost'
                                    disabled={checkList.length === 0}
                                    danger
                                    onClick={() => {
                                        deleteTip.show = true;
                                        deleteTip.list = checkList;
                                        setDeleteTip({ ...deleteTip })
                                    }}
                                >
                                    批量删除
                                </Button>
                                {
                                    userIssue ?
                                        <Dropdown
                                            overlay={menu}
                                            disabled={checkList.length === 0}
                                        >
                                            <Button type="primary" style={{ marginLeft: '10px' }}>批量修改发布状态<DownOutlined /></Button>
                                        </Dropdown> : undefined
                                }
                            </div> : undefined
                    }
                    <div className='right'>
                        <Search
                            placeholder="请输入搜索内容"
                            style={{ width: 250 }}
                            value={filter.search}
                            onChange={(e) => {
                                filter.page = 1
                                filter.search = e.target.value
                                setFilter({ ...filter });
                            }}
                        />
                        <Divider type='vertical' />
                        <Button
                            type="primary"
                            onClick={() => window.open("/#/activity/pluginform/0/0")}
                        >
                            提交插件
                        </Button>
                    </div>
                </div>
                <Table
                    dataSource={initData.data}
                    rowKey={record => record.id}
                    bordered
                    rowSelection={userType > 1 && rowSelection}
                    onChange={handleTableChange}
                    columns={userType > 1 ? columns : columns.filter(ea => ea.dataIndex !== 'resource_level')}
                    pagination={false}
                />
                <Pagination
                    showQuickJumper={true}
                    current={filter.page}
                    pageSize={filter.size}
                    total={initData.allCount}
                    hideOnSinglePage={initData.allCount === 0}
                    pageSizeOptions={['10', '20', '50', '100']}
                    showSizeChanger={true}
                    className="flip-over"
                    style={{ margin: "30px 0" }}
                    onChange={(page, pageSize) => {
                        let data = { ...filter }
                        data.page = page;
                        data.size = pageSize;
                        setFilter(data)
                    }}
                />
            </div>
            {
                deleteTip.show &&
                <DeleteBox
                    close={() => {
                        deleteTip.show = false;
                        deleteTip.list = [];
                        setDeleteTip({ ...deleteTip })
                    }}
                    title='插件'
                    submit={() => resourceDelete(deleteTip.list)}
                />
            }
            <Confirm
                boxProperty={confirmShow}
                onClose={() => {
                    setConfirmShow({ ...confirmShow, visible: false })
                }}
                onOk={handleIssue}
                ids={checkList}
            />
        </div>
    )
}

export default PlugIn;