/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Search from 'antd/lib/input/Search';
import { Table, Button, Pagination } from 'antd';
import instance from '../Communal/instance';
const { Column } = Table;
function CtfSubmit() {
    const [initData, setInitData] = useState(
        {
            data: [],
            allCount: []
        }
    );
    const [filter, setFilter] = useState(
        {
            page: 1,
            size: 6,
            search: "",
            classify: [],
            ordering: "",
            status: [],
        }
    );
    const [classify, setClassify] = useState([])
    const [sortedInfo, setSortedInfo] = useState({})
    const [filteredInfo] = useState({})
    let handleTableChange = (pagination, filters, sorter) => {
        if (filters.classify) {
            filter.classify = filters.classify;
        } else {
            filter.classify = []
        }
        if (filters.status) {
            filter.status = filters.status;
        } else {
            filter.status = []
        }
        if (sorter.order) {
            if (sorter.columnKey === "is_env") {
                if (sorter.order === "ascend") {
                    filter.ordering = "is_env"
                } else {
                    filter.ordering = "-is_env"
                }
            }
            if (sorter.columnKey === "coin") {
                if (sorter.order === "ascend") {
                    filter.ordering = "coin"
                } else {
                    filter.ordering = "-coin"
                }
            }
        } else {
            filter.ordering = ""
        }
        setFilter({ ...filter })
        setSortedInfo(sorter)
    }
    useEffect(() => {
        getList();
    }, [filter]);
    //获取ctf列表
    let getList = () => {
        instance.get("/users/sub/vuln/", { params: filter }).then(data => {
            initData.data = data.results;
            initData.allCount = data.count;
            setInitData({ ...initData })
        })
    }
    //获取题目类型
    useEffect(() => {
        instance.get("/ctf/classify/?type=3").then(data => {
            if (data.length > 0) {
                for (var i = 0; i < data.length; i++) {
                    data[i].text = data[i].name;
                    data[i].value = data[i].id
                }
            }
            setClassify(data)
        })
    }, [])
    return (
        <div className="resource-submit-list">
            <div className="title">提交漏洞</div>
            <div className="content">
                <div className="all-count">
                    总计：共<b>{initData.allCount}</b>个素材
                    <Search
                        value={filter.search}
                        placeholder="请输入关键字"
                        onChange={(e) => {
                            filter.page = 1;
                            filter.search = e.target.value;
                            setFilter({ ...filter })
                        }}
                    />
                </div>
                <Table
                    dataSource={initData.data}
                    rowKey={record => record.id}
                    bordered
                    pagination={false}
                    onChange={handleTableChange}
                >
                    <Column
                        title="序号"
                        key="serial"
                        align="center"
                        width="8%"
                        render={(text, record, index) => {
                            return filter.size * (filter.page - 1) + index + 1
                        }}
                    />
                    <Column
                        title="漏洞名称"
                        key="name"
                        align="center"
                        dataIndex="name"
                    />
                    <Column
                        title="漏洞分类"
                        key="classify"
                        align="center"
                        dataIndex="classify"
                        filters={classify}
                        filteredValue={filteredInfo.classify || null}
                        width="13%"
                    />
                    <Column
                        title="环境"
                        key="is_env"
                        align="center"
                        dataIndex="is_env"
                        sorter="true"
                        sortOrder={sortedInfo.columnKey === 'is_env' && sortedInfo.order}
                        render={(record) => {
                            return record ? "有" : "否"
                        }}
                        width="10%"
                    />
                    <Column
                        title="审核状态"
                        key="status"
                        align="center"
                        dataIndex="status"
                        width="13%"
                        filters={
                            [
                                { text: "待审核", value: "0" },
                                { text: "审核通过", value: "1" },
                                { text: "待补充", value: "2" },
                                { text: "拒绝", value: "3" }
                            ]
                        }
                        filteredValue={filteredInfo.status || null}
                        render={(record) => {
                            return <span className="audit-status">
                                {
                                    record === 0 && "待审核"
                                }
                                {
                                    record === 1 && <span className="pass">审核通过</span>
                                }
                                {
                                    record === 2 && <span className="added">待补充</span>
                                }
                                {
                                    record === 3 && <span className="denied">拒绝</span>
                                }
                            </span>
                        }}
                    />
                    <Column
                        title="奖励"
                        key="coin"
                        align="center"
                        dataIndex="coin"
                        width="10%"
                        sorter="true"
                        sortOrder={sortedInfo.columnKey === 'coin' && sortedInfo.order}
                    />
                    <Column
                        title="提交时间"
                        key="sub_time"
                        align="center"
                        dataIndex="sub_time"
                        width="15%"
                    />
                    <Column
                        width="18%"
                        title="操作"
                        key="operate"
                        align="center"
                        render={(record) => {
                            return <span className="table-operation">
                                <Button type="link" onClick={() => window.location.href = "/#/activity/vulndetail/1/" + record.id}>详情</Button>
                                <b></b>
                                {
                                    record.status === 1 ?
                                        <Button type="link" disabled>编辑</Button>
                                        : <Button type="link" onClick={() => window.location.href = "/#/activity/submitvuln/-1/1/" + record.id}>编辑</Button>
                                }
                            </span>
                        }}
                    />
                </Table>
                <Pagination
                    showQuickJumper={true}
                    current={filter.page}
                    pageSize={filter.size}
                    total={initData.allCount}
                    hideOnSinglePage={true}
                    showSizeChanger={false}
                    className="flip-over"
                    style={{ margin: "30px 0" }}
                    onChange={
                        ((page) => {
                            let data = { ...filter }
                            data.page = page;
                            setFilter(data)
                        })
                    }
                />
            </div>
        </div>
    )
}
export default CtfSubmit;