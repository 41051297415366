/* eslint-disable react-hooks/exhaustive-deps */
import React,{useCallback, useState, useEffect} from 'react';
import {Avatar, Input, Button} from 'antd';
import {useMappedState } from "redux-react-hook";
import realmName from '../../json/realmName.json';
function Info(param){
    // 拿到公共的值---实时变化
    const mapstate = useCallback((state) => ({
		login:state.login,
		userInfo:state.userInfo
	}),[]);
	//获取数据并订阅存储
    const loginInfo= useMappedState(mapstate);
    const [initData,setInitData]=useState(
        {
            name:"",
            show:false
        }
    );
    useEffect(()=>{
        initData.name=loginInfo.userInfo.realname;
        setInitData({...initData})
    },[loginInfo])
    //点击保存姓名修改
    let onSave=()=>{
        var list={realname:initData.name};
        param.editSign(list);
        initData.show=false;
        setInitData({...initData})
    }
    //点击更改头像
    let fileChange=()=>{
        let file = document.getElementsByClassName("photo-upload")[0].files[0];
        if(file!==undefined){
            let form = document.getElementById('photo-file');
            let formdData=new FormData(form);
            param.editSign(formdData);
        }
    }
    return (
        <div className="personal-message">
            <div className="title">资料与账号</div>
            <div className="photo">
                <div>
                    <Avatar src={loginInfo.userInfo.photo} size={80}/>
                    <div className="bg" onClick={()=>{
                        document.getElementsByClassName("photo-upload")[0].click();
                        document.getElementsByClassName("photo-upload")[0].value="";
                    }}>
                        更改头像
                        <form id="photo-file">
                            <input 
                                type="file" 
                                name="photo"
                                className="photo-upload" 
                                accept=".jpg,.png,.gif"
                                style={{display:"none"}}
                                onChange={fileChange}
                            />
                        </form>
                    </div>
                </div>
                <div>
                    只支持JPG、PNG、GIF、大小不超过1M<br/>
                    建议尺寸为100*100
                </div>
            </div>
            <div className="basic-info">
                <div>
                    <span>用户名</span>
                    <div>{loginInfo.userInfo.username}</div>
                </div>
                <div>
                    <span>姓名</span>
                    {
                        !initData.show?
                        [
                            <div key="1">{initData.name?initData.name:"暂未填写"}</div>,
                            <span key="2" className="operate" onClick={()=>{
                                initData.show=true;
                                setInitData({...initData})
                            }}>修改</span>
                        ]
                        :<div className="edit">
                            <Input 
                                value={initData.name}
                                placeholder="请输入真实姓名"
                                onChange={(e)=>{
                                    initData.name=e.target.value;
                                    setInitData({...initData})
                                }}
                            />
                            <Button type="primary" onClick={onSave}>保存</Button>
                            <Button onClick={()=>{
                                initData.show=false;
                                initData.name=loginInfo.userInfo.realname;
                                setInitData({...initData})
                            }}>取消</Button>
                        </div>
                    }
                </div>
                <div>
                    <span>手机号</span>
                    <div>{loginInfo.userInfo.phone?loginInfo.userInfo.phone:"暂未绑定"}</div>
                    <span className="operate" onClick={()=>window.open(realmName.sso +"/server_app/#/person/editphone/first/4/"+loginInfo.userInfo.user_uuid)}>修改</span>
                </div>
                <div>
                    <span>微信</span>
                    <div>{loginInfo.userInfo.nickname?loginInfo.userInfo.nickname:"暂未绑定"}</div>
                    <span className="operate" onClick={()=>{
                        if(loginInfo.userInfo.sso_user_id){
                            window.open("https://open.weixin.qq.com/connect/qrconnect?appid=wxe62cb4c2e60ee9ef&redirect_uri=https%3a%2f%2fsso.seclover.com%2fserver_app%2fcallback%2f&response_type=code&scope=snsapi_login&state=" + loginInfo.userInfo.sso_user_id +"where=4")
                        }
                    }}>去绑定</span>
                </div>
                <div>
                    <span>登录密码</span>
                    <div>********</div>
                    <span className="operate" onClick={()=>window.open(realmName.sso +"/server_app/#/person/editpass/first/4/" + loginInfo.userInfo.user_uuid)}>修改</span>
                </div>
            </div>
        </div>
    )
}
export default Info;