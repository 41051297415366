import React, { useEffect, useState } from 'react';
import { Button, Input, Select } from 'antd';
import Denied from '../../../../images/denied.png';
import PassBox from '../../../Activity/PassBox';
import DeclineBox from '../../../Activity/DeclineBox';
import AddedBox from '../../../Activity/AddedBox';
import history from '../../../Communal/history';
import instance from '../../../Communal/instance';
import { Alert } from '../../../Communal/utility';
import Retrieval from '../../../Activity/retrieval';
const { TextArea } = Input;
const { Option } = Select;

const ThreatenForm = ({ param }) => {
    const [type, setType] = useState("0"); //type: 0 添加 1 编辑 2 审核
    const [tags, setTags] = useState([]);
    const [classify, setClassify] = useState([]);
    const [sceneObj, setSceneObj] = useState([]);
    const [selectSceneObj, setSelectSceneObj] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [retrievalBox, setRetrievalBox] = useState({
        show: false,
        type: 0,
        count: 0
    });
    const [submit, setSubmit] = useState({
        name: '',
        desc: '',
        classify: undefined,
        level: 0,
        tags: [],
        scene_objs: []
    });
    const [error, setError] = useState({
        name: [],
        desc: [],
        classify: [],
        tags: [],
        scene_objs: []
    });
    const [initBox, setInitBox] = useState({
        passBox: false,
        declineBox: false,
        addedBox: false
    })
    useEffect(() => {
        setType(param.match.params.type);
        if (param.match.params.id !== '0') {
            getDetail(param.match.params.id)
        }
        getTag();
        getClassData();
        getSceneObj();
        document.getElementsByClassName('submit-ctf')[0].style.minHeight = 'calc(100vh - 40px)';
    }, [param]);
    const getDetail = (id) => {
        instance.get(`/threat_point/${id}/`).then(res => {
            res.tags = res.tags_name;
            let scene_objs = [];
            let scene_objs_name = [];
            for (var i = 0; i < res.scene_objs_list.length; i++) {
                scene_objs.push(res.scene_objs_list[i].id);
                scene_objs_name.push(res.scene_objs_list[i].name);
            }
            res.scene_objs = scene_objs;
            setSelectSceneObj(scene_objs_name);
            setSubmit(res);
        })
    }
    const getTag = () => {
        instance.get('/threat_point/tag/').then(res => {
            setTags(res)
        })
    }
    const getClassData = () => {
        instance.get('/threat_point/classify/').then(res => {
            setClassify(res)
        })
    }
    const getSceneObj = () => {
        instance.get(`/scene_obj/?nopage=0&status=1`).then(res => {
            setSceneObj(res)
        })
    }
    let inputChange = (e, name) => {
        submit[name] = e.target.value;
        setSubmit({ ...submit })
    }
    let handleChangeLevel = (key) => {
        submit.level = key;
        setSubmit({ ...submit })
    }
    let handleFouce = (name) => {
        error[name] = []
        setError({ ...error })
    }
    //点击检索按钮进行检索，根据类型判断 检索的字段
    let retrievalClick = (type, text) => {
        if (text.replace(/(^\s*)|(\s*$)/g, "") === "") {
            Alert("warn", "请输入检索内容");
            return
        }
        let list = {
            type: "threat_point",
            flag: text,
            search_type: type
        }
        instance.get("/ctf/search/", { params: list }).then(data => {
            retrievalBox.show = true;
            retrievalBox.count = data.count;
            retrievalBox.type = type;
            setRetrievalBox({ ...retrievalBox })
        })
    }
    let handleChangeTag = (key) => {
        submit.tags = key;
        setSubmit({ ...submit })
    }
    let handleChangeClass = (key) => {
        submit.classify = key;
        setSubmit({ ...submit })
    }
    let commitResources = (type) => {
        setSubmitLoading(true);
        if (submit.name === '') {
            error.name = ['请输入威胁点名称']
        }
        if (submit.tags.length === 0) {
            error.tags = ['请选择威胁点标签']
        }
        if (!submit.classify) {
            error.classify = ['请选择威胁点分类']
        }
        if (submit.desc === '') {
            error.desc = ['请输入威胁点描述']
        }
        if (submit.name === '' || submit.tags.length === 0 || !submit.classify || submit.desc === '') {
            setError({ ...error });
            setSubmitLoading(false);
            Alert('warn', '提交内容有误，请检查！')
            return
        }
        let list = {
            name: submit.name,
            desc: submit.desc,
            classify: submit.classify,
            level: submit.level,
            tags: submit.tags,
            scene_objs: submit.scene_objs
        }
        if (type === 1) {
            instance.post('/threat_point/add/', list).then(res => {
                Alert("success", "提交成功");
                history.push({ pathname: '/resource/sceneelement', state: { tabKey: 2 } })
                setSubmitLoading(false)
            }).catch(err => {
                setSubmitLoading(false);
                errorInfo(err);
            })
        } else if (type === 2) {
            instance.patch(`/threat_point/${param.match.params.id}/`, list).then(res => {
                Alert("success", "提交成功");
                history.push({ pathname: '/resource/sceneelement', state: { tabKey: 2 } });
                setSubmitLoading(false)
            }).catch(err => {
                setSubmitLoading(false);
                errorInfo(err);
            })
        }
    }
    const errorInfo = (err) => {
        if (err.error) {
            Alert('error', err.error)
        }
        if (err.name) {
            error.name = err.name
        }
        if (err.desc) {
            error.desc = err.desc
        }
        if (err.classify) {
            error.classify = err.classify
        }
        if (err.tags) {
            error.tags = err.tags
        }
        if (err.scene_objs) {
            error.scene_objs = err.scene_objs
        }
        Alert('warn', '提交内容有误，请检查！')
        setError({ ...error })
    }
    const handleChangeSceneObj = (value, option) => {
        let ids = [];
        for (var i = 0; i < option.length; i++) {
            ids.push(option[i].data_id)
        }
        submit.scene_objs = ids;
        setSubmit({ ...submit });
        setSelectSceneObj(value);
    }
    let handleAudit = (data) => {
        if (data.status === 1) {
            if (data.data === "") {
                Alert("error", "请输入奖励奖励数");
                return
            }
        }
        if ((data.status === 2 || data.status === 3) && data.data === "") {
            Alert("error", "请输入审核意见");
            return
        }
        instance.post("/threat_point/" + param.match.params.id + "/audit/", data).then(data => {
            Alert("success", "操作成功");
            history.push({ pathname: '/resource/sceneelement', state: { tabKey: 2 } })
        }).catch(err => {
            Alert("error", err.error)
        })
    }
    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-div submit-ctf-name">
                    {
                        type === "0" && "提交威胁点"
                    }
                    {
                        type === "1" && "编辑威胁点"
                    }
                    {
                        type === "2" && "审核威胁点"
                    }
                    {
                        submit.status === 3 &&
                        <div className="status">
                            <img src={Denied} alt="" />
                        </div>
                    }
                </div>
                {
                    submit.is_show_opin && submit.opin && submit.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    submit.opin &&
                                    submit.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">{val.msg}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div subject-info">
                    <div className="title">
                        威胁点信息
                    </div>
                    <div className="content">
                        <div className="retrieval-div">
                            <span style={{ width: '100px' }}><b>*</b>威胁点名称</span>
                            <Input
                                value={submit.name}
                                className="content-input"
                                style={{ width: 'calc(100% - 180px)' }}
                                placeholder="请输入威胁点名称"
                                onChange={(e) => inputChange(e, "name")}
                                maxLength="250"
                                onFocus={() => handleFouce("name")}
                            />
                            <span className="number">{250 - submit.name.length}</span>
                            <Button type="primary" onClick={() => retrievalClick(1, submit.name)}>检索</Button>
                            <div className="error-message">{error.name[0]}</div>
                        </div>
                        <div>
                            <span style={{ width: '100px' }}><b>*</b>威胁点标签</span>
                            <Select
                                mode="tags"
                                style={{ width: 'calc(100% - 180px)' }}
                                placeholder="请选择威胁点标签"
                                value={submit.tags}
                                onChange={handleChangeTag}
                                onClick={() => handleFouce("tags")}
                            >
                                {
                                    tags.map((val, key) => {
                                        return <Option key={key} value={val.name}>
                                            {val.name}
                                        </Option>
                                    })

                                }
                            </Select>
                            <div className="error-message">{error.tags[0]}</div>
                        </div>
                        <div>
                            <span style={{ width: '100px' }}><b>*</b>威胁点类型</span>
                            <Select
                                value={submit.classify}
                                placeholder='请选择威胁点类型'
                                style={{ width: 180 }}
                                onChange={handleChangeClass}
                                onClick={() => handleFouce("classify")}
                            >
                                {
                                    classify.map((val, key) => {
                                        return <Option
                                            key={key + "key"}
                                            value={val.id}
                                        >
                                            {val.name}
                                        </Option>
                                    })
                                }
                            </Select>
                            <div className="error-message">{error.classify[0]}</div>
                        </div>
                        <div>
                            <span style={{ width: '100px' }}><b>*</b>威胁点等级</span>
                            <Select
                                value={submit.level}
                                placeholder='请选择威胁点等级'
                                style={{ width: 180 }}
                                onChange={handleChangeLevel}
                                onClick={() => handleFouce("level")}
                            >
                                <Option
                                    value={0}
                                >
                                    低危
                                </Option>
                                <Option
                                    value={1}
                                >
                                    中危
                                </Option>
                                <Option
                                    value={2}
                                >
                                    高危
                                </Option>
                            </Select>
                        </div>
                        <div>
                            <span style={{ width: '100px' }}>关联场景对象</span>
                            <Select
                                mode='multiple'
                                value={selectSceneObj}
                                placeholder='请选择关联场景对象'
                                style={{ width: 'calc(100% - 180px)' }}
                                onChange={handleChangeSceneObj}
                                onClick={() => handleFouce("scene_objs")}
                            >
                                {
                                    sceneObj.map((val, key) => {
                                        return <Option
                                            key={key + "key"}
                                            value={val.name}
                                            data_id={val.id}
                                        >
                                            {val.name}
                                        </Option>
                                    })
                                }
                            </Select>
                            <div className="error-message">{error.scene_objs[0]}</div>
                        </div>
                        <div className="retrieval-div">
                            <span style={{ width: '100px' }}><b>*</b>威胁点描述</span>
                            <TextArea
                                value={submit.desc}
                                className="content-input"
                                placeholder="请输入威胁点描述"
                                onChange={(e) => inputChange(e, "desc")}
                                style={{ width: 'calc(100% - 180px)' }}
                                maxLength="1000"
                                onFocus={() => handleFouce("desc")}
                            />
                            <span className="number">{1000 - submit.desc.length}</span>
                            <Button type="primary" onClick={() => retrievalClick(2, submit.desc)}>检索</Button>
                            <div className="error-message">{error.desc[0]}</div>
                        </div>
                    </div>
                </div>
                {
                    type === "0" &&
                    <div className="submit-btn">
                        <Button type="primary" loading={submitLoading} onClick={() => commitResources(1)}>提交</Button>
                    </div>
                }
                {
                    type === "1" &&
                    <div className="submit-btn">
                        <Button type="primary" loading={submitLoading} onClick={() => commitResources(2)}>提交</Button>
                    </div>
                }
                {
                    type === "2" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => {
                                initBox.passBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            通过
                        </Button>
                        <Button
                            style={{ backgroundColor: "transparent", color: "#266cff" }}
                            type="primary"
                            onClick={() => history.push({ pathname: '/resource/sceneelement', state: { tabKey: 2 } })}
                        >
                            取消
                        </Button>
                        <Button className="modify" loading={submitLoading} onClick={() => commitResources(2)}>保存修改</Button>
                        <Button
                            className="decline"
                            onClick={() => {
                                initBox.declineBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            拒绝
                        </Button>
                        <Button
                            className="added"
                            onClick={() => {
                                initBox.addedBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            待补充
                        </Button>
                        <Button
                            className="added"
                            onClick={() => handleAudit({ status: 0 })}
                        >
                            待审核
                        </Button>
                    </div>
                }
            </div>
            {
                initBox.passBox &&
                <PassBox
                    close={() => {
                        initBox.passBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.declineBox &&
                <DeclineBox
                    close={() => {
                        initBox.declineBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.addedBox &&
                <AddedBox
                    close={() => {
                        initBox.addedBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                retrievalBox.show &&
                <Retrieval
                    close={() => {
                        retrievalBox.show = false;
                        setRetrievalBox({ ...retrievalBox })
                    }}
                    count={retrievalBox.count}
                    name={
                        {
                            1: "威胁点名称",
                            2: "威胁点描述"
                        }[retrievalBox.type]
                    }
                />
            }
        </div>
    )
}
export default ThreatenForm;