import React, { useState, useEffect, useCallback } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useMappedState } from "redux-react-hook";
import { Button, Table, Input, Pagination } from 'antd'
import { Alert } from '../../../Communal/utility';
import instance from '../../../Communal/instance';
import DeteleBox from '../../deteleBox';

const SceneTask = ({ keyword }) => {
    const mapstate = useCallback((state) => ({
        login: state.login,
        userInfo: state.userInfo,
    }), []);
    const [permission, setPermission] = useState(''); // 0 不能访问 1 只能查看 2 查看 添加
    const loginInfo = useMappedState(mapstate);
    const [initData, setInitData] = useState({
        data: [],
        allCount: 0
    });
    const [checkList, setCheckList] = useState([]);
    const [filter, setFilter] = useState({
        page: 1,
        size: 10,
        search: keyword,
        classify: [],
        status: [],
        username: "",
    });
    const [deleteTip, setDeleteTip] = useState({
        show: false,
        list: []
    })
    const [filteredList, setFilteredList] = useState({
        classify: [],
        status: []
    });
    useEffect(() => {
        tableData()
    }, [filter])
    useEffect(() => {
        getPermission()
    })
    const tableData = () => {
        instance.get('/scene/api/all/', { params: filter }).then(data => {
            initData.data = data.results;
            initData.allCount = data.count;
            setInitData({ ...initData })
        })
    }
    const getPermission = () => {
        instance.get('/scene_obj/permission/?type=scenetask').then(res => {
            setPermission(res.user_role);
        })
    }
    const handleTableChange = (pagination, filters) => {
        if (filters.classify) {
            filter.classify = filters.classify;
            filteredList.type = filters.type
        } else {
            filter.classify = [];
            filteredList.type = [];
        }
        if (filters.status) {
            filter.status = filters.status;
            filteredList.status = filters.status;
        } else {
            filter.status = [];
            filteredList.status = [];
        }
        filter.page = 1;
        setFilter({ ...filter });
        setFilteredList({ ...filteredList })
    }
    //单个删除或者批量删除操作
    const resourceDelete = (list) => {
        if (list.length === 0) {
            Alert("warn", "请选择需要删除的场景任务");
            return
        }
        instance.post("/scene/api/deletes/", { id: list }).then(data => {
            if (filter.page > 1 && (initData.data.length === list.length)) {
                filter.page -= 1;
                setFilter({ ...filter })
            } else {
                tableData();
            }
            Alert("success", "删除成功");
            setCheckList([]);
            deleteTip.list = [];
            deleteTip.show = false;
            setDeleteTip({ ...deleteTip })
        })
    }
    const rowSelection = {
        selectedRowKeys: checkList,
        preserveSelectedRowKeys:true,
        onChange: (selectedRowKeys, selectedRows) => {
            deleteTip.list = selectedRowKeys
            setDeleteTip({ ...deleteTip })
            setCheckList(selectedRowKeys)
        },
        getCheckboxProps: (record) => {
            if (record.on_delete === false) {
                return { disabled: true }
            } else {
                return null;
            }
        }
    }
    const columns = [
        {
            title: '序号',
            key: 'id',
            width: "7%",
            align: 'center',
            // 递增序号
            render: (text, record, index) => (
                <span
                    className="click-href"
                    onClick={() => {
                        window.location.href = "/#/activity/scenetaskdetail/" + record.id
                    }}
                >
                    {filter.size * (filter.page - 1) + index + 1}
                </span>
            )
        }, {
            title: '任务名称',
            align: 'center',
            key: 'name',
            dataIndex: 'name',
            width: '30%',
            render: (text, record, index) => {
                return record.name
            }
        }, {
            title: '所属场景',
            key: 'scene',
            dataIndex: 'scene',
            width: '12%',
            align: 'center',
        }, {
            title: '审核状态',
            key: 'status',
            dataIndex: 'status',
            width: '10%',
            align: 'center',
            filters: [
                { text: "待审核", value: "0" },
                { text: "通过", value: "1" },
                { text: "待补充", value: "2" },
                { text: "拒绝", value: "3" }
            ],
            render: (record) => {
                return <span className="audit-status">
                    {
                        record === 0 && "待审核"
                    }
                    {
                        record === 1 && <span className="pass">通过</span>
                    }
                    {
                        record === 2 && <span className="added">待补充</span>
                    }
                    {
                        record === 3 && <span className="denied">拒绝</span>
                    }
                </span>
            },
        }, {
            title: '提交者',
            key: 'username',
            dataIndex: 'username',
            width: '10%',
            align: 'center',
            filterDropdown: ({ clearFilters }) => (
                <div className="table-search">
                    <Input
                        placeholder="请输入提交者名称"
                        value={filter.username}
                        onChange={(e) => {
                            filter.username = e.target.value;
                            filter.page = 1;
                            setFilter({ ...filter })
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => { clearFilters() }}
                    >
                        确定
                    </Button>
                    <Button onClick={() => {
                        clearFilters();
                        filter.username = "";
                        filter.page = 1;
                        setFilter({ ...filter })
                    }}>取消</Button>
                </div>
            ),
            filterIcon: <SearchOutlined />
        }, {
            title: '提交时间',
            key: 'sub_time',
            dataIndex: 'sub_time',
            width: '15%',
            align: 'center',
        }, {
            title: '操作',
            key: 'operate',
            dataIndex: 'operate',
            align: 'center',
            width: "10%",
            render: (text, record) => {
                return <div className="table-operation">
                    <div>
                        <span>
                            <b></b>
                            <b></b>
                            <b></b>
                        </span>
                        {loginInfo.userInfo.role_type === 1 &&
                            <div>
                                <Button
                                    type="link"
                                    onClick={() => {
                                        window.location.href = "/#/activity/scenetaskdetail/" + record.id
                                    }}
                                >
                                    详情
                                </Button>
                                <Button
                                    key="2"
                                    type="link"
                                    disabled={loginInfo.userInfo.role_type !== 1 && record.status === 1}
                                    onClick={() => {
                                        window.location.href = "/#/activity/scenetaskform/1/" + record.id
                                    }}
                                >
                                    编辑
                                </Button>
                                <Button
                                    key="4"
                                    type="link"
                                    disabled={loginInfo.userInfo.role_type !== 1}
                                    onClick={() => window.open("/#/activity/scenetaskform/2/" + record.id)}
                                >
                                    审核
                                </Button>
                                <Button
                                    key="6"
                                    type="link"
                                    style={{ color: "#FC7057" }}
                                    disabled={loginInfo.userInfo.role_type !== 1 && record.status === 1}
                                    onClick={() => {
                                        deleteTip.show = true
                                        deleteTip.list = [record.id]
                                        setDeleteTip({ ...deleteTip })
                                    }}
                                >删除</Button>
                            </div>
                        }
                        {
                            loginInfo.userInfo.role_type === 0 &&
                            <div>
                                <Button
                                    type="link"
                                    onClick={() => {
                                        window.location.href = "/#/activity/scenetaskdetail/" + record.id
                                    }}
                                >
                                    详情
                                </Button>
                                {
                                    record.status !== 1 &&
                                    <>
                                        <Button
                                            key="2"
                                            type="link"
                                            disabled={loginInfo.userInfo.role_type !== 1 && record.status === 1}
                                            onClick={() => {
                                                window.location.href = "/#/activity/scenetaskform/1/" + record.id
                                            }}
                                        >
                                            编辑
                                        </Button>
                                        <Button
                                            key="6"
                                            type="link"
                                            style={{ color: "#FC7057" }}
                                            disabled={loginInfo.userInfo.role_type !== 1 && record.status === 1}
                                            onClick={() => {
                                                deleteTip.show = true
                                                deleteTip.list = [record.id]
                                                setDeleteTip({ ...deleteTip })
                                            }}
                                        >删除</Button>
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
            }
        }
    ]
    return (
        <div className="ctf-resource-list" style={{ padding: 0 }}>
            <div className="ctf-resource-table content-container" style={{ marginTop: 0 }}>
                {
                    // loginInfo.userInfo.role_type === 1 &&
                    <div className="handle">
                        <div style={{ display: 'inline-block' }}>
                            <Button
                                type='ghost'
                                disabled={checkList.length === 0 ? true : false}
                                danger
                                onClick={() => {
                                    deleteTip.show = true;
                                    deleteTip.list = checkList;
                                    setDeleteTip({ ...deleteTip })
                                }}
                            >
                                批量删除
                            </Button>
                            {/* <Button
                                type='primary'
                                style={{ marginLeft: '15px' }}
                                disabled={checkList.length === 0 ? true : false}
                            // onClick={exprotTool}
                            >
                                批量导出
                            </Button> */}
                        </div>
                        <Button
                            type="primary"
                            onClick={() => window.open("/#/activity/scenetaskform/0/0")}
                        >
                            提交场景任务
                        </Button>
                        {/* <Button
                            type='primary'
                            disabled={initData.allCount === 0 ? true : false}
                            style={{ marginRight: '15px' }}
                        // onClick={()=>setBoxShow(true)}
                        >
                            全部导出
                        </Button> */}
                    </div>
                }
                {
                    permission === 2 && loginInfo.userInfo.role_type !== 1 &&
                    <div className="handle">
                        <Button
                            type="primary"
                            onClick={() => window.open("/#/activity/scenetaskform/0/0")}
                        >
                            提交场景任务
                        </Button>
                    </div>
                }
                <Table
                    dataSource={initData.data}
                    rowKey={record => record.id}
                    bordered
                    rowSelection={rowSelection}
                    onChange={handleTableChange}
                    columns={columns}
                    pagination={false}
                />
                <Pagination
                    showQuickJumper={true}
                    current={filter.page}
                    pageSize={filter.size}
                    total={initData.allCount}
                    // hideOnSinglePage={true}
                    pageSizeOptions={['10', '20', '50', '100']}
                    showSizeChanger={true}
                    className="flip-over"
                    style={{ margin: "30px 0" }}
                    onChange={
                        ((page,pageSize) => {
                            let data = { ...filter }
                            data.page = page;
                            data.size = pageSize;
                            setFilter(data)
                        })
                    }
                />
            </div>
            {
                deleteTip.show &&
                <DeteleBox
                    close={() => {
                        deleteTip.show = false;
                        deleteTip.list = [];
                        setDeleteTip({ ...deleteTip })
                    }}
                    title='场景任务'
                    submit={() => resourceDelete(deleteTip.list)}
                />
            }
        </div>
    )
}

export default SceneTask;