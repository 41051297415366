import React, { useEffect, useState, useCallback } from 'react';
import { Button, Input, Select, InputNumber, Spin, Row, Col } from 'antd';
import { Alert } from '../../../Communal/utility';
import instance from '../../../Communal/instance';
import { debounce } from "lodash";
import Retrieval from '../../../Activity/retrieval'
import { RulesHandling, RuleSplicing, RulesGenerate, stage, actions, deal, Ip } from '../../../../utils/method';
import CommitBox from '../commitModal';
import ConfirmBox from '../confirm';

const { Option } = Select;
const { TextArea } = Input;

const App = ({
    param
}) => {
    const [submit, setSubmit] = useState({
        class_id: null,
        stage: [],
        desc_rules: '',
        remarks: '',
        level: 4,
        new_content: ""
    })
    const [classTypeList, setClassTypeList] = useState([])
    const [classType, setClassType] = useState(null)
    const [error, setError] = useState({
        class_id: [],
        stage: [],
        desc_rules: [],
        remarks: [],
        new_content: [],
        level: [],
    })
    const [ruleContent, setRuleContent] = useState({
        action: "",
        protocol: "",
        sourceIp: "",
        sourcePort: "",
        destinationIp: "",
        destinationPort: "",
        rev: "",
        reference: '',
    })
    const [retrievalBox, setRetrievalBox] = useState({
        show: false,
        count: 0
    })
    const [updateLoading, setUpdateLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [detailLoading, setDetailLoading] = useState(false)
    const [commitBox, setCommitBox] = useState({
        show: false
    })
    const [confirmBox, setConfirmBox] = useState({
        show: false
    })

    useEffect(() => {
        getDetail(param.match.params.id)
        getClassTypeList()
    }, []);

    const getDetail = (id) => {
        setDetailLoading(true)
        instance.get(`/rules/outside/${id}/`).then(data => {
            setDetailLoading(false)
            setSubmit({ ...submit, ...data })
            setClassType(data.english_name)
            if (data.status === 0)
                ruleContentChange(data.content)
            if (data.status !== 0 && data?.new_content) {
                ruleContentChange(data.new_content)
            }
        }).catch((err) => {
            setDetailLoading(false)
        })
    }

    const getClassTypeList = () => {
        instance.get('/rules/class/', { params: { status: 1, nopage: 1 } }).then(data => {
            if (Array.isArray(data)) {
                setClassTypeList(data)
            }
        })
    }

    const changeFun = useCallback(value => {
        const list = RulesHandling(value)
        if (list?.action) {
            ruleContent.action = list.action
        }
        if (list?.destinationIp) {
            ruleContent.destinationIp = list.destinationIp
        }
        if (list?.destinationPort) {
            ruleContent.destinationPort = list.destinationPort
        }
        if (list?.protocol) {
            ruleContent.protocol = list.protocol
        }
        if (list?.sourceIp) {
            ruleContent.sourceIp = list.sourceIp
        }
        if (list?.sourcePort) {
            ruleContent.sourcePort = list.sourcePort
        }
        if (list?.rev) {
            ruleContent.rev = list.rev
        }
        if (list?.reference) {
            let str = ''
            if (list.reference.indexOf(',') >= 0) {
                str = list.reference.split(',').join(':')
            } else {
                str = list.reference
            }
            ruleContent.reference = str
        }
        if (list?.metadata) {
            submit.stage = list.metadata
        }
        if (list?.msg) {
            submit.desc_rules = list.msg
        }
        setRuleContent({ ...ruleContent })
    }, []);

    const debounceFun = useCallback(
        debounce((e) => changeFun(e), 1000, {
            leading: false,
            trailing: true
        }),
        [changeFun]
    );

    const ruleContentChange = useCallback(
        e => {
            debounceFun(e);
        },
        [debounceFun]
    );

    const CreateRules = () => {
        handleFocus('new_content')
        let headList = {}
        let list = {}
        if (ruleContent.action) {
            headList.action = ruleContent.action
        }
        if (ruleContent.protocol) {
            headList.protocol = ruleContent.protocol
        }
        if (ruleContent.sourceIp) {
            headList.sourceIp = ruleContent.sourceIp
        }
        if (ruleContent.sourcePort) {
            headList.sourcePort = ruleContent.sourcePort
        }
        if (ruleContent.destinationIp) {
            headList.destinationIp = ruleContent.destinationIp
        }
        if (ruleContent.destinationPort) {
            headList.destinationPort = ruleContent.destinationPort
        }
        if (ruleContent.rev) {
            list.rev = ruleContent.rev
        }
        if (ruleContent.reference) {
            list.reference = ruleContent.reference
        }
        if (classType !== undefined) {
            list.classtype = classType
        }
        if (submit?.stage.length >= 0) {
            list.metadata = submit.stage
        }
        if (submit.desc_rules) {
            list.msg = submit.desc_rules
        }
        const str = RulesGenerate(submit.content, headList, list)
        submit.new_content = str
        setSubmit({ ...submit })
    }

    const inputChange = (value, name) => {
        submit[name] = value;
        setSubmit({ ...submit })
    }

    const contentChange = (value, name) => {
        ruleContent[name] = value;
        setRuleContent({ ...ruleContent })
    }

    const handleFocus = (name) => {
        error[name] = []
        setError({ ...error })
    }

    const handleSearch = () => {
        if (submit.new_content == '') {
            Alert("error", "规则内容为空");
            return
        }
        const list = {
            data: submit.new_content,
            status: 1,
        }
        instance.post(`/rules/outside/${param.match.params.id}/ `, list).then(data => {
            if (data.status === 1) {
                data.msg ? retrievalBox.count = 1 : retrievalBox.count = 0
                retrievalBox.show = true
            }
            setRetrievalBox({ ...retrievalBox })
        }).catch((err) => {
            Alert('error', err.error)
        })
    }

    const IgnoreUpdates = () => {
        setUpdateLoading(true)
        instance.post(`/rules/outside/${param.match.params.id}/`, { ids: param.match.params.id, status: 0 }).then(data => {
            setUpdateLoading(false)
            Alert('success', '忽略成功')
            confirmBox.show = false
            setConfirmBox({ ...confirmBox })
            getDetail(param.match.params.id)
        }).catch((err) => {
            confirmBox.show = false
            setConfirmBox({ ...confirmBox })
            setUpdateLoading(false)
        })
    }

    const handleSubmit = () => {
        setSubmitLoading(true)
        let list = {
            source_sid: submit.sid,
            rules_class: submit.class_id,
            stage: submit.stage,
            desc: submit.desc_rules,
            remarks: submit.remarks,
            content: submit.new_content,
            level: submit.level,
            reference: ruleContent.reference
        }
        instance.post(`/rules/outside/audit/`, list).then(data => {
            setSubmitLoading(false)
            Alert('success', '归档成功')
            commitBox.show = false
            setCommitBox({ ...commitBox })
            getDetail(data.id)
        }).catch((err) => {
            setSubmitLoading(false)
            const { content } = err;
            error.new_content = content
            setError({ ...error })
            commitBox.show = false
            setCommitBox({ ...commitBox })
        })
    }

    const handleCommit = () => {
        if (submit.class_id === null || submit.class_id === '') {
            error.class_id = ["请选择规则分类"]
        }
        if (submit.stage && submit.stage.length === 0) {
            error.stage = ['请选择规则阶段']
        }
        if (submit.desc_rules === "") {
            error.desc_rules = ["请输入规则描述"]
        }
        if (submit.remarks === "") {
            error.remarks = ["请输入规则备注"]
        }
        if (submit.new_content === "") {
            error.new_content = ["请输入规则内容或选择以上选项生成规则内容"]
        }
        if (submit.class_id === undefined ||
            submit.class_id === null ||
            submit.class_id === '' ||
            submit.stage.length === 0 ||
            submit.desc_rules === "" ||
            submit.remarks === "" ||
            submit.new_content === ""
        ) {
            setError({ ...error })
            return false
        }
        let content = submit.new_content
        let headArr = content.trim().split("(")[0].trim().split(" ")
        if (headArr.length !== 7) {
            error.new_content = ["规则内容格式错误，请检查"]
        }
        if (ruleContent.protocol === 'any') {
            error.new_content = ["协议字段错误，请修改"]
        }
        if (headArr.length !== 7 ||
            ruleContent.protocol === 'any'
        ) {
            setError({ ...error })
            return false
        }
        commitBox.show = true
        setCommitBox({ ...commitBox })
        return true
    }

    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-div submit-ctf-name">
                    <div>
                        外部规则详情
                    </div>
                    <div className="status" style={{ position: 'relative' }}>
                        {
                            submit.status === 0 &&
                            <div className='logo wait'>待确认</div>
                        }
                        {
                            submit.status === 1 &&
                            <div className='logo handle'>已归档</div>
                        }
                        {
                            submit.status === 2 &&
                            <div className='logo del'>已忽略</div>
                        }
                    </div>
                </div>
                <Spin spinning={detailLoading}>
                    <div className="submit-ctf-div subject-info">
                        <div className="title">
                            规则信息
                            {
                                submit?.username === "" ? undefined : <div className='right'>
                                    <span>操作人：{submit?.username}</span>
                                    <span>操作时间：{submit?.time}</span>
                                    <span>{submit.status === 1 ? "归档" : submit.status === 2 ? "忽略规则" : ""}</span>
                                </div>
                            }

                        </div>
                        <div className="content">
                            <div className="retrieval-div">
                                <span>原规则内容</span>
                                <TextArea
                                    autoSize
                                    value={submit.content}
                                    className="content-input"
                                    placeholder="原规则内容"
                                />
                            </div>
                            <Row>
                                <Col span={8} className="retrieval-div">
                                    <span className='name'><b>*</b>规则分类</span>
                                    <Select
                                        className='width-select'
                                        value={submit.class_id}
                                        placeholder='请选择规则分类'
                                        style={{ width: 180 }}
                                        onChange={(value) => {
                                            inputChange(value, 'class_id')
                                            const items = classTypeList.filter((item) => {
                                                return item.id === value
                                            })
                                            setClassType(items[0].english_name)
                                        }
                                        }
                                        onClick={() => handleFocus("class_id")}
                                    >
                                        {
                                            classTypeList.map((val, key) => {
                                                return <Option
                                                    key={key}
                                                    value={val.id}
                                                >
                                                    {val.name}
                                                </Option>
                                            })
                                        }
                                    </Select>
                                    <div className="error-message">{error?.class_id[0]}</div>
                                </Col>
                                <Col span={16} className="retrieval-div">
                                    <span className='name'><b>*</b>规则阶段</span>
                                    <Select
                                        value={submit?.stage}
                                        mode="multiple"
                                        placeholder='请选择规则阶段'
                                        style={{ width: '80%' }}
                                        onChange={(value) => inputChange(value, 'stage')}
                                        onClick={() => handleFocus("stage")}
                                    >
                                        {
                                            stage.map((val, key) => {
                                                return <Option key={key} value={val.name}>
                                                    {val.name}
                                                </Option>
                                            })

                                        }
                                    </Select>
                                    <div className="error-message">{error?.stage[0]}</div>
                                </Col>
                            </Row>
                            <div className="retrieval-div">
                                <span><b>*</b>规则描述</span>
                                <Input
                                    value={submit.desc_rules}
                                    className="content-input"
                                    placeholder="请输入规则针对的威胁描述"
                                    onChange={(e) => inputChange(e.target.value, "desc_rules")}
                                    maxLength="200"
                                    onFocus={() => handleFocus("desc_rules")}
                                />
                                <div className="error-message">{error.desc_rules[0]}</div>
                            </div>
                            <div
                                className="retrieval-div"
                                style={{ color: '#999', marginTop: 0 }}>
                                <span></span>
                                （请翻译msg字段内容）
                            </div>
                            <div className="retrieval-div">
                                <span><b>*</b>规则备注</span>
                                <Input
                                    value={submit.remarks}
                                    className="content-input"
                                    placeholder="请输入规则备注"
                                    onChange={(e) => inputChange(e.target.value, "remarks")}
                                    maxLength="200"
                                    onFocus={() => handleFocus("remarks")}
                                />
                                <div className="error-message">{error.remarks[0]}</div>
                            </div>
                            <div className="retrieval-div">
                                <span>参考资料</span>
                                <Input
                                    value={ruleContent.reference}
                                    className="content-input"
                                    placeholder="请输入规则参考资料"
                                    onChange={(e) => contentChange(e.target.value, 'reference')}
                                    onClick={() => handleFocus("reference")}
                                />
                            </div>
                            <Row>
                                <Col span={8} className="retrieval-div">
                                    <span className='name'>行为</span>
                                    <Select
                                        className='width-select'
                                        value={ruleContent?.action}
                                        placeholder='请选择行为'
                                        style={{ width: 180 }}
                                        onChange={(value) => contentChange(value, 'action')}
                                        onClick={() => handleFocus("action")}
                                    >
                                        {
                                            actions.map((val, key) => {
                                                return <Option key={key} value={val.name}>
                                                    {val.name}
                                                </Option>
                                            })
                                        }
                                    </Select>
                                </Col>
                                <Col span={8} className="retrieval-div">
                                    <span className='name'>协议</span>
                                    <Select
                                        className='width-select'
                                        value={ruleContent?.protocol}
                                        placeholder='请选择协议'
                                        style={{ width: 180 }}
                                        onChange={(value) => contentChange(value, 'protocol')}
                                        onClick={() => handleFocus("protocol")}
                                    >
                                        {
                                            deal.map((val, key) => {
                                                return <Option key={key} value={val.name}>
                                                    {val.name}
                                                </Option>
                                            })
                                        }
                                    </Select>
                                </Col>
                                <Col span={8} className="retrieval-div">
                                    <span className='name'>源地址</span>
                                    <Select
                                        className='width-select'
                                        value={ruleContent.sourceIp}
                                        placeholder='请选择源地址'
                                        style={{ width: 180 }}
                                        onChange={(value) => contentChange(value, 'sourceIp')}
                                        onClick={() => handleFocus("sourceIp")}
                                    >
                                        {
                                            Ip.map((val, key) => {
                                                return <Option key={key} value={val.name}>
                                                    {val.name}
                                                </Option>
                                            })
                                        }
                                    </Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8} className="retrieval-div">
                                    <span className='name'>源端口</span>
                                    <Input
                                        value={ruleContent.sourcePort}
                                        style={{ width: 180 }}
                                        className="content-input"
                                        placeholder="请输入源端口"
                                        onChange={(e) => contentChange(e.target.value, "sourcePort")}
                                        maxLength="150"
                                        onFocus={() => handleFocus("sourcePort")}
                                    />
                                </Col>
                                <Col span={8} className="retrieval-div">
                                    <span className='name'>目的地址</span>
                                    <Select
                                        className='width-select' value={ruleContent.destinationIp}
                                        placeholder='请选择目的地址'
                                        style={{ width: 180 }}
                                        onChange={(value) => contentChange(value, 'destinationIp')}
                                        onClick={() => handleFocus("destinationIp")}
                                    >
                                        {
                                            Ip.map((val, key) => {
                                                return <Option key={key} value={val.name}>
                                                    {val.name}
                                                </Option>
                                            })
                                        }
                                    </Select>
                                </Col>
                                <Col span={8} className="retrieval-div">
                                    <span className='name'>目的端口</span>
                                    <Input
                                        value={ruleContent.destinationPort}
                                        className="content-input"
                                        style={{ width: 180 }}
                                        placeholder="请输入目的端口"
                                        onChange={(e) => contentChange(e.target.value, "destinationPort")}
                                        maxLength="150"
                                        onFocus={() => handleFocus("destinationPort")}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8} className="retrieval-div">
                                    <span className='name'>Rev</span>
                                    <Input
                                        style={{ width: 180 }}
                                        value={ruleContent.rev}
                                        className="content-input"
                                        placeholder="请输入关键字标识"
                                        onChange={(e) => contentChange(e.target.value, "rev")}
                                        maxLength="150"
                                        onFocus={() => handleFocus("rev")}
                                    />
                                </Col>
                                <Col span={8} className="retrieval-div">
                                    <span className='name'>等级</span>
                                    <InputNumber
                                        style={{ width: 180 }}
                                        precision={0}
                                        min={0}
                                        max={10000}
                                        value={submit.level}
                                        className="content-input"
                                        onChange={(value) => inputChange(value, "level")}
                                        onFocus={() => handleFocus("level")}
                                    />
                                    <div className="error-message">{error.level[0]}</div>
                                </Col>
                            </Row>
                            <div className="retrieval-div">
                                <span></span>
                                <Button
                                    type='primary'
                                    onClick={CreateRules}
                                >
                                    生成规则
                                </Button>
                                <Button
                                    danger
                                    onClick={() => {
                                        confirmBox.show = true
                                        setConfirmBox({ ...confirmBox })
                                    }}
                                >
                                    忽略规则
                                </Button>
                            </div>
                            <div style={{ color: '#FC7057' }}>
                                {/* <span></span> */}
                                <div>
                                    <div>温馨提示：</div>
                                    <div>1.根据【原规则内容】选择对应字段，然后针对规则做好描述，最后点击【生成规则】按钮即可在下方看到汉化处理后的规则</div>
                                    <div>2.如果需要增加额外字段可在下面规则内容手动编辑后进行搜索是都存在，点击保存即可保存规则</div>
                                    <div>3.如果该规则不需要，可点击【忽略规则】，则规则会被标记为已忽略</div>
                                    <div>4.已忽略的规则如果还要归档则点击详情，生成对应的规则后点击保存，就会在规则资源中添加该规则，其次原始规则会被修改为已归档</div>
                                </div>
                            </div>
                            <div className="retrieval-div">
                                <span><b>*</b>规则内容</span>
                                <TextArea
                                    autoSize
                                    maxLength={3000}
                                    value={submit.new_content}
                                    className="content-input"
                                    placeholder="请输入规则内容或选择以上选项生成规则内容"
                                    onChange={(e) => {
                                        inputChange(e.target.value, "new_content")
                                        ruleContentChange(e.target.value)
                                    }}
                                    onFocus={() => handleFocus("new_content")}
                                />
                                <Button
                                    type='primary'
                                    onClick={() => handleSearch()}
                                >
                                    搜索
                                </Button>
                                <div className="error-message">{error.new_content[0]}</div>
                            </div>
                            <div className="retrieval-div" style={{ textAlign: 'center' }}>
                                <Button
                                    type='primary'
                                    size='large'
                                    disabled={submit.prev === null}
                                    onClick={() => { window.location.href = "/#/activity/externalrulesform/0/" + submit.prev }}
                                >
                                    上一条
                                </Button>
                                <Button
                                    type='primary'
                                    size='large'
                                    disabled={submit.next === null}
                                    onClick={() => { window.location.href = "/#/activity/externalrulesform/0/" + submit.next }}
                                >
                                    下一条
                                </Button>
                            </div>
                        </div>
                    </div>
                </Spin>
                <div className="submit-btn">
                    <Button
                        type="primary"
                        // loading={submitLoading}
                        onClick={() => {
                            handleCommit()
                        }}
                    >
                        保存规则
                    </Button>
                </div>
            </div >
            {
                retrievalBox.show &&
                <Retrieval
                    close={() => {
                        retrievalBox.show = false;
                        setRetrievalBox({ ...retrievalBox })
                    }}
                    count={retrievalBox.count}
                    name={"规则内容"}
                />
            }
            < CommitBox
                commit={commitBox}
                resourceName={"规则"}
                loading={submitLoading}
                onOK={() => {
                    handleSubmit()
                }}
                onClose={() => {
                    commitBox.show = false
                    setCommitBox({ ...commitBox })
                }}
            />
            < ConfirmBox
                boxProperty={confirmBox}
                resourceName={"规则"}
                operate={'忽略'}
                loading={updateLoading}
                onOk={() => {
                    IgnoreUpdates()
                }}
                onClose={() => {
                    confirmBox.show = false
                    setConfirmBox({ ...confirmBox })
                }}
            />
        </div >
    )
}
export default App;