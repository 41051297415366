/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import CodeHighlighter from 'braft-extensions/dist/code-highlighter';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import instance from '../../Communal/instance';
import Added from '../../../images/added.png';
import AuditStatus from '../../../images/audit-status.png';
import Denied from '../../../images/denied.png';
import Pass from '../../../images/pass.png';
import styled from 'styled-components';
import history from '../../Communal/history';
const options = {
    syntaxs: [
        {
            name: 'JavaScript',
            syntax: 'javascript'
        }, {
            name: 'HTML',
            syntax: 'html'
        }, {
            name: 'CSS',
            syntax: 'css'
        }, {
            name: 'Java',
            syntax: 'java',
        }, {
            name: 'PHP',
            syntax: 'php'
        }, {
            name: "Python",
            syntax: 'python'
        }
    ],
}
BraftEditor.use(CodeHighlighter(options))
const Div = styled.div`
    width:100%!important;
    color:#333;
    .bf-content {
        height:auto
    }
    .bf-container .public-DraftEditor-content .braft-code-block-wrapper .braft-code-block{
        max-height:10000px
    }
`
function CtfDetail(param) {
    const [detail, setDetail] = useState({});
    // 获取详情
    useEffect(() => {
        getDetail()
    }, [])
    const getDetail = () => {
        instance.get("/recruit/enterp/" + param.param.match.params.id + "/").then(data => {
            setDetail(data)
        })
    }

    return (
        <div className="activity-ctf-detail submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-name submit-ctf-div">
                    <div style={{ display: "inline-block" }} className='ctf-name'>
                        <span
                            onClick={() => window.location.href = "/#/resource"}
                        >
                            资源中心
                        </span>
                        &nbsp;＞&nbsp;
                        <span
                            onClick={() => {
                                history.push({pathname:'/resource/recruit',state:{tabKey:2}})
                            }}
                        >
                            招聘企业
                        </span>
                        &nbsp;＞&nbsp;
                        {detail.name}
                    </div>
                    {
                        detail.is_show_opin &&
                        <div className="status">
                            {
                                detail.status === 0 &&
                                <img src={AuditStatus} alt="" />
                            }
                            {
                                detail.status === 1 &&
                                <img src={Pass} alt="" />
                            }
                            {
                                detail.status === 2 &&
                                <img src={Added} alt="" />
                            }
                            {
                                detail.status === 3 &&
                                <img src={Denied} alt="" />
                            }
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div
                            className="title"
                            style={{ borderBottom: "1px solid #f4f4f4" }}
                        >
                            审核意见
                        </div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    detail.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">
                                                [{val.time}]
                                            </div>
                                            <div className="details">
                                                <pre
                                                    style={{ whiteSpace: "pre-line" }}
                                                >{val.msg}
                                                </pre>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div">
                    <div className="title">企业信息</div>
                    <div className="content">
                        <div>
                            <span>企业编号</span>
                            <div>{detail.number}</div>
                        </div>
                        <div>
                            <span>企业logo</span>
                            <div>
                                <img
                                    alt='企业logo'
                                    src={detail.logo}
                                    style={{ width: '100px' }}
                                />
                            </div>
                        </div>
                        <div>
                            <span>企业全称</span>
                            <div>{detail.name}</div>
                        </div>
                        <div>
                            <span>企业简介</span>
                            <div style={{ paddingTop: "9px", lineHeight: "25px" }}>
                                <pre style={{ whiteSpace: "pre-wrap" }}>
                                    {detail.intro}
                                </pre>
                            </div>
                        </div>
                        <div>
                            <span>企业标签</span>
                            <div className="tag">
                                {
                                    detail.label &&
                                    detail.label.map((value, key) => {
                                        return <span key={"key" + key}>
                                            {value}
                                        </span>
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            <span>企业规模</span>
                            <div>{detail.scale}</div>
                        </div>
                    </div>
                    {
                        detail.stations && detail.stations.map((val, key) => {
                            return <div key={key} className='enterprise-detail' >
                                <div>
                                    <div className='etp-title'>
                                        <span className='name'>
                                            {val.name}
                                        </span>
                                        <span>[{val.address}]</span>
                                        <span className='time'>更新于{val.update_time}</span>
                                    </div>
                                    <div className='etp-content'>
                                        <span className='treatment'>{val.treatment}</span>
                                        <span>{val.experience} | {val.education}</span>
                                    </div>
                                </div>
                                <div
                                    className='etp-info'
                                    onClick={() => window.location.href = "/#/activity/recruitdetail/" + val.id}
                                >
                                    岗位详情
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}
export default CtfDetail;