/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import instance from '../../../Communal/instance';
import Added from '../../../../images/added.png';
import AuditStatus from '../../../../images/audit-status.png';
import Denied from '../../../../images/denied.png';
import Pass from '../../../../images/pass.png';
import BuyCtf from '../../../Activity/buyCtf';
import { Button } from 'antd';
import Reminder from '../../../Communal/reminder';
import history from '../../../Communal/history';
import '../index.less';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism'

function PlugDetail({ param }) {
    const [detail, setDetail] = useState({});
    const [buyCtf, setBuyCtf] = useState(false);
    const [reminderShow, setReminderShow] = useState(false)
    useEffect(() => {
        getDetail();
    }, []);
    let getDetail = () => {
        instance.get(`/vulninte/api/plug/${param.match.params.id}/`).then(data => {
            setDetail(data);
        })
    }

    return (
        <div className="activity-ctf-detail submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-name submit-ctf-div">
                    {
                        param.match.params.where === "0" &&
                        <div style={{ display: "inline-block" }} className='ctf-name'>
                            <span onClick={() => window.location.href = "/#/resource"}>资源中心</span> ＞ <span onClick={() => {
                                history.push({ pathname: '/resource/vulninfo', state: { tabKey: "4" } })
                            }}>插件库</span> ＞ {detail.name}
                        </div>
                    }
                    {
                        detail.is_show_opin &&
                        <div className="status">
                            {
                                detail.status === 0 &&
                                <img src={AuditStatus} alt="" />
                            }
                            {
                                detail.status === 1 &&
                                <img src={Pass} alt="" />
                            }
                            {
                                detail.status === 2 &&
                                <img src={Added} alt="" />
                            }
                            {
                                detail.status === 3 &&
                                <img src={Denied} alt="" />
                            }
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    detail.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">
                                                <pre style={{ whiteSpace: "pre-line" }}>{val.msg}</pre>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div">
                    <div className="award">
                        <div>
                            <span>{detail.coin}</span>
                            <span>奖励</span>
                        </div>
                        <p onClick={() => setReminderShow(true)}>赚取更多奖励＞</p>
                    </div>
                    <div className="title">插件信息</div>
                    <div className="content">
                        <div>
                            <span>插件名称</span>
                            <div>{detail?.name}</div>
                        </div>
                        <div>
                            <span>插件类型</span>
                            <div>{detail?.read_pulg_type}</div>
                        </div>
                        <div>
                            <span>资源等级</span>
                            <div>{detail?.resource_level}</div>
                        </div>
                        <div className='plug-content'>
                            <span>插件内容</span>
                            <div style={{ width: '100%' }}>
                                {!detail.is_buy ? <span style={{ color: 'red' }}>购买后可查看</span> : <SyntaxHighlighter
                                    children={String(detail?.content_read).replace(/\n$/, '')}
                                    style={tomorrow}
                                    language="javascript"
                                    showLineNumbers
                                />}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    !detail.is_buy &&
                    <div className="buy-button">
                        <Button type="primary" onClick={() => setBuyCtf(true)}>购买</Button>
                    </div>
                }
            </div>
            {
                buyCtf &&
                <BuyCtf
                    coin={detail.coin}
                    url={`/vulninte/api/buy/${detail.id}/`}
                    close={() => setBuyCtf(false)}
                    getDetail={getDetail}
                />
            }
            {
                reminderShow &&
                <Reminder
                    close={() => setReminderShow(false)}
                />
            }
        </div>
    )
}
export default PlugDetail;
