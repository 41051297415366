import React ,{useEffect}from 'react';
import '../css/App.css';
import {Router,Route,Switch} from 'react-router-dom';
import Header from './Home/Header';
import Footer from './Home/Footer';
import history from './Communal/history'
import Home from './Home';
import Activity from './Activity';
import Resource from './Resource';
import Personal from './Personal';
import instance from './Communal/instance';
import { useDispatch } from "redux-react-hook";

function App() {
	const dispatch = useDispatch();
	useEffect(()=>{
		document.getElementsByClassName("app-content")[0].style.minHeight=document.body.clientHeight-40+"px";
		window.addEventListener('resize',handleResize);
		instance.get("/users/status/").then(data=>{
			dispatch({
				type: "SET_LOGIN",
				login: data.status
			})
			if(data.status){
				instance.get("/users/user_info/").then(detail=>{
					dispatch({
						type: "SET_USERINFO",
						userInfo: detail
					})
				})
			}
		})
		return ()=>{
			window.removeEventListener('resize',handleResize)
		}
	})
	const handleResize=()=>{
		document.getElementsByClassName("app-content")[0].style.minHeight=document.body.clientHeight-40+"px";
	}
	return (
		<Router history={history}>
			<div className="App">
				<Header />
				<div className="app-content">
					<Switch>
						<Route exact path="/" component={(param)=>{
							return <Home />
						}}/>
						<Route path="/activity" component={(param)=>{
							return <Activity />
						}}/>
						<Route path="/resource" component={(param)=>{
							return <Resource />
						}}/>
						<Route path="/personal/:type" component={(param)=>{
							return <Personal  param={param}/>
						}}/>
					</Switch>
				</div>
				<Footer/>
			</div>
		</Router>
	);
}

export default App;
