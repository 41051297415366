/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import instance from '../../Communal/instance';
import Added from '../../../images/added.png';
import AuditStatus from '../../../images/audit-status.png';
import Denied from '../../../images/denied.png';
import Pass from '../../../images/pass.png';
import { Tag } from 'antd';
import Reminder from '../../Communal/reminder';
import Cao from '../../../images/cao.png';
import HuiCao from '../../../images/huicao.png';
const option_type_text = { 1: '单选', 2: '多选', 3: '判断', 4: '填空' };
let letter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
function CtfDetail(param) {
    const [detail, setDetail] = useState({});
    const [reminderShow, setReminderShow] = useState(false)
    useEffect(() => {
        getDetail();
    }, []);
    let getDetail = () => {
        instance.get("/test/" + param.param.match.params.id + "/").then(data => {
            setDetail(data)
        })
    }
    return (
        <div className="activity-ctf-detail submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-name submit-ctf-div">
                    {
                        param.param.match.params.where === "0" &&
                        <div style={{ display: "inline-block" }} className='ctf-name'>
                            <span onClick={() => window.location.href = "/#/resource"}>资源中心</span> ＞ <span onClick={() => window.location.href = "/#/resource/test"}>基础题目资源库</span> ＞ {detail.name}
                        </div>
                    }
                    {
                        param.param.match.params.where === "2" &&
                        <div style={{ display: "inline-block" }} className='ctf-name'>
                            <span onClick={() => window.location.href = "/#/personal/8"}>个人中心</span> ＞ {detail.name}
                        </div>
                    }
                    {
                        detail.is_show_opin &&
                        <div className="status">
                            {
                                detail.status === 0 &&
                                <img src={AuditStatus} alt="" />
                            }
                            {
                                detail.status === 1 &&
                                <img src={Pass} alt="" />
                            }
                            {
                                detail.status === 2 &&
                                <img src={Added} alt="" />
                            }
                            {
                                detail.status === 3 &&
                                <img src={Denied} alt="" />
                            }
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    detail.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">
                                                <pre style={{ whiteSpace: "pre-line" }}>{val.msg}</pre>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div">
                    <div className="award">
                        <div>
                            <span>{detail.coin}</span>
                            <span>奖励</span>
                        </div>
                        <p onClick={() => setReminderShow(true)}>赚取更多奖励＞</p>
                    </div>
                    <div className="title">题目详情</div>
                    <div className="content">
                        {
                            detail.status === 1 &&
                            <div>
                                <span>题目编号</span>
                                <div>{detail.number ? detail.number : "暂无编号"}</div>
                            </div>
                        }
                        <div>
                            <span>题目问题</span>
                            <div>{detail.name}</div>
                        </div>
                        <div>
                            <span>题目别名</span>
                            <div>{detail.alias}</div>
                        </div>
                        <div className="">
                            <span>题目等级</span>
                            <div className="test-level">
                                {
                                    Array(3).fill("").map((val, key) => {
                                        return <img
                                            key={key}
                                            src={(key + 1) > detail.level ? HuiCao : Cao}
                                            alt=""
                                        />
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            <span>题目类型</span>
                            <div>{option_type_text[detail.option_type] || ''}</div>
                        </div>
                        <div>
                            <span>选项答案</span>
                            <div>{(Object.entries(detail.content || {}) || []).map((x, i) => {
                                return <div key={i} className={detail.option_type !== 4 && `option-style ${detail.option.includes(letter[i]) && 'option-answer-style'}`}>
                                    {detail.option_type === 4 ? `空${i + 1}` : letter[i]}：
                                    <span>{x[1]}</span>
                                </div>
                            })}</div>
                        </div>
                        <div>
                            <span>题目分类</span>
                            <div>{detail.classify}</div>
                        </div>
                        <div>
                            <span>题目解析</span>
                            <div>{detail.parse}</div>
                        </div>
                        <div>
                            <span>知识点</span>
                            <div className="tag-know">
                                {
                                    detail.keyword_list &&
                                    detail.keyword_list.map((val, key) => {
                                        return <Tag key={"key" + key} color="blue" > {val}</Tag>
                                    })
                                }

                            </div>
                        </div>
                        {
                            detail.status === 1 && [
                                <div key="2">
                                    <span>提交用户</span>
                                    <div>
                                        {detail.username}
                                    </div>
                                </div>,
                                <div key="3">
                                    <span>提交时间</span>
                                    <div>
                                        {detail.sub_time}
                                    </div>
                                </div>
                            ]
                        }
                    </div>
                </div>
            </div>
            {
                reminderShow &&
                <Reminder
                    close={() => setReminderShow(false)}
                />
            }
        </div>
    )
}
export default CtfDetail;