import React,{useEffect} from 'react';
import BuyGold from '../../images/buy-gold.png';
import instance from '../Communal/instance';
import { Alert } from '../Communal/utility';

function BuyCtf(param){
    useEffect(()=>{
        document.body.style.overflow="hidden";
        return ()=>{
            document.body.style.overflow="auto";
        }
    })
    let buyCtf=()=>{
        instance.post(param.url).then(data=>{
            Alert("success","购买成功");
            param.getDetail();
            param.close();
        }).catch(err=>{
            Alert("error",err.error)
        })
    }
    return (
        <div className="buy-ctf-detail">
            <div className="content">
                <img src={BuyGold} alt=""/>
                <div className="cost">
                    本资源价值<span>{param.coin}</span>奖励，是否购买？
                </div>
                <div className="btn">
                    <span onClick={param.close}>放弃</span>
                    <span onClick={buyCtf}>购买</span>
                </div>
            </div>
        </div>
    )
}
export default BuyCtf;