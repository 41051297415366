import React, { useEffect,useState } from 'react';
import { Button,Input,Select, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Denied from '../../../../images/denied.png';
import PassBox from '../../../Activity/PassBox';
import DeclineBox from '../../../Activity/DeclineBox';
import AddedBox from '../../../Activity/AddedBox';
import history from '../../../Communal/history';
import instance from '../../../Communal/instance';
import { Alert } from '../../../Communal/utility';
import Retrieval from '../../../Activity/retrieval';
const { TextArea } = Input;
const { Option } = Select;

const ElementForm=({param})=>{
    const [type,setType]=useState("0"); //type: 0 添加 1 编辑 2 审核
    const [submit,setSubmit] = useState({
        name:'',
        desc:'',
        classify:undefined,
        photo:''
    });
    const [retrievalBox,setRetrievalBox]=useState({
        show:false,
        type:0,
        count:0
    });
    const [initBox,setInitBox]=useState({
        passBox:false,
        declineBox:false,
        addedBox:false
    })
    const [submitLoading,setSubmitLoading]=useState(false);
    const [loading,setLoading]=useState(false);
    const [calssDesc,setClassDesc] = useState({
        name:'',
        desc:''
    })
    const [error,setError] = useState({
        name:[],
        desc:[],
        classify:[],
        photo:[]
    });
    const [classify,setClassify] = useState([]);
    useEffect(()=>{
        setType(param.match.params.type);
        getClassData();
        if(param.match.params.id!=='0'){
            getDetail(param.match.params.id)
        }
        document.getElementsByClassName('submit-ctf')[0].style.minHeight='calc(100vh - 40px)';
    },[param]);
    useEffect(()=>{
        for(let i=0;i<classify.length;i++){
            if(classify[i].id===submit.classify){
                calssDesc.name=classify[i].name;
                calssDesc.desc=classify[i].desc;
            }
        }
        setClassDesc({...calssDesc})
    },[classify,submit.classify])
    const getDetail=(id)=>{
        instance.get(`/scene_obj/${id}/`).then(res=>{
            setSubmit(res);
        })
    }
    const getClassData=()=>{
        instance.get('/scene_obj/classify/').then(res=>{
            setClassify(res);
        })
    }
    let inputChange=(e,name)=>{
        submit[name]=e.target.value;
        setSubmit({...submit})
    }
    let handleFouce=(name)=>{
        error[name]=[]
        setError({...error})
    }
    //点击检索按钮进行检索，根据类型判断 检索的字段
    let retrievalClick=(type,text)=>{
        if(text.replace(/(^\s*)|(\s*$)/g, "")===""){
            Alert("warn","请输入检索内容");
            return
        }
        let list={
            type:"scene_obj",
            flag:text,
            search_type:type
        }
        instance.get("/ctf/search/",{params:list}).then(data=>{
            retrievalBox.show=true;
            retrievalBox.count=data.count;
            retrievalBox.type=type;
            setRetrievalBox({...retrievalBox})
        })
    }
    let handleChange=(key,option)=>{
        submit.classify=key;
        setClassDesc({
            desc:option.data_desc,
            name:option.data_name
        })
        setSubmit({...submit})
    }
    let beforeUpload=(file)=>{
        setLoading(true)
        const fd = new FormData();
        fd.append('image', file);
        fd.append("model","scene_obj");
        instance.post("/ctf/upload_image/",fd).then(res=>{
            setSubmit({...submit,photo:res.url});
            setLoading(false)
        }).catch(err=>{
            if(err.error){
                Alert('error',err.error)
            }
            if(err.file){
                Alert('error',err.file)
            }
        })
        return false
    }
    let commitResources=(type)=>{
        setSubmitLoading(true)
        if(submit.name===''){
            error.name=['请输入对象名称']
        }
        if(submit.desc===''){
            error.desc=['请输入对象描述']
        }
        if(!submit.classify){
            error.classify=['请选择对象分类']
        }
        if(submit.name===''||submit.desc===''||!submit.classify){
            setError({...error});
            Alert('warn', '提交内容有误，请检查！')
            setSubmitLoading(false)
            return
        }
        let list={
            name:submit.name,
            desc:submit.desc,
            classify:submit.classify,
            photo:submit.photo
        }
        if(type===1){
            instance.post('/scene_obj/add/',list).then(res=>{
                Alert("success","提交成功");
                history.push({pathname:'/resource/sceneelement',state:{tabKey:1}})
                setSubmitLoading(false)
            }).catch(err=>{
                setSubmitLoading(false);
                errorInfo(err);
            })
        }else if(type===2){
            instance.patch(`/scene_obj/${param.match.params.id}/`,list).then(res=>{
                Alert("success","提交成功");
                history.push({pathname:'/resource/sceneelement',state:{tabKey:1}})
                setSubmitLoading(false)
            }).catch(err=>{
                setSubmitLoading(false);
                errorInfo(err);
            })
        }
    }
    const errorInfo=(err)=>{
        if(err.error){
            Alert('error',err.error) 
        }
        if(err.name){
            error.name=err.name
        }
        if(err.desc){
            error.desc=err.desc
        }
        if(err.classify){
            error.classify=err.classify
        }
        if(err.photo){
            error.photo=err.photo
        }
        Alert('warn', '提交内容有误，请检查！')
        setError({...error})
    }
    let handleAudit=(data)=>{
        if (data.status === 1) {
            if (data.data === "") {
                Alert("error", "请输入奖励奖励数");
                return
            }
        }
        if ((data.status === 2 || data.status === 3) && data.data === "") {
            Alert("error", "请输入审核意见");
            return
        }
        instance.post("/scene_obj/" + param.match.params.id + "/audit/", data).then(data => {
            Alert("success", "操作成功");
            history.push({pathname:'/resource/sceneelement',state:{tabKey:1}})
        }).catch(err => {
            Alert("error", err.error)
        })
    }
    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )
    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-div submit-ctf-name">
                    {
                        type==="0"&&"提交场景对象"
                    }
                    {
                         type==="1"&&"编辑场景对象"
                    }
                    {
                        type==="2"&&"审核场景对象"
                    }
                    {
                        submit.status===3&&
                        <div className="status">
                            <img src={Denied} alt=""/>
                        </div>
                    }
                 </div>
                 {
                    submit.is_show_opin&&submit.opin&&submit.opin.length>0&&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{borderBottom:"1px solid #f4f4f4"}}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    submit.opin&&
                                    submit.opin.map((val,key)=>{
                                        return <div key={key+"key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">{val.msg}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div subject-info">
                    <div className="title">
                        场景对象信息
                    </div>
                    <div className="content">
                        <div className="retrieval-div">
                            <span><b>*</b>对象名称</span>
                            <Input 
                                value={submit.name}
                                className="content-input" 
                                placeholder="请输入对象名称"
                                onChange={(e)=>inputChange(e,"name")}
                                maxLength="15"
                                onFocus={()=>handleFouce("name")}
                            />
                            <span className="number">{15-submit.name.length}</span>
                            <Button type="primary" onClick={()=>retrievalClick(1,submit.name)}>检索</Button>
                            <div className="error-message">{error.name[0]}</div>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>对象描述</span>
                            <TextArea  
                                value={submit.desc}
                                className="content-input" 
                                placeholder="请输入对象描述"
                                onChange={(e)=>inputChange(e,"desc")}
                                // autoSize
                                maxLength="1000"
                                onFocus={()=>handleFouce("desc")}
                            />
                            <span className="number">{1000-submit.desc.length}</span>
                            <Button type="primary" onClick={()=>retrievalClick(2,submit.desc)}>检索</Button>
                            <div className="error-message">{error.desc[0]}</div>
                        </div>
                        <div>
                            <span><b>*</b>对象分类</span>
                            <Select 
                                value={submit.classify} 
                                placeholder='请选择对象分类'
                                style={{ width: 180}} 
                                onChange={handleChange}
                                onClick={()=>handleFouce("classify")}
                            >
                                {
                                    classify.map((val,key)=>{
                                        return  <Option 
                                            key={key+"key"}
                                            value={val.id}
                                            data_desc={val.desc}
                                            data_name={val.name}
                                        >
                                            {val.name}
                                        </Option>
                                    })
                                } 
                            </Select>
                            {
                                calssDesc.name!==''&&
                                <div className='class-desc'>{calssDesc.name}：{calssDesc.desc}</div>
                            }
                            <div className="error-message">{error.classify[0]}</div>
                        </div>
                        <div>
                            <span>对象图标</span>
                            <Upload
                                name="photo"
                                accept='.jpg, .jpeg, .png'
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                            >
                                {submit.photo!=='' ? <img src={submit.photo} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                            <div className='element-photo'>
                                图片仅支持PNG、JPG、JPEG，尺寸比例限制：1:1
                            </div>
                        </div>
                    </div>
                </div>
                {
                    type==="0"&&
                    <div className="submit-btn">
                        <Button type="primary" loading={submitLoading} onClick={()=>commitResources(1)}>提交</Button>
                    </div>
                }
                {
                    type==="1"&&
                    <div className="submit-btn">
                        <Button type="primary" loading={submitLoading} onClick={()=>commitResources(2)}>提交</Button>
                    </div>
                }
                {
                    type==="2"&&
                    <div className="submit-btn">
                        <Button 
                            type="primary" 
                            onClick={()=>{
                                initBox.passBox=true;
                                setInitBox({...initBox})
                            }}
                        >
                            通过
                        </Button>
                        <Button 
                            style={{backgroundColor:"transparent",color: "#266cff"}} 
                            type="primary"
                            onClick={()=>history.push({pathname:'/resource/sceneelement',state:{tabKey:1}})}
                        >
                            取消
                        </Button>
                        <Button className="modify" loading={submitLoading} onClick={()=>commitResources(2)}>保存修改</Button>
                        <Button 
                            className="decline"
                            onClick={()=>{
                                initBox.declineBox=true;
                                setInitBox({...initBox})
                            }}
                        >
                            拒绝
                        </Button>
                        <Button 
                            className="added"
                            onClick={()=>{
                                initBox.addedBox=true;
                                setInitBox({...initBox})
                            }}
                        >
                            待补充
                        </Button>
                        <Button 
                            className="added"
                            onClick={()=>handleAudit({status:0})}
                        >
                            待审核
                        </Button>
                    </div>
                }
            </div>
            {
                initBox.passBox&&
                <PassBox 
                    close={()=>{
                        initBox.passBox=false;
                        setInitBox({...initBox})
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.declineBox&&
                <DeclineBox 
                    close={()=>{
                        initBox.declineBox=false;
                        setInitBox({...initBox})
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.addedBox&&
                <AddedBox 
                    close={()=>{
                        initBox.addedBox=false;
                        setInitBox({...initBox})
                    }}
                    click={handleAudit}
                />
            }
            {
                retrievalBox.show&&
                <Retrieval 
                    close={()=>{
                        retrievalBox.show=false;
                        setRetrievalBox({...retrievalBox})
                    }}
                    count={retrievalBox.count}
                    name={
                        {
                            1:"对象名称",
                            2:"对象描述"
                        }[retrievalBox.type]
                    }
                />
            }
        </div>
    )
}
export default ElementForm;