/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Input, Table, Button, Pagination } from 'antd';
import instance from '../Communal/instance';
const { Search } = Input;
const { Column } = Table;
function MySubmit(param) {
    const [initData, setInitData] = useState({
        data: [],
        allCount: 0
    })
    const [filter, setFilter] = useState(
        {
            page: 1,
            size: 10,
            search: "",
            classify: [],
            status: [],
            ordering: ""
        }
    );
    const [sortedInfo, setSortedInfo] = useState({})
    const [filteredInfo, setFilteredInfo] = useState({
        classify_name: [],
        status: []
    });
    const [classify, setClassify] = useState([]);
    let handleTableChange = (pagination, filters, sorter) => {
        if (filters.classify_name) {
            filter.classify = filters.classify_name;
            filteredInfo.classify_name = filters.classify_name
        } else {
            filter.classify = [];
            filteredInfo.classify_name = [];
        }
        if (filters.status) {
            filter.status = filters.status;
            filteredInfo.status = filters.status;
        } else {
            filter.status = [];
            filteredInfo.status = [];
        }
        if (sorter.order) {
            if (sorter.columnKey === "is_env") {
                if (sorter.order === "ascend") {
                    filter.ordering = "is_env"
                } else {
                    filter.ordering = "-is_env"
                }
            } else if (sorter.columnKey === "coin") {
                if (sorter.order === "ascend") {
                    filter.ordering = "coin"
                } else {
                    filter.ordering = "-coin"
                }
            }
        } else {
            filter.ordering = ""
        }
        filter.page = 1;
        setFilter({ ...filter });
        setSortedInfo(sorter);
        setFilteredInfo({ ...filteredInfo })
    }
    useEffect(() => {
        if (param.type) {
            getSubmit();
        }
    }, [filter, param]);
    useEffect(() => {
        if (param.type) {
            instance.get("/ctf/classify/?type=" + param.type).then(data => {
                if (data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        data[i].text = data[i].name;
                        data[i].value = data[i].id
                    }
                }
                setClassify(data)
            })
        }
    }, [param])
    //获取提交列表
    let getSubmit = () => {
        let list = {
            activity_id: param.id,
            page: filter.page,
            size: filter.size,
            search: filter.search
        }
        if (filter.classify.length > 0) {
            list.classify = filter.classify
        }
        if (filter.status.length > 0) {
            list.status = filter.status
        }
        if (filter.ordering !== "") {
            list.ordering = filter.ordering
        }
        let url = "";
        if (param.type === 1) {
            url = "/activity/submission/";
        } else if (param.type === 2) {
            url = "/experiment/submit/";
        } else if (param.type === 3) {
            url = "/vuln/submit/";
        } else if (param.type === 4) {
            url = "/tool/submit/";
        } else if (param.type === 6) {
            url = "/activity/awd/submission/";
        }
        instance.get(url, { params: list }).then(data => {
            initData.data = data.results;
            initData.allCount = data.count;
            setInitData({ ...initData })
        })
    }
    let hrefDetail = (id) => {
        if (param.type === 1) {
            window.open("/#/activity/ctfdetail/1/" + id)
        } else if (param.type === 2) {
            window.open("/#/activity/expdetail/1/" + id)
        } else if (param.type === 3) {
            window.open("/#/activity/vulndetail/1/" + id)
        } else if (param.type === 4) {
            window.open("/#/activity/tooldetail/1/" + id)
        } else if (param.type === 6) {
            window.open("/#/activity/awddetail/1/" + id)
        }
    }
    let editTopic = (id) => {
        if (param.type === 1) {
            window.location.href = "/#/activity/submitctf/" + param.id + "/1/" + id
        } else if (param.type === 2) {
            window.location.href = "/#/activity/submitexp/" + param.id + "/1/" + id
        } else if (param.type === 3) {
            window.location.href = "/#/activity/submitvuln/" + param.id + "/1/" + id
        } else if (param.type === 4) {
            window.location.href = "/#/activity/submittool/" + param.id + "/1/" + id
        } else if (param.type === 6) {
            window.location.href = "/#/activity/submitawd/" + param.id + "/1/" + id
        }
    }
    return (
        <div className="activity-my-submit">
            <div className="table-header">
                总计：共<span>{initData.allCount}</span>个素材
                <Search
                    placeholder="请输入关键字"
                    value={filter.value}
                    style={{ width: 200 }}
                    className="search"
                    onChange={(e) => {
                        filter.search = e.target.value;
                        filter.page = 1;
                        setFilter({ ...filter })
                    }}
                />
            </div>
            <Table
                dataSource={initData.data}
                rowKey={record => record.id}
                bordered
                onChange={handleTableChange}
            >
                {
                    param.type !== 4 && <>
                        <Column
                            title="序号"
                            key="serial"
                            align="center"
                            render={(text, record, index) => {
                                return filter.size * (filter.page - 1) + index + 1
                            }}
                            width="8%"
                        />
                        <Column
                            title={
                                {
                                    1: "题目标题",
                                    2: "实验名称",
                                    3: "漏洞名称",
                                    6: "题目标题",
                                }[param.type]
                            }
                            key="name"
                            align="center"
                            dataIndex="name"
                        />
                        <Column
                            title={
                                {
                                    1: "题目分类",
                                    2: "实验分类",
                                    3: "漏洞分类",
                                    6: "题目分类",
                                }[param.type]
                            }
                            key="classify_name"
                            align="center"
                            dataIndex="classify_name"
                            filters={classify}
                            filteredValue={filteredInfo.classify_name || null}
                            width="10%"
                        />
                        {
                            param.type !== 6 && <Column
                                title="环境"
                                key="is_env"
                                align="center"
                                dataIndex="is_env"
                                sorter={true}
                                sortOrder={sortedInfo.columnKey === 'is_env' && sortedInfo.order}
                                width="8%"
                            />
                        }

                        <Column
                            title="审核状态"
                            key="status"
                            align="center"
                            dataIndex="status"
                            width="10%"
                            filters={[
                                { text: "待审核", value: "0" },
                                { text: "通过", value: "1" },
                                { text: "待补充", value: "2" },
                                { text: "已拒绝", value: "3" }
                            ]}
                            filteredValue={filteredInfo.status || null}
                            render={(record) => {
                                return <span className="audit-status">
                                    {
                                        record === 0 && "待审核"
                                    }
                                    {
                                        record === 1 && <span className="pass">通过</span>
                                    }
                                    {
                                        record === 2 && <span className="added">待补充</span>
                                    }
                                    {
                                        record === 3 && <span className="denied">已拒绝</span>
                                    }
                                </span>
                            }}
                        />
                        <Column
                            title="奖励"
                            key="coin"
                            align="center"
                            dataIndex="coin"
                            sorter={true}
                            sortOrder={sortedInfo.columnKey === 'coin' && sortedInfo.order}
                            width="8%"
                        />
                        <Column
                            title="提交时间"
                            key="sub_time"
                            align="center"
                            dataIndex="sub_time"
                            width="15%"
                        />
                        <Column
                            title="操作"
                            key="operate"
                            align="center"
                            width="15%"
                            render={(record) => {
                                return <span className="table-operation">
                                    <Button type="link" onClick={() => hrefDetail(record.id)}>详情</Button>
                                    <b></b>
                                    {
                                        record.status === 1 ?
                                            <Button type="link" disabled>编辑</Button>
                                            : <Button type="link" onClick={() => editTopic(record.id)}>编辑</Button>
                                    }
                                </span>
                            }}
                        />
                    </>
                }
                {
                    param.type === 4 && <>
                        <Column
                            title="序号"
                            key="serial"
                            align="center"
                            render={(text, record, index) => {
                                return filter.size * (filter.page - 1) + index + 1
                            }}
                            width="10%"
                        />
                        <Column
                            title='工具名称'
                            key="name"
                            align="center"
                            dataIndex="name"
                            width='20%'
                        />
                        <Column
                            title='工具分类'
                            key="classify_name"
                            align="center"
                            dataIndex="classify_name"
                            filters={classify}
                            filteredValue={filteredInfo.classify_name || null}
                            width="15%"
                            render={(text, record) => {
                                return <span>{record.classify_name} / {record.sec_classify_name}</span>
                            }}
                        />
                        <Column
                            title="审核状态"
                            key="status"
                            align="center"
                            dataIndex="status"
                            width="15%"
                            filters={[
                                { text: "待审核", value: "0" },
                                { text: "通过", value: "1" },
                                { text: "待补充", value: "2" },
                                { text: "已拒绝", value: "3" }
                            ]}
                            filteredValue={filteredInfo.status || null}
                            render={(record) => {
                                return <span className="audit-status">
                                    {
                                        record === 0 && "待审核"
                                    }
                                    {
                                        record === 1 && <span className="pass">通过</span>
                                    }
                                    {
                                        record === 2 && <span className="added">待补充</span>
                                    }
                                    {
                                        record === 3 && <span className="denied">已拒绝</span>
                                    }
                                </span>
                            }}
                        />
                        <Column
                            title="奖励"
                            key="coin"
                            align="center"
                            dataIndex="coin"
                            sorter={true}
                            sortOrder={sortedInfo.columnKey === 'coin' && sortedInfo.order}
                            width="10%"
                        />
                        <Column
                            title="提交时间"
                            key="sub_time"
                            align="center"
                            dataIndex="sub_time"
                            width="15%"
                        />
                        <Column
                            title="操作"
                            key="operate"
                            align="center"
                            width="15%"
                            render={(record) => {
                                return <span className="table-operation">
                                    <Button type="link" onClick={() => hrefDetail(record.id)}>详情</Button>
                                    <b></b>
                                    {
                                        record.status === 1 ?
                                            <Button type="link" disabled>编辑</Button>
                                            : <Button type="link" onClick={() => editTopic(record.id)}>编辑</Button>
                                    }
                                </span>
                            }}
                        />
                    </>
                }
            </Table>
            <Pagination
                showQuickJumper={true}
                current={filter.page}
                pageSize={filter.size}
                total={initData.allCount}
                // total={100}
                pageSizeOptions={['10', '20', '50', '100']}
                showSizeChanger={true}
                className="flip-over"
                style={{ margin: "30px 0" }}
                onChange={
                    ((page, pageSize) => {
                        let data = { ...filter }
                        data.page = page;
                        data.size = pageSize;
                        setFilter(data)
                    })
                }
            />
        </div>
    )
}
export default MySubmit;