//活动详情
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import Underway from '../../images/underway-icon.png';
import MySubmit from './mySubmit';
import Ranking from './ranking';
import instance from '../Communal/instance';
import { useMappedState } from "redux-react-hook";
function Detail(param) {
    const [detail, setDetail] = useState({})
    const [type, setType] = useState(1);
    useEffect(() => {
        if (param.param.location.state && param.param.location.state.where) {
            setType(2)
        }
        instance.get("/activity/" + param.param.match.params.id + "/").then(data => {
            setDetail(data)
        })
    }, [])
    // 拿到公共的值---实时变化
    const mapstate = useCallback((state) => ({
        login: state.login,
        userInfo: state.userInfo
    }), []);
    //获取数据并订阅存储
    const loginInfo = useMappedState(mapstate);
    let submitResource = () => {
        if (detail.type === 1) {
            window.open("/#/activity/submitctf/" + param.param.match.params.id + "/0/0")
        } else if (detail.type === 2) {
            window.open("/#/activity/submitexp/" + param.param.match.params.id + "/0/0")
        } else if (detail.type === 3) {
            window.open("/#/activity/submitvuln/" + param.param.match.params.id + "/0/0")
        } else if (detail.type === 4) {
            window.open("/#/activity/submittool/" + param.param.match.params.id + "/0/0")
        } else if (detail.type === 6) {
            window.open("/#/activity/submitawd/" + param.param.match.params.id + "/0/0")
        }
    }
    return (
        <div className="activity-detail">
            <div className="banner"></div>
            <div className="content-container activity-detail-content">
                {
                    detail.activity_status === 0 &&
                    <img src={Underway} alt="" />
                }

                <div className="current-position">
                    当前位置：<span onClick={() => window.location.href = "/#/activity"}>活动中心</span>＞{detail.name}
                </div>
                <div className="name">
                    {detail.name}
                </div>
                <div className="time">
                    活动日期：{detail.start_time} - {detail.end_time}
                </div>
                <div className="intro">
                    {detail.intro}
                </div>
                {
                    loginInfo.userInfo.role_type !== 1 && detail.activity_status === 0 &&
                    <div className="submit-matter">
                        <button onClick={submitResource}>提交素材</button>
                    </div>
                }
                <div className="switch-label">
                    <span
                        className={type === 1 ? "active" : ""}
                        onClick={() => setType(1)}
                    >
                        活动介绍
                    </span>
                    <b></b>
                    <span
                        className={type === 2 ? "active" : ""}
                        onClick={() => setType(2)}
                    >
                        我的提交
                    </span>
                    <b></b>
                    <span
                        className={type === 3 ? "active" : ""}
                        onClick={() => setType(3)}
                    >
                        活动排行
                    </span>
                </div>
                <div className="detail-show">
                    {
                        type === 1 &&
                        <div className="introduce" dangerouslySetInnerHTML={{ __html: detail.detail }}></div>
                    }
                    {
                        type === 2 &&
                        <MySubmit
                            id={param.param.match.params.id}
                            type={detail.type}
                        />
                    }
                    {
                        type === 3 &&
                        <Ranking id={param.param.match.params.id} />
                    }
                </div>
            </div>
        </div>
    );
}

export default Detail;