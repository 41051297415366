/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '../../../css/ctfResource.css';
import { Button, Table, Input, Pagination, Divider, Dropdown, Menu } from 'antd'
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import instance from '../../Communal/instance';
import { Alert } from '../../Communal/utility';
import DeteleBox from '../deteleBox';
import Comfirm from '../comfirm';

function List({ keyword }) {
    const [stationsData, setStationsData] = useState(
        {
            data: [],//表格数据
            allCount: 0,//表格数据长度
        }
    )
    const [filter, setFilter] = useState(
        {
            page: 1,
            size: 10,
            search: "",
            status: [],
            enterprise_name: "",
            name: "",
        }
    )
    const [checkList, setCheckList] = useState([])
    const [deleteInfo, setDeleteInfo] = useState(
        {
            show: false,
            list: [],
            listetp: []
        }
    )
    const [userType, setUserType] = useState(0);
    const [userIssue, setIssue] = useState(false);
    const [comfirmShow, setComfirmShow] = useState({
        visible: false,
        mode: 'station',
        issue: '',
    })
    useEffect(() => {
        instance.get("/ctf/user/type/?mode=station").then(data => {
            setUserType(data.user_type);
            setIssue(data.issue);
        })
    }, [])
    useEffect(() => {
        handelSearch(keyword)
    }, [keyword])

    useEffect(() => {
        getRecruit()
    }, [filter])
    let getRecruit = () => {
        instance.get("/recruit/stations/", { params: filter }).then(data => {
            stationsData.data = data.results
            stationsData.allCount = data.count
            setStationsData({ ...stationsData })
        })
    }
    // 点击搜索
    const handelSearch = (value) => {
        if (value !== filter.search) {
            filter.search = value;
            filter.page = 1;
            setFilter({ ...filter })
        }
    }
    // 表格改动
    const handleTableChange = (pagination, filters, sorter) => {
        if (filters.status) {
            filter.status = filters.status
        } else {
            filter.status = []
        }
        if (filters.issue) {
            filter.issue = filters.issue;
        } else {
            filter.issue = [];
        }
        filter.page = 1
        setFilter({ ...filter });
    }
    // 删除
    const resourceDelete = (deleteInfo) => {
        let list = {
            type: 'Station',
            id: deleteInfo.list
        }
        instance.post(`/recruit/delete/`, { ...list }).then(data => {
            if (data.error) {
                Alert('warn', data.error)
                return
            }
            if (filter.page > 1 && (stationsData.data.length === list.id.length)) {
                filter.page -= 1;
                setFilter({ ...filter })
            }
            else {
                // 更新数据
                getRecruit()
            }
            Alert("success", "删除成功");
            setCheckList([]);
            deleteInfo.list = [];
            deleteInfo.listetp = [];
            deleteInfo.show = false;
            setDeleteInfo({ ...deleteInfo })
        })
    }
    const rowSelection = {
        selectedRowKeys: checkList,
        preserveSelectedRowKeys:true,
        onChange: (selectedRowKeys, selectedRows) => {
            setCheckList(selectedRowKeys)
        }
    }
    const columns = [
        {
            title: '序号',
            key: 'id',
            width: 80,
            fixed: 'left',
            align: 'center',
            // 递增序号
            render: (text, record, index) => (
                <span
                    className="click-href"
                    onClick={() => {
                        window.location.href = "/#/activity/recruitdetail/" + record.id
                    }}
                >
                    {filter.size * (filter.page - 1) + index + 1}
                </span>
            )
        }, {
            title: '岗位编号',
            key: 'number',
            dataIndex: 'number',
            align: 'center',
            width: 150,
            fixed: 'left',
            render: (text, record) => (
                text ? <span
                    className="click-href"
                    onClick={() => {
                        window.location.href = "/#/activity/recruitdetail/" + record.id
                    }}
                >
                    {text}
                </span>
                    : "暂无编号"
            )
        }, {
            title: '岗位名称',
            align: 'center',
            key: 'name',
            dataIndex: 'name',
            width: 250,
            fixed: 'left',
            filterDropdown: ({ clearFilters }) => (
                <div className="table-search">
                    <Input
                        placeholder="请输入岗位名称"
                        value={filter.name}
                        onChange={(e) => {
                            filter.name = e.target.value;
                            filter.page = 1;
                            setFilter({ ...filter })
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => { clearFilters() }}
                    >
                        确定
                    </Button>
                    <Button onClick={() => {
                        clearFilters();
                        filter.name = "";
                        filter.page = 1;
                        setFilter({ ...filter })
                    }}>取消</Button>
                </div>
            ),
            filterIcon: <SearchOutlined />
        },
        {
            title: '岗位标签',
            align: 'center',
            key: 'label',
            dataIndex: 'label',
            width: 150,
            render: (text, record, index) => {
                return record.label + ' ';
            }
        },
        {
            title: '需求企业',
            align: 'center',
            key: 'enterprise_name',
            dataIndex: 'enterprise_name',
            width: 250,
            filterDropdown: ({ clearFilters }) => (
                <div className="table-search">
                    <Input
                        placeholder="请输入企业名称"
                        value={filter.enterprise_name}
                        onChange={(e) => {
                            filter.enterprise_name = e.target.value;
                            filter.page = 1;
                            setFilter({ ...filter })
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => { clearFilters() }}
                    >
                        确定
                    </Button>
                    <Button onClick={() => {
                        clearFilters();
                        filter.enterprise_name = "";
                        filter.page = 1;
                        setFilter({ ...filter })
                    }}>取消</Button>
                </div>
            ),
            filterIcon: <SearchOutlined />
        },
        {
            title: '审核状态',
            key: 'status',
            dataIndex: 'status',
            width: 150,
            align: 'center',
            filters: [
                { text: "待审核", value: "0" },
                { text: "通过", value: "1" },
                { text: "待补充", value: "2" },
                { text: "拒绝", value: "3" }
            ],
            render: (record) => {
                return <span className="audit-status">
                    {
                        record === "0" && "待审核"
                    }
                    {
                        record === "1" && <span className="pass">通过</span>
                    }
                    {
                        record === "2" && <span className="added">待补充</span>
                    }
                    {
                        record === "3" && <span className="denied">拒绝</span>
                    }
                </span>
            },
        }, {
            title: '发布状态',
            key: 'issue',
            dataIndex: 'issue',
            width: 150,
            filterMultiple:false,
            align: 'center',
            filters: [
                { text: "未发布", value: 1 },
                { text: "已发布", value: 2 },
            ],
            render: (record) => {
                return <span className="audit-status">
                    {
                        record === 1 && <span className="added">未发布</span>
                    }
                    {
                        record === 2 && <span className="pass">已发布</span>
                    }
                </span>
            },
        }, {
            title: '提交者',
            key: 'username',
            dataIndex: 'username',
            width: 150,
            align: 'center',
        }, {
            title: '提交时间',
            key: 'sub_time',
            dataIndex: 'sub_time',
            width: 200,
            align: 'center',
        }, {
            title: '操作',
            key: 'operate',
            dataIndex: 'operate',
            align: 'center',
            width: userType > 1 ? userIssue ? 260 : 210 : 160,
            fixed: 'right',
            render: (text, record) => {
                return <div>
                    <Button
                        style={{ padding: 0 }}
                        type="link"
                        onClick={() => {
                            window.location.href = "/#/activity/recruitdetail/" + record.id
                        }}
                    >
                        详情
                    </Button>
                    <Divider type='vertical' />
                    <Button
                        style={{ padding: 0 }}
                        disabled={!record.change}
                        type="link"
                        onClick={() => {
                            window.location.href = "/#/activity/submitRecruit/0/1/" + record.id
                        }}
                    >
                        编辑
                    </Button>
                    <Divider type='vertical' />
                    {
                        userType > 1 ? <>
                            <Button
                                style={{ padding: 0 }}
                                type="link"
                                onClick={() => {
                                    window.open("/#/activity/submitRecruit/0/2/" + record.id)
                                }}
                            >
                                审核
                            </Button>
                            <Divider type='vertical' />
                        </> : undefined
                    }
                    {
                        userIssue ? <>
                            {
                                record.issue === 1 ?
                                    <Button
                                        style={{ padding: 0 }}
                                        disabled={record.status !== '1'}
                                        type="link"
                                        onClick={() => {
                                            setComfirmShow({ ...comfirmShow, visible: true, issue: 2 });
                                            setCheckList([record.id])
                                        }}
                                    >
                                        发布
                                    </Button> :
                                    <Button
                                        style={{ padding: 0 }}
                                        type="link"
                                        danger
                                        onClick={() => {
                                            setComfirmShow({ ...comfirmShow, visible: true, issue: 1 });
                                            setCheckList([record.id])
                                        }}
                                    >
                                        回收
                                    </Button>
                            }
                            <Divider type='vertical' />
                        </> : undefined
                    }
                    <Button
                        type="link"
                        disabled={!record.change}
                        style={{ padding: 0 }}
                        danger
                        onClick={() => {
                            deleteInfo.show = true
                            deleteInfo.list = [record.id]
                            setDeleteInfo({ ...deleteInfo })
                        }}
                    >删除</Button>
                </div>
            }
        }
    ]
    const menu = (
        <Menu onClick={({ key }) => { changeStatus(key) }}>
            <Menu.Item key={1}>回收</Menu.Item>
            <Menu.Item key={2}>发布</Menu.Item>
        </Menu>
    );
    const changeStatus = (key) => {
        setComfirmShow({ ...comfirmShow, visible: true, issue: Number(key) });
    }
    const handleIssue = () => {
        setCheckList([]);
        setFilter({ ...filter });
    }
    return (
        <div className="ctf-resource-list" style={{ padding: 0 }}>
            <div className="ctf-resource-table content-container" style={{ marginTop: 0 }}>
                <div className="handle">
                    {
                        userType > 1 ?
                            <div style={{ display: 'inline-block' }}>
                                <Button
                                    type='ghost'
                                    disabled={checkList.length === 0 ? true : false}
                                    danger
                                    onClick={() => {
                                        deleteInfo.show = true;
                                        deleteInfo.list = checkList;
                                        setDeleteInfo({ ...deleteInfo })
                                    }}
                                >
                                    批量删除
                                </Button>
                                {
                                    userIssue ?
                                        <Dropdown
                                            overlay={menu}
                                            disabled={checkList.length === 0}
                                        >
                                            <Button type="primary" style={{ marginLeft: '10px' }}>批量修改发布状态<DownOutlined /></Button>
                                        </Dropdown> : undefined
                                }
                            </div> : undefined
                    }
                    <Button
                        type="primary"
                        onClick={() => window.open("/#/activity/submitRecruit/0/0/0")}
                        style={{ float: 'right' }}
                    >
                        提交岗位
                    </Button>
                </div>
                <Table
                    dataSource={stationsData.data}
                    rowKey={record => record.id}
                    bordered
                    scroll={{ x: 1200 }}
                    rowSelection={userType > 1 ? rowSelection : false}
                    onChange={handleTableChange}
                    style={{ marginTop: '15px' }}
                    columns={columns}
                    pagination={false}
                />
                <Pagination
                    showQuickJumper={true}
                    current={filter.page}
                    pageSize={filter.size}
                    total={stationsData.allCount}
                    hideOnSinglePage={stationsData.allCount === 0}
                    pageSizeOptions={['10', '20', '50', '100']}
                    showSizeChanger={true}
                    className="flip-over"
                    style={{ margin: "30px 0" }}
                    onChange={
                        ((page,pageSize) => {
                            let data = { ...filter }
                            data.page = page;
                            data.size = pageSize;
                            setFilter(data)
                        })
                    }
                />
            </div>
            {
                deleteInfo.show &&
                <DeteleBox
                    close={() => {
                        deleteInfo.show = false;
                        deleteInfo.list = [];
                        deleteInfo.listetp = [];
                        setDeleteInfo({ ...deleteInfo })
                    }}
                    submit={() => resourceDelete(deleteInfo)}
                />
            }
            <Comfirm
                boxProperty={comfirmShow}
                onClose={() => {
                    setComfirmShow({ ...comfirmShow, visible: false })
                }}
                onOk={handleIssue}
                ids={checkList}
            />
        </div>
    )
}
export default List;