import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input, Select, InputNumber, Row, Col } from 'antd';
import DeclineBox from '../../../Activity/DeclineBox';
import AddedBox from '../../../Activity/AddedBox';
import PassBox from '../../../Activity/PassBox';
import Retrieval from '../../../Activity/retrieval';
import history from '../../../Communal/history';
import { Alert } from '../../../Communal/utility';
import instance from '../../../Communal/instance';
import CommitModal from '../commitModal';
import Added from '../../../../images/added.png';
import AuditStatus from '../../../../images/audit-status.png';
import Denied from '../../../../images/denied.png';
import Pass from '../../../../images/pass.png';
import { debounce } from "lodash";
import { RulesHandling, RuleSplicing, RulesGenerate, stage, actions, deal, Ip, RuleMatching } from '../../../../utils/method';

const { Option } = Select;
const { TextArea } = Input;

const App = ({ param }) => {
    const [type, setType] = useState("0"); //type: 0 添加 1 编辑 2 审核
    const [ruleField, setRuleField] = useState({});
    const [submit, setSubmit] = useState({
        class_id: undefined,
        stage: [],
        desc: '',
        remarks: '',
        level: 4,
        content_read: ""
    });
    const [error, setError] = useState({
        class_id: [],
        stage: [],
        desc: [],
        remarks: [],
        content_read: [],
        level: [],
    });
    const [initBox, setInitBox] = useState({
        passBox: false,
        declineBox: false,
        addedBox: false
    })
    const [submitLoading, setSubmitLoading] = useState(false);
    const [ruleContent, setRuleContent] = useState({
        action: "alert",
        protocol: "udp",
        sourceIp: "any",
        sourcePort: "any",
        destinationIp: "any",
        destinationPort: "any",
        rev: 1,
        reference: '',
    })
    const [retrievalBox, setRetrievalBox] = useState({
        show: false,
        count: 0
    });
    const [commitBox, setCommitBox] = useState({
        show: false
    })
    const [classTypeList, setClassTypeList] = useState([])
    const [classType, setClassType] = useState(null)

    useEffect(() => {
        setType(param.match.params.type);
    }, [param.match.params.type])

    useEffect(() => {
        getClassType()
        if (param.match.params.type !== '0') {
            // 获取详情
            getDetail(param.match.params.id)
        }
    }, [])

    const getClassType = () => {
        instance.get('/rules/class/', { params: { status: 1, nopage: 1 } }).then(data => {
            if (Array.isArray(data)) {
                setClassTypeList(data)
            }
        })
    }

    const getDetail = (id) => {
        instance.get(`/rules/${param.match.params.id}/`).then(data => {
            let sidReg = /(?<=sid:).*?(?=;)/;
            let sid = '';
            if (sidReg.exec(data.content_read)) {
                sid = 'sid:' + sidReg.exec(data.content_read)?.[0] + ';'
            }
            data.content_read = data.content_read.replace(sid, '');
            let content = RuleMatching(data.content_read);
            submit.class_id = data.class_id
            submit.stage = data.stage
            submit.desc = data.desc || content.msg
            submit.remarks = data.remarks || content.msg
            submit.level = data.level
            submit.content_read = data.content_read
            submit.status = data.status
            ruleContent.reference = data.reference;
            setSubmit({ ...submit });
            setRuleContent({ ...ruleContent });
            setClassType(data.english_name)
            ruleContentChange(data.content_read)
            setRuleField(content)
        })
    }

    const inputChange = (value, name) => {
        submit[name] = value;
        setSubmit({ ...submit })
    }

    const contentChange = (value, name) => {
        ruleContent[name] = value;
        setRuleContent({ ...ruleContent })
    }

    const handleFocus = (name) => {
        error[name] = []
        setError({ ...error })
    }

    const CreateRules = () => {
        handleFocus('content_read')
        let headList = {}
        let list = {}
        if (ruleContent.action) {
            headList.action = ruleContent.action
        }
        if (ruleContent.protocol) {
            headList.protocol = ruleContent.protocol
        }
        if (ruleContent.sourceIp) {
            headList.sourceIp = ruleContent.sourceIp
        }
        if (ruleContent.sourcePort) {
            headList.sourcePort = ruleContent.sourcePort
        }
        if (ruleContent.destinationIp) {
            headList.destinationIp = ruleContent.destinationIp
        }
        if (ruleContent.destinationPort) {
            headList.destinationPort = ruleContent.destinationPort
        }
        if (ruleContent.rev) {
            list.rev = ruleContent.rev
        }
        if (ruleContent.reference) {
            list.reference = ruleContent.reference
        }
        if (classType !== undefined) {
            list.classtype = classType
        }
        if (submit?.stage.length >= 0) {
            list.metadata = submit.stage
        }
        if (submit.desc) {
            list.msg = submit.desc
        }
        let addList = {
            action: ruleContent.action,
            protocol: ruleContent.protocol,
            sourceIp: ruleContent.sourceIp,
            sourcePort: ruleContent.sourcePort,
            destinationIp: ruleContent.destinationIp,
            destinationPort: ruleContent.destinationPort,
            rev: ruleContent.rev,
            reference: ruleContent.reference,
            classType: classType,
            metadata: submit.stage,
            msg: submit.desc
        }
        let str = ''
        if (type === "0") {
            str = RuleSplicing(addList)
        }
        if (type === "1" || type === "2") {
            str = RulesGenerate(submit.content_read, headList, list, ruleField)
        }
        submit.content_read = str
        setSubmit({ ...submit })
    }

    const handleSearch = () => {
        if (submit.content_read === '') {
            Alert("error", "规则内容为空");
            return
        }
        instance.get("/rules/", { params: { content: submit.content_read } }).then(data => {
            retrievalBox.count = data.count
            retrievalBox.show = true
            setRetrievalBox({ ...retrievalBox })
        })
    }

    const changeFun = useCallback(value => {
        const list = RulesHandling(value)
        if (list.action) {
            ruleContent.action = list.action
        }
        if (list.destinationIp) {
            ruleContent.destinationIp = list.destinationIp
        }
        if (list.destinationPort) {
            ruleContent.destinationPort = list.destinationPort
        }
        if (list.protocol) {
            ruleContent.protocol = list.protocol
        }
        if (list.sourceIp) {
            ruleContent.sourceIp = list.sourceIp
        }
        if (list.sourcePort) {
            ruleContent.sourcePort = list.sourcePort
        }
        if (list.rev) {
            ruleContent.rev = list.rev
        }
        // if (list.metadata) {
        //     submit.stage = list.metadata.split('stage ');
        // }
        if (list.reference) {
            ruleContent.reference = list.reference
        }
        if (list.msg) {
            submit.desc = list.msg
        }
        setRuleContent({ ...ruleContent })
        // setSubmit({ ...submit })
    }, []);

    const debounceFun = useCallback(
        debounce((e) => changeFun(e), 1000, {
            leading: false,
            trailing: true
        }),
        [changeFun]
    );

    const ruleContentChange = useCallback(
        e => {
            debounceFun(e);
        },
        [debounceFun]
    );

    // 审核
    let handleAudit = (data) => {
        let list = {
            ids: param.match.params.id,
            status: data.status,
            data: data.data
        }
        if (data.status === 1) {
            if (data.data === "") {
                Alert("error", "请输入奖励数");
                return
            }
        }
        if ((data.status === 2 || data.status === 3) && data.data === "") {
            Alert("error", "请输入审核意见");
            return
        }
        instance.post("/rules/audit/", list).then(data => {
            Alert("success", data.msg);
            history.push({ pathname: '/resource/rule', state: { tabKey: 1 } })
            initBox.declineBox = false;
            initBox.addedBox = false;
            setInitBox({ ...initBox })
            if (data.status === 1) {
                window.close()
            }
        }).catch(err => {
            Alert("error", err.error)
        })
    }

    let handleAdd = () => {
        let list = {}
        list.rules_class = submit.class_id
        list.stage = submit.stage
        list.desc = submit.desc
        list.remarks = submit.remarks
        list.level = submit.level
        list.content = submit.content_read
        list.reference = ruleContent.reference
        const res = handleCommit()
        if (!res) {
            return
        }
        if (type == 0) {
            setSubmitLoading(true)
            instance.post("/rules/", list).then(data => {
                setSubmitLoading(false)
                Alert("success", "提交成功");
                history.push({ pathname: '/resource/rule', state: { tabKey: 1 } })
            }).catch(err => {
                errorInfo(err)
                setSubmitLoading(false)
                const { content } = err;
                error.content_read = content
                setError({ ...error })
                commitBox.show = false
                setCommitBox({ ...commitBox })
            })
        }
        if (type == 1 || type == 2) {
            if (param.match.params.id)
                setSubmitLoading(true)
            instance.patch(`/rules/${param.match.params.id}/`, list).then(data => {
                Alert("success", "编辑成功");
                setSubmitLoading(false)
                if (type == 1) {
                    history.push({ pathname: '/resource/rule', state: { tabKey: 1 } })
                } else {
                    commitBox.show = false
                    setCommitBox({ ...commitBox })
                }
            }).catch(err => {
                setSubmitLoading(false)
                errorInfo(err)
                const { content } = err;
                error.content_read = content
                setError({ ...error })
                commitBox.show = false
                setCommitBox({ ...commitBox })
            })
        }
    }

    const handleCommit = () => {
        if (submit.class_id === undefined) {
            error.class_id = ["请选择规则分类"]
        }
        if (submit.stage.length === 0) {
            error.stage = ['请选择规则阶段']
        }
        if (submit.desc === "") {
            error.desc = ["请输入规则描述"]
        }
        if (submit.remarks === "") {
            error.remarks = ["请输入规则备注"]
        }
        if (submit.content_read === "") {
            error.content_read = ["请输入规则内容或选择以上选项生成规则内容"]
        }
        if (submit.class_id === undefined ||
            submit.stage.length === 0 ||
            submit.desc === "" ||
            submit.remarks === "" ||
            submit.content_read === ""
        ) {
            setError({ ...error })
            return false
        }
        let content = submit.content_read
        let headArr = content.trim().split("(")[0].trim().split(" ")
        if (headArr.length !== 7) {
            error.content_read = ["规则内容格式错误，请检查"]
        }
        if (ruleContent.protocol === 'any') {
            error.content_read = ["协议字段错误，请修改"]
        }
        if (headArr.length !== 7 ||
            ruleContent.protocol === 'any'
        ) {
            setError({ ...error })
            return false
        }
        commitBox.show = true
        setCommitBox({ ...commitBox })
        return true
    }

    const errorInfo = (err) => {
        if (err.error) {
            Alert('error', err.error)
        }
        if (err.rules_class) {
            error.class_id = err.rules_class
        }
        if (err.stage) {
            error.stage = err.stage
        }
        if (err.desc) {
            error.desc = err.desc
        }
        if (err.remarks) {
            error.remarks = err.remarks
        }
        if (err.content_read) {
            error.content_read = err.content_read
        }
        if (err.level) {
            error.level = err.level
        }
        setError({ ...error })
    }

    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-div submit-ctf-name">
                    {
                        type === "0" && "提交规则"
                    }
                    {
                        type === "1" && "编辑规则"
                    }
                    {
                        type === "2" && "审核规则"
                    }
                    <div className="status">
                        {
                            submit.status === 0 &&
                            <img src={AuditStatus} alt="" />
                        }
                        {
                            submit.status === 1 &&
                            <img src={Pass} alt="" />
                        }
                        {
                            submit.status === 2 &&
                            <img src={Added} alt="" />
                        }
                        {
                            submit.status === 3 &&
                            <img src={Denied} alt="" />
                        }
                    </div>
                </div>
                {
                    submit.is_show_opin && submit.opin && submit.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    submit.opin &&
                                    submit.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">{val.msg}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div subject-info">
                    <div className="title">
                        规则信息
                    </div>
                    <div className="content">
                        <Row>
                            <Col span={8} className="retrieval-div">
                                <span className='name'><b>*</b>规则分类</span>
                                <Select
                                    className='width-select'
                                    value={submit.class_id}
                                    placeholder='请选择规则分类'
                                    style={{ width: 180 }}
                                    onChange={(value) => {
                                        inputChange(value, 'class_id')
                                        const items = classTypeList.filter((item) => {
                                            return item.id === value
                                        })
                                        setClassType(items[0].english_name)
                                    }
                                    }
                                    onClick={() => handleFocus("class_id")}
                                >
                                    {
                                        classTypeList.map((val, key) => {
                                            return <Option
                                                key={key}
                                                value={val.id}
                                            >
                                                {val.name}
                                            </Option>
                                        })

                                    }
                                </Select>
                                <div className="error-message">{error?.rules_class}</div>
                            </Col>
                            <Col span={16} className="retrieval-div">
                                <span className='name'><b>*</b>规则阶段</span>
                                <Select
                                    value={submit.stage}
                                    mode="multiple"
                                    placeholder='请选择规则阶段'
                                    style={{ width: '80%' }}
                                    onChange={(value) => inputChange(value, 'stage')}
                                    onClick={() => handleFocus("stage")}
                                >
                                    {
                                        stage.map((val, key) => {
                                            return <Option key={key} value={val.name}>
                                                {val.name}
                                            </Option>
                                        })

                                    }
                                </Select>
                                <div className="error-message">{error?.stage}</div>
                            </Col>
                        </Row>
                        <div className="retrieval-div">
                            <span><b>*</b>规则描述</span>
                            <Input
                                value={submit.desc}
                                className="content-input"
                                placeholder="请输入规则针对的威胁描述"
                                onChange={(e) => inputChange(e.target.value, "desc")}
                                maxLength="200"
                                onFocus={() => handleFocus("desc")}
                            />
                            <div className="error-message">{error?.desc[0]}</div>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>规则备注</span>
                            <Input
                                value={submit.remarks}
                                className="content-input"
                                placeholder="请输入规则备注"
                                onChange={(e) => inputChange(e.target.value, "remarks")}
                                maxLength="150"
                                onFocus={() => handleFocus("remarks")}
                            />
                            <div className="error-message">{error?.remarks}</div>
                        </div>
                        <div className="retrieval-div">
                            <span>参考资料</span>
                            <Input
                                value={ruleContent.reference}
                                className="content-input"
                                placeholder="请输入规则参考资料"
                                onChange={(e) => contentChange(e.target.value, 'reference')}
                                onClick={() => handleFocus("reference")}
                            />
                        </div>
                        <Row>
                            <Col span={8} className="retrieval-div">
                                <span className='name'>行为</span>
                                <Select
                                    className='width-select'
                                    value={ruleContent.action}
                                    placeholder='请选择行为'
                                    style={{ width: 180 }}
                                    onChange={(value) => contentChange(value, 'action')}
                                    onClick={() => handleFocus("action")}
                                >
                                    {
                                        actions.map((val, key) => {
                                            return <Option key={key} value={val.name}>
                                                {val.name}
                                            </Option>
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={8} className="retrieval-div">
                                <span className='name'>协议</span>
                                <Select
                                    className='width-select'
                                    value={ruleContent.protocol}
                                    placeholder='请选择协议'
                                    style={{ width: 180 }}
                                    onChange={(value) => contentChange(value, 'protocol')}
                                    onClick={() => handleFocus("protocol")}
                                >
                                    {
                                        deal.map((val, key) => {
                                            return <Option key={key} value={val.name}>
                                                {val.name}
                                            </Option>
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={8} className="retrieval-div">
                                <span className='name'>源地址</span>
                                <Select
                                    className='width-select'
                                    value={ruleContent.sourceIp}
                                    placeholder='请选择源地址'
                                    style={{ width: 180 }}
                                    onChange={(value) => contentChange(value, 'sourceIp')}
                                    onClick={() => handleFocus("sourceIp")}
                                >
                                    {
                                        Ip.map((val, key) => {
                                            return <Option key={key} value={val.name}>
                                                {val.name}
                                            </Option>
                                        })
                                    }
                                </Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8} className="retrieval-div">
                                <span className='name'>源端口</span>
                                <Input
                                    value={ruleContent.sourcePort}
                                    style={{ width: 180 }}
                                    className="content-input"
                                    placeholder="请输入源端口"
                                    onChange={(e) => contentChange(e.target.value, "sourcePort")}
                                    maxLength="150"
                                    onFocus={() => handleFocus("sourcePort")}
                                />
                            </Col>
                            <Col span={8} className="retrieval-div">
                                <span className='name'>目的地址</span>
                                <Select
                                    className='width-select'
                                    value={ruleContent.destinationIp}
                                    placeholder='请选择目的地址'
                                    style={{ width: 180 }}
                                    onChange={(value) => contentChange(value, 'destinationIp')}
                                    onClick={() => handleFocus("destinationIp")}
                                >
                                    {
                                        Ip.map((val, key) => {
                                            return <Option key={key} value={val.name}>
                                                {val.name}
                                            </Option>
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={8} className="retrieval-div">
                                <span className='name'>目的端口</span>
                                <Input
                                    value={ruleContent.destinationPort}
                                    className="content-input"
                                    style={{ width: 180 }}
                                    placeholder="请输入目的端口"
                                    onChange={(e) => contentChange(e.target.value, "destinationPort")}
                                    maxLength={150}
                                    onFocus={() => handleFocus("destinationPort")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8} className="retrieval-div">
                                <span className='name'>Rev</span>
                                <Input
                                    style={{ width: '180px' }}
                                    value={ruleContent.rev}
                                    className="content-input"
                                    placeholder="请输入关键字标识"
                                    onChange={(e) => contentChange(e.target.value, "rev")}
                                    maxLength="150"
                                    onFocus={() => handleFocus("rev")}
                                />
                            </Col>
                            <Col span={8} className="retrieval-div">
                                <span className='name'>等级</span>
                                <InputNumber
                                    style={{ width: 180 }}
                                    precision={0}
                                    min={0}
                                    // defaultValue={4}
                                    value={submit.level}
                                    className="content-input"
                                    // placeholder="请输入关键字标识"
                                    onChange={(value) => inputChange(value, "level")}
                                    onFocus={() => handleFocus("level")}
                                />
                                <div className="error-message">{error.level}</div>
                            </Col>
                            <Col span={8}></Col>
                        </Row>
                        <div className="retrieval-div">
                            <span></span>
                            <Button
                                type='primary'
                                onClick={CreateRules}
                            >
                                生成规则
                            </Button>
                        </div>

                        <div className="retrieval-div" style={{ color: '#FC7057' }}>
                            选择上面字段后点击【生成规则】按钮即可在下方看到想要添加的规则，如需要增加额外字段可在下面规则内容手动编辑后进行搜索是否存在，点击保存即可保存规则
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>规则内容</span>
                            <TextArea
                                maxLength={3000}
                                style={{ width: "calc(100% - 350px)" }}
                                autoSize
                                value={submit.content_read}
                                className="content-input"
                                placeholder="请输入规则内容或选择以上选项生成规则内容"
                                onChange={(e) => {
                                    inputChange(e.target.value, "content_read")
                                    ruleContentChange(e.target.value)
                                }}
                                onFocus={() => handleFocus("content_read")}
                            />
                            <Button
                                type='primary'
                                onClick={() => handleSearch()}
                            >
                                搜索
                            </Button>
                            <div className="error-message">{error?.content_read}</div>
                        </div>
                        <div className="retrieval-div" style={{ color: '#FC7057' }}>
                            提示：sid在审核时会自动生成，sid命名规则是，年份+月份+四位数，为校验规则，规则内容中无需填写sid
                        </div>
                    </div>
                </div>
                {
                    //新增
                    type === "0" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            loading={submitLoading}
                            onClick={() => {
                                handleCommit()
                            }}
                        >
                            提交
                        </Button>
                    </div>
                }
                {
                    //编辑
                    type === "1" &&
                    <div className="submit-btn">
                        <Button type="primary" loading={submitLoading} onClick={() => {
                            handleCommit()
                        }}>提交</Button>
                    </div>
                }
                {
                    type === "2" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => handleAudit({ status: 1, data: 1 })}
                        >
                            通过
                        </Button>
                        <Button
                            style={{ backgroundColor: "transparent", color: "#266cff" }}
                            type="primary"
                            onClick={() => history.push({ pathname: '/resource/rule', state: { tabKey: 1 } })}
                        >
                            取消
                        </Button>
                        <Button className="modify" loading={submitLoading} onClick={() => {
                            handleCommit()
                        }}>保存修改</Button>
                        <Button
                            className="decline"
                            onClick={() => {
                                initBox.declineBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            拒绝
                        </Button>
                        <Button
                            className="added"
                            onClick={() => {
                                initBox.addedBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            待补充
                        </Button>
                        <Button
                            className="added"
                            onClick={() => handleAudit({ status: 0 })}
                        >
                            待审核
                        </Button>
                    </div>
                }
            </div>
            {
                initBox.passBox &&
                <PassBox
                    close={() => {
                        initBox.passBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.declineBox &&
                <DeclineBox
                    close={() => {
                        initBox.declineBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.addedBox &&
                <AddedBox
                    close={() => {
                        initBox.addedBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                retrievalBox.show &&
                <Retrieval
                    close={() => {
                        retrievalBox.show = false;
                        setRetrievalBox({ ...retrievalBox })
                    }}
                    count={retrievalBox.count}
                    name={"规则内容"}
                />
            }
            <CommitModal
                commit={commitBox}
                resourceName={"规则"}
                loading={submitLoading}
                onOK={() => {
                    handleAdd()
                }}
                onClose={() => {
                    commitBox.show = false;
                    setCommitBox({ ...commitBox })
                }}
            />
        </div >
    )
}
export default App;