import React, { useState } from 'react';
import Search from 'antd/lib/input/Search';
import { Table, Button, Pagination } from 'antd';
const { Column } = Table;
function CtfSubmit() {
    const [data] = useState([]);
    const [filter, setFilter] = useState(
        {
            page: 1,
            size: 6
        }
    );
    const [sortedInfo, setSortedInfo] = useState({})
    const [filteredInfo] = useState({})
    let handleTableChange = (pagination, filters, sorter) => {
        setSortedInfo({ ...sorter })
    }
    return (
        <div className="resource-submit-list">
            <div className="title">提交插件题目</div>
            <div className="content">
                <div className="all-count">
                    总计：共<b>0</b>个素材
                    <Search
                        placeholder="请输入关键字"
                    />
                </div>
                <Table
                    dataSource={data}
                    rowKey={record => record.id}
                    bordered
                    pagination={false}
                    onChange={handleTableChange}
                >
                    <Column
                        title="序号"
                        key="number"
                        align="center"
                        render={(text, record, index) => {
                            return index + 1
                        }}
                    />
                    <Column
                        title="题目标题"
                        key="name"
                        align="center"
                        dataIndex="name"
                    />
                    <Column
                        title="题目类型"
                        key="type"
                        align="center"
                        dataIndex="type"
                        filters={
                            [
                                { text: "MySQL", value: "MySQL" },
                                { text: "Oracle", value: "Oracle" }
                            ]
                        }
                        filteredValue={filteredInfo.type || null}
                    />
                    <Column
                        title="环境"
                        key="ifFile"
                        align="center"
                        dataIndex="ifFile"
                        sorter="true"
                        sortOrder={sortedInfo.columnKey === 'ifFile' && sortedInfo.order}
                        render={(record) => {
                            return record ? "有" : "否"
                        }}
                    />
                    <Column
                        title="审核状态"
                        key="status"
                        align="center"
                        dataIndex="status"
                        filters={
                            [
                                { text: "MySQL", value: "MySQL" },
                                { text: "Oracle", value: "Oracle" }
                            ]
                        }
                        filteredValue={filteredInfo.status || null}
                        render={(record) => {
                            return <span className="audit-status">
                                {
                                    record === 0 && "待审核"
                                }
                                {
                                    record === 1 && <span className="pass">通过</span>
                                }
                                {
                                    record === 2 && <span className="added">待补充</span>
                                }
                                {
                                    record === 3 && <span className="denied">已拒绝</span>
                                }
                            </span>
                        }}
                    />
                    <Column
                        title="奖励"
                        key="gold"
                        align="center"
                        dataIndex="gold"
                        sorter="true"
                        sortOrder={sortedInfo.columnKey === 'gold' && sortedInfo.order}
                    />
                    <Column
                        title="提交时间"
                        key="time"
                        align="center"
                        dataIndex="time"
                    />
                    <Column
                        title="操作"
                        key="operate"
                        align="center"
                        render={(record) => {
                            return <span className="table-operation">
                                <Button type="link" onClick={() => window.open("/#/activity/ctfdetail/0/0/0")}>详情</Button>
                                <b></b>
                                {
                                    record.status === 1 ?
                                        <Button type="link" disabled>编辑</Button>
                                        : <Button type="link">编辑</Button>
                                }
                            </span>
                        }}
                    />
                </Table>
                {/* <Pagination
                    showQuickJumper={true}
                    current={filter.page}
                    pageSize={filter.size}
                    total={initData.allCount}
                    hideOnSinglePage={true}
                    className="flip-over"
                    style={{ margin: "30px 0" }}
                    onChange={
                        ((page) => {
                            let data = { ...filter }
                            data.page = page;
                            setFilter(data)
                        })
                    }
                /> */}
            </div>
        </div>
    )
}
export default CtfSubmit;