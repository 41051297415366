/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import '../../../css/submitCtf.css';
import '../../../css/box.css';
import { Button, Input, Select } from 'antd';
import instance from '../../Communal/instance';
import Retrieval from '../../Activity/retrieval';
import { Alert, srcUrl } from '../../Communal/utility';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import DeclineBox from '../../Activity/DeclineBox';
import AddedBox from '../../Activity/AddedBox';
import Denied from '../../../images/denied.png';
import { ContentUtils } from 'braft-utils'
const { Option } = Select;
const { TextArea } = Input;
function SubmitCTF(param) {
    //id===0管理员 type: 0 添加 1 编辑 2 审核
    const dateRef = useRef()
    const [type, setType] = useState("0");
    const [detail, setDetail] = useState({})
    const [submit, setSubmit] = useState(
        {
            name: '',
            label: [],//标签
            classify: undefined,
            treatment: '',//待遇
            education: '',//学历要求
            experience: '',//工作经验
            enterprise: null,//需求企业
            address: '',//工作地点
            source: '',//岗位来源
            content: BraftEditor.createEditorState(null),//岗位详情
        }
    )
    const [error, setError] = useState(
        {
            name: [],
            label: [],
            treatment: [],
            education: [],
            experience: [],
            enterprise: [],
            address: [],
            source: [],
            content: [],
        }
    )
    const [label, setLabel] = useState([])
    const [enterprise, setEnterprise] = useState([])
    const [imagesType, setImagesType] = useState(0);
    const [isSubmit, setIsSubmit] = useState(true);
    const [initBox, setInitBox] = useState(
        {
            passBox: false,
            declineBox: false,
            addedBox: false
        }
    )
    const [retrievalBox, setRetrievalBox] = useState({
        show: false,
        count: 0
    });
    useEffect(() => {
        setType(param.param.match.params.type);
    }, [param.param.match.params.type])
    useEffect(() => {
        if (param.param.match.params.type !== "0") {
            // 详情
            instance.get("/recruit/station/" + param.param.match.params.id + "/").then(data => {
                submit.name = data.name
                submit.label = data.label
                submit.treatment = data.treatment
                submit.education = data.education
                submit.experience = data.experience
                submit.enterprise = data.enterprise
                submit.address = data.address
                submit.source = data.source
                submit.content = BraftEditor.createEditorState(data.details)
                setSubmit({ ...submit })
                getLabel()
                setDetail(data)
            })
        } else {
            getLabel()
        }
    }, [])
    useEffect(() => {
        instance.get('/recruit/enterp_name/').then(data => {
            setEnterprise(data)
        })
    }, [])
    let getLabel = () => {
        instance.get("/recruit/labels/?type=1").then(data => {
            // submit.label = data.results
            // setSubmit({ ...submit })
            setLabel(data.results)
        })
    }
    // 表单元素改变
    const inputChange = (value, name) => {
        submit[name] = value;
        setSubmit({ ...submit })
        if (name === 'label') {
            if (submit.label.length > 5) {
                Alert('warn', '最多只能添加5个岗位标签')
                submit.label = submit.label.slice(0, 5)
                setSubmit({ ...submit })
                return
            }
        }
    }
    // 表单得到焦点去掉错误提示
    const handleFocus = (name) => {
        error[name] = []
        setError({ ...error })
    }
    //点击检索按钮进行检索，根据类型判断 检索的字段
    const retrievalClick = (type, text) => {
        if (text.trim() === "") {
            Alert('warn', '请输入岗位名称进行检索')
            return
        }
        let list = {
            type: 'station',
            flag: text,
            search_type: type
        }
        instance.get("/ctf/search/", { params: list }).then(data => {
            retrievalBox.count = data.count
            retrievalBox.show = true
            setRetrievalBox({ ...retrievalBox })
        })
    }
    // 插入图片到媒体库
    const addMediaItem = () => {
        document.getElementsByClassName("images")[0].click();
        document.getElementsByClassName("images")[0].value = "";
        setImagesType(0);
    }
    // 插入图片到编辑器
    const insertMediItem = () => {
        document.getElementsByClassName("images")[0].click();
        document.getElementsByClassName("images")[0].value = "";
        setImagesType(1);
    }
    // 上传图片
    const changeImage = () => {
        const file = document.getElementsByClassName("images")[0].files[0];
        if (file !== undefined) {
            if (file.size > 5 * 1024 * 1024) {
                Alert("error", "上传图片不能大于5M")
                return
            }
            const form = document.getElementById('file-image');
            const formData = new FormData(form);
            formData.append("model", "recruit");
            instance.post("/ctf/upload_image/", formData).then(data => {
                if (imagesType === 0) {
                    if (dateRef.current !== undefined) {
                        let braftFinder = dateRef.current.getFinderInstance();
                        braftFinder.addItems([
                            {
                                id: new Date().getTime(),
                                type: 'IMAGE',
                                url: data.url
                            }
                        ])
                    }
                } else if (imagesType === 1) {
                    const editorState = ContentUtils.insertMedias(submit.content, [
                        {
                            type: 'IMAGE',
                            url: data.url
                        }
                    ])
                    submit.content = editorState;
                    setSubmit({ ...submit })
                }
                Alert("success", "上传成功")
            }).catch(err => {
                if (err.image) {
                    Alert("error", err.image)
                }
            })
        }
    }
    // 接口错误提示
    const errorInfo = (err) => {
        if (err.name) {
            error.name = err.name
        }
        if (err.details) {
            error.content = err.details
        }
        if (err.source) {
            error.source = err.source
        }
        if (err.post_label) {
            error.label = err.post_label
        }
        if (err.address) {
            error.address = err.address
        }
        if (err.treatment) {
            error.treatment = err.treatment
        }
        setError({ ...error })
    }
    // 提交
    const commitResources = (actionType) => {
        if (srcUrl(submit.content.toHTML())) {
            Alert('warn', '岗位详情中图片存在外部链接，请检查！！！')
            return
        }
        if (!isSubmit) {
            Alert("warn", "正在提交数据，请稍后！")
            return
        }
        // 传入接口得数据
        const list = {
            name: submit.name,
            treatment: submit.treatment,
            education: submit.education,
            experience: submit.experience,
            address: submit.address,
            source: submit.source,
            details: submit.content.toHTML(),
            post_label: submit.label,
            enterprise: submit.enterprise
        }
        if (submit.name.trim() === '') {
            error.name = ["请输入岗位名称"]
        }
        if (submit.label.length === 0) {
            error.label = ['请添加岗位标签']
        }
        if (submit.treatment.trim() === '') {
            error.treatment = ['请填写岗位待遇']
        }
        if (submit.education.trim() === '') {
            error.education = ['请填写学历要求']
        }
        if (submit.experience.trim() === '') {
            error.experience = ['请填写工作经验']
        }
        if (submit.enterprise === null) {
            error.enterprise = ['请选择需求企业']
        }
        if (submit.address.trim() === '') {
            error.address = ['请填写工作地点']
        }
        if (submit.source.trim() === '') {
            error.source = ['请填写岗位来源']
        }
        if (submit.content.toHTML() === '<p></p>') {
            error.content = ['请填写岗位详情']
        }
        if (
            submit.name.trim() === "" ||
            submit.label.length === 0 ||
            submit.treatment.trim() === "" ||
            submit.education.trim() === "" ||
            submit.experience.trim() === "" ||
            submit.enterprise === null ||
            submit.address.trim() === "" ||
            submit.source.trim() === "" ||
            submit.content.toHTML() === '<p></p>'
        ) {
            setError({ ...error })
            return
        }
        setIsSubmit(false)
        // 提交
        if (actionType === 1) {
            instance.post("/recruit/stations/", list).then(data => {
                Alert("success", "提交成功");
                setIsSubmit(true);
                setSubmit(
                    {
                        name: '',
                        label: [],//标签
                        classify: undefined,
                        treatment: '',//待遇
                        education: '',//学历要求
                        experience: '',//工作经验
                        enterprise: null,//需求企业
                        address: '',//工作地点
                        source: '',//岗位来源
                        content: BraftEditor.createEditorState(null),//岗位详情
                    }
                )
            }).catch(err => {
                errorInfo(err);
                setIsSubmit(true);
            })
        }
        // 编辑 审核保存修改
        else if (actionType === 2) {
            instance.patch("/recruit/station/" + param.param.match.params.id + "/", list
            ).then(data => {
                Alert("success", "提交成功");
                setIsSubmit(true);
            }).catch((err) => {
                errorInfo(err);
                setIsSubmit(true);
            })
        }

    }
    // 点击审核
    const handleAudit = (data) => {
        let list = { type: "Enterprise", ...data }
        if (submit.address) {
            list.type = "Station"
        }
        if ((data && data.status === 2 || data && data.status === 3) && data.data === "") {
            Alert("error", "请输入审核意见");
            return
        }
        instance.post("/recruit/audit/" + param.param.match.params.id + "/", list).then(data => {
            Alert("success", "操作成功");
            window.location.href = "/#/resource/recruit"
        }).catch(err => {
            Alert("error", err.error)
        })
    }
    let myUploadFn = (param) => {
        const fd = new FormData();
        fd.append('image', param.file);
        fd.append("model", "experiment");
        instance.post("/ctf/upload_image/", fd).then(data => {
            param.success({
                url: data.url,
            })
        })
    }
    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-div submit-ctf-name">
                    {
                        type === "0" && "提交岗位"
                    }
                    {
                        type === "1" && "编辑岗位"
                    }
                    {
                        type === "2" && "审核岗位"
                    }
                    {
                        detail.status === 3 &&
                        <div className="status">
                            <img src={Denied} alt="" />
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    detail.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">{val.msg}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div subject-info">
                    <div className="title">
                        岗位信息
                    </div>
                    <div className='content'>
                        <div className='retrieval-div'>
                            <span><b>*</b>岗位名称</span>
                            <Input
                                value={submit.name}
                                className="content-input"
                                placeholder="请输入岗位名称"
                                onChange={(e) => inputChange(e.target.value, "name")}
                                maxLength="50"
                                onFocus={() => handleFocus("name")}
                            />
                            <span className="number">{50 - submit.name.length}</span>
                            <Button
                                type="primary"
                                onClick={() => retrievalClick(0, submit.name)}
                            >
                                检索
                            </Button>
                            <div className="error-message">{error.name[0]}</div>
                        </div>
                        <div className='retrieval-div'>
                            <span><b>*</b>岗位标签</span>
                            <Select
                                mode="tags"
                                style={{ width: 300 }}
                                value={submit.label}
                                maxTagTextLength={6}
                                onFocus={() => handleFocus("label")}
                                onChange={
                                    (value) => inputChange(value, 'label')
                                }
                            >
                                {
                                    label.map((val, key) => {
                                        return <Option value={val.name} key={'key' + key}>
                                            {val.name}
                                        </Option>
                                    })
                                }
                            </Select>
                            <div className="error-message">{error.label[0]}</div>
                        </div>
                        <div className='retrieval-div'>
                            <span><b>*</b>岗位待遇</span>
                            <Input
                                value={submit.treatment}
                                className="content-input"
                                placeholder="10k-15k"
                                onChange={(e) => inputChange(e.target.value, "treatment")}
                                maxLength="30"
                                onFocus={() => handleFocus("treatment")}
                            />
                            <span className="number">{30 - submit.treatment.length}</span>
                            <div className="error-message">{error.treatment[0]}</div>
                        </div>
                        <div className='retrieval-div'>
                            <span><b>*</b>学历要求</span>
                            <Input
                                value={submit.education}
                                className="content-input"
                                placeholder="本科毕业"
                                onChange={(e) => inputChange(e.target.value, "education")}
                                maxLength="50"
                                onFocus={() => handleFocus("education")}
                            />
                            <span className="number">{50 - submit.education.length}</span>
                            <div className="error-message">{error.education[0]}</div>
                        </div>
                        <div className='retrieval-div'>
                            <span><b>*</b>工作经验</span>
                            <Input
                                value={submit.experience}
                                className="content-input"
                                placeholder="1-2年工作经验"
                                onChange={(e) => inputChange(e.target.value, "experience")}
                                maxLength="50"
                                onFocus={() => handleFocus("experience")}
                            />
                            <span className="number">{50 - submit.experience.length}</span>
                            <div className="error-message">{error.experience[0]}</div>
                        </div>
                        <div className='retrieval-div'>
                            <span><b>*</b>需求企业</span>
                            <Select
                                showSearch
                                style={{ width: 287 }}
                                value={submit.enterprise}
                                placeholder="西安四叶草信息技术有限公司"
                                onChange={(value) => inputChange(value, 'enterprise')}
                                onFocus={() => handleFocus("enterprise")}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    enterprise.map((val, key) => {
                                        return <Option value={val.id} key={key}>
                                            {val.name}
                                        </Option>
                                    })
                                }
                            </Select>
                            <div className="error-message">{error.enterprise[0]}</div>
                        </div>
                        <div className='retrieval-div'>
                            <span><b>*</b>工作地点</span>
                            <Input
                                value={submit.address}
                                className="content-input"
                                placeholder="西安、北京、深圳"
                                onChange={(e) => inputChange(e.target.value, "address")}
                                maxLength="50"
                                onFocus={() => handleFocus("address")}
                            />
                            <span className="number">{50 - submit.address.length}</span>
                            <div className="error-message">{error.address[0]}</div>
                        </div>
                        <div className='retrieval-div'>
                            <span><b>*</b>岗位来源</span>
                            <TextArea
                                value={submit.source}
                                className="content-input"
                                placeholder="请输入岗位来源（可以是链接或者文字）"
                                onChange={(e) => inputChange(e.target.value, "source")}
                                maxLength="250"
                                onFocus={() => handleFocus("source")}
                            />
                            <span className="number">{250 - submit.source.length}</span>
                            <div className="error-message">{error.source[0]}</div>
                        </div>
                    </div>
                </div>
                <div className="submit-ctf-div topic-answer">
                    <div className="title">
                        岗位详情
                    </div>
                    <div>
                        <span><b>*</b>岗位详情</span>
                        <div className='my-component'>
                            <BraftEditor
                                value={submit.content}
                                onChange={(editorState) => inputChange(editorState, 'content')}
                                onFocus={() => handleFocus("content")}
                                ref={dateRef}
                                maxLength={2}
                                media={{
                                    uploadFn: myUploadFn,
                                    image: false
                                }}
                                excludeControls={["superscript", "subscript", "emoji", "remove-styles"]}
                                extendControls={[
                                    'separator',
                                    {
                                        key: 'add-media',
                                        type: 'button',
                                        text: '插入图片到媒体库',
                                        onClick: () => addMediaItem()
                                    },
                                    {
                                        key: 'insert-media',
                                        type: 'button',
                                        text: '插入图片到编辑器',
                                        onClick: () => insertMediItem()
                                    }
                                ]}
                            />
                            <div
                                className="error-message"
                                style={{ margin: "0" }}
                            >
                                {error.content[0]}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    // 提交
                    type === "0" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => commitResources(1)}
                            loading={!isSubmit}
                        >
                            提交
                        </Button>
                    </div>
                }
                {
                    // 编辑
                    type === "1" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => commitResources(2)}
                        >
                            提交
                        </Button>
                    </div>
                }
                {
                    // 审核
                    type === "2" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => {
                                handleAudit({ status: 1, type: "Station" })
                            }}
                        >
                            通过
                        </Button>
                        <Button
                            style={{ backgroundColor: "transparent", color: "#266cff" }}
                            type="primary"
                            onClick={() => window.location.href = "/#/resource/recruit"}
                        >
                            取消
                        </Button>
                        <Button
                            className="modify"
                            onClick={() => commitResources(2)}
                        >
                            保存修改
                        </Button>
                        <Button
                            className="decline"
                            onClick={() => {
                                initBox.declineBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            拒绝
                        </Button>
                        <Button
                            className="added"
                            onClick={() => {
                                initBox.addedBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            待补充
                        </Button>
                        <Button
                            className="added"
                            onClick={() => handleAudit({ status: 0 })}
                        >
                            待审核
                        </Button>
                    </div>
                }
            </div>
            {
                retrievalBox.show &&
                <Retrieval
                    close={() => {
                        retrievalBox.show = false;
                        setRetrievalBox({ ...retrievalBox })
                    }}
                    count={retrievalBox.count}
                    name={"岗位名称"}
                />
            }
            {
                initBox.declineBox &&
                <DeclineBox
                    close={() => {
                        initBox.declineBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.addedBox &&
                <AddedBox
                    close={() => {
                        initBox.addedBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            <form id="file-image">
                <input
                    type="file"
                    name="image"
                    accept=".jpg,.png,.gif"
                    style={{ display: "none" }}
                    className="images"
                    onChange={changeImage}
                />
            </form>
        </div>
    )
}
export default SubmitCTF;