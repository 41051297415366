import React, { useState, useEffect } from 'react';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Table, Input, Pagination, Menu, Dropdown } from 'antd'
import { Alert } from '../../../Communal/utility';
import instance from '../../../Communal/instance';
import DeteleBox from '../../deteleBox';
import Confirm from '../../comfirm'
import { stringify } from 'querystring';
import { levelList } from '../../../../utils/method';

const RuleResources = ({ keyword, userType, userIssue }) => {
    const [initData, setInitData] = useState({
        data: [],
        allCount: 0
    });
    const [classType, setClassType] = useState([]);
    const [checkList, setCheckList] = useState([]);
    const [filter, setFilter] = useState({
        page: 1,
        size: 10,
        search: keyword,
        rules_class: [],
        stage: [],
        status: [],
        username: "",
    });
    const [deleteTip, setDeleteTip] = useState({
        show: false,
        list: []
    })
    const [filteredList, setFilteredList] = useState({
        classify: [],
        stage: [],
        status: []
    });
    const [confirmShow, setConfirmShow] = useState({
        visible: false,
        mode: 'rules',
        issue: '',
    })
    const [exportFilter, setExportFilter] = useState({})

    useEffect(() => {
        tableData()
    }, [filter])

    useEffect(() => {
        getClassType()
        return () => {
            setFilter({
                page: 1,
                size: 10,
                search: keyword,
                classify: [],
                stage: [],
                status: [],
                username: "",
            })
        }
    }, [])

    useEffect(() => {
        let list = {}
        if (filter?.rules_class.length > 0) {
            list.status = 1
            list.class = filter.rules_class
        } else {
            list.status = 0
        }
        setExportFilter(list)
    }, [filter.rules_class])


    const tableData = () => {
        instance.get('/rules/', { params: filter }).then(data => {
            initData.data = data.results;
            initData.allCount = data.count;
            setInitData({ ...initData })
        })
    }

    const getClassType = () => {
        instance.get('/rules/class/', { params: { status: 1, nopage: 1 } }).then(data => {
            if (Array.isArray(data)) {
                let list = []
                data.map((value, key) => {
                    list.push({
                        text: value.name,
                        value: value.id
                    })
                })
                setClassType(list)
            }
        })
    }

    const handleTableChange = (pagination, filters) => {
        if (filters.stage) {
            filter.stage = filters.stage;
            filteredList.stage = filters.stage
        } else {
            filter.stage = [];
            filteredList.stage = [];
        }
        if (filters.level) {
            filter.level = filters.level;
            filteredList.level = filters.level
        } else {
            filter.level = [];
            filteredList.level = [];
        }
        if (filters.rules_class_name) {
            filter.rules_class = filters.rules_class_name;
            filteredList.rules_class = filters.rules_class_name
        } else {
            filter.rules_class = [];
            filteredList.rules_class = [];
        }
        if (filters.issue) {
            filter.issue = filters.issue;
            filteredList.issue = filters.issue
        } else {
            filter.issue = [];
            filteredList.issue = [];
        }
        if (filters.status) {
            filter.status = filters.status;
            filteredList.status = filters.status;
        } else {
            filter.status = [];
            filteredList.status = [];
        }
        filter.page = 1;
        setFilter({ ...filter });
        setFilteredList({ ...filteredList })
    }

    //单个删除或者批量删除操作
    const resourceDelete = (list) => {
        if (list.length === 0) {
            Alert("warn", "请选择需要删除的规则资源");
            return
        }
        instance.post("/rules/batch/", { ids: list, status: 1 }).then(data => {
            if (filter.page > 1 && (initData.data.length === list.length)) {
                filter.page -= 1;
                setFilter({ ...filter })
            }
            if (initData.allCount === list.length) {
                filter.page = 1;
                setFilter({ ...filter })
            }
            tableData();
            Alert("success", "删除成功");
            setCheckList([]);
            deleteTip.list = [];
            deleteTip.show = false;
            setDeleteTip({ ...deleteTip })
        })
    }

    const changeStatus = (key) => {
        setConfirmShow({ ...confirmShow, visible: true, issue: Number(key) });
    }

    const handleIssue = () => {
        setCheckList([]);
        setFilter({ ...filter });
    }

    const rowSelection = {
        selectedRowKeys: checkList,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            deleteTip.list = selectedRowKeys
            setDeleteTip({ ...deleteTip })
            setCheckList(selectedRowKeys)
        },
        getCheckboxProps: (record) => {
            if (record.on_delete === false) {
                return { disabled: true }
            } else {
                return null;
            }
        }
    }

    const columns = [
        {
            title: '序号',
            key: 'id',
            width: 70,
            fixed: 'left',
            align: 'center',
            // 递增序号
            render: (text, record, index) => (
                <span
                    className="click-href"
                    onClick={() => {
                        window.location.href = "/#/activity/ruleresourcedetail/0/" + record.id
                    }}
                >
                    {filter.size * (filter.page - 1) + index + 1}
                </span>
            )
        }, {
            title: '规则sid',
            align: 'center',
            key: 'new_sid',
            dataIndex: 'new_sid',
            width: 200,
            fixed: 'left',
            render: (text, record) => (
                <>
                    {
                        text ? <span
                            className="click-href"
                            onClick={() => {
                                window.location.href = "/#/activity/ruleresourcedetail/0/" + record.id
                            }}
                        >
                            {text}
                        </span> : '--'
                    }
                </>
            )
        }, {
            title: '规则分类',
            align: 'center',
            key: 'rules_class_name',
            dataIndex: 'rules_class_name',
            width: 120,
            fixed: 'left',
            ellipsis: true,
            filters: classType,
            render: (text) => {
                return text ? text : '（未关联规则分类或分类未审核通过）'
            }
        }, {
            title: '审核状态',
            key: 'status',
            dataIndex: 'status',
            width: 100,
            align: 'center',
            filters: [
                { text: "待审核", value: "0" },
                { text: "通过", value: "1" },
                { text: "待补充", value: "2" },
                { text: "拒绝", value: "3" }
            ],
            render: (record) => {
                return <span className="audit-status">
                    {
                        record === 0 && "待审核"
                    }
                    {
                        record === 1 && <span className="pass">通过</span>
                    }
                    {
                        record === 2 && <span className="added">待补充</span>
                    }
                    {
                        record === 3 && <span className="denied">拒绝</span>
                    }
                </span>
            },
        }, {
            title: '规则描述',
            key: 'desc',
            dataIndex: 'desc',
            width: 200,
            align: 'center',
            ellipsis: true,
        }, {
            title: '适合阶段',
            key: 'stage',
            dataIndex: 'stage',
            width: 150,
            align: 'center',
            ellipsis: true,
            filters: [
                { value: "信息收集", text: "信息收集" },
                { value: "信息泄露", text: "信息泄露" },
                { value: "尝试登录", text: "尝试登录" },
                { value: "登录成功", text: "登录成功" },
                { value: "网络扫描", text: "网络扫描" },
                { value: "漏洞探测", text: "漏洞探测" },
                { value: "漏洞利用", text: "漏洞利用" },
                { value: "上传木马", text: "上传木马" },
                { value: "武器投递", text: "武器投递" },
                { value: "远程控制", text: "远程控制" },
                { value: "代理转发", text: "代理转发" },
                { value: "横向移动", text: "横向移动" },
            ],
            render: (text, record) => {
                return text.join('，')
            },
        }, {
            title: '资源等级',
            key: 'level',
            dataIndex: 'level',
            width: 100,
            align: 'center',
            filters: levelList
        }, {
            title: '发布状态',
            key: 'issue',
            dataIndex: 'issue',
            filterMultiple: false,
            width: 100,
            align: 'center',
            filters: [
                { text: "已发布", value: 2 },
                { text: "未发布", value: 1 },
            ],
            render: (record) => {
                return <span className="audit-status">
                    {
                        record === 2 ? <span className="pass">已发布</span> : <span className="added">未发布</span>
                    }

                </span>
            },
        }, {
            title: '提交者',
            key: 'username',
            dataIndex: 'username',
            width: 100,
            align: 'center',
            ellipsis: true,
            filterDropdown: ({ clearFilters }) => (
                <div className="table-search">
                    <Input
                        placeholder="请输入提交者名称"
                        value={filter.username}
                        onChange={(e) => {
                            filter.username = e.target.value;
                            filter.page = 1;
                            setFilter({ ...filter })
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => { clearFilters() }}
                    >
                        确定
                    </Button>
                    <Button onClick={() => {
                        clearFilters();
                        filter.username = "";
                        filter.page = 1;
                        setFilter({ ...filter })
                    }}>取消</Button>
                </div>
            ),
            filterIcon: <SearchOutlined />
        }, {
            title: '提交时间',
            key: 'sub_time',
            dataIndex: 'sub_time',
            width: 150,
            align: 'center',
        }, {
            title: '操作',
            key: 'operate',
            dataIndex: 'operate',
            align: 'center',
            fixed: 'right',
            width: userType > 1 ? 300 : 250,
            render: (text, record) => {
                return <div className="table-operation">
                    <Button
                        type="link"
                        onClick={() => {
                            window.location.href = "/#/activity/ruleresourcedetail/0/" + record.id
                        }}
                    >
                        详情
                    </Button>
                    <Button
                        key="2"
                        type="link"
                        disabled={userType < 2 && record.status === 1}
                        onClick={() => {
                            window.open("/#/activity/ruleresourceform/1/" + record.id)
                        }}
                    >
                        编辑
                    </Button>
                    {
                        userType > 1 ? <>
                            <Button
                                key="4"
                                type="link"
                                onClick={() => {
                                    window.open("/#/activity/ruleresourceform/2/" + record.id)
                                }}
                            >
                                审核
                            </Button>
                        </> : undefined
                    }
                    {
                        userIssue ? <>
                            {
                                record.issue !== 2 ?
                                    <Button
                                        disabled={record.status !== 1}
                                        type="link"
                                        onClick={() => {
                                            setConfirmShow({ ...confirmShow, visible: true, issue: 2 });
                                            setCheckList([record.id])
                                        }}
                                    >
                                        发布
                                    </Button> :
                                    <Button
                                        type="link"
                                        danger
                                        onClick={() => {
                                            setConfirmShow({ ...confirmShow, visible: true, issue: 1 });
                                            setCheckList([record.id])
                                        }}
                                    >
                                        回收
                                    </Button>
                            }
                        </> : undefined
                    }
                    <Button
                        key="6"
                        type="link"
                        danger
                        disabled={userType < 2 && record.status === 1}
                        onClick={() => {
                            deleteTip.show = true
                            deleteTip.list = [record.id]
                            setDeleteTip({ ...deleteTip })
                        }}
                    >删除</Button>
                </div>
            }
        }
    ]

    const menu = (
        <Menu onClick={({ key }) => { changeStatus(key) }}>
            <Menu.Item key={1}>回收</Menu.Item>
            <Menu.Item key={2}>发布</Menu.Item>
        </Menu>
    );

    return (
        <div className="ctf-resource-list" style={{ padding: 0 }}>
            <div className="ctf-resource-table content-container" style={{ marginTop: 0 }}>
                <div className="handle">
                    {
                        userType > 1 ?
                            <div style={{ display: 'inline-block' }}>
                                <Button
                                    type='ghost'
                                    disabled={checkList.length === 0}
                                    danger
                                    onClick={() => {
                                        deleteTip.show = true;
                                        deleteTip.list = checkList;
                                        setDeleteTip({ ...deleteTip })
                                    }}
                                >
                                    批量删除
                                </Button>
                                {
                                    userIssue ?
                                        <Dropdown
                                            overlay={menu}
                                            disabled={checkList.length === 0}
                                        >
                                            <Button type="primary" style={{ marginLeft: '10px' }}>批量修改发布状态<DownOutlined /></Button>
                                        </Dropdown> : undefined
                                }
                                <a href={"/rules/batch/?" + stringify({ status: 2, ids: checkList })} download>
                                    <Button
                                        type='primary'
                                        style={{ marginLeft: '15px' }}
                                        disabled={checkList.length === 0 ? true : false}
                                    >
                                        批量导出
                                    </Button>
                                </a>
                                <a href={"/rules/derive/?" + stringify({ ids: checkList })} download>
                                    <Button
                                        type='primary'
                                        style={{ marginLeft: '15px' }}
                                        disabled={checkList.length === 0 ? true : false}
                                    >
                                        批量导出Excel
                                    </Button>
                                </a>
                            </div> : undefined
                    }
                    <div className='right'>
                        {userType > 1 ?
                            <>
                                <a href={"/rules/batch/?" + stringify(exportFilter)} download>
                                    <Button
                                        type='primary'
                                        disabled={initData.allCount === 0}
                                        style={{ marginRight: '15px' }}
                                    >
                                        全部导出
                                    </Button>
                                </a>
                                <a href={"/rules/derive/?" + stringify(exportFilter)} download>
                                    <Button
                                        type='primary'
                                        disabled={initData.allCount === 0}
                                        style={{ marginRight: '15px' }}
                                    >
                                        全部导出Excel
                                    </Button>
                                </a>
                            </>
                            : undefined
                        }
                        <Button
                            type="primary"
                            onClick={() => window.open("/#/activity/ruleresourceform/0/0")}
                        >
                            提交规则
                        </Button>
                    </div>
                </div>
                <Table
                    dataSource={initData.data}
                    rowKey={record => record.id}
                    bordered
                    scroll={{ x: 2000 }}
                    rowSelection={rowSelection}
                    onChange={handleTableChange}
                    columns={columns}
                    pagination={false}
                />
                <Pagination
                    showQuickJumper={true}
                    current={filter.page}
                    pageSize={filter.size}
                    total={initData.allCount}
                    hideOnSinglePage={initData.allCount === 0}
                    pageSizeOptions={['10', '20', '50', '100']}
                    showSizeChanger={true}
                    className="flip-over"
                    style={{ margin: "30px 0" }}
                    onChange={
                        ((page, pageSize) => {
                            let data = { ...filter }
                            data.page = page;
                            data.size = pageSize;
                            setFilter(data)
                        })
                    }
                />
            </div>
            {
                deleteTip.show &&
                <DeteleBox
                    close={() => {
                        deleteTip.show = false;
                        deleteTip.list = [];
                        setDeleteTip({ ...deleteTip })
                    }}
                    title='规则资源'
                    submit={() => resourceDelete(deleteTip.list)}
                />
            }
            <Confirm
                boxProperty={confirmShow}
                onClose={() => {
                    setConfirmShow({ ...confirmShow, visible: false })
                }}
                onOk={handleIssue}
                ids={checkList}
            />
        </div >
    )
}

export default RuleResources;