/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import '../../../css/submitCtf.css';
import '../../../css/box.css';
import { Button, Input, Select, Radio, Progress, message } from 'antd';
import { CloseOutlined, PaperClipOutlined, CheckCircleFilled } from '@ant-design/icons';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import CodeHighlighter from 'braft-extensions/dist/code-highlighter';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import Retrieval from '../../Activity/retrieval';
import instance from '../../Communal/instance';
import { Alert, srcUrl } from '../../Communal/utility';
import Denied from '../../../images/denied.png';
import DeteleIcon from '../../../images/detele-icon.png';
import DeleteTest from '../../../images/delete-test-icon.png';
import PassBox from '../../Activity/PassBox';
import DeclineBox from '../../Activity/DeclineBox';
import AddedBox from '../../Activity/AddedBox';
import history from '../../Communal/history';
import { ContentUtils } from 'braft-utils'
import Cao from '../../../images/cao.png';
import HuiCao from '../../../images/huicao.png';
import TipIcon from '../../../images/tip-icon.png';
import FlagTip from '../../Activity/FlagTip';
import $ from 'jquery'
// import { ajax } from 'jquery';
const options = {
    syntaxs: [
        {
            name: 'JavaScript',
            syntax: 'javascript'
        }, {
            name: 'HTML',
            syntax: 'html'
        }, {
            name: 'CSS',
            syntax: 'css'
        }, {
            name: 'Java',
            syntax: 'java',
        }, {
            name: 'PHP',
            syntax: 'php'
        }, {
            name: "Python",
            syntax: 'python'
        }
    ],
}

BraftEditor.use(CodeHighlighter(options))
const { Option } = Select;
const { TextArea } = Input;
let letter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
function SubmitCTF(param) {
    //id===0管理员 type: 0 添加 1 编辑 2 审核
    const [type, setType] = useState("0");
    const [changeTest, setChangeTest] = useState(0);
    const [flagTipShow, setFlagTipShow] = useState(false)
    const [submit, setSubmit] = useState(
        {
            name: "",
            intro: "",
            source: "",
            classify: "",
            env_desc: "",
            keyword: [],
            activity_id: "",
            env_attached: "",
            tool_attached: "",
            env_attached_name: "",
            tool_attached_name: "",
            editorState: BraftEditor.createEditorState(null),
            test_list: [
                {
                    name: "",
                    content: ["", ""],
                    parse: "",
                    option: [],
                    option_type: 1
                },
                {
                    name: "",
                    content: ["", ""],
                    parse: "",
                    option: [],
                    option_type: 1
                },
                {
                    name: "",
                    content: ["", ""],
                    parse: "",
                    option: [],
                    option_type: 1
                }
            ],
            is_flag: true,//是否有flag
            level: 1,//等级 1-5
            flag_list: [
                {
                    flag_type: 1,//1 静态Flag  2 动态flag
                    flag: "", // flag
                    flag_script: "" //flag 脚本
                }
            ]
        }
    );
    const dateRef = useRef()
    const [detail, setDetail] = useState({});
    const [changeFlag, setChangeFlag] = useState(0);
    const [error, setError] = useState(
        {
            name: [],
            intro: [],
            source: [],
            env_desc: [],
            classify: [],
            keyword: [],
            activity_id: [],
            env_attached: [],
            tool_attached: [],
            editorState: [],
            test_list: []
        }
    )
    const [keyword, setKeyword] = useState("")
    const [retrievalBox, setRetrievalBox] = useState({
        show: false,
        type: 0,
        count: 0
    });
    const [classify, setClassify] = useState([]);
    const [initBox, setInitBox] = useState(
        {
            passBox: false,
            declineBox: false,
            addedBox: false
        }
    )
    const [imagesType, setImagesType] = useState(0);
    const [isSubmit, setIsSubmit] = useState(true);
    const [envShow, setEnvShow] = useState(false)
    const [toolShow, setToolShow] = useState(false)
    const [toolPercent, setToolPercent] = useState(0)
    const [envPercent, setEnvPercent] = useState(0)

    useEffect(() => {
        setType(param.param.match.params.type);
    }, [param.param.match.params.type])
    useEffect(() => {
        submit.activity_id = param.param.match.params.where;
        setSubmit({ ...submit });
        if (param.param.match.params.type !== "0") {
            //ctf详情
            instance.get("/experiment/" + param.param.match.params.id + "/").then(data => {
                let test_list = [];
                submit.name = data.name;
                submit.intro = data.intro;
                submit.content = data.content;
                submit.source = data.source;
                submit.env_desc = data.env_desc;
                submit.keyword = data.keyword_list;
                submit.editorState = BraftEditor.createEditorState(data.content);
                submit.env_attached = data.env_attached;
                submit.tool_attached = data.tool_attached;
                submit.is_flag = data.is_flag;
                submit.level = data.level;
                submit.env_attached_name = data.env_attached_name;
                submit.tool_attached_name = data.tool_attached_name;
                if (data.test_list.length > 0) {
                    for (var i = 0; i < data.test_list.length; i++) {
                        data.test_list[i].content_copy = [];
                        for (var key in data.test_list[i].content) {
                            data.test_list[i].content_copy.push(data.test_list[i].content[key])
                        }
                        test_list.push({
                            name: data.test_list[i].name,
                            content: data.test_list[i].content_copy,
                            parse: data.test_list[i].parse,
                            option: data.test_list[i].option,
                            option_type: data.test_list[i].option_type
                        })
                    }
                } else {
                    test_list = [{
                        name: "",
                        content: ["", ""],
                        parse: "",
                        option: [],
                        option_type: 1
                    }]
                }
                if (data.flag_list.length > 0) {
                    submit.flag_list = data.flag_list;
                } else {
                    submit.flag_list = [{
                        flag_type: 1,
                        flag: "",
                        flag_script: ""
                    }]
                }
                submit.test_list = test_list;
                setSubmit({ ...submit });
                setDetail(data);
                getClassify(data.classify_name);
            })
        } else {
            getClassify();
            instance.get("/media/resource/WPTemplate/exp/template.json").then(data => {
                submit.editorState = BraftEditor.createEditorState(data?.template);
                setSubmit({ ...submit });
            })
        }

    }, []);
    //获取题目类型
    let getClassify = (name) => {
        instance.get("/ctf/classify/?type=2").then(data => {
            if (data.length > 0) {
                if (!name) {
                    submit.classify = data[0].id;
                } else {
                    for (var i = 0; i < data.length; i++) {
                        if (name === data[i].name) {
                            submit.classify = data[i].id;
                        }
                    }
                }
            }
            setSubmit({ ...submit })
            setClassify(data)
        })
    }
    //题目分类选择
    let handleChange = (key) => {
        submit.classify = key;
        setSubmit({ ...submit })
    }
    //添加关键字回车事件
    let onPressEnter = (e) => {
        if (e.target.value.replace(/(^\s*)|(\s*$)/g, "") === "") {
            return
        }
        var count = 0;
        if (submit.keyword.length < 5) {
            if (submit.keyword.length > 0) {
                for (var i = 0; i < submit.keyword.length; i++) {
                    if (submit.keyword[i] !== e.target.value) {
                        count++
                    }
                }
                if (count === submit.keyword.length) {
                    submit.keyword.push(e.target.value);
                }
            } else {
                submit.keyword.push(e.target.value);
            }
            setKeyword("");
            setSubmit({ ...submit })
        }
    }
    //关键字change事件
    let keywordChange = (e) => {
        setKeyword(e.target.value.replace(",", ""));
    }
    //删除关键字事件
    let deleteChange = (key) => {
        submit.keyword.splice(key, 1)
        setSubmit({ ...submit })
    }
    //点击选择文件上传事件 1：上传附件 2：其他附件
    let uploadChange = (type) => {
        if (envShow === true || toolShow === true) {
            Alert('warn', '有文件正在上传中，请稍候')
            return
        }
        if (type === 1) {
            document.getElementsByClassName("upload-file-one")[0].click();
            document.getElementsByClassName("upload-file-one")[0].value = "";
            submit.env_attached_name = "";
            submit.env_attached = "";
            error.env_attached = [];

        } else if (type === 2) {
            document.getElementsByClassName("upload-file-two")[0].click();
            document.getElementsByClassName("upload-file-two")[0].value = "";
            submit.tool_attached_name = "";
            submit.tool_attached = "";
            error.tool_attached = [];
        }
        setSubmit({ ...submit })
        setError({ ...error })
    }
    //input change 事件
    let inputChange = (e, name) => {
        submit[name] = e.target.value;
        setSubmit({ ...submit })
    }
    let handleEditorChange = (editorState) => {
        submit.editorState = editorState;
        setSubmit({ ...submit })
    }
    //上传文件change事件
    let getAjax = (formdData, type) => {
        $.ajax({
            url: "/ctf/upload_file/",
            type: "POST",
            data: formdData,
            contentType: false,
            processData: false,
            xhr: () => {
                var myXhr = $.ajaxSettings.xhr()
                if (myXhr.upload) { // check if upload property exists 
                    myXhr.upload.addEventListener('progress', (e) => {
                        var loaded = e.loaded;//已经上传大小情况
                        var tot = e.total;//附件总大小
                        var per = Math.floor(100 * loaded / tot);  //已经上传的百分比
                        if (type === 1) {
                            setEnvPercent(per)
                            if (myXhr.readyState === 1 && per === 100) {
                                setEnvPercent(99)
                            }
                        } else if (type === 2) {
                            setToolPercent(per)
                            if (myXhr.readyState === 1 && per === 100) {
                                setToolPercent(99)
                            }
                        }
                    }, false);
                }
                return myXhr;
            },
            success: (data) => {
                if (type === 1) {
                    setEnvShow(false)
                    setEnvPercent(0)
                    submit.env_attached_name = data.origin_file_name;
                    submit.env_attached = data.url
                    setSubmit({ ...submit })
                } else if (type === 2) {
                    setToolShow(false)
                    setToolPercent(0)
                    submit.tool_attached_name = data.origin_file_name;
                    submit.tool_attached = data.url
                    setSubmit({ ...submit })
                }
            },
            error: (err) => {
                if (type === 1) {
                    if (err.file) {
                        error.env_attached = err.file;
                    }
                    if (err.non_field_errors) {
                        error.env_attached = err.non_field_errors;
                    }
                    setError({ ...error })
                } else if (type === 2) {
                    if (err.file) {
                        error.tool_attached = err.file;
                    }
                    if (err.non_field_errors) {
                        error.tool_attached = err.non_field_errors;
                    }
                    setError({ ...error })
                }
            }
        })
    }
    let changeFile = (type) => {
        if (type === 1) {
            let file = document.getElementsByClassName("upload-file-one")[0].files[0];
            if (file !== undefined) {
                if (file.size > 1024 * 1024 * 500) {
                    Alert("error", "上传附件不能大于500M");
                    return
                }
                setEnvShow(true)//开启进度条
                let form = document.getElementById('formImg1');
                let formdData = new FormData(form);
                formdData.append("model", "experiment")
                getAjax(formdData, 1)
            }
        }
        if (type === 2) {
            let file = document.getElementsByClassName("upload-file-two")[0].files[0];
            if (file !== undefined) {
                if (file.size > 1024 * 1024 * 500) {
                    Alert("error", "上传附件不能大于500M")
                    return
                }
                setToolShow(true)//开启进度条
                let form = document.getElementById('formImg2');
                let formdData = new FormData(form);
                formdData.append("model", "experiment")
                getAjax(formdData, 2)
            }
        }
        setSubmit({ ...submit })
    }
    let handleFouce = (name) => {
        error[name] = []
        setError({ ...error })
    }
    //点击提交资源
    let commitResources = (actionType) => {
        if (srcUrl(submit.editorState.toHTML())) {
            Alert('warn', '实验内容中图片存在外部链接，请检查！！！')
            return
        }
        if (!isSubmit) {
            Alert("warn", "正在提交数据，请稍后！")
            return
        }
        let list = {
            name: submit.name,
            intro: submit.intro,
            is_flag: submit.is_flag,
            source: submit.source,
            classify: submit.classify,
            env_desc: submit.env_desc,
            content: submit.editorState.toHTML(),
            keyword: submit.keyword,
            env_attached: submit.env_attached,
            env_attached_name: submit.env_attached_name,
            tool_attached: submit.tool_attached,
            tool_attached_name: submit.tool_attached_name,
            level: submit.level,
        }
        if (submit.is_flag) {
            list.flag_list = submit.flag_list
        } else {
            list.flag_list = [];
        }
        let test_list = [];
        for (var i = 0; i < submit.test_list.length; i++) {
            submit.test_list[i].content_copy = {};
            for (var j = 0; j < submit.test_list[i].content.length; j++) {
                submit.test_list[i].content_copy[letter[j]] = submit.test_list[i].content[j]
            }
            test_list.push({
                name: submit.test_list[i].name,
                content: submit.test_list[i].content_copy,
                parse: submit.test_list[i].parse,
                option: submit.test_list[i].option,
                option_type: submit.test_list[i].option_type
            })
        }
        if (submit.activity_id !== "" && actionType === 1 && submit.activity_id !== "0" && submit.activity_id !== "-1") {
            list.activity_id = submit.activity_id;
        }
        if (submit.name === "") {
            error.name = ["请输入实验名称"]
        }
        if (submit.intro === "") {
            error.intro = ["请输入实验简介"]
        }
        if (submit.classify === "") {
            error.classify = ["请输入实验分类"]
        }
        if (submit.editorState.toHTML() === "<p></p>") {
            Alert("error", "请输入实验内容");
            return
        }
        if (submit.keyword.length === 0) {
            error.keyword = ["请输入关键字"]
        }
        if (
            submit.name === "" ||
            submit.intro === "" ||
            submit.classify === "" ||
            submit.keyword.length === 0
        ) {
            setError({ ...error })
            Alert('warn', '提交内容有误，请检查！')
            return
        }
        if (envShow === true || toolShow === true) {
            Alert("warn", "有文件正在上传中，请稍候");
            return
        }
        list.test_list = test_list;
        setIsSubmit(false);
        if (actionType === 1) {
            instance.post("/experiment/add/", list).then(data => {
                Alert("success", "提交成功");
                setChangeTest(0);
                setChangeFlag(0)
                setIsSubmit(true);
                instance.get("/media/resource/WPTemplate/exp/template.json").then(data => {
                    setSubmit(
                        {
                            name: "",
                            intro: "",
                            source: "",
                            classify: classify[0].id || "",
                            keyword: [],
                            activity_id: param.param.match.params.where,
                            env_attached: "",
                            tool_attached: "",
                            env_desc: "",
                            env_attached_name: "",
                            tool_attached_name: "",
                            test_list: [
                                {
                                    name: "",
                                    content: ["", ""],
                                    parse: "",
                                    option: [],
                                    option_type: 1
                                },
                                {
                                    name: "",
                                    content: ["", ""],
                                    parse: "",
                                    option: [],
                                    option_type: 1
                                },
                                {
                                    name: "",
                                    content: ["", ""],
                                    parse: "",
                                    option: [],
                                    option_type: 1
                                }
                            ],
                            editorState: BraftEditor.createEditorState(data.template),
                            is_flag: true,//是否有flag
                            level: 1,//等级 1-5
                            flag_list: [
                                {
                                    flag_type: 1,//1 静态Flag  2 动态flag
                                    flag: "", // flag
                                    flag_script: "" //flag 脚本
                                }
                            ]
                        }
                    )
                })

            }).catch(err => {
                errorInfo(err);
                setIsSubmit(true);
            })
        } else if (actionType === 2) {
            instance.patch("/experiment/" + param.param.match.params.id + "/", list).then(data => {
                Alert("success", "提交成功");
                setIsSubmit(true);
                if (type === "1") {
                    if (submit.activity_id !== "" && submit.activity_id !== "0" && submit.activity_id !== "-1") {
                        history.push({ pathname: "/activity/detail/" + submit.activity_id, state: { where: 1 } })
                    } else if (submit.activity_id === "-1") {
                        window.location.href = "/#/personal/4"
                    } else {
                        window.location.href = "/#/resource/exp"
                    }
                }
            }).catch(err => {
                errorInfo(err);
                setIsSubmit(true);
            })
        }
    }
    let errorInfo = (err) => {
        if (err.name) {
            error.name = err.name
        }
        if (err.intro) {
            error.intro = err.intro
        }
        if (err.env_desc) {
            error.env_desc = err.env_desc
        }
        if (err.source) {
            error.source = err.source
        }
        if (err.classify) {
            error.classify = err.classify
        }
        if (err.content) {
            Alert("error", err.content);
        }
        if (err.flag_list) {
            Alert("error", err.flag_list);
        }
        if (err.keyword) {
            error.keyword = err.keyword
        }
        if (err.env_attached) {
            error.env_attached = err.env_attached
        }
        if (err.tool_attached) {
            error.tool_attached = err.tool_attached
        }
        if (err.non_field_errors) {
            Alert("error", err.non_field_errors[0])
        }
        if (err.activity_id) {
            Alert("error", err.activity_id[0])
        }
        if (err.error) {
            Alert("error", err.error)
        }
        if (err.test_list) {
            for (var i = 0; i < err.test_list.length; i++) {
                if (err.test_list[i].content) {
                    Alert("error", "第" + (i + 1) + "题：" + err.test_list[i].content)
                }
                if (err.test_list[i].name) {
                    Alert("error", "第" + (i + 1) + "题：" + err.test_list[i].name)
                }
                if (err.test_list[i].option) {
                    Alert("error", "第" + (i + 1) + "题：" + err.test_list[i].option)
                }
                if (err.test_list[i].parse) {
                    Alert("error", "第" + (i + 1) + "题：" + err.test_list[i].parse)
                }
                if (err.test_list[i].option_type) {
                    Alert("error", "第" + (i + 1) + "题：" + err.test_list[i].option_type)
                }
                if (err.test_list[i].non_field_errors) {
                    Alert("error", "第" + (i + 1) + "题：" + err.test_list[i].non_field_errors)
                }
            }
        }
        Alert('warn', '提交内容有误，请检查！')
        setError({ ...error })
    }
    //点击审核操作
    let handleAudit = (data) => {
        if (data.status === 1) {
            if (data.data === "") {
                Alert("error", "请输入奖励奖励数");
                return
            }
        }
        if ((data.status === 2 || data.status === 3) && data.data === "") {
            Alert("error", "请输入审核意见");
            return
        }
        instance.post("/experiment/" + param.param.match.params.id + "/audit/", data).then(data => {
            Alert("success", "操作成功");
            window.location.href = "/#/resource/exp"
        }).catch(err => {
            Alert("error", err.error)
        })
    }
    //点击上传到媒体库
    let addMediaItem = () => {
        document.getElementsByClassName("images")[0].click();
        document.getElementsByClassName("images")[0].value = "";
        setImagesType(0)

    }
    //点击插入到编辑器
    let insertMediItem = () => {
        document.getElementsByClassName("images")[0].click();
        document.getElementsByClassName("images")[0].value = "";
        setImagesType(1)
    }
    //点击上传文件
    let changeImage = () => {
        let file = document.getElementsByClassName("images")[0].files[0];
        if (file !== undefined) {
            if (file.size > 5 * 1024 * 1024) {
                Alert("error", "上传图片不能大于5M")
                return
            }
            let form = document.getElementById('file-image');
            let formdData = new FormData(form);
            formdData.append("model", "experiment");
            instance.post("/ctf/upload_image/", formdData).then(data => {
                if (imagesType === 0) {
                    if (dateRef.current !== undefined) {
                        let braftFinder = dateRef.current.getFinderInstance();
                        braftFinder.addItems([
                            {
                                id: new Date().getTime(),
                                type: 'IMAGE',
                                url: data.url
                            }
                        ])
                    }
                } else if (imagesType === 1) {
                    let editorState = ContentUtils.insertMedias(submit.editorState, [
                        {
                            type: 'IMAGE',
                            url: data.url
                        }
                    ])
                    submit.editorState = editorState;
                    setSubmit({ ...submit })
                }
                Alert("success", "上传成功")
            }).catch(err => {
                if (err.image) {
                    Alert("error", err.image)
                }
            })
        }
    }
    //点击检索按钮进行检索，根据类型判断 检索的字段
    let retrievalClick = (type, text) => {
        if (text.replace(/(^\s*)|(\s*$)/g, "") === "") {
            Alert("warn", "请输入检索内容");
            return
        }
        let list = {
            type: "experiment",
            flag: text,
            search_type: type
        }
        instance.get("/ctf/search/", { params: list }).then(data => {
            retrievalBox.show = true;
            retrievalBox.count = data.count;
            retrievalBox.type = type;
            setRetrievalBox({ ...retrievalBox })
        })
    }
    let myUploadFn = (param) => {
        const fd = new FormData();
        fd.append('image', param.file);
        fd.append("model", "experiment");
        instance.post("/ctf/upload_image/", fd).then(data => {
            param.success({
                url: data.url,
            })
        })
    }
    //点击增加自测题
    let addTest = () => {
        if (submit.test_list.length < 5) {
            submit.test_list.push({
                name: "",
                content: ["", ""],
                parse: "",
                option: [],
                option_type: 1
            })
            setSubmit({ ...submit })
        } else {
            Alert("warn", "最多添加五道自测题！！！")
        }
    }
    //点击删除自测题
    let handleDeleteTest = (key) => {
        if (submit.test_list.length > 3) {
            submit.test_list.splice(key, 1);
            setSubmit({ ...submit });
            setChangeTest(0)
        } else {
            Alert("warn", "最少添加三道自测题！！！")
        }
    }
    //点击删除Flag
    let handleDeleteFlag = (key) => {
        if (submit.flag_list.length > 1) {
            submit.flag_list.splice(key, 1);
            setSubmit({ ...submit });
            setChangeFlag(0)
        }
    }
    //点击添加Flag
    let addFlag = () => {
        submit.flag_list.push({
            flag_type: 1,
            flag: "",
            flag_script: ""
        })
        setSubmit({ ...submit })
    }
    //选择答案
    let handleChangeOption = (key) => {
        let type = submit.test_list[changeTest].option_type;
        if (type === 2) {
            if (submit.test_list[changeTest].option.indexOf(letter[key]) !== -1) {
                submit.test_list[changeTest].option.splice(submit.test_list[changeTest].option.indexOf(letter[key]), 1)
            } else {
                submit.test_list[changeTest].option.push(letter[key]);
            }
        } else {
            submit.test_list[changeTest].option = [letter[key]]
        }
        setSubmit({ ...submit })
    }
    //增加选项
    let moreOptions = () => {
        submit.test_list[changeTest].content.push("");
        setSubmit({ ...submit })
    }
    //删除选项
    let deleteOption = (key) => {
        if (submit.test_list[changeTest].content.length > 2) {
            submit.test_list[changeTest].content.splice(key, 1)
        } else {
            Alert("warn", "最少存在两个选项！！！")
        }
        setSubmit({ ...submit })
    }
    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-div submit-ctf-name">
                    {
                        type === "0" && "提交安全实验"
                    }
                    {
                        type === "1" && "编辑安全实验"
                    }
                    {
                        type === "2" && "审核安全实验"
                    }
                    {
                        detail.status === 3 &&
                        <div className="status">
                            <img src={Denied} alt="" />
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    detail.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">{val.msg}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div subject-info">
                    <div className="title">
                        实验信息
                    </div>
                    <div className="content">
                        <div className="retrieval-div">
                            <span><b>*</b>实验名称</span>
                            <Input
                                value={submit.name}
                                className="content-input"
                                placeholder="请输入实验名称"
                                onChange={(e) => inputChange(e, "name")}
                                maxLength="200"
                                onFocus={() => handleFouce("name")}
                            />
                            <span className="number">{200 - submit.name.length}</span>
                            <Button type="primary" onClick={() => retrievalClick(1, submit.name)}>检索</Button>
                            <div className="error-message">{error.name[0]}</div>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>实验简介</span>
                            <TextArea
                                value={submit.intro}
                                className="content-input"
                                placeholder="请输入实验简介"
                                onChange={(e) => inputChange(e, "intro")}
                                // autoSize
                                maxLength="180"
                                onFocus={() => handleFouce("intro")}
                            />
                            <span className="number">{180 - submit.intro.length}</span>
                            <Button type="primary" onClick={() => retrievalClick(2, submit.intro)}>检索</Button>
                            <div className="error-message">{error.intro[0]}</div>
                        </div>
                        <div>
                            <span>实验来源</span>
                            <Input
                                value={submit.source}
                                onChange={(e) => inputChange(e, "source")}
                                className="content-input"
                                placeholder="请输入实验来源"
                                maxLength="100"
                                onFocus={() => handleFouce("source")}
                            />
                            <span className="number">{100 - submit.source.length}</span>
                            <div className="error-message">{error.source[0]}</div>
                        </div>
                        <div>
                            <span><b>*</b>实验分类</span>
                            <Select
                                value={submit.classify}
                                style={{ width: 180 }}
                                onChange={handleChange}
                                onClick={() => handleFouce("classify")}
                            >
                                {
                                    classify.map((val, key) => {
                                        return <Option
                                            key={key + "key"}
                                            value={val.id}
                                        >
                                            {val.name}
                                        </Option>
                                    })
                                }
                            </Select>
                            <div className="error-message">{error.classify[0]}</div>
                        </div>
                        <div>
                            <span><b>*</b>实验等级</span>
                            <div className="test-level">
                                {
                                    Array(5).fill("").map((val, key) => {
                                        return <img
                                            key={key}
                                            src={(key + 1) > submit.level ? HuiCao : Cao}
                                            alt=""
                                            onClick={() => {
                                                submit.level = key + 1;
                                                setSubmit({ ...submit })
                                            }}
                                        />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="submit-ctf-div exp-flag">
                    <div className="title">
                        实验Flag
                    </div>
                    <div className="single-flag-show">
                        <span>是否有flag</span>
                        <div className="test-level" style={{ lineHeight: "40px" }}>
                            <Radio.Group
                                value={submit.is_flag}
                                onChange={e => {
                                    submit.is_flag = e.target.value;
                                    setSubmit({ ...submit })
                                }}
                            >
                                <Radio value={true}>有</Radio>
                                <Radio value={false}>无</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    {
                        submit.is_flag &&
                        <div className="flag-list">
                            <div className="title">
                                {
                                    submit.flag_list.map((val, key) => {
                                        return <div
                                            key={key}
                                            className={key === changeFlag ? "active" : ""}
                                        >
                                            <span
                                                onClick={() => {
                                                    setChangeFlag(key)
                                                }}
                                            >
                                                Flag{key + 1}
                                            </span>
                                            {
                                                submit.flag_list.length > 1 &&
                                                <img src={DeleteTest} alt="" onClick={() => handleDeleteFlag(key)} />
                                            }
                                        </div>
                                    })
                                }
                                <span onClick={addFlag}>+增加Flag</span>
                            </div>
                            <div className="single-flag-show">
                                <span>Flag类型</span>
                                <div>
                                    <Radio.Group
                                        value={submit.flag_list[changeFlag].flag_type}
                                        onChange={e => {
                                            submit.flag_list[changeFlag].flag_type = e.target.value;
                                            setSubmit({ ...submit })
                                        }}
                                    >
                                        <Radio value={1}>静态Flag</Radio>
                                        <Radio value={2}>动态Flag</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div className="single-flag-show">
                                <span><b>*</b>实验Flag</span>
                                <div>
                                    <Input
                                        value={submit.flag_list[changeFlag].flag}
                                        onChange={(e) => {
                                            submit.flag_list[changeFlag].flag = e.target.value;
                                            setSubmit({ ...submit })
                                        }}
                                        className="content-input"
                                        placeholder="请输入实验Flag"
                                        maxLength="100"
                                    />
                                </div>
                            </div>
                            {
                                submit.flag_list[changeFlag].flag_type === 2 &&
                                <div className="single-flag-show">
                                    <span><b>*</b>Flag脚本</span>
                                    <div>
                                        <TextArea
                                            style={{ width: "calc(100% - 30px)" }}
                                            value={submit.flag_list[changeFlag].flag_script}
                                            onChange={(e) => {
                                                submit.flag_list[changeFlag].flag_script = e.target.value;
                                                setSubmit({ ...submit })
                                            }}
                                            className="content-input"
                                            placeholder="请输入Flag脚本"
                                        />
                                        <img className="flag-tip-icon" onClick={() => setFlagTipShow(true)} src={TipIcon} alt="" />
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className="submit-ctf-div topic-answer">
                    <div className="title">
                        实验内容
                    </div>
                    <div>
                        <span><b>*</b>实验内容</span>
                        <div className="my-component">
                            <BraftEditor
                                value={submit.editorState}
                                onChange={handleEditorChange}
                                ref={dateRef}
                                media={{
                                    uploadFn: myUploadFn,
                                    image: false
                                }}
                                excludeControls={["superscript", "subscript", "emoji", "remove-styles"]}
                                extendControls={[
                                    'separator',
                                    {
                                        key: 'add-media',
                                        type: 'button',
                                        text: '插入图片到媒体库',
                                        onClick: () => addMediaItem()
                                    },
                                    {
                                        key: 'insert-media',
                                        type: 'button',
                                        text: '插入图片到编辑器',
                                        onClick: () => insertMediItem()
                                    }
                                ]}
                            />
                            <div className="error-message" style={{ margin: "0" }}>{error.editorState[0]}</div>
                        </div>
                    </div>
                </div>
                <div className="submit-ctf-div subject-keyword">
                    <div className="title">
                        添加关键字
                    </div>
                    <div className="content">
                        <div>
                            <b>*</b>
                            关键字
                        </div>
                        <div>
                            <div>
                                <Input
                                    value={keyword}
                                    placeholder="题目关键字，回车添加关键字"
                                    onPressEnter={onPressEnter}
                                    onChange={keywordChange}
                                    onFocus={() => handleFouce("keyword")}
                                    maxLength={16}
                                />
                                <span className="number">{16 - keyword.length}</span>
                                添加关键字（{5 - submit.keyword.length}）
                            </div>
                            <div>
                                {
                                    submit.keyword.map((val, key) => {
                                        return <span key={"key" + key}>
                                            {val}
                                            <CloseOutlined
                                                onClick={() => deleteChange(key)}
                                            />
                                        </span>
                                    })
                                }
                            </div>
                        </div>
                        <div className="error-message">{error.keyword[0]}</div>
                    </div>
                </div>
                <div className="submit-ctf-div subject-upload">
                    <div className="title">
                        上传附件
                        <span>提供环境附件或者工具附件及搭建说明，将能得到更多金币</span>
                    </div>
                    <div className="content">
                        <div className="env-explain">
                            <span>环境搭建说明</span>
                            <Input
                                value={submit.env_desc}
                                className="content-input"
                                placeholder="请输入环境搭建说明"
                                onChange={(e) => inputChange(e, "env_desc")}
                                maxLength="180"
                                onFocus={() => handleFouce("env_desc")}
                            />
                            <span className="number">{180 - submit.env_desc.length}</span>
                            <div className="error-message">{error.env_desc[0]}</div>
                        </div>
                        <div>
                            <span>环境附件</span>
                            <form id="formImg1">
                                <div>
                                    <span className="upload" onClick={() => uploadChange(1)}>
                                        选择
                                        <input
                                            type="file"
                                            name="file"
                                            className="upload-file-one"
                                            accept=".zip,.rar"
                                            onChange={() => changeFile(1)}
                                        />
                                    </span>
                                    （ZIP/RAR，500M以内）
                                    {
                                        submit.env_attached !== "" && [
                                            <span className="file-name" key="1">
                                                <a href={submit.env_attached} download>
                                                    <PaperClipOutlined />
                                                    {submit.env_attached_name}
                                                </a>
                                            </span>,
                                            <Button type="link" key="2" danger onClick={() => {
                                                submit.env_attached_name = "";
                                                submit.env_attached = "";
                                                setSubmit({ ...submit })
                                            }}>删除</Button>
                                        ]
                                    }
                                    {
                                        envShow &&
                                        <>
                                            <Progress percent={envPercent} />
                                            <span> （上传中，请稍候）</span>
                                        </>
                                    }
                                </div>
                            </form>
                            <div style={{ marginLeft: "140px" }} className="error-message">{error.env_attached[0]}</div>
                        </div>
                        <div>
                            <span>工具附件</span>
                            <form id="formImg2">
                                <div>
                                    <span className="upload" onClick={() => uploadChange(2)}>
                                        选择
                                        <input
                                            type="file"
                                            name="file"
                                            accept=".zip,.rar"
                                            className="upload-file-two"
                                            onChange={() => changeFile(2)}
                                        />
                                    </span>
                                    （ZIP/RAR，500M以内）
                                    {
                                        submit.tool_attached !== "" && [
                                            <span className="file-name" key="1">
                                                <a href={submit.tool_attached} download>
                                                    <PaperClipOutlined />
                                                    {submit.tool_attached_name}
                                                </a>
                                            </span>,
                                            <Button key="2" type="link" danger onClick={() => {
                                                submit.tool_attached_name = "";
                                                submit.tool_attached = "";
                                                setSubmit({ ...submit })
                                            }}>删除</Button>
                                        ]
                                    }
                                    {
                                        toolShow &&
                                        <>
                                            <Progress percent={toolPercent} />
                                            <span> （上传中，请稍候）</span>
                                        </>
                                    }
                                </div>
                            </form>
                            <div style={{ marginLeft: "140px" }} className="error-message">{error.tool_attached[0]}</div>
                        </div>
                    </div>
                </div>
                <div className="submit-ctf-div experimental-subject">
                    <div className="title">
                        {
                            submit.test_list.map((val, key) => {
                                return <span
                                    key={key}
                                    className={key === changeTest ? "active" : ""}
                                >
                                    <span
                                        onClick={() => {
                                            setChangeTest(key)
                                        }}
                                    >
                                        自测题{key + 1}
                                    </span>
                                    {
                                        submit.test_list.length > 3 &&
                                        <img src={DeleteTest} alt="" onClick={() => handleDeleteTest(key)} />
                                    }
                                </span>
                            })
                        }
                        <div>
                            <span onClick={addTest}>+增加自测题目</span>
                            <span>最少提供3道自测题目</span>
                        </div>
                    </div>
                    {
                        submit.test_list[changeTest] &&
                        <div className="content">
                            <div>
                                <span><b>*</b>类型：</span>
                                <div>
                                    <Radio.Group value={submit.test_list[changeTest].option_type} onChange={e => {
                                        submit.test_list[changeTest].option_type = e.target.value;
                                        setSubmit({ ...submit })
                                    }}>
                                        <Radio value={1}>单选</Radio>
                                        <Radio value={2}>多选</Radio>
                                        <Radio value={3}>判断</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div>
                                <span><b>*</b>题目：</span>
                                <div>
                                    <Input
                                        value={submit.test_list[changeTest].name}
                                        onChange={(e) => {
                                            submit.test_list[changeTest].name = e.target.value;
                                            setSubmit({ ...submit })
                                        }}
                                        className="content-input"
                                        placeholder="请输入题目描述"
                                    />
                                </div>
                            </div>
                            <div>
                                <span><b>*</b>解析：</span>
                                <div>
                                    <Input
                                        value={submit.test_list[changeTest].parse}
                                        onChange={(e) => {
                                            submit.test_list[changeTest].parse = e.target.value;
                                            setSubmit({ ...submit })
                                        }}
                                        className="content-input"
                                        placeholder="请输入题目解析"
                                    />
                                </div>
                            </div>
                            <div>
                                <span><b>*</b>选项：</span>
                                <div className="option">
                                    {
                                        submit.test_list[changeTest].content.map((val, key) => {
                                            return <div key={key}>
                                                <img src={DeteleIcon} alt="" onClick={() => deleteOption(key)} />
                                                选项{letter[key]}：
                                                <Input
                                                    value={val}
                                                    onChange={(e) => {
                                                        submit.test_list[changeTest].content[key] = e.target.value;
                                                        setSubmit({ ...submit })
                                                    }}
                                                    className="content-input"
                                                    placeholder="请输入选项描述"
                                                />
                                                <span
                                                    onClick={() => handleChangeOption(key)}
                                                    className={submit.test_list[changeTest].option.indexOf(letter[key]) !== -1 ? "active" : ""}
                                                >
                                                    <CheckCircleFilled />
                                                </span>
                                            </div>
                                        })
                                    }
                                    <div className="add-btn">
                                        <span onClick={moreOptions}>+增加选项</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {
                    type === "0" &&
                    <div className="submit-btn">
                        <Button type="primary" onClick={() => commitResources(1)}>提交</Button>
                    </div>
                }
                {
                    type === "1" &&
                    <div className="submit-btn">
                        <Button type="primary" onClick={() => commitResources(2)}>提交</Button>
                    </div>
                }
                {
                    type === "2" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => {
                                initBox.passBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            通过
                        </Button>
                        <Button
                            style={{ backgroundColor: "transparent", color: "#266cff" }}
                            type="primary"
                            onClick={() => window.location.href = "/#/resource/exp"}
                        >
                            取消
                        </Button>
                        <Button className="modify" onClick={() => commitResources(2)}>保存修改</Button>
                        <Button
                            className="decline"
                            onClick={() => {
                                initBox.declineBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            拒绝
                        </Button>
                        <Button
                            className="added"
                            onClick={() => {
                                initBox.addedBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            待补充
                        </Button>
                        <Button
                            className="added"
                            onClick={() => handleAudit({ status: 0 })}
                        >
                            待审核
                        </Button>
                    </div>
                }
            </div>
            {
                retrievalBox.show &&
                <Retrieval
                    close={() => {
                        retrievalBox.show = false;
                        setRetrievalBox({ ...retrievalBox })
                    }}
                    count={retrievalBox.count}
                    name={
                        {
                            0: "Flag",
                            1: "实验名称",
                            2: "实验简介"
                        }[retrievalBox.type]
                    }
                />
            }
            {
                initBox.passBox &&
                <PassBox
                    close={() => {
                        initBox.passBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.declineBox &&
                <DeclineBox
                    close={() => {
                        initBox.declineBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.addedBox &&
                <AddedBox
                    close={() => {
                        initBox.addedBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            <form id="file-image">
                <input
                    type="file"
                    name="image"
                    accept=".jpg,.png,.gif"
                    style={{ display: "none" }}
                    className="images"
                    onChange={changeImage}
                />
            </form>
            {
                flagTipShow &&
                <FlagTip
                    onClose={() => setFlagTipShow(false)}
                />
            }
        </div>
    )
}
export default SubmitCTF;