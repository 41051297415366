import React, { useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import Banner from '../../../images/scene-banner.png';
import instance from '../../Communal/instance';
import history from '../../Communal/history';
import SceneManage from './SceneManage';
import SceneTask from './SceneTask';

const { TabPane } = Tabs;
const ThreatResources = () => {
    const [activeType, setActiveType] = useState('');
    const [search, setsSearch] = useState({
        show: false,
        value: ""
    });
    const [initData, setInitData] = useState({
        hotKeyword: [],
        resourceNumber: {}
    });
    const [filter, setFilter] = useState({
        search: "",
    });
    useEffect(() => {
        if (history.location.state && history.location.state.tabKey === 2) {
            setActiveType("2")
        } else {
            setActiveType("1")
        }
    }, [history.location.state])
    useEffect(() => {
        resourceNumber();
    }, [activeType])
    let resourceNumber = () => {
        let type = '';
        if (activeType === '1') {
            // type = 'method_scene'
        }
        if (activeType === '2') {
            type = 'scenetask'
        }
        if (type === '') {
            return
        }
        instance.get("/ctf/sum/", { params: { type } }).then(data => {
            initData.resourceNumber = data;
            setInitData({ ...initData })
        })
        instance.get("/ctf/hot_word/", { params: { type, size: 5 } }).then(data => {
            initData.hotKeyword = data;
            setInitData({ ...initData })
        })
    }
    let onClicks = (ev) => {
        var e = ev || window.event; //浏览器兼容性
        var elem = e.target || e.srcElement;
        while (elem) {
            //循环判断至跟节点，防止点击的是div子元素
            if (elem.className === "top") {
                return;
            } else {
                elem = elem.parentNode;
            }
        }
        search.show = false;
        setsSearch({ ...search });
    }
    let handelSearch = () => {
        if (search.value !== filter.search) {
            filter.search = search.value;
            setFilter({ ...filter })
        }
        search.show = true;
        setsSearch({ ...search });
    }
    //搜索回车事件
    let calAge = (e) => {
        var evt = window.event || e;
        if (evt.keyCode === 13) {
            handelSearch();
        }
    }
    const handleToggle = (key) => {
        setActiveType(key);
        filter.search = '';
        search.value = '';
        setsSearch({ ...search })
        setFilter({ ...filter });
    }
    return (
        <div className="ctf-resource-list" onClick={onClicks}>
            <div className="banner" style={{ backgroundImage: `url(${Banner})` }}></div>
            <div className="ctf-resource-search">
                <div className="top">
                    {
                        search.show ?
                            <div className="search-input">
                                <input
                                    className="input-fouce"
                                    type="text"
                                    placeholder="请输入关键字进行搜索"
                                    value={search.value}
                                    onKeyDown={(e) => calAge(e)}
                                    onChange={(e) => {
                                        search.value = e.target.value;
                                        setsSearch({ ...search })
                                    }}
                                />
                            </div>
                            : <div className="statistics">
                                <div>
                                    <span>{initData.resourceNumber.day}</span>
                                    <span>今日新增</span>
                                </div>
                                <div>
                                    <span>{initData.resourceNumber.month}</span>
                                    <span>本月新增</span>
                                </div>
                                <div>
                                    <span>{initData.resourceNumber.sum}</span>
                                    <span>总资源数</span>
                                </div>
                            </div>
                    }
                    <div className="search-btn" onClick={() => handelSearch()}>
                        <SearchOutlined />
                    </div>
                </div>
                <div className="bottom">
                    今天热点搜索：
                    {
                        initData.hotKeyword.map((val, key) => {
                            return <span key={"key" + key} onClick={() => {
                                filter.page = 1;
                                filter.search = val;
                                setFilter({ ...filter })
                            }}>{val}</span>
                        })
                    }
                    {
                        initData.hotKeyword.length === 0 &&
                        "暂无热搜关键字"
                    }
                </div>
            </div>
            <div className="ctf-resource-table content-container">
                <Tabs
                    activeKey={activeType}
                    onChange={(key) => {
                        handleToggle(key)
                    }}
                    style={{ 'overflow': 'visible', 'marginBottom': '10px' }}
                >
                    <TabPane
                        tab='场景管理'
                        key='1'
                    >
                        <SceneManage
                            keyword={filter.search}
                            key={filter.search}
                        />
                    </TabPane>
                    <TabPane
                        tab='场景任务'
                        key='2'
                    >
                        <SceneTask
                            keyword={filter.search}
                            key={filter.search}
                        />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}
export default ThreatResources;