import React, { useState, useEffect } from 'react';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Table, Input, Pagination, Divider, Dropdown, Menu } from 'antd'
import { Alert } from '../../../Communal/utility';
import instance from '../../../Communal/instance';
import DeteleBox from '../../deteleBox';
import Comfirm from '../../comfirm';

const { Column } = Table;
const Methods = ({ keyword }) => {
    const [initData, setInitData] = useState({
        data: [],
        allCount: 0,
    });
    const [checkList, setCheckList] = useState([]);
    const [filter, setFilter] = useState({
        page: 1,
        size: 10,
        search: "",
        classify: [],
        status: [],
        username: "",
    });
    const [classify, setClassify] = useState([]);
    const [deleteTip, setDeteleTip] = useState({
        show: false,
        list: []
    })
    const [comfirmShow, setComfirmShow] = useState({
        visible: false,
        mode: 'method',
        issue: '',
    })
    const [userType, setUserType] = useState(0);
    const [userIssue, setIssue] = useState(false);
    useEffect(() => {
        getClassData();
        instance.get("/ctf/user/type/?mode=method").then(data => {
            setUserType(data.user_type);
            setIssue(data.issue);
        })
    }, [])
    useEffect(() => {
        getData();
    }, [filter]);
    useEffect(() => {
        handelSearch(keyword)
    }, [keyword])
    let getData = () => {
        instance.get("/method/", { params: filter }).then(data => {
            initData.data = data.results;
            initData.allCount = data.count;
            setInitData({ ...initData })
        })
    }
    const getClassData = () => {
        instance.get('/ctf/classify/?type=8').then(res => {
            if (res.length > 0) {
                for (var i = 0; i < res.length; i++) {
                    res[i].text = res[i].name;
                    res[i].value = res[i].id
                }
            }
            setClassify(res);
        })
    }
    let handelSearch = (value) => {
        if (value !== filter.search) {
            filter.search = value;
            filter.page = 1;
            setFilter({ ...filter })
        }
    }
    //单个删除或者批量删除操作
    const resourceDelete = (list) => {
        if (list.length === 0) {
            Alert("warn", "请选择需要删除的手法");
            return
        }
        instance.post("/method/deletion/", { id: list }).then(data => {
            if (filter.page > 1 && (initData.data.length === list.length)) {
                filter.page -= 1;
                setFilter({ ...filter })
            } else {
                getData();
            }
            Alert("success", "删除成功");
            setCheckList([]);
            deleteTip.list = [];
            deleteTip.show = false;
            setDeteleTip({ ...deleteTip })
        })
    }
    let handleTableChange = (pagination, filters, sorter) => {
        if (filters.classify) {
            filter.classify = filters.classify;
        } else {
            filter.classify = [];
        }
        if (filters.status) {
            filter.status = filters.status;
        } else {
            filter.status = [];
        }
        if (filters.issue) {
            filter.issue = filters.issue;
        } else {
            filter.issue = [];
        }
        filter.page = 1;
        setFilter({ ...filter });
    }
    const menu = (
        <Menu onClick={({ key }) => { changeStatus(key) }}>
            <Menu.Item key={1}>回收</Menu.Item>
            <Menu.Item key={2}>发布</Menu.Item>
        </Menu>
    );
    const changeStatus = (key) => {
        setComfirmShow({ ...comfirmShow, visible: true, issue: Number(key) });
    }
    const handleIssue = () => {
        setCheckList([]);
        setFilter({ ...filter });
    }
    return (
        <div className="ctf-resource-list" style={{ padding: 0 }}>
            <div className="ctf-resource-table content-container" style={{ marginTop: 0 }}>
                <div className="handle">
                    {
                        userType > 1 ?
                            <div style={{ display: 'inline-block' }}>
                                <Button
                                    type='ghost'
                                    disabled={checkList.length === 0 ? true : false}
                                    danger
                                    onClick={() => {
                                        deleteTip.show = true;
                                        deleteTip.list = checkList;
                                        setDeteleTip({ ...deleteTip })
                                    }}
                                >
                                    批量删除
                                </Button>
                                {
                                    userIssue ?
                                        <Dropdown
                                            overlay={menu}
                                            disabled={checkList.length === 0}
                                        >
                                            <Button type="primary" style={{ marginLeft: '15px' }}>批量修改发布状态<DownOutlined /></Button>
                                        </Dropdown> : undefined
                                }
                                {/* <Button
                                    type='primary'
                                    style={{ marginLeft: '15px' }}
                                    disabled={checkList.length === 0 ? true : false}
                                // onClick={exprotTool}
                                >
                                    批量导出
                                </Button>
                                <Button
                                    type='primary'
                                    disabled={initData.allCount === 0 ? true : false}
                                    style={{ marginRight: '15px' }}
                                // onClick={()=>setBoxShow(true)}
                                >
                                    全部导出
                                </Button> */}
                            </div> : undefined
                    }
                    <Button
                        type="primary"
                        onClick={() => window.open("/#/activity/methodform/0/0")}
                    >
                        提交手法
                    </Button>
                </div>
                <Table
                    dataSource={initData.data}
                    rowKey={record => record.id}
                    bordered
                    pagination={false}
                    scroll={{ x: 1200 }}
                    rowSelection={userType > 1 && {
                        type: "checkbox",
                        selectedRowKeys: checkList,
                        preserveSelectedRowKeys: true,
                        onChange: ((selectedRowKeys) => {
                            setCheckList(selectedRowKeys)
                        })
                    }}
                    onChange={handleTableChange}
                >
                    <Column
                        title="序号"
                        key="serial"
                        align="center"
                        width={80}
                        fixed='left'
                        render={(text, record, index) => {
                            return <span
                                className="click-href"
                                onClick={() => {
                                    window.location.href = "/#/activity/methoddetail/" + record.id
                                }}
                            >
                                {filter.size * (filter.page - 1) + index + 1}
                            </span>
                        }}
                    />
                    <Column
                        title="手法编号"
                        key="number"
                        align="center"
                        dataIndex="number"
                        width={150}
                        fixed='left'
                        render={(text, record) => {
                            return text ? <span
                                className="click-href"
                                onClick={() => {
                                    window.location.href = "/#/activity/methoddetail/" + record.id
                                }}
                            >
                                {text}
                            </span>
                                : "暂无编号"
                        }}
                    />
                    <Column
                        title="手法名称"
                        key="name"
                        align="center"
                        dataIndex="name"
                        width={250}
                        fixed='left'
                        render={(text, record) => {
                            return <a
                                className="click-href"
                                href={"/#/activity/methoddetail/" + record.id}
                            >
                                {text}
                            </a>
                        }}
                    />
                    <Column
                        title="手法类型"
                        key="classify"
                        align="center"
                        dataIndex="classify"
                        filters={classify}
                        width={150}
                    />
                    <Column
                        title="审核状态"
                        key="status"
                        align="center"
                        dataIndex="status"
                        width={150}
                        filters={
                            [
                                { text: "待审核", value: "0" },
                                { text: "通过", value: "1" },
                                { text: "待补充", value: "2" },
                                { text: "拒绝", value: "3" }
                            ]
                        }
                        render={(record) => {
                            return <span className="audit-status">
                                {
                                    record === 0 && "待审核"
                                }
                                {
                                    record === 1 && <span className="pass">通过</span>
                                }
                                {
                                    record === 2 && <span className="added">待补充</span>
                                }
                                {
                                    record === 3 && <span className="denied">拒绝</span>
                                }
                            </span>
                        }}
                    />
                    <Column
                        title="发布状态"
                        key="issue"
                        align="center"
                        dataIndex="issue"
                        filterMultiple={false}
                        width={150}
                        filters={
                            [
                                { text: "未发布", value: 1 },
                                { text: "已发布", value: 2 },
                            ]
                        }
                        render={(record) => {
                            return <span className="audit-status">
                                {
                                    record === 1 && <span className="added">未发布</span>
                                }
                                {
                                    record === 2 && <span className="pass">已发布</span>
                                }
                            </span>
                        }}
                    />
                    <Column
                        title="提交者"
                        key="username"
                        align="center"
                        dataIndex="username"
                        width={150}
                        filterDropdown={({ clearFilters }) => {
                            return <div className="table-search">
                                <Input
                                    placeholder="请输入提交者名称"
                                    value={filter.username}
                                    onChange={(e) => {
                                        filter.username = e.target.value;
                                        filter.page = 1;
                                        setFilter({ ...filter })
                                    }}
                                />
                                <Button type="primary" onClick={() => { clearFilters() }}>确定</Button>
                                <Button onClick={() => {
                                    clearFilters();
                                    filter.username = "";
                                    filter.page = 1;
                                    setFilter({ ...filter })
                                }}>取消</Button>
                            </div>
                        }}
                        filterIcon={<SearchOutlined />}
                    />
                    <Column
                        title="提交时间"
                        key="sub_time"
                        align="center"
                        dataIndex="sub_time"
                        width={200}
                    />
                    <Column
                        title="操作"
                        key="operate"
                        align="center"
                        width={userType > 1 ? userIssue ? 260 : 210 : 160}
                        fixed='right'
                        render={(record) => {
                            return <div>
                                <Button
                                    style={{ padding: 0 }}
                                    type="link"
                                    onClick={() => {
                                        window.location.href = "/#/activity/methoddetail/" + record.id
                                    }}
                                >
                                    详情
                                </Button>
                                <Divider type='vertical' />
                                <Button
                                    style={{ padding: 0 }}
                                    disabled={!record.change}
                                    type="link"
                                    onClick={() => {
                                        window.location.href = "/#/activity/methodform/1/" + record.id
                                    }}
                                >
                                    编辑
                                </Button>
                                <Divider type='vertical' />
                                {
                                    userType > 1 ? <>
                                        <Button
                                            style={{ padding: 0 }}
                                            type="link"
                                            onClick={() => {
                                                window.open("/#/activity/methodform/2/" + record.id)
                                            }}
                                        >
                                            审核
                                        </Button>
                                        <Divider type='vertical' />
                                    </> : undefined
                                }
                                {
                                    userIssue ? <>
                                        {
                                            record.issue === 1 ?
                                                <Button
                                                    style={{ padding: 0 }}
                                                    disabled={record.status !== 1}
                                                    type="link"
                                                    onClick={() => {
                                                        setComfirmShow({ ...comfirmShow, visible: true, issue: 2 });
                                                        setCheckList([record.id])
                                                    }}
                                                >
                                                    发布
                                                </Button> :
                                                <Button
                                                    style={{ padding: 0 }}
                                                    type="link"
                                                    danger
                                                    onClick={() => {
                                                        setComfirmShow({ ...comfirmShow, visible: true, issue: 1 });
                                                        setCheckList([record.id])
                                                    }}
                                                >
                                                    回收
                                                </Button>
                                        }
                                        <Divider type='vertical' />
                                    </> : undefined
                                }
                                <Button
                                    type="link"
                                    disabled={!record.change}
                                    style={{ padding: 0 }}
                                    danger
                                    onClick={() => {
                                        deleteTip.show = true;
                                        deleteTip.list = [record.id];
                                        setDeteleTip({ ...deleteTip })
                                    }}
                                >删除</Button>
                            </div>
                        }}
                    />
                </Table>
                <Pagination
                    showQuickJumper={true}
                    current={filter.page}
                    pageSize={filter.size}
                    total={initData.allCount}
                    hideOnSinglePage={initData.allCount === 0}
                    pageSizeOptions={['10', '20', '50', '100']}
                    showSizeChanger={true}
                    className="flip-over"
                    style={{ margin: "30px 0" }}
                    onChange={
                        ((page,pageSize) => {
                            let data = { ...filter }
                            data.page = page;
                            data.size = pageSize;
                            setFilter(data)
                        })
                    }
                />
            </div>
            {
                deleteTip.show &&
                <DeteleBox
                    close={() => {
                        deleteTip.show = false;
                        deleteTip.list = [];
                        setDeteleTip({ ...deleteTip })
                    }}
                    title='手法'
                    submit={() => resourceDelete(deleteTip.list)}
                />
            }
            <Comfirm
                boxProperty={comfirmShow}
                onClose={() => {
                    setComfirmShow({ ...comfirmShow, visible: false })
                }}
                onOk={handleIssue}
                ids={checkList}
            />
        </div>
    )
}
export default Methods;