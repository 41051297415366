import React, { useEffect, useState, useRef } from 'react';
import { Button, Input, Select, Progress, Tabs, Radio } from 'antd';
import { PaperClipOutlined, CheckCircleFilled } from '@ant-design/icons';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import { ContentUtils } from 'braft-utils'
import Denied from '../../../../images/denied.png';
import DeclineBox from '../../../Activity/DeclineBox';
import DeleteTest from '../../../../images/delete-test-icon.png';
import DeteleIcon from '../../../../images/detele-icon.png';
import AddedBox from '../../../Activity/AddedBox';
import history from '../../../Communal/history';
import { Alert } from '../../../Communal/utility';
import instance from '../../../Communal/instance';
import $ from 'jquery'
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;
const options = {
    syntaxs: [
        {
            name: 'JavaScript',
            syntax: 'javascript'
        }, {
            name: 'HTML',
            syntax: 'html'
        }, {
            name: 'CSS',
            syntax: 'css'
        }, {
            name: 'Java',
            syntax: 'java',
        }, {
            name: 'PHP',
            syntax: 'php'
        }, {
            name: "Python",
            syntax: 'python'
        }
    ],
}
const SceneTaskForm = ({ param }) => {
    // const operations = <Button>文件导入</Button>
    const dateRef = useRef()
    const [type, setType] = useState("0"); //type: 0 添加 1 编辑 2 审核
    const [submit, setSubmit] = useState({
        scene: null,
        name: '',
        remarks: '',
        taskdesc: '',
        attached: '',
        attached_name: '',
        guidebook: BraftEditor.createEditorState(null),
        tags: [],
        testquestions: []
    });
    const [error, setError] = useState({
        scene: [],
        name: [],
        remarks: [],
        taskdesc: [],
        tags: [],
        attached: [],
        guidebook: [],
        testquestions: []
    });
    const [imagesType, setImagesType] = useState(0);
    const [initBox, setInitBox] = useState({
        passBox: false,
        declineBox: false,
        addedBox: false
    })
    const [activeType, setActiveType] = useState("1");
    const [submitLoading, setSubmitLoading] = useState(false);
    const [attachedShow, setAttachedShow] = useState(false);
    const [attachedPercent, setAttachedPercent] = useState(0)
    const [changeTest, setChangeTest] = useState(0);
    const [scene, setScene] = useState([]);
    const [sceneName, setSceneName] = useState();
    useEffect(() => {
        setType(param.match.params.type);
        getInfo();
        if (param.match.params.id !== '0') {
            // 获取详情
            getDetail(param.match.params.id)
        }
    }, [])
    const getInfo = () => {
        instance.get('/scene/api/scenename/').then(data => {
            setSceneName(data[0]?.id)
            submit.scene = data[0]?.id
            setScene(data)
        })
    }
    const getDetail = (id) => {
        instance.get(`/scene/api/all/${id}/`).then(data => {
            let testquestions = []
            data.guidebook = BraftEditor.createEditorState(data.guidebook)
            if (data.testquestions.length > 0) {
                for (var i = 0; i < data.testquestions.length; i++) {
                    if (data.testquestions[i].type === 1) {
                        data.testquestions[i].option_copy = [];
                        for (var key in data.testquestions[i].option) {
                            data.testquestions[i].option_copy.push(data.testquestions[i].option[key])
                        }
                        testquestions.push({
                            type: 1,
                            title: data.testquestions[i].title,
                            option: data.testquestions[i].option_copy,
                            answer: data.testquestions[i].answer,
                            score: data.testquestions[i].score,
                            theorytype: data.testquestions[i].theorytype,
                            analysis: data.testquestions[i].analysis,
                        })
                    } else {
                        testquestions.push({
                            type: 0,
                            flag: data.testquestions[i].flag,
                            desc: data.testquestions[i].desc,
                            score: data.testquestions[i].score,
                        })
                    }
                }
            }
            data.testquestions = testquestions;
            setSubmit(data);
        })
    }
    const inputChange = (value, name) => {
        submit[name] = value;
        setSubmit({ ...submit })
    }
    const handleFocus = (name) => {
        error[name] = []
        setError({ ...error })
    }
    const uploadChange = () => {
        if (attachedShow === true) {
            Alert('warn', '有文件正在上传中，请稍候')
            return
        }
        document.getElementsByClassName("upload-file-one")[0].click();
        document.getElementsByClassName("upload-file-one")[0].value = "";
        submit.attached = '';
        submit.attached_name = '';
        error.attached = [];
        setSubmit({ ...submit })
        setError({ ...error })
    }
    // 插入图片到媒体库
    const addMediaItem = () => {
        document.getElementsByClassName("images")[0].click();
        document.getElementsByClassName("images")[0].value = "";
        setImagesType(0);
    }
    // 插入图片到编辑器
    const insertMediItem = () => {
        document.getElementsByClassName("images")[0].click();
        document.getElementsByClassName("images")[0].value = "";
        setImagesType(1);
    }
    let myUploadFn = (param) => {
        const fd = new FormData();
        fd.append('image', param.file);
        fd.append("model", "scenetask");
        instance.post("/ctf/upload_image/", fd).then(data => {
            param.success({
                url: data.url,
            })
        })
    }
    // 上传图片
    const changeImage = () => {
        const file = document.getElementsByClassName("images")[0].files[0];
        if (file !== undefined) {
            if (file.size > 5 * 1024 * 1024) {
                Alert("error", "上传图片不能大于5M")
                return
            }
            const form = document.getElementById('file-image');
            const formData = new FormData(form);
            formData.append("model", "scenetask");
            instance.post("/ctf/upload_image/", formData).then(data => {
                if (imagesType === 0) {
                    if (dateRef.current !== undefined) {
                        let braftFinder = dateRef.current.getFinderInstance();
                        braftFinder.addItems([
                            {
                                id: new Date().getTime(),
                                type: 'IMAGE',
                                url: data.url
                            }
                        ])
                    }
                } else if (imagesType === 1) {
                    const editorState = ContentUtils.insertMedias(submit.content, [
                        {
                            type: 'IMAGE',
                            url: data.url
                        }
                    ])
                    submit.content = editorState;
                    setSubmit({ ...submit })
                }
                Alert("success", "上传成功")
            }).catch(err => {
                if (err.image) {
                    Alert("error", err.image)
                }
            })
        }
    }
    // 上传文件
    const changeFile = () => {
        let file = document.getElementsByClassName("upload-file-one")[0].files[0];
        if (file !== undefined) {
            if (file.size > 1024 * 1024 * 500) {
                Alert("error", "上传附件不能大于500M");
                return
            }
            let form = document.getElementById('formImg1');
            let formData = new FormData(form);
            /* 场景任务 */
            formData.append("model", "scenetask")
            setAttachedShow(true)
            $.ajax({
                url: "/ctf/upload_file/",
                type: "POST",
                data: formData,
                contentType: false,
                processData: false,
                xhr: () => {
                    var myXhr = $.ajaxSettings.xhr()
                    if (myXhr.upload) { // check if upload property exists 
                        myXhr.upload.addEventListener('progress', (e) => {
                            var loaded = e.loaded;//已经上传大小情况
                            var tot = e.total;//附件总大小
                            var per = Math.floor(100 * loaded / tot);  //已经上传的百分比
                            setAttachedPercent(per)
                            if (myXhr.readyState === 1 && per === 100) {
                                setAttachedPercent(99)
                            }
                        }, false);
                    }
                    return myXhr;
                },
                success: (data) => {
                    setAttachedShow(false)
                    setAttachedPercent(0)
                    submit.attached_name = data.origin_file_name;
                    submit.attached = data.url
                    setSubmit({ ...submit })
                },
                error: (err) => {
                    error.attached = err.responseJSON.non_field_errors
                    setAttachedShow(false)
                    setError({ ...error })
                }
            })
        }
    }
    //点击增加自测题
    let addTest = (type) => {
        // type 0flag  1理论
        if (submit.testquestions.length < 5) {
            if (type === 1) {
                submit.testquestions.push({
                    type: 1,
                    theorytype: 0,//类型 单选多选
                    score: '',
                    title: "",//题目
                    option: ["", ""],//所有选项
                    analysis: '',//解析
                    answer: []//答案
                })
            } else {
                submit.testquestions.push({
                    type: 0,
                    flag: "",
                    desc: '',
                    score: '',
                })
            }
            setSubmit({ ...submit })
        } else {
            Alert("warn", "最多添加五道自测题！！！")
        }
    }
    //点击删除自测题
    const handleDeleteTest = (key) => {
        submit.testquestions.splice(key, 1);
        setSubmit({ ...submit });
        setChangeTest(0)
    }
    // 删除选项
    const deleteOptions = (key) => {
        submit.testquestions[changeTest].option.splice(key, 1)
        setSubmit({ ...submit })
    }
    // 选择答案
    const handleOptionAnswer = (key) => {
        const letterKey = String.fromCharCode(64 + parseInt(key + 1))
        const type = submit.testquestions[changeTest].theorytype
        // 多选
        if (type === 1) {
            if (submit.testquestions[changeTest].answer.indexOf(letterKey) === -1) {
                submit.testquestions[changeTest].answer.push(letterKey)
            } else {
                submit.testquestions[changeTest].answer.splice(submit.testquestions[changeTest].answer.indexOf(letterKey), 1)
            }
        } else {
            submit.testquestions[changeTest].answer = letterKey.split('')
        }
        setSubmit({ ...submit })
    }
    //增加选项
    const moreOptions = () => {
        if (submit.testquestions[changeTest].option.length < 7) {
            submit.testquestions[changeTest].option.push("");
            setSubmit({ ...submit })
        }
        else {
            Alert('warn', '最多只能添加7个选项！')
        }
    }
    // 审核
    let handleAudit = (data) => {
        if ((data.status === 2 || data.status === 3) && data.data === "") {
            Alert("error", "请输入审核意见");
            return
        }
        instance.post("/scene/api/audit/" + param.match.params.id + "/", data).then(data => {
            Alert("success", "操作成功");
            history.push({ pathname: '/resource/scene', state: { tabKey: 2 } })
            initBox.declineBox = false;
            initBox.addedBox = false;
            setInitBox({ ...initBox })
            handleAdd(2, 2)
        }).catch(err => {
            Alert("error", err.error)
        })
    }
    let handleAdd = (type, role) => {
        setSubmitLoading(true);
        if (submit.scene === null) {
            error.scene = ['请选择所属场景']
        }
        if (submit.name === '') {
            error.name = ['请输入任务名称']
        }
        if (submit.taskdesc === '') {
            error.taskdesc = ['请输入任务描述']
        }
        if (submit.guidebook.toHTML() === "<p></p>") {
            Alert('warn', "请填写任务指导书")
            error.guidebook = ['请填写任务指导书']
        }
        if (submit.testquestions.length === 0) {
            Alert('warn', "请最少提供一道自测题")
            error.testquestions = ['请最少提供一道自测题']
        }
        if (
            submit.scene === null ||
            submit.name === '' ||
            submit.taskdesc === '' ||
            submit.guidebook.toHTML() === "<p></p>" ||
            submit.testquestions.length === 0
        ) {
            setError({ ...error });
            setSubmitLoading(false);
            return
        }
        let list = {
            scene: submit.scene,
            name: submit.name,
            remarks: submit.remarks,
            taskdesc: submit.taskdesc,
            attached: submit.attached,
            attached_name: submit.attached_name,
            guidebook: submit.guidebook.toHTML(),
            testquestions: submit.testquestions
        }
        let testquestions = [];
        list.testquestions = testquestions;
        for (var i = 0; i < submit.testquestions.length; i++) {
            if (submit.testquestions[i].type === 1) {
                submit.testquestions[i].option_copy = {};
                for (var j = 0; j < submit.testquestions[i].option.length; j++) {
                    submit.testquestions[i].option_copy[String.fromCharCode(64 + parseInt(j + 1))] = submit.testquestions[i].option[j]
                }
                testquestions.push({
                    type: 1,
                    title: submit.testquestions[i].title,
                    option: submit.testquestions[i].option_copy,
                    answer: submit.testquestions[i].answer,
                    score: submit.testquestions[i].score,
                    theorytype: submit.testquestions[i].theorytype,
                    analysis: submit.testquestions[i].analysis,
                })
            }
            else {
                testquestions.push({
                    type: 0,
                    flag: submit.testquestions[i].flag,
                    desc: submit.testquestions[i].desc,
                    score: submit.testquestions[i].score,
                })
            }
        }
        if (attachedShow === true) {
            Alert('warn', '有文件正在上传中，请稍候')
            return
        }
        if (type === 1) {
            instance.post('/scene/api/all/', list).then(res => {
                Alert("success", "提交成功");
                // history.push({ pathname: '/resource/scene', state: { tabKey: 2 } })
                setSubmit({
                    scene: sceneName,
                    name: '',
                    remarks: '',
                    taskdesc: '',
                    attached: '',
                    attached_name: '',
                    guidebook: BraftEditor.createEditorState(null),
                    tags: [],
                    testquestions: []
                })
                setSubmitLoading(false)
            }).catch(err => {
                if (err.testquestions) {
                    Alert('warn', err.testquestions[0])
                }
                setSubmitLoading(false);
                errorInfo(err);
            })
        } else if (type === 2) {
            instance.patch(`/scene/api/all/${param.match.params.id}/`, list).then(res => {
                if (role === 1) {
                    Alert("success", "修改成功");
                } else if (role === 2) {
                    // Alert("success","修改成功");
                } else {
                    Alert("success", "提交成功");
                    history.push({ pathname: '/resource/scene', state: { tabKey: 2 } });
                }
                setSubmitLoading(false)
            }).catch(err => {
                if (err.testquestions) {
                    Alert('warn', err.testquestions[0])
                }
                setSubmitLoading(false);
                errorInfo(err);
            })
        }
    }
    const errorInfo = (err) => {
        if (err.error) {
            Alert('error', err.error)
        }
        if (err.scene) {
            error.scene = err.scene
        }
        if (err.name) {
            error.name = err.name
        }
        if (err.remarks) {
            error.remarks = err.remarks
        }
        if (err.taskdesc) {
            error.taskdesc = err.taskdesc
        }
        if (err.attached) {
            error.attached = err.attached
        }
        if (err.guidebook) {
            error.guidebook = err.guidebook
        }
        if (err.attached_name) {
            error.attached = err.attached_name
        }
        setError({ ...error })
    }

    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-div submit-ctf-name">
                    {
                        type === "0" && "提交场景任务"
                    }
                    {
                        type === "1" && "编辑场景任务"
                    }
                    {
                        type === "2" && "审核场景任务"
                    }
                    {
                        submit.status === 3 &&
                        <div className="status">
                            <img src={Denied} alt="" />
                        </div>
                    }
                </div>
                {
                    submit.is_show_opin && submit.opin && submit.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    submit.opin &&
                                    submit.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">{val.msg}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div subject-info">
                    <div className="content">
                        <div className="retrieval-div">
                            <span><b>*</b>所属场景</span>
                            <Select
                                value={submit.scene}
                                placeholder='请选择任务场景'
                                style={{ width: 180 }}
                                onChange={(value) => inputChange(value, 'scene')}
                                onClick={() => handleFocus("scene")}
                            >
                                {
                                    scene.map((val, key) => {
                                        return <Option key={key} value={val.id}>
                                            {val.name}
                                        </Option>
                                    })

                                }
                            </Select>
                            <div className="error-message">{error.scene[0]}</div>
                        </div>
                        <div className="retrieval-div">
                            <span ><b>*</b>任务名称</span>
                            <Input
                                value={submit.name}
                                className="content-input"
                                placeholder="请输入任务名称"
                                onChange={(e) => inputChange(e.target.value, "name")}
                                maxLength="50"
                                onFocus={() => handleFocus("name")}
                            />
                            <div className="error-message">{error.name[0]}</div>
                        </div>
                        <div className="retrieval-div">
                            <span >用途备注</span>
                            <Input
                                value={submit.remarks}
                                className="content-input"
                                placeholder="请输入用途备注"
                                onChange={(e) => inputChange(e.target.value, "remarks")}
                                maxLength="150"
                                onFocus={() => handleFocus("remarks")}
                            />
                            <div className="error-message">{error.remarks[0]}</div>
                        </div>
                        <div className="retrieval-div">
                            <span ><b>*</b>任务描述</span>
                            <TextArea
                                value={submit.taskdesc}
                                className="content-input"
                                placeholder="请输入手法背景"
                                onChange={(e) => inputChange(e.target.value, "taskdesc")}
                                maxLength="300"
                                onFocus={() => handleFocus("taskdesc")}
                            />
                            <div className="error-message">{error.taskdesc[0]}</div>
                        </div>
                        <div className="methods-scene-tags  retrieval-div upload-tool-div">
                            <span>任务附件</span>
                            <form
                                id="formImg1"
                                style={{ 'display': 'inline-block' }}
                            >
                                <div>
                                    <span
                                        className="upload"
                                        onClick={uploadChange}
                                        style={{
                                            'color': '#266cff',
                                            'margin': '0 10px 0 45px',
                                            'fontSize': '15px',
                                            'cursor': 'pointer'
                                        }}
                                    >
                                        选择
                                        <input
                                            type="file"
                                            name="file"
                                            className="upload-file-one"
                                            accept=".zip,.rar"
                                            style={{ 'display': 'none' }}
                                            onChange={changeFile}
                                        />
                                    </span>
                                    {
                                        submit.attached !== '' && [
                                            <span className="file-name" key="1">
                                                <a href={submit.attached} download>
                                                    <PaperClipOutlined />
                                                    {submit.attached_name}
                                                </a>
                                            </span>,
                                            <Button type="link" key="2" danger onClick={() => {
                                                submit.attached_name = "";
                                                submit.attached = '';
                                                setSubmit({ ...submit })
                                            }}>删除</Button>
                                        ]
                                    }
                                    {
                                        attachedShow &&
                                        <>
                                            <Progress percent={attachedPercent} />
                                            <span> （上传中，请稍候）</span>
                                        </>
                                    }
                                </div>
                            </form>
                            <div> <div className="error-message" >{error.attached[0]}</div></div>
                        </div>
                        <div className="retrieval-div">
                            <Tabs
                                activeKey={activeType}
                                onChange={(key) => {
                                    setActiveType(key);
                                }}
                            // tabBarExtraContent={activeType === "1" && operations}
                            >
                                <TabPane
                                    tab='任务指导书'
                                    key='1'
                                >
                                    <BraftEditor
                                        value={submit.guidebook}
                                        onChange={(editorState) => inputChange(editorState, 'guidebook')}
                                        onFocus={() => handleFocus("guidebook")}
                                        ref={dateRef}
                                        media={{
                                            uploadFn: myUploadFn,
                                            image: false
                                        }}
                                        excludeControls={["superscript", "subscript", "emoji", "remove-styles"]}
                                        extendControls={[
                                            'separator',
                                            {
                                                key: 'add-media',
                                                type: 'button',
                                                text: '插入图片到媒体库',
                                                onClick: () => addMediaItem()
                                            },
                                            {
                                                key: 'insert-media',
                                                type: 'button',
                                                text: '插入图片到编辑器',
                                                onClick: () => insertMediItem()
                                            }
                                        ]}
                                    />
                                    <div
                                        className="error-message"
                                        style={{ margin: "0" }}
                                    >
                                        {error.guidebook[0]}
                                    </div>
                                </TabPane>
                                <TabPane
                                    tab='任务测试题'
                                    key='2'
                                >
                                    <div className='submit-ctf-div experimental-subject'>
                                        <div className='title'>
                                            {
                                                submit.testquestions.map((val, key) => {
                                                    return <span
                                                        key={key}
                                                        className={key === changeTest ? "active" : ""}>

                                                        <span
                                                            onClick={() => {
                                                                setChangeTest(key)
                                                            }}
                                                        >
                                                            自测题{key + 1}
                                                        </span>
                                                        {submit.testquestions.length > 1 && <img src={DeleteTest} alt="" onClick={() => handleDeleteTest(key)} />
                                                        }
                                                    </span>
                                                })
                                            }
                                            <div>
                                                <span>
                                                    <span
                                                        className='addTest'
                                                        onClick={() => addTest(1)}
                                                        style={{ 'marginRight': '20px' }}
                                                    >+新建理论测试</span>
                                                    <span
                                                        className='addTest'
                                                        onClick={() => addTest(0)}
                                                    >+新建Flag测试</span>
                                                </span>
                                                <span>最少提供一道自测题目</span>
                                            </div>
                                        </div>
                                        {
                                            submit.testquestions.length > 0 ?
                                                <div className="content">
                                                    {
                                                        submit.testquestions[changeTest].type === 1 ?
                                                            <>
                                                                <div>
                                                                    <span><b>*</b>类型：</span>
                                                                    <div>
                                                                        <Radio.Group
                                                                            value={submit.testquestions[changeTest].theorytype}
                                                                            onChange={e => {
                                                                                submit.testquestions[changeTest].theorytype = e.target.value;
                                                                                setSubmit({ ...submit })
                                                                            }}>
                                                                            <Radio value={0}>单选</Radio>
                                                                            <Radio value={1}>多选</Radio>
                                                                            <Radio value={2}>判断</Radio>
                                                                        </Radio.Group>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <span><b>*</b>分值：</span>
                                                                    <div>
                                                                        <Input
                                                                            type='text'
                                                                            value={submit.testquestions[changeTest].score}
                                                                            onChange={(e) => {
                                                                                submit.testquestions[changeTest].score = e.target.value;
                                                                                setSubmit({ ...submit })
                                                                            }}
                                                                            maxLength='5'
                                                                            style={{ width: 180 }}
                                                                            placeholder="请输入题目分值"
                                                                            onKeyUp={(e) => { e.target.value = e.target.value.replace(/[^\d]/g, '') }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <span><b>*</b>题目：</span>
                                                                    <div>
                                                                        <Input
                                                                            value={submit.testquestions[changeTest].title}
                                                                            onChange={(e) => {
                                                                                submit.testquestions[changeTest].title = e.target.value;
                                                                                setSubmit({ ...submit })
                                                                            }}
                                                                            maxLength='100'
                                                                            className="content-input"
                                                                            placeholder="请输入题目"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <span><b>*</b>选项：</span>
                                                                    <div className="option">
                                                                        {
                                                                            submit.testquestions[changeTest].option.map((val, key) => {
                                                                                return <div key={key}>
                                                                                    {
                                                                                        submit.testquestions[changeTest].option.length > 2 &&
                                                                                        <img src={DeteleIcon} alt="" onClick={() => deleteOptions(key)} />
                                                                                    }
                                                                                    选项 {String.fromCharCode(64 + parseInt(key + 1))}：
                                                                                    <Input
                                                                                        value={val}
                                                                                        onChange={(e) => {
                                                                                            submit.testquestions[changeTest].option[key] = e.target.value;
                                                                                            setSubmit({ ...submit })
                                                                                        }}
                                                                                        className="content-input"
                                                                                        maxLength='100'
                                                                                        placeholder="请输入选项内容"
                                                                                    />
                                                                                    <span
                                                                                        onClick={() => { handleOptionAnswer(key) }}
                                                                                        className={
                                                                                            submit.testquestions[changeTest].answer.indexOf(String.fromCharCode(64 + parseInt(key + 1))) === -1 ? "" : "active"
                                                                                        }
                                                                                    >
                                                                                        <CheckCircleFilled
                                                                                            style={{ fontSize: 18 }}

                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                            })
                                                                        }
                                                                        <div className="add-btn">
                                                                            <span onClick={moreOptions}>+增加选项</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <span><b>*</b>解析：</span>
                                                                    <div>
                                                                        <Input
                                                                            value={submit.testquestions[changeTest].analysis}
                                                                            onChange={(e) => {
                                                                                submit.testquestions[changeTest].analysis = e.target.value;
                                                                                setSubmit({ ...submit })
                                                                            }}
                                                                            maxLength="300"
                                                                            className="content-input"
                                                                            placeholder="请输入题目解析"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div>
                                                                    <span className='flagtest-title'><b>*</b>flag：</span>
                                                                    <div className='flagtest-content'>
                                                                        <Input
                                                                            value={submit.testquestions[changeTest].flag}
                                                                            onChange={(e) => {
                                                                                submit.testquestions[changeTest].flag = e.target.value;
                                                                                setSubmit({ ...submit })
                                                                            }}
                                                                            maxLength="100"
                                                                            className="content-input"
                                                                            placeholder="请输入flag"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <span className='flagtest-title'><b>*</b>flag说明：</span>
                                                                    <div className='flagtest-content'>
                                                                        <Input
                                                                            value={submit.testquestions[changeTest].desc}
                                                                            onChange={(e) => {
                                                                                submit.testquestions[changeTest].desc = e.target.value;
                                                                                setSubmit({ ...submit })
                                                                            }}
                                                                            className="content-input"
                                                                            placeholder="flag为32位"
                                                                            maxLength={32}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <span className='flagtest-title'><b>*</b>flag分值：</span>
                                                                    <div className='flagtest-content'>
                                                                        <Input
                                                                            value={submit.testquestions[changeTest].score}
                                                                            onChange={(e) => {
                                                                                submit.testquestions[changeTest].score = e.target.value;
                                                                                setSubmit({ ...submit })
                                                                            }}
                                                                            className="content-input"
                                                                            placeholder="请输入flag分值"
                                                                            type='text'
                                                                            maxLength='5'
                                                                            style={{ width: 180 }}
                                                                            onKeyUp={(e) => { e.target.value = e.target.value.replace(/[^\d]/g, '') }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                :
                                                <div className="content">
                                                    暂无测试题目，根据场景特点点击【新建理论测试】/【新建Flag测试】添加对应的测试题目
                                                    <div
                                                        className="error-message"
                                                        style={{ margin: "0" }}
                                                    >
                                                        {error.testquestions[0]}
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </div>
                {
                    //新增
                    type === "0" &&
                    <div className="submit-btn">
                        <Button type="primary" loading={submitLoading} onClick={() => handleAdd(1)}>提交</Button>
                    </div>
                }
                {
                    //编辑
                    type === "1" &&
                    <div className="submit-btn">
                        <Button type="primary" loading={submitLoading} onClick={() => handleAdd(2)}>提交</Button>
                    </div>
                }
                {
                    type === "2" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => {
                                // initBox.passBox = true;
                                // setInitBox({ ...initBox })
                                handleAudit({ status: 1 })
                            }}
                        >
                            通过
                        </Button>
                        <Button
                            style={{ backgroundColor: "transparent", color: "#266cff" }}
                            type="primary"
                            onClick={() => history.push({ pathname: '/resource/scene', state: { tabKey: 2 } })}
                        >
                            取消
                        </Button>
                        <Button className="modify" loading={submitLoading} onClick={() => handleAdd(2, 1)}>保存修改</Button>
                        <Button
                            className="decline"
                            onClick={() => {
                                initBox.declineBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            拒绝
                        </Button>
                        <Button
                            className="added"
                            onClick={() => {
                                initBox.addedBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            待补充
                        </Button>
                        <Button
                            className="added"
                            onClick={() => handleAudit({ status: 0 })}
                        >
                            待审核
                        </Button>
                    </div>
                }
            </div>
            <form id="file-image">
                <input
                    type="file"
                    name="image"
                    accept=".jpg,.png,.gif"
                    style={{ display: "none" }}
                    className="images"
                    onChange={changeImage}
                />
            </form>
            {
                initBox.declineBox &&
                <DeclineBox
                    close={() => {
                        initBox.declineBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.addedBox &&
                <AddedBox
                    close={() => {
                        initBox.addedBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
        </div>
    )
}
export default SceneTaskForm;