/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Search from 'antd/lib/input/Search';
import { Table,Pagination } from 'antd';
import instance from '../Communal/instance';
const { Column } = Table;
function HaveBought() {
    const [initData, setInitData] = useState(
        {
            data: [],
            allCount: 0
        }
    )
    const [filter, setFilter] = useState(
        {
            page: 1,
            size: 10,
            search: "",
            type: "ctf"
        }
    );
    const [classify, setClassify] = useState([])
    const [type, setType] = useState(1)
    const [sortedInfo, setSortedInfo] = useState({})
    const [filteredInfo] = useState({})
    let handleTableChange = (pagination, filters, sorter) => {
        if (filters.classify_name) {
            let classify_name = [];
            classify.forEach(x => {
                if (filters.classify_name.includes(String(x.id))) {
                    classify_name.push(x.name)
                }
            })
            filter.classify_name = classify_name
        } else {
            filter.classify_name = []
        }
        if (filters.status) {
            filter.status = filters.status;
        } else {
            filter.status = []
        }
        if (sorter.order) {
            if (sorter.columnKey === "is_env") {
                if (sorter.order === "ascend") {
                    filter.ordering = "is_env"
                } else {
                    filter.ordering = "-is_env"
                }
            }
            if (sorter.columnKey === "consume_coin") {
                if (sorter.order === "ascend") {
                    filter.ordering = "consume_coin"
                } else {
                    filter.ordering = "-consume_coin"
                }
            }
        } else {
            filter.ordering = ""
        }
        setFilter({ ...filter })
        setSortedInfo(sorter)
    }
    useEffect(() => {
        getClass(1)
    }, [])
    //获取类型
    let getClass = (type) => {
        instance.get("/ctf/classify/?type=" + type).then(data => {
            if (data.length > 0) {
                for (var i = 0; i < data.length; i++) {
                    data[i].text = data[i].name;
                    data[i].value = data[i].id
                }
            }
            setClassify(data)
        })
    }
    //工具类型
    const getToolClass = () => {
        instance.get("/tool/classify/").then(data => {
            if (data.length > 0) {
                for (var i = 0; i < data.length; i++) {
                    data[i].text = data[i].name;
                    data[i].value = data[i].id
                }
            }
            setClassify(data)
        })
    }
    useEffect(() => {
        getCtf()
    }, [filter, type]);
    //获取ctf学习列表
    let getCtf = () => {
        instance.get("/ctf/learn/log/", { params: filter }).then(data => {
            initData.data = data.results;
            initData.allCount = data.count;
            setInitData({ ...initData })
        })
    }
    let toggleType = (type) => {
        filter.page = 1;
        filter.size = 10;
        filter.search = "";
        if (filter.classify_name) {
            delete filter.classify_name
        }
        if (type === 1) {
            filter.type = "ctf"
        } else if (type === 2) {
            filter.type = "experiment"
        } else if (type === 3) {
            filter.type = "vuln"
        } else if (type === 4) {
            filter.type = "tool"
        }
        setFilter({ ...filter })
        if (type === 4) {
            getToolClass()
        } else {
            getClass(type)
        }
        setInitData({
            data: [],
            allCount: 0
        })
        setType(type)
    }
    return (
        <div className="have-bought resource-submit-list">
            <div className="toggle">
                <span
                    className={type === 1 ? "active" : ""}
                    onClick={() => {
                        toggleType(1)
                    }}
                >
                    购买CTF题目记录
                    <b></b>
                </span>
                <span
                    className={type === 2 ? "active" : ""}
                    onClick={() => {
                        toggleType(2)
                    }}
                >
                    购买实验资源记录
                    <b></b>
                </span>
                <span
                    className={type === 3 ? "active" : ""}
                    onClick={() => {
                        toggleType(3)
                    }}
                >
                    购买漏洞资源记录
                    <b></b>
                </span>
                <span
                    className={type === 4 ? "active" : ""}
                    onClick={() => {
                        toggleType(4)
                    }}
                >
                    购买工具资源记录
                    <b></b>
                </span>
                {/* <span
                    className={type===5?"active":""}
                    onClick={()=>{
                        toggleType(5)
                    }}
                >
                    购买插件资源记录
                    <b></b>
                </span> */}
            </div>
            <div className="content">
                <div className="all-count">
                    总计：共<b>{initData.allCount}</b>个素材
                    <Search
                        value={filter.search}
                        placeholder="请输入关键字"
                        onChange={(e) => {
                            filter.page = 1;
                            filter.search = e.target.value;
                            setFilter({ ...filter })
                        }}
                    />
                </div>
                <Table
                    dataSource={initData.data}
                    rowKey={record => record.id}
                    bordered
                    pagination={false}
                    onChange={handleTableChange}
                >
                    {type === 4 ? <>
                        <Column
                            title="序号"
                            key="serial"
                            align="center"
                            width="10%"
                            render={(text, record, index) => {
                                return filter.size * (filter.page - 1) + index + 1
                            }}
                        />
                        <Column
                            title="工具编号"
                            key="number"
                            align="center"
                            dataIndex="number"
                            width="20%"
                            render={(record) => {
                                return record ? record : "暂无编号"
                            }}
                        />
                        <Column
                            title="工具名称"
                            key="name"
                            align="center"
                            dataIndex="name"
                            width="20%"
                        />
                        <Column
                            title="工具类型"
                            key="classify_name"
                            align="center"
                            dataIndex="classify_name"
                            filters={classify}
                            filteredValue={filteredInfo.classify || null}
                            width="15%"
                            render={(text, record) => {
                                return <div>
                                    {record.classify_name ? record.classify_name : <span>暂无一级分类</span>}
                                </div>
                            }}
                        />
                        <Column
                            title="花费奖励"
                            key="consume_coin"
                            align="center"
                            width="15%"
                            dataIndex="consume_coin"
                            sorter="true"
                            sortOrder={sortedInfo.columnKey === 'consume_coin' && sortedInfo.order}
                        />
                        <Column
                            title="购买时间"
                            key="buy_time"
                            align="center"
                            dataIndex="buy_time"
                            width="20%"
                        />
                    </> : <>
                        <Column
                            title="序号"
                            key="serial"
                            align="center"
                            width="8%"
                            render={(text, record, index) => {
                                return filter.size * (filter.page - 1) + index + 1
                            }}
                        />
                        <Column
                            title={type === 1 ? "题目编号" : type === 2 ? "实验编号" : type === 3 ? "漏洞编号" : ""}
                            key="number"
                            align="center"
                            dataIndex="number"
                            width="16%"
                            render={(record) => {
                                return record ? record : "暂无编号"
                            }}
                        />
                        <Column
                            title={type === 1 ? "题目标题" : type === 2 ? "实验名称" : type === 3 ? "漏洞名称" : ""}
                            key="name"
                            align="center"
                            dataIndex="name"
                            render={(text, record) => {
                                return <span>
                                    {
                                        type === 1 &&
                                        <a style={{ color: "#333" }} href={"/#/activity/ctfdetail/2/" + record.resource_id}>{text}</a>
                                    }
                                    {
                                        type === 2 &&
                                        <a style={{ color: "#333" }} href={"/#/activity/expdetail/2/" + record.resource_id}>{text}</a>
                                    }
                                    {
                                        type === 3 &&
                                        <a style={{ color: "#333" }} href={"/#/activity/vulndetail/2/" + record.resource_id}>{text}</a>
                                    }
                                </span>
                            }}
                        />
                        <Column
                            title={type === 1 ? "题目类型" : type === 2 ? "实验类型" : type === 3 ? "漏洞类型" : ""}
                            key="classify_name"
                            align="center"
                            dataIndex="classify_name"
                            width="13%"
                            filters={classify}
                            filteredValue={filteredInfo.classify_name || null}
                        />
                        <Column
                            title="环境"
                            key="is_env"
                            align="center"
                            dataIndex="is_env"
                            sorter="true"
                            width="10%"
                            sortOrder={sortedInfo.columnKey === 'is_env' && sortedInfo.order}
                            render={(record) => {
                                return record ? "有" : "否"
                            }}
                        />
                        <Column
                            title="花费奖励"
                            key="consume_coin"
                            align="center"
                            width="13%"
                            dataIndex="consume_coin"
                            sorter="true"
                            sortOrder={sortedInfo.columnKey === 'consume_coin' && sortedInfo.order}
                        />
                        <Column
                            title="购买时间"
                            key="buy_time"
                            align="center"
                            dataIndex="buy_time"
                            width="13%"
                        />
                    </>}
                </Table>
                <Pagination
                    showQuickJumper={true}
                    current={filter.page}
                    pageSize={filter.size}
                    total={initData.allCount}
                    hideOnSinglePage={true}
                    showSizeChanger={false}
                    className="flip-over"
                    style={{ margin: "30px 0" }}
                    onChange={
                        ((page) => {
                            let data = { ...filter }
                            data.page = page;
                            setFilter(data)
                        })
                    }
                />
            </div>
        </div>
    )
}
export default HaveBought;