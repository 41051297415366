/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import '../../../css/submitCtf.css';
import '../../../css/box.css';
import { Button, Input, Select, Radio, Progress } from 'antd';
import { CloseOutlined, PaperClipOutlined } from '@ant-design/icons';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import CodeHighlighter from 'braft-extensions/dist/code-highlighter';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import Retrieval from '../../Activity/retrieval';
import instance from '../../Communal/instance';
import { Alert, srcUrl } from '../../Communal/utility';
import Denied from '../../../images/denied.png';
import PassBox from '../../Activity/PassBox';
import DeclineBox from '../../Activity/DeclineBox';
import AddedBox from '../../Activity/AddedBox';
import history from '../../Communal/history';
import { ContentUtils } from 'braft-utils'
import Cao from '../../../images/cao.png';
import HuiCao from '../../../images/huicao.png';
import TipIcon from '../../../images/tip-icon.png';
import FlagTip from '../../Activity/FlagTip';
import $ from 'jquery'
const options = {
    syntaxs: [
        {
            name: 'JavaScript',
            syntax: 'javascript'
        }, {
            name: 'HTML',
            syntax: 'html'
        }, {
            name: 'CSS',
            syntax: 'css'
        }, {
            name: 'Java',
            syntax: 'java',
        }, {
            name: 'PHP',
            syntax: 'php'
        }, {
            name: "Python",
            syntax: 'python'
        }
    ],
}

BraftEditor.use(CodeHighlighter(options))
const { Option } = Select;
const { TextArea } = Input;
function SubmitCTF(param) {
    //id===0管理员 type: 0 添加 1 编辑 2 审核
    const [type, setType] = useState("0")
    const [submit, setSubmit] = useState(
        {
            title: "",
            content: "",
            flag: "",
            source: "",
            class: "",
            keyword: [],
            activity_id: "",
            other_attached: "",
            attached: "",
            other_attached_name: "",
            attached_name: "",
            flag_type: 1,//flag 类型 1 静态 2 动态
            flag_script: "",//flag脚本
            test_type: 2,//题目类型 1 环境型 2 附件型
            score: "",//分值
            level: 1,//题目等级 1-5
            remark: '',
            resource_level: 4,//资源等级 
            editorState: BraftEditor.createEditorState(null)
        }
    );
    const [flagTipShow, setFlagTipShow] = useState(false)
    const dateRef = useRef()
    const [detail, setDetail] = useState({})
    const [error, setError] = useState(
        {
            title: [],
            content: [],
            flag: [],
            source: [],
            class: [],
            keyword: [],
            activity_id: [],
            other_attached: [],
            attached: [],
            editorState: [],
            flag_script: [],
            score: [],
            remark: [],
            resource_level: []
        }
    )
    const [keyword, setKeyword] = useState("")
    const [retrievalBox, setRetrievalBox] = useState({
        show: false,
        type: 0,
        count: 0
    });
    const [classify, setClassify] = useState([]);
    const [initBox, setInitBox] = useState(
        {
            passBox: false,
            declineBox: false,
            addedBox: false
        }
    )
    const [imagesType, setImagesType] = useState(0);
    const [isSubmit, setIsSubmit] = useState(true);
    const [attachedShow, setAttachedShow] = useState(false)
    const [otherShow, setOtherShow] = useState(false)
    const [otherPercent, setOtherPercent] = useState(0)
    const [attachedPercent, setAttachedPercent] = useState(0)
    useEffect(() => {
        setType(param.param.match.params.type);
    }, [param.param.match.params.type]);
    useEffect(() => {
        submit.activity_id = param.param.match.params.where;
        setSubmit({ ...submit });
        if (param.param.match.params.type !== "0") {
            //ctf详情
            instance.get("/ctf/" + param.param.match.params.id + "/").then(data => {
                submit.title = data.name;
                submit.content = data.desc;
                submit.flag = data.flag;
                submit.source = data.source;
                submit.keyword = data.keyword_list;
                submit.editorState = BraftEditor.createEditorState(data.answer);
                submit.other_attached = data.other_attached;
                submit.attached = data.attached;
                submit.other_attached_name = data.other_attached_name;
                submit.attached_name = data.attached_name;
                submit.flag_type = data.flag_type;
                submit.flag_script = data.flag_script;
                submit.test_type = data.test_type;
                submit.score = data.score;
                submit.level = data.level;
                submit.remark = data.remark;
                submit.resource_level = data.resource_level;
                setSubmit({ ...submit });
                getClassify(data.classify_name);
                setDetail(data)
            })
        } else {
            getClassify();
            instance.get("/media/resource/WPTemplate/ctf/template.json").then(data => {
                submit.editorState = BraftEditor.createEditorState(data.template);
                setSubmit({ ...submit });
            })
        }
    }, []);
    //获取题目类型
    let getClassify = (classify_name) => {
        instance.get("/ctf/classify/?type=1").then(data => {
            if (data.length > 0 && submit.class === "") {
                if (classify_name) {
                    for (var i = 0; i < data.length; i++) {
                        if (classify_name === data[i].name) {
                            submit.class = data[i].id;
                        }
                    }
                } else {
                    submit.class = data[0].id;
                }
                setSubmit({ ...submit })
            }
            setClassify(data)
        })
    }
    //题目分类选择
    let handleChange = (key) => {
        submit.class = key;
        setSubmit({ ...submit })
    }
    //添加关键字回车事件
    let onPressEnter = (e) => {
        if (e.target.value.replace(/(^\s*)|(\s*$)/g, "") === "") {
            return
        }
        var count = 0;
        if (submit.keyword.length < 5) {
            if (submit.keyword.length > 0) {
                for (var i = 0; i < submit.keyword.length; i++) {
                    if (submit.keyword[i] !== e.target.value) {
                        count++
                    }
                }
                if (count === submit.keyword.length) {
                    submit.keyword.push(e.target.value);
                }
            } else {
                submit.keyword.push(e.target.value);
            }
            setKeyword("");
            setSubmit({ ...submit })
        }
    }
    //关键字change事件
    let keywordChange = (e) => {
        setKeyword(e.target.value.replace(",", ""));
    }
    //删除关键字事件
    let deleteChange = (key) => {
        submit.keyword.splice(key, 1)
        setSubmit({ ...submit })
    }
    //点击选择文件上传事件 1：上传附件 2：其他附件
    let uploadChange = (type) => {
        if (otherShow === true || attachedShow === true) {
            Alert("warn", "有文件正在上传中，请稍候");
            return
        }
        if (type === 1) {
            document.getElementsByClassName("upload-file-one")[0].click();
            document.getElementsByClassName("upload-file-one")[0].value = "";
            submit.attached_name = "";
            submit.attached = "";
            error.attached = [];

        } else if (type === 2) {
            document.getElementsByClassName("upload-file-two")[0].click();
            document.getElementsByClassName("upload-file-two")[0].value = "";
            submit.other_attached_name = "";
            submit.other_attached = "";
            error.other_attached = [];
        }
        setSubmit({ ...submit });
        setError({ ...error })
    }
    //input change 事件
    let inputChange = (e, name) => {
        if (name === 'score' || name === 'resource_level') {
            submit[name] = Number(e.target.value);
        } else {
            submit[name] = e.target.value;
        }
        setSubmit({ ...submit })
    }
    let handleEditorChange = (editorState) => {
        submit.editorState = editorState;
        setSubmit({ ...submit })
    }
    //上传文件change事件
    let getAjax = (formdData, type) => {
        // type 1上传附件 2其他附件
        $.ajax({
            url: "/ctf/upload_file/",
            type: "POST",
            data: formdData,
            contentType: false,
            processData: false,
            xhr: () => {
                var myXhr = $.ajaxSettings.xhr()
                if (myXhr.upload) { // check if upload property exists 
                    myXhr.upload.addEventListener('progress', (e) => {
                        var loaded = e.loaded;//已经上传大小情况
                        var tot = e.total;//附件总大小
                        var per = Math.floor(100 * loaded / tot);  //已经上传的百分比
                        if (type === 1) {
                            setAttachedPercent(per)
                            if (myXhr.readyState === 1 && per === 100) {
                                setAttachedPercent(99)
                            }
                        } else if (type === 2) {
                            setOtherPercent(per)
                            if (myXhr.readyState === 1 && per === 100) {
                                setOtherPercent(99)
                            }
                        }
                    }, false);
                }
                return myXhr;
            },
            success: (data) => {
                if (type === 1) {
                    setAttachedShow(false)
                    setAttachedPercent(0)
                    submit.attached_name = data.origin_file_name;
                    submit.attached = data.url
                    setSubmit({ ...submit })
                } else if (type === 2) {
                    setOtherShow(false)
                    setOtherPercent(0)
                    submit.other_attached_name = data.origin_file_name;
                    submit.other_attached = data.url
                    setSubmit({ ...submit })
                }
            },
            error: (err) => {
                if (type === 1) {
                    if (err.file) {
                        error.attached = err.file;
                    }
                    if (err.non_field_errors) {
                        error.attached = err.non_field_errors;
                    }
                    setError({ ...error })
                } else if (type === 2) {
                    if (err.file) {
                        error.other_attached = err.file;
                    }
                    if (err.non_field_errors) {
                        error.other_attached = err.non_field_errors;
                    }
                    setError({ ...error })
                }
            }
        })
    }
    let changeFile = (type) => {
        if (type === 1) {
            let file = document.getElementsByClassName("upload-file-one")[0].files[0];
            if (file !== undefined) {
                if (file.size > 1024 * 1024 * 500) {
                    Alert("error", "上传附件不能大于500M");
                    return
                }
                let form = document.getElementById('formImg1');
                let formdData = new FormData(form);
                formdData.append("model", "ctf")
                setAttachedShow(true)
                getAjax(formdData, 1)
            }
        } else if (type === 2) {
            let file = document.getElementsByClassName("upload-file-two")[0].files[0];
            if (file !== undefined) {
                if (file.size > 1024 * 1024 * 500) {
                    Alert("error", "上传附件不能大于500M")
                    return
                }
                let form = document.getElementById('formImg2');
                let formdData = new FormData(form);
                formdData.append("model", "ctf")
                setOtherShow(true)
                getAjax(formdData, 2)
            }
        }
    }
    let handleFouce = (name) => {
        error[name] = []
        setError({ ...error })
    }
    //点击提交资源
    let commitResources = (actionType) => {
        if (srcUrl(submit.editorState.toHTML())) {
            Alert('warn', '题目解答中图片存在外部链接，请检查！！！')
            return
        }
        if (!isSubmit) {
            Alert("warn", "正在提交数据，请稍后！")
            return
        }
        let list = {
            name: submit.title,
            desc: submit.content,
            flag: submit.flag,
            source: submit.source,
            classify: submit.class,
            answer: submit.editorState.toHTML(),
            keyword: submit.keyword,
            attached: submit.attached,
            attached_name: submit.attached_name,
            other_attached: submit.other_attached,
            other_attached_name: submit.other_attached_name,
            flag_type: submit.flag_type,
            flag_script: submit.flag_script,
            test_type: submit.test_type,
            score: submit.score,
            level: submit.level,
            remark: submit.remark,
            resource_level: submit.resource_level
        }
        if (submit.activity_id !== "" && actionType === 1 && submit.activity_id !== "0" && submit.activity_id !== "-1") {
            list.activity_id = submit.activity_id;
        }
        if (submit.title === "") {
            error.title = ["请输入题目标题"]
        }
        if (submit.content === "") {
            error.content = ["请输入题目内容"]
        }
        if (submit.flag === "") {
            error.flag = ["请输入题目Flag"]
        }
        if (submit.source === "") {
            error.source = ["请输入题目来源"]
        }
        if (submit.score === "") {
            error.score = ["请输入题目分值"]
        }
        if (submit.class === "") {
            error.class = ["请输入题目分类"]
        }
        if (submit.resource_level !== 0 && !submit.resource_level) {
            error.resource_level = ["请输入资源等级"]
        }
        if (submit.editorState.toHTML() === "<p></p>") {
            Alert("error", "请输入题目解答");
        }
        if (submit.keyword.length === 0) {
            error.keyword = ["请输入关键字"]
        }
        if (
            submit.title === "" ||
            submit.content === "" ||
            submit.flag === "" ||
            submit.source === "" ||
            submit.class === "" ||
            submit.answer === "" ||
            submit.keyword.length === 0 ||
            submit.score === "" ||
            submit.resource_level !== 0 && !submit.resource_level
        ) {
            setError({ ...error })
            Alert('warn', '提交内容有误，请检查！')
            return
        }
        if (attachedShow === true || otherShow === true) {
            Alert("warn", "有文件正在上传中，请稍候");
            return
        }
        setIsSubmit(false);
        if (actionType === 1) {
            instance.post("/ctf/add/", list).then(data => {
                Alert("success", "提交成功");
                setIsSubmit(true);
                instance.get("/media/resource/WPTemplate/ctf/template.json").then(data => {
                    setSubmit(
                        {
                            title: "",
                            content: "",
                            flag: "",
                            source: "",
                            class: classify[0].id || "",
                            keyword: [],
                            other_attached: "",
                            attached: "",
                            activity_id: param.param.match.params.where,
                            other_attached_name: "",
                            attached_name: "",
                            flag_type: 1,
                            flag_script: "",
                            test_type: 1,
                            score: "",
                            level: 1,
                            remark: '',
                            resource_level: 4,
                            editorState: BraftEditor.createEditorState(data.template)
                        }
                    )
                })
            }).catch(err => {
                errorInfo(err);
                setIsSubmit(true);
            })
        } else if (actionType === 2) {
            instance.patch("/ctf/" + param.param.match.params.id + "/", list).then(data => {
                Alert("success", "提交成功");
                setIsSubmit(true);
                if (type === "1") {
                    if (submit.activity_id !== "" && submit.activity_id !== "0" && submit.activity_id !== "-1") {
                        history.push({ pathname: "/activity/detail/" + submit.activity_id, state: { where: 1 } })
                    } else if (submit.activity_id === "-1") {
                        window.location.href = "/#/personal/2"
                    } else {
                        window.location.href = "/#/resource/ctf"
                    }
                }
            }).catch(err => {
                errorInfo(err);
                setIsSubmit(true);
            })
        }
    }
    let errorInfo = (err) => {
        if (err.name) {
            error.title = err.name
        }
        if (err.desc) {
            error.content = err.desc
        }
        if (err.flag) {
            error.flag = err.flag
        }
        if (err.source) {
            error.source = err.source
        }
        if (err.classify) {
            error.class = err.classify
        }
        if (err.resource_level) {
            error.resource_level = err.resource_level
        }
        if (err.answer) {
            Alert("error", err.answer);
        }
        if (err.keyword) {
            error.keyword = err.keyword
        }
        if (err.attached) {
            error.attached = err.attached
        }
        if (err.other_attached) {
            error.other_attached = err.other_attached
        }
        if (err.flag_script) {
            error.flag_script = err.flag_script
        }
        if (err.score) {
            error.score = err.score
        }
        if (err.remark) {
            error.remark = err.remark;
        }
        if (err.non_field_errors) {
            Alert("error", err.non_field_errors[0])
        }
        if (err.activity_id) {
            Alert("error", err.activity_id[0])
        }
        if (err.error) {
            Alert("error", err.error)
        }
        Alert('warn', '提交内容有误，请检查！')
        setError({ ...error })
    }
    //点击审核操作
    let handleAudit = (data) => {
        if (data.status === 1) {
            if (data.data === "") {
                Alert("error", "请输入奖励奖励数");
                return
            }
        }
        if ((data.status === 2 || data.status === 3) && data.data === "") {
            Alert("error", "请输入审核意见");
            return
        }
        instance.post("/ctf/" + param.param.match.params.id + "/audit/", data).then(data => {
            Alert("success", "操作成功");
            window.location.href = "/#/resource/ctf"
        }).catch(err => {
            Alert("error", err.error)
        })
    }
    //点击上传到媒体库
    let addMediaItem = () => {
        document.getElementsByClassName("images")[0].click();
        document.getElementsByClassName("images")[0].value = "";
        setImagesType(0)

    }
    //点击插入到编辑器
    let insertMediItem = () => {
        document.getElementsByClassName("images")[0].click();
        document.getElementsByClassName("images")[0].value = "";
        setImagesType(1)
    }
    //点击上传文件
    let changeImage = () => {
        let file = document.getElementsByClassName("images")[0].files[0];
        if (file !== undefined) {
            if (file.size > 5 * 1024 * 1024) {
                Alert("error", "上传图片不能大于5M")
                return
            }
            let form = document.getElementById('file-image');
            let formdData = new FormData(form);
            formdData.append("model", "ctf");
            instance.post("/ctf/upload_image/", formdData).then(data => {
                if (imagesType === 0) {
                    if (dateRef.current !== undefined) {
                        let braftFinder = dateRef.current.getFinderInstance();
                        braftFinder.addItems([
                            {
                                id: new Date().getTime(),
                                type: 'IMAGE',
                                url: data.url
                            }
                        ])
                    }
                } else if (imagesType === 1) {
                    let editorState = ContentUtils.insertMedias(submit.editorState, [
                        {
                            type: 'IMAGE',
                            url: data.url
                        }
                    ])
                    submit.editorState = editorState;
                    setSubmit({ ...submit })
                }
                Alert("success", "上传成功")
            }).catch(err => {
                if (err.image) {
                    Alert("error", err.image)
                }
            })
        }
    }
    //点击检索按钮进行检索，根据类型判断 检索的字段
    let retrievalClick = (type, text) => {
        if (text.replace(/(^\s*)|(\s*$)/g, "") === "") {
            Alert("warn", "请输入检索内容");
            return
        }
        let list = {
            type: "ctf",
            flag: text,
            search_type: type
        }
        instance.get("/ctf/search/", { params: list }).then(data => {
            retrievalBox.show = true;
            retrievalBox.count = data.count;
            retrievalBox.type = type;
            setRetrievalBox({ ...retrievalBox })
        })
    }
    let myUploadFn = (param) => {
        const fd = new FormData();
        fd.append('image', param.file);
        fd.append("model", "ctf");
        instance.post("/ctf/upload_image/", fd).then(data => {
            param.success({
                url: data.url,
            })
        })
    }
    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-div submit-ctf-name">
                    {
                        type === "0" && "提交CTF题目"
                    }
                    {
                        type === "1" && "编辑CTF题目"
                    }
                    {
                        type === "2" && "审核CTF题目"
                    }
                    {
                        detail.status === 3 &&
                        <div className="status">
                            <img src={Denied} alt="" />
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    detail.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">{val.msg}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div subject-info">
                    <div className="title">
                        题目信息
                    </div>
                    <div className="content">
                        <div className="retrieval-div">
                            <span><b>*</b>题目标题</span>
                            <Input
                                value={submit.title}
                                className="content-input"
                                placeholder="请输入题目标题"
                                onChange={(e) => inputChange(e, "title")}
                                maxLength="200"
                                onFocus={() => handleFouce("title")}
                            />
                            <span className="number">{200 - submit.title.length}</span>
                            <Button type="primary" onClick={() => retrievalClick(1, submit.title)}>检索</Button>
                            <div className="error-message">{error.title[0]}</div>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>题目内容</span>
                            <TextArea
                                value={submit.content}
                                className="content-input"
                                placeholder="请输入题目内容"
                                onChange={(e) => inputChange(e, "content")}
                                // autoSize
                                maxLength="180"
                                onFocus={() => handleFouce("content")}
                            />
                            <span className="number">{180 - submit.content.length}</span>
                            <Button type="primary" onClick={() => retrievalClick(2, submit.content)}>检索</Button>
                            <div className="error-message">{error.content[0]}</div>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>Flag类型</span>
                            <Radio.Group
                                value={submit.flag_type}
                                onChange={e => {
                                    submit.flag_type = e.target.value;
                                    submit.flag_script = "";
                                    setSubmit({ ...submit })
                                }}
                            >
                                <Radio value={1}>静态Flag</Radio>
                                <Radio value={2}>动态Flag</Radio>
                            </Radio.Group>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>题目Flag</span>
                            <Input
                                value={submit.flag}
                                onChange={(e) => inputChange(e, "flag")}
                                className="content-input"
                                placeholder="请输入题目Flag"
                                maxLength="100"
                                onFocus={() => handleFouce("flag")}
                            />
                            <span className="number">{100 - submit.flag.length}</span>
                            <Button type="primary" onClick={() => retrievalClick(0, submit.flag)}>检索</Button>
                            <div className="error-message">{error.flag[0]}</div>
                        </div>
                        {
                            submit.flag_type === 2 &&
                            <div className="retrieval-div">
                                <span><b>*</b>Flag脚本</span>
                                <TextArea
                                    value={submit.flag_script}
                                    className="content-input"
                                    placeholder="请输入Flag脚本"
                                    onChange={(e) => inputChange(e, "flag_script")}
                                    onFocus={() => handleFouce("flag_script")}
                                />
                                <img className="flag-tip-icon" onClick={() => setFlagTipShow(true)} src={TipIcon} alt="" />
                                <div className="error-message">{error.flag_script[0]}</div>
                            </div>
                        }
                        <div>
                            <span><b>*</b>题目来源</span>
                            <Input
                                value={submit.source}
                                onChange={(e) => inputChange(e, "source")}
                                className="content-input"
                                placeholder="请输入题目来源"
                                maxLength="200"
                                onFocus={() => handleFouce("source")}
                            />
                            <span className="number">{200 - submit.source.length}</span>
                            <div className="error-message">{error.source[0]}</div>
                        </div>
                        <div>
                            <span><b>*</b>题目分类</span>
                            <Select
                                value={submit.class}
                                style={{ width: 180 }}
                                onChange={handleChange}
                                onClick={() => handleFouce("class")}
                            >
                                {
                                    classify.map((val, key) => {
                                        return <Option
                                            key={key + "key"}
                                            value={val.id}
                                        >
                                            {val.name}
                                        </Option>
                                    })
                                }
                            </Select>
                            <div className="error-message">{error.class[0]}</div>
                        </div>
                        <div>
                            <span><b>*</b>题目分值</span>
                            <Input
                                value={submit.score}
                                type="number"
                                onChange={(e) => inputChange(e, "score")}
                                className="content-input"
                                placeholder="请输入题目分值"
                                onFocus={() => handleFouce("score")}
                            />
                            <div className="error-message">{error.score[0]}</div>
                        </div>
                        <div>
                            <span><b>*</b>题目等级</span>
                            <div className="test-level">
                                {
                                    Array(5).fill("").map((val, key) => {
                                        return <img
                                            key={key}
                                            src={(key + 1) > submit.level ? HuiCao : Cao}
                                            alt=""
                                            onClick={() => {
                                                submit.level = key + 1;
                                                setSubmit({ ...submit })
                                            }}
                                        />
                                    })
                                }
                            </div>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>题目类型</span>
                            <Radio.Group
                                value={submit.test_type}
                                onChange={e => {
                                    submit.test_type = e.target.value;
                                    setSubmit({ ...submit })
                                }}
                            >
                                <Radio value={1}>环境型</Radio>
                                <Radio value={2}>附件型</Radio>
                            </Radio.Group>
                        </div>
                        <div>
                            <span><b>*</b>资源等级</span>
                            <Input
                                type='number'
                                value={submit.resource_level}
                                onChange={(e) => inputChange(e, "resource_level")}
                                className="content-input"
                                placeholder="请输入资源等级"
                                maxLength="200"
                                onFocus={() => handleFouce("resource_level")}
                            />
                            <div className="error-message">{error.resource_level[0]}</div>
                        </div>
                        <div>
                            <span>题目备注</span>
                            <Input
                                value={submit.remark}
                                onChange={(e) => inputChange(e, "remark")}
                                className="content-input"
                                placeholder="请输入题目备注"
                                maxLength="200"
                                onFocus={() => handleFouce("remark")}
                            />
                            <div className="error-message">{error.remark[0]}</div>
                        </div>
                    </div>
                </div>
                <div className="submit-ctf-div topic-answer">
                    <div className="title">
                        题目解答
                    </div>
                    <div>
                        <span><b>*</b>题目解答</span>
                        <div className="my-component">
                            <BraftEditor
                                value={submit.editorState}
                                onChange={handleEditorChange}
                                ref={dateRef}
                                media={{
                                    uploadFn: myUploadFn,
                                    image: false
                                }}
                                excludeControls={["superscript", "subscript", "emoji", "remove-styles"]}
                                extendControls={[
                                    'separator',
                                    {
                                        key: 'add-media',
                                        type: 'button',
                                        text: '插入图片到媒体库',
                                        onClick: () => addMediaItem()
                                    },
                                    {
                                        key: 'insert-media',
                                        type: 'button',
                                        text: '插入图片到编辑器',
                                        onClick: () => insertMediItem()
                                    }
                                ]}
                            />
                            <div className="error-message" style={{ margin: "0" }}>{error.editorState[0]}</div>
                        </div>
                    </div>
                </div>
                <div className="submit-ctf-div subject-keyword">
                    <div className="title">
                        添加关键字
                    </div>
                    <div className="content">
                        <div>
                            <b>*</b>
                            关键字
                        </div>
                        <div>
                            <div>
                                <Input
                                    value={keyword}
                                    placeholder="题目关键字，回车添加关键字"
                                    onPressEnter={onPressEnter}
                                    onChange={keywordChange}
                                    onFocus={() => handleFouce("keyword")}
                                    maxLength={16}
                                />
                                <span className="number">{16 - keyword.length}</span>
                                添加关键字（{5 - submit.keyword.length}）
                            </div>
                            <div>
                                {
                                    submit.keyword.map((val, key) => {
                                        return <span key={"key" + key}>
                                            {val}
                                            <CloseOutlined
                                                onClick={() => deleteChange(key)}
                                            />
                                        </span>
                                    })
                                }
                            </div>
                        </div>
                        <div className="error-message">{error.keyword[0]}</div>
                    </div>
                </div>
                <div className="submit-ctf-div subject-upload">
                    <div className="title">
                        上传附件
                        <span>提供题目的离线附件或者Web题目的源码压缩包，或者题目环境，将能得到更多奖励</span>
                    </div>
                    <div className="content">
                        <div>
                            <span>上传附件</span>
                            <form id="formImg1">
                                <div>
                                    <span className="upload" onClick={() => uploadChange(1)}>
                                        选择
                                        <input
                                            type="file"
                                            name="file"
                                            className="upload-file-one"
                                            accept=".zip,.rar"
                                            onChange={() => changeFile(1)}
                                        />
                                    </span>
                                    （ZIP/RAR，500M以内）
                                    {
                                        submit.attached !== "" && [
                                            <span className="file-name" key="1">
                                                <a href={submit.attached} download>
                                                    <PaperClipOutlined />
                                                    {submit.attached_name}
                                                </a>
                                            </span>,
                                            <Button type="link" key="2" danger onClick={() => {
                                                submit.attached_name = "";
                                                submit.attached = "";
                                                setSubmit({ ...submit })
                                            }}>删除</Button>
                                        ]
                                    }
                                    {
                                        attachedShow &&
                                        <>
                                            <Progress percent={attachedPercent} />
                                            <span> （上传中，请稍候）</span>
                                        </>
                                    }
                                </div>
                            </form>
                            <div style={{ marginLeft: "140px" }} className="error-message">{error.attached[0]}</div>
                        </div>
                        <div>
                            <span>其他附件</span>
                            <form id="formImg2">
                                <div>
                                    <span className="upload" onClick={() => uploadChange(2)}>
                                        选择
                                        <input
                                            type="file"
                                            name="file"
                                            accept=".zip,.rar"
                                            className="upload-file-two"
                                            onChange={() => changeFile(2)}
                                        />
                                    </span>
                                    （ZIP/RAR，500M以内）
                                    {
                                        submit.other_attached !== "" && [
                                            <span className="file-name" key="1">
                                                <a href={submit.other_attached} download>
                                                    <PaperClipOutlined />
                                                    {submit.other_attached_name}
                                                </a>
                                            </span>,
                                            <Button key="2" type="link" danger onClick={() => {
                                                submit.other_attached_name = "";
                                                submit.other_attached = "";
                                                setSubmit({ ...submit })
                                            }}>删除</Button>
                                        ]
                                    }
                                    {
                                        otherShow &&
                                        <>
                                            <Progress percent={otherPercent} />
                                            <span> （上传中，请稍候）</span>
                                        </>
                                    }
                                </div>
                            </form>
                            <div style={{ marginLeft: "140px" }} className="error-message">{error.other_attached[0]}</div>
                        </div>
                    </div>
                </div>
                {
                    type === "0" &&
                    <div className="submit-btn">
                        <Button type="primary" onClick={() => commitResources(1)}>提交</Button>
                    </div>
                }
                {
                    type === "1" &&
                    <div className="submit-btn">
                        <Button type="primary" onClick={() => commitResources(2)}>提交</Button>
                    </div>
                }
                {
                    type === "2" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => {
                                initBox.passBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            通过
                        </Button>
                        <Button
                            style={{ backgroundColor: "transparent", color: "#266cff" }}
                            type="primary"
                            onClick={() => window.location.href = "/#/resource/ctf"}
                        >
                            取消
                        </Button>
                        <Button className="modify" onClick={() => commitResources(2)}>保存修改</Button>
                        <Button
                            className="decline"
                            onClick={() => {
                                initBox.declineBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            拒绝
                        </Button>
                        <Button
                            className="added"
                            onClick={() => {
                                initBox.addedBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            待补充
                        </Button>
                        <Button
                            className="added"
                            onClick={() => handleAudit({ status: 0 })}
                        >
                            待审核
                        </Button>
                    </div>
                }
            </div>
            {
                retrievalBox.show &&
                <Retrieval
                    close={() => {
                        retrievalBox.show = false;
                        setRetrievalBox({ ...retrievalBox })
                    }}
                    count={retrievalBox.count}
                    name={
                        {
                            0: "Flag",
                            1: "题目标题",
                            2: "题目内容"
                        }[retrievalBox.type]
                    }
                />
            }
            {
                initBox.passBox &&
                <PassBox
                    close={() => {
                        initBox.passBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.declineBox &&
                <DeclineBox
                    close={() => {
                        initBox.declineBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.addedBox &&
                <AddedBox
                    close={() => {
                        initBox.addedBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            <form id="file-image">
                <input
                    type="file"
                    name="image"
                    accept=".jpg,.png,.gif"
                    style={{ display: "none" }}
                    className="images"
                    onChange={changeImage}
                />
            </form>
            {
                flagTipShow &&
                <FlagTip
                    onClose={() => setFlagTipShow(false)}
                />
            }
        </div>
    )
}
export default SubmitCTF;