//确认框
import React from 'react';
import { Modal, Button, Row } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import '../../css/comfirm.less';
import { Alert } from '../Communal/utility';
import instance from '../Communal/instance';
function App({ boxProperty, ids, onClose, onOk }) {
    const handleCancel = () => {
        onClose()
    }
    const handleOk = () => {
        let list = {
            mode: boxProperty.mode,
            issue: boxProperty.issue,
            ids: ids
        }
        instance.post("/ctf/module/issue/", list).then(data => {
            if (data.status === 1) {
                if (boxProperty.issue === 1) {
                    Alert('success', '回收成功！');
                }
                if (boxProperty.issue === 2) {
                    Alert('success', '发布成功！');
                }
                onClose();
                onOk();
            }
            if (data.status === 2) {
                Alert('error', data.msg);
            }
        }).catch(err => {
            if (err.error) {
                Alert('error', err.error);
            }
        })
    }

    return (
        <Modal
            className="my-confirm-modal"
            visible={boxProperty.visible}
            centered={true}
            closable={false}
            footer={<div className="my-modal-footer">
                <Button className="cancel-btn" onClick={handleCancel}>取消</Button>
                <Button type="primary"
                    onClick={handleOk}>确认</Button>
            </div>}
        >
            <Row className="line">
                <div className="title-icon">
                    <QuestionOutlined className="del-icon" />
                </div>
            </Row>
            <Row className="line">
                <div className="title">确定{boxProperty.issue === 1 ? '回收' : '发布'}所选资源？</div>
            </Row>
        </Modal>
    )
}

export default App