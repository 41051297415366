//确认框
import React from 'react';
import { Modal, Button, Row } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import '../../../css/comfirm.less';
function App({ boxProperty, ids, operate, resourceName, onClose, onOk, loading }) {
    const handleCancel = () => {
        onClose()
    }
    const handleOk = () => {
        onOk()
    }

    return (
        <Modal
            className="my-del-modal"
            visible={boxProperty.show}
            centered={true}
            closable={false}
            footer={<div className="my-modal-footer">
                <Button className="cancel-btn" onClick={handleCancel}>取消</Button>
                <Button type="primary"
                    className="del-btn"
                    loading={loading}
                    onClick={handleOk}>确认</Button>
            </div>}
        >
            <Row className="line">
                <div className="title-icon">
                    <QuestionOutlined className="del-icon" />
                </div>
            </Row>
            <Row className="line">
                <div className="title">确定{operate ? operate : '操作'}{resourceName ? resourceName : '资源'}？</div>
            </Row>
        </Modal>
    )
}

export default App