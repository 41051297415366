import React,{useEffect} from 'react';
import {Button} from 'antd';
function Reminder(param){
    useEffect(()=>{
        document.body.style.overflow="hidden";
        return ()=>{
            document.body.style.overflow="auto";
        }
    })
    return (
        <div className="audit-bounced">
            <div className="content reminder-box">
                <p>云演HUB资源中心温馨提示</p>
                <p>欢迎到您入驻云演HUB资源中心，云演HUB提倡“资源贡献，集中共享”的理念</p>
                <p>1、在活动中心按要求提交对应的资源，挣取获得您的第一桶金。</p>
                <p>2、为把控资源的质量，会根据提交的资源进行审核。仅提交题目的writeup审核通过后获得5个奖励，提交非Web类的题目及题目附件且申审核通过，则获得10个奖励，如果您提交的是Web类题目且还带上复盘题目的源码则审核通过后获得15个奖励</p>
                <p>3、为了秉承“尊重劳动成果，资源共享”的理念，每个资源均需要消费，每个资源消费时需要支付该资源提交时给予的奖励</p>
                <p>4、为了更好的集中学习和管理资源，云演HUB欢迎大家共享与使用各类资源！我们也将在资源征集中做出各类活动，快乐学习、快乐分享</p>
                <div>
                    <Button type="primary" onClick={param.close}>确定</Button>
                </div>
            </div>
        </div>
    )
}
export default Reminder;