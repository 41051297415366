import React, { useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Table, Input, Pagination } from 'antd'
import { Alert } from '../../../Communal/utility';
import instance from '../../../Communal/instance';
import DeteleBox from '../../deteleBox';

const ExternalRules = ({ keyword, userType, userIssue }) => {
    const [initData, setInitData] = useState({
        data: [],
        allCount: 0
    });
    const [checkList, setCheckList] = useState([]);
    const [filter, setFilter] = useState({
        page: 1,
        size: 10,
        search: keyword,
        username: "",
        rules_class: "",
    });
    const [deleteTip, setDeleteTip] = useState({
        show: false,
        list: []
    })
    const [updateList, setUpdateList] = useState({})
    const [updateLoading, setUpdateLoading] = useState(false)

    useEffect(() => {
        tableData()
    }, [filter])

    useEffect(() => {
        getClassType()
        checkUpdate()
        return () => {
            setFilter({
                page: 1,
                size: 10,
                search: keyword,
                username: "",
            })
        }
    }, [])

    const tableData = () => {
        instance.get('/rules/outside/', { params: filter }).then(data => {
            initData.data = data.results;
            initData.allCount = data.count;
            setInitData({ ...initData })
        })
    }

    const resourceDelete = (list) => {
        instance.post("/rules/outside/1/", { ids: list, status: 2 }).then(data => {
            if (data.status === 1) {
                Alert('success', data.msg)
            }
            if (filter.page > 1 && (initData.data.length === list.length)) {
                filter.page -= 1;
                setFilter({ ...filter })
            }
            if (initData.allCount === list.length) {
                filter.page = 1;
                setFilter({ ...filter })
            }
            tableData();
            setCheckList([]);
            deleteTip.list = [];
            deleteTip.show = false;
            setDeleteTip({ ...deleteTip })
        })

    }

    const handleTableChange = (pagination, filters) => {
        if (filters.status) {
            filter.status = filters.status;
        } else {
            filter.status = [];
        }
        filter.page = 1;
        setFilter({ ...filter });
    }


    const getClassType = () => {
        instance.get('/rules/class/', { params: { status: 1, nopage: 1 } }).then(data => {
            if (Array.isArray(data)) {
                let list = []
                data.map((value, key) => {
                    list.push({
                        text: value.name,
                        value: value.id
                    })
                })
            }
        })
    }

    const handleUpdate = () => {
        setUpdateLoading(true)
        instance.post('/rules/outside/').then(data => {
            Alert('success', '检查更新成功')
            setUpdateLoading(false)
            tableData()
            checkUpdate()
        }).catch((err) => {

        })
    }

    const checkUpdate = () => {
        instance.get('/rules/outside/audit/').then(data => {
            setUpdateList(data.data)
        }).catch((err) => {

        })
    }

    const rowSelection = {
        selectedRowKeys: checkList,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            deleteTip.list = selectedRowKeys
            setDeleteTip({ ...deleteTip })
            setCheckList(selectedRowKeys)
        },
        getCheckboxProps: (record) => {
            if (record.on_delete === false) {
                return { disabled: true }
            } else {
                return null;
            }
        }
    }
    const columns = [
        {
            title: '序号',
            key: 'id',
            width: 70,
            fixed: 'left',
            align: 'center',
            render: (text, record, index) => (
                <span
                    className="click-href"
                    onClick={() => {
                        window.open("/#/activity/externalrulesform/0/" + record.id)
                    }}
                >
                    {filter.size * (filter.page - 1) + index + 1}
                </span>
            )
        }, {
            title: '规则sid',
            align: 'center',
            key: 'sid',
            dataIndex: 'sid',
            width: 150,
            fixed: 'left',
            render: (text, record) => (
                <span
                    className="click-href"
                    onClick={() => {
                        window.open("/#/activity/externalrulesform/0/" + record.id)
                    }}
                >
                    {text}
                </span>
            )
        }, {
            title: '规则分类',
            align: 'center',
            key: 'rules_class',
            dataIndex: 'rules_class',
            width: 120,
            align: 'center',
            ellipsis: true,
            filterDropdown: ({ clearFilters }) => (
                <div className="table-search">
                    <Input
                        placeholder="请输入规则分类"
                        value={filter.rules_class}
                        onChange={(e) => {
                            filter.rules_class = e.target.value;
                            filter.page = 1;
                            setFilter({ ...filter })
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => { clearFilters() }}
                    >
                        确定
                    </Button>
                    <Button onClick={() => {
                        clearFilters();
                        filter.rules_class = "";
                        filter.page = 1;
                        setFilter({ ...filter })
                    }}>取消</Button>
                </div>
            ),
            filterIcon: <SearchOutlined />,
            render: (text) => {
                return text ? text : '（未关联规则分类或分类未审核通过）'
            }
        }, {
            title: '规则描述',
            key: 'desc',
            dataIndex: 'desc',
            width: 200,
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                return record?.desc ? text : '无'
            }
        }, {
            title: '规则状态',
            key: 'status',
            dataIndex: 'status',
            width: 120,
            align: 'center',
            filters: [
                { text: "待确认", value: "0" },
                { text: "已归档", value: "1" },
                { text: "已忽略", value: "2" },
            ],
            render: (record) => {
                return <span className="audit-status">
                    {
                        record === 0 && "待确认"
                    }
                    {
                        record === 1 && <span className="pass">已归档</span>
                    }
                    {
                        record === 2 && <span className="denied">已忽略</span>
                    }
                </span>
            },
        }, {
            title: '更新时间',
            key: 'update_time',
            dataIndex: 'update_time',
            width: 150,
            align: 'center',
            render: (text) => {
                return text || '--'
            }
        }, {
            title: '操作者',
            key: 'username',
            dataIndex: 'username',
            width: 100,
            align: 'center',
            ellipsis: true,
            filterDropdown: ({ clearFilters }) => (
                <div className="table-search">
                    <Input
                        placeholder="请输入提交者名称"
                        value={filter.username}
                        onChange={(e) => {
                            filter.username = e.target.value;
                            filter.page = 1;
                            setFilter({ ...filter })
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => { clearFilters() }}
                    >
                        确定
                    </Button>
                    <Button onClick={() => {
                        clearFilters();
                        filter.username = "";
                        filter.page = 1;
                        setFilter({ ...filter })
                    }}>取消</Button>
                </div>
            ),
            filterIcon: <SearchOutlined />,
            render: (text) => {
                return text || '--'
            }
        }, {
            title: '提交时间',
            key: 'sub_time',
            dataIndex: 'sub_time',
            width: 150,
            align: 'center',
            render: (text) => {
                return text || '--'
            }
        }, {
            title: '操作',
            key: 'operate',
            dataIndex: 'operate',
            align: 'center',
            fixed: 'right',
            width: userType > 1 ? 200 : 100,
            render: (text, record) => {
                return <div className="table-operation">
                    {
                        <div>
                            <Button
                                type="link"
                                onClick={() => {
                                    window.open("/#/activity/externalrulesform/0/" + record.id)
                                }}
                            >
                                详情
                            </Button>
                            {
                                userType > 1 &&
                                <>
                                    <Button
                                        key="6"
                                        type="link"
                                        style={{ color: "#FC7057" }}
                                        onClick={() => {
                                            deleteTip.show = true
                                            deleteTip.list = [record.id]
                                            setDeleteTip({ ...deleteTip })
                                        }}
                                    >删除</Button>
                                </>
                            }
                        </div>
                    }
                </div>
            }
        }
    ]
    return (
        <div className="ctf-resource-list" style={{ padding: 0 }}>
            <div className="ctf-resource-table content-container" style={{ marginTop: 0 }}>
                {
                    userType > 1 &&
                    <div className="handle">
                        <div style={{ display: 'inline-block' }}>
                            <Button
                                type='ghost'
                                disabled={checkList.length === 0}
                                danger
                                onClick={() => {
                                    deleteTip.show = true;
                                    deleteTip.list = checkList;
                                    setDeleteTip({ ...deleteTip })
                                }}
                            >
                                批量删除
                            </Button>
                        </div>
                        <div className='right'>
                            {
                                updateList.create_time && <>
                                    <span style={{ marginRight: '20px' }}>
                                        最近一次更新时间：{updateList?.create_time}
                                    </span>
                                    <span style={{ marginRight: '20px' }}>
                                        执行者：{updateList?.update_user}
                                    </span>
                                </>
                            }
                            <Button
                                type="primary"
                                loading={updateLoading}
                                onClick={() => { handleUpdate() }}
                            >
                                {updateLoading ? '检查中' : '检查更新'}
                            </Button>
                        </div>
                    </div>
                }
                <Table
                    dataSource={initData.data}
                    rowKey={record => record.id}
                    bordered
                    scroll={{ x: 1500 }}
                    rowSelection={rowSelection}
                    onChange={handleTableChange}
                    columns={columns}
                    pagination={false}
                />
                <Pagination
                    showQuickJumper={true}
                    current={filter.page}
                    pageSize={filter.size}
                    total={initData.allCount}
                    hideOnSinglePage={initData.allCount === 0}
                    pageSizeOptions={['10', '20', '50', '100']}
                    showSizeChanger={true}
                    className="flip-over"
                    style={{ margin: "30px 0" }}
                    onChange={
                        ((page, pageSize) => {
                            let data = { ...filter }
                            data.page = page;
                            data.size = pageSize;
                            setFilter(data)
                        })
                    }
                />
            </div>
            {
                deleteTip.show &&
                <DeteleBox
                    close={() => {
                        deleteTip.show = false;
                        deleteTip.list = [];
                        setDeleteTip({ ...deleteTip })
                    }}
                    title='外部规则'
                    submit={() => resourceDelete(deleteTip.list)}
                />
            }
        </div>
    )
}
export default ExternalRules