/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import '../../../css/submitCtf.css';
import '../../../css/box.css';
import { Button, Input, Select, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import instance from '../../Communal/instance';
import Retrieval from '../../Activity/retrieval';
import { Alert } from '../../Communal/utility';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/code-highlighter.css';
import DeclineBox from '../../Activity/DeclineBox';
import AddedBox from '../../Activity/AddedBox';
import Denied from '../../../images/denied.png';
import history from '../../Communal/history';
import $ from 'jquery'
const { Option } = Select;
const { TextArea } = Input;
function SubmitCTF(param) {
    //id===0管理员 type: 0 添加 1 编辑 2 审核
    const [type, setType] = useState("0")
    const [detail, setDetail] = useState({})
    const [submit, setSubmit] = useState(
        {
            name: '',
            intro: '',
            scale: '',
            label: [],
            tag: '',
            imageUrl: ''
        }
    )
    const [error, setError] = useState(
        {
            name: [],
            intro: [],
            scale: [],
            label: [],
            imageUrl: [],
        }
    )
    const [imgLoading, setImgLoading] = useState(false)
    const [isSubmit, setIsSubmit] = useState(true);
    const [retrievalBox, setRetrievalBox] = useState({
        show: false,
        count: 0,
        type: 0
    });
    const [initBox, setInitBox] = useState(
        {
            declineBox: false,
            addedBox: false
        }
    )
    const [label, setLabel] = useState([])
    const [retrievalName, setRetrievalName] = useState("")
    useEffect(() => {
        setType(param.param.match.params.type);
    }, [param.param.match.params.type])
    useEffect(() => {
        if (param.param.match.params.type !== "0") {
            // 企业详情
            instance.get("/recruit/enterp/" + param.param.match.params.id + "/").then(data => {
                submit.name = data.name
                submit.intro = data.intro
                submit.scale = data.scale
                submit.label = data.label
                submit.imageUrl = data.logo
                setSubmit({ ...submit })
                setDetail(data)
                getLabel()
            })
        } else {
            getLabel()
        }
    }, [])

    // 表单元素改变
    const inputChange = (value, name) => {
        submit[name] = value;
        setSubmit({ ...submit })
        if (name === 'label') {
            if (submit.label.length > 5) {
                Alert('warn', '最多只能添加5个岗位标签')
                submit.label = submit.label.slice(0, 5)
                setSubmit({ ...submit })
                return
            }
        }
    }

    // 查重
    const retrievalClick = (type, text) => {
        if (type === 0) {
            setRetrievalName("企业全称")
        } else if (type === 1) {
            setRetrievalName("企业简介")
        }

        if (text.trim() === '') {
            Alert('warn', '请输入检索内容')
            return
        }
        let list = {
            type: 'enterprise',
            flag: text,
            search_type: type
        }
        instance.get("/ctf/search/", { params: list }).then(data => {
            retrievalBox.count = data.count
            retrievalBox.show = true
            retrievalBox.type = type;
            setRetrievalBox({ ...retrievalBox })
        })

    }
    // 标签
    let getLabel = () => {
        instance.get("/recruit/labels/?type=2").then(data => {
            setLabel(data.results)
        })
    }
    // 上传图片前的限制
    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('图片仅支持PNG、JPG、JPEG!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片大小不能超过2MB!');
        }
        return isJpgOrPng && isLt2M && checkImageWH(file, 1);
    }
    function checkImageWH(file, wh) {
        let self = this
        return new Promise(function (resolve, reject) {
            let filereader = new FileReader();
            filereader.onload = e => {
                let src = e.target.result;
                const image = new Image();
                image.onload = function () {
                    if (this.width / this.height !== wh) {
                        message.error('图片尺寸比例需为1:1')
                        reject();
                    } else {
                        resolve()
                    }
                };
                image.onerror = reject;
                image.src = src;
            };
            filereader.readAsDataURL(file);
        });
    }

    // 上传图片
    const handleChange = info => {
        error.imageUrl = []
        setError({ ...error })
        if (info.file.status === 'uploading') {
            setImgLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            submit.imageUrl = info.file.response.url
            setImgLoading(false)
        }
    };
    // 自定义图片上传
    const customRequest = (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        const fd = new FormData();
        fd.append('image', options.file);
        fd.append("model", "recruit");
        instance.post("/ctf/upload_image/", fd).then(data => {
            options.onSuccess({
                url: data.url,
            })
        })
    }
    // 清除错误提示
    const handleFocus = (name) => {
        error[name] = []
        setError({ ...error })
    }
    // 提交
    const commitResources = (actionType) => {
        if (!isSubmit) {
            Alert("warn", "正在提交数据，请稍后！")
            return
        }
        // 传入接口的数据
        const list = {
            logo: submit.imageUrl,
            name: submit.name,
            intro: submit.intro,
            scale: submit.scale,
            post_label: submit.label,
        }
        if (submit.name.trim() === '') {
            error.name = ["请输入企业名称"]
        }
        if (submit.intro.trim() === '') {
            error.intro = ['请填写企业简介']
        }
        if (submit.label.length === 0) {
            error.label = ['请填写企业标签']
        }
        if (submit.scale.trim() === '') {
            error.scale = ['请填写企业规模']
        }
        if (submit.imageUrl === '') {
            error.imageUrl = ['请上传企业logo']
        }
        if (
            submit.name.trim() === "" ||
            submit.intro.trim() === '' ||
            submit.scale.trim() === '' ||
            submit.label.length === 0 ||
            submit.imageUrl === ''
        ) {
            setError({ ...error })
            return
        }

        setIsSubmit(false)
        // 提交
        if (actionType === 1) {
            instance.post("/recruit/enterps/", list).then(data => {
                Alert("success", "提交成功");
                setIsSubmit(true);
                setSubmit(
                    {
                        name: '',
                        intro: '',
                        scale: '',
                        label: [],
                        tag: '',
                        imageUrl: ''
                    }
                )
            }).catch(err => {
                Alert('warn', err.error)
                errorInfo(err);
                setIsSubmit(true);
            })
        }
        // 编辑 审核保存修改
        else if (actionType === 2) {
            instance.patch("/recruit/enterp/" + param.param.match.params.id + "/", list).then(data => {
                Alert("success", "提交成功");
                setIsSubmit(true);
            }).catch((err) => {
                errorInfo(err);
                setIsSubmit(true);
            })
        }
    }
    // 接口错误提示
    const errorInfo = (err) => {
        if (err.name) {
            error.name = err.name
        }
        if (err.intro) {
            error.intro = err.intro
        }
        if (err.scale) {
            error.scale = err.scale
        }
        if (err.label) {
            error.label = err.label
        }
        if (err.imageUrl) {
            error.imageUrl = err.imageUrl
        }
        setError({ ...error })
    }
    // 点击审核
    const handleAudit = (data) => {
        let list = { type: "Enterprise", ...data }
        if (submit.address) {
            list.type = "Station"
        }
        if ((data && data.status === 2 || data && data.status === 3) && data.data === "") {
            Alert("error", "请输入审核意见");
            return
        }
        instance.post("/recruit/audit/" + param.param.match.params.id + "/", list).then(data => {
            Alert("success", "操作成功");
            history.push({pathname:'/resource/recruit',state:{tabKey:2}})
        }).catch(err => {
            Alert("error", err.error)
        })
    }

    const uploadButton = (
        <div>
            {imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    return (
        <div className="submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className='submit-ctf-div submit-ctf-name'>
                    {
                        type === "0" && "提交招聘需求企业"
                    }
                    {
                        type === "1" && "编辑招聘需求企业"
                    }
                    {
                        type === "2" && "审核招聘需求企业"
                    }
                    {
                        detail.status === 3 &&
                        <div className="status">
                            <img src={Denied} alt="" />
                        </div>
                    }
                </div>
                <div className="submit-ctf-div subject-info">
                    <div className="title">
                        企业信息
                    </div>
                    <div className="content">
                        <div className="retrieval-div">
                            <span><b>*</b>企业全称</span>
                            <Input
                                value={submit.name}
                                className="content-input"
                                placeholder="请输入企业全称"
                                onChange={(e) => inputChange(e.target.value, "name")}
                                maxLength={15}
                                onFocus={() => handleFocus("name")}
                            />
                            <span className="number">
                                {15 - submit.name.length}
                            </span>
                            <Button
                                type="primary"
                                onClick={() => retrievalClick(0, submit.name)}
                            >
                                检索
                            </Button>
                            <div className="error-message">{error.name[0]}</div>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>企业简介</span>
                            <TextArea
                                value={submit.intro}
                                className="content-input"
                                placeholder="请输入企业简介"
                                onChange={(e) => inputChange(e.target.value, "intro")}
                                maxLength="250"
                                onFocus={() => handleFocus("intro")}
                            />
                            <span className="number">
                                {250 - submit.intro.length}
                            </span>
                            <Button
                                type="primary"
                                onClick={() => retrievalClick(1, submit.intro)}
                            >
                                检索
                            </Button>
                            <div className="error-message">{error.intro[0]}</div>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>企业标签</span>
                            <Select
                                mode="tags"
                                placeholder='请选择企业标签'
                                style={{ width: 300 }}
                                value={submit.label}
                                maxTagTextLength={6}
                                onFocus={() => handleFocus("label")}
                                onChange={
                                    (value) => inputChange(value, 'label')
                                }
                            >
                                {
                                    label.map((val, key) => {
                                        return <Option value={val.name} key={"key" + key}>
                                            {val.name}
                                        </Option>
                                    })
                                }
                            </Select>
                            <div className="error-message">{error.label[0]}</div>
                        </div>
                        <div className="retrieval-div">
                            <span><b>*</b>企业规模</span>
                            <Input
                                value={submit.scale}
                                className="content-input"
                                placeholder="请输入类似100人500人字样"
                                onChange={(e) => inputChange(e.target.value, "scale")}
                                maxLength={15}
                                onFocus={() => handleFocus("scale")}
                            />
                            <span className="number">
                                {15 - submit.scale.length}
                            </span>
                            <div className="error-message">{error.scale[0]}</div>
                        </div>
                        <div className="retrieval-div enterprise-div">
                            <span><b>*</b>企业logo</span>
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                maxCount={1}
                                customRequest={customRequest}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                                onClick={() => handleFocus("imageUrl")}
                            >
                                {submit.imageUrl ? <img src={submit.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                            <span className='avatar-limit'>
                                图片仅支持PNG、JPG、JPEG，尺寸比例限制：1:1
                            </span>
                            <div className="error-message">{error.imageUrl[0]}</div>
                        </div>
                    </div>
                </div>
                {
                    // 提交
                    type === "0" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => commitResources(1)}
                            loading={!isSubmit}
                        >
                            提交
                        </Button>
                    </div>
                }
                {
                    // 编辑
                    type === "1" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => commitResources(2)}
                        >
                            提交
                        </Button>
                    </div>
                }
                {
                    // 审核
                    type === "2" &&
                    <div className="submit-btn">
                        <Button
                            type="primary"
                            onClick={() => {
                                handleAudit({ status: 1, type: "Enterprise" })
                            }}
                        >
                            通过
                        </Button>
                        <Button
                            style={{ backgroundColor: "transparent", color: "#266cff" }}
                            type="primary"
                            onClick={() => history.push({ pathname: '/resource/recruit', state: { tabKey: 2 } })}
                        >
                            取消
                        </Button>
                        <Button
                            className="modify"
                            onClick={() => commitResources(2)}
                        >
                            保存修改
                        </Button>
                        <Button
                            className="decline"
                            onClick={() => {
                                initBox.declineBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            拒绝
                        </Button>
                        <Button
                            className="added"
                            onClick={() => {
                                initBox.addedBox = true;
                                setInitBox({ ...initBox })
                            }}
                        >
                            待补充
                        </Button>
                        <Button
                            className="added"
                            onClick={() => handleAudit({ status: 0 })}
                        >
                            待审核
                        </Button>
                    </div>
                }
            </div>
            {
                retrievalBox.show &&
                <Retrieval
                    close={() => {
                        retrievalBox.show = false;
                        setRetrievalBox({ ...retrievalBox })
                    }}
                    count={retrievalBox.count}
                    name={retrievalName}
                />
            }
            {
                initBox.declineBox &&
                <DeclineBox
                    close={() => {
                        initBox.declineBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.addedBox &&
                <AddedBox
                    close={() => {
                        initBox.addedBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
        </div>
    )
}
export default SubmitCTF;