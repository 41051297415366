import React, { useEffect, useState } from 'react';
import { Button, Input, Row, Col, Drawer } from 'antd';
import '../../../../css/comfirm.less';
import DeclineBox from '../../../Activity/DeclineBox';
import Added from '../../../../images/added.png';
import AuditStatus from '../../../../images/audit-status.png';
import Denied from '../../../../images/denied.png';
import Pass from '../../../../images/pass.png';
import AddedBox from '../../../Activity/AddedBox';
import { Alert } from '../../../Communal/utility';
import instance from '../../../Communal/instance';
const App = ({
    submitTip,
    close,
    getData
}) => {
    const [submitLoading, setSubmitLoading] = useState(false)

    const [submit, setSubmit] = useState({
        name: "",
        english_name: ""
    })
    const [errMsg, setErrMsg] = useState({
        name: "",
        english_name: ""
    })
    const [initBox, setInitBox] = useState(
        {
            passBox: false,
            declineBox: false,
            addedBox: false
        }
    )

    useEffect(() => {
        if (!submitTip.show) {
            setErrMsg({
                name: "",
                english_name: ""
            })
            setSubmit({
                name: "",
                english_name: ""
            })
        }
        if (submitTip.show && submitTip.type !== "0") {
            getDetail(submitTip.id)
        }
    }, [submitTip.show])

    const getDetail = (id) => {
        instance.get(`/rules/class/${id}/`).then(data => {
            setSubmit(data)
        })
    }

    const handleChange = (key, value) => {
        submit[key] = value
        setSubmit({ ...submit })
    }

    const clearErr = (key) => {
        let err = { ...errMsg }
        err[key] = ""
        setErrMsg({ ...err })
    }

    const handleCancel = () => {
        close()
    }

    const handleOk = (type) => {
        let err = { ...errMsg }
        const list = {
            name: submit.name,
            english_name: submit.english_name,
        }
        if (submit.name === '') {
            err.name = '请输入分类名称'
        }
        if (submit.english_name === '') {
            err.english_name = '请输入英文标识'
        }
        if (submit.name === '' || submit.english_name === '') {
            setErrMsg({ ...err })
            return
        }
        setSubmitLoading(true)
        if (submitTip.type === "0") {
            // 添加
            instance.post("/rules/class/", list).then(data => {
                setSubmitLoading(false);
                Alert("success", "添加成功");
                handleCancel()
                getData()
            }).catch(err => {
                setSubmitLoading(false);
                Alert("error", err.error)
            })
        }
        if (submitTip.type === "1" || type === "1") {
            // 编辑
            instance.patch(`/rules/class/${submitTip.id}/`, list).then(data => {
                setSubmitLoading(false);
                Alert("success", "编辑成功");
                handleCancel()
                getData()
            }).catch(err => {
                setSubmitLoading(false);
                Alert("error", err.error)
            })
        }
        if (submitTip.type === "2") {
            // 审核
        }

    }

    let handleAudit = (data) => {
        let list = {
            ids: submitTip.id,
            status: data.status,
            data: data.data
        }
        if ((data.status === 2 || data.status === 3) && data.data === "") {
            Alert("error", "请输入审核意见");
            return
        }
        instance.post("/rules/class/audit/", list).then(data => {
            Alert("success", "操作成功");
            initBox.declineBox = false;
            initBox.addedBox = false;
            setInitBox({ ...initBox })
            handleCancel()
            getData()
        }).catch(err => {
            initBox.declineBox = false;
            initBox.addedBox = false;
            setInitBox({ ...initBox })
            Alert("error", err.error)
        })
    }

    return (
        <Drawer
            className="form-modal submit-ctf"
            style={{ backgroundColor: 'transparent' }}
            visible={submitTip.show}
            onClose={handleCancel}
            width={800}
            destroyOnClose
            title={'分类' + (submitTip.type === "0" ? '提交' : submitTip.type === "1" ? "编辑" : submitTip.type === "2" ? "审核" : "详情")}
            footer={null}
        >
            <Row className="line">
                <Col span={6} className='left'><b>*</b>分类名称：</Col>
                <Col span={18} className='right'>
                    <Input
                        placeholder='请输入分类名称'
                        onChange={(e) => { handleChange('name', e.target.value) }}
                        value={submit.name}
                        disabled={submitTip.type === "3"}
                        maxLength={50}
                        onFocus={() => { clearErr('name') }}
                    />
                </Col>
            </Row>
            <Row className="error">
                <Col span={6}></Col>
                <Col span={18}>{errMsg?.name} </Col>
            </Row>
            <Row className="line">
                <Col span={6} className='left'><b>*</b>英文标识：</Col>
                <Col span={18} className='right'>
                    <Input
                        placeholder='请输入分类名称'
                        onChange={(e) => { handleChange('english_name', e.target.value) }}
                        value={submit.english_name}
                        disabled={submitTip.type === "3"}
                        maxLength={100}
                        onFocus={() => { clearErr('english_name') }}
                    />
                </Col>
            </Row>
            <Row className="error">
                <Col span={6}></Col>
                <Col span={18}>{errMsg?.english_name} </Col>
            </Row>
            <Row className="line info">
                <Col span={24}>提示：分类名称尽可能用可以表达的中文词汇，英文标识使用无空格的英文单词，例如：Web应用攻击，英文标识为web-attack</Col>
            </Row>
            {
                submitTip.type === "3" && submit?.is_show_opin && submit?.opin && submit.opin.length > 0 &&
                <div
                    className="submit-ctf-div audit-opinion"
                    style={{ marginTop: "0" }}
                >
                    <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                    <div className="content">
                        <div>
                            {
                                submit.opin &&
                                submit.opin.map((val, key) => {
                                    return <div key={key + "key"}>
                                        <div className="time">[{val.time}]</div>
                                        <div className="details">
                                            <pre style={{ whiteSpace: "pre-line" }}>{val.msg}</pre>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            }
            {
                submitTip.type === "3" && submit.is_show_opin &&
                <div className="status" style={{ textAlign: 'end' }}>
                    {
                        submit.status === 0 &&
                        <img src={AuditStatus} alt="" />
                    }
                    {
                        submit.status === 1 &&
                        <img src={Pass} alt="" />
                    }
                    {
                        submit.status === 2 &&
                        <img src={Added} alt="" />
                    }
                    {
                        submit.status === 3 &&
                        <img src={Denied} alt="" />
                    }
                </div>
            }
            {
                submitTip.type !== "3" ?
                    <div
                        style={{ textAlign: 'center' }}
                        className="my-modal-footer submit-btn" >
                        {
                            submitTip.type === "2" ?
                                <>
                                    <Button
                                        type="primary"
                                        onClick={() => handleAudit({ status: 1 })}
                                    >
                                        通过
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: "transparent", color: "#266cff" }}
                                        type="primary"
                                        onClick={handleCancel}
                                    >
                                        取消
                                    </Button>
                                    <Button
                                        className="modify"
                                        onClick={() => { handleOk("1") }}
                                    >
                                        保存修改
                                    </Button>
                                    <Button
                                        className="decline"
                                        onClick={() => {
                                            initBox.declineBox = true;
                                            setInitBox({ ...initBox })
                                        }}
                                    >
                                        拒绝
                                    </Button>
                                    <Button
                                        className="added"
                                        onClick={() => {
                                            initBox.addedBox = true;
                                            setInitBox({ ...initBox })
                                        }}
                                    >
                                        待补充
                                    </Button>
                                    <Button
                                        className="added"
                                        onClick={() => handleAudit({ status: 0 })}
                                    >
                                        待审核
                                    </Button>
                                </>
                                :
                                <>
                                    <Button className="cancel-btn" onClick={handleCancel}>取消</Button>
                                    <Button type="primary" loading={submitLoading} onClick={handleOk}>确认</Button>
                                </>
                        }

                    </div>
                    :
                    null
            }
            {
                initBox.declineBox &&
                <DeclineBox
                    close={() => {
                        initBox.declineBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
            {
                initBox.addedBox &&
                <AddedBox
                    close={() => {
                        initBox.addedBox = false;
                        setInitBox({ ...initBox })
                    }}
                    click={handleAudit}
                />
            }
        </Drawer>
    )
}
export default App;