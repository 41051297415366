//提交确认框
import React from 'react';
import { Modal, Button, Row } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';

function App({ submit, onClose, onOK, resourceName, loading }) {
    const handleCancel = () => {
        onClose()
    }
    const handleOk = () => {
        onOK()
    }

    return (
        <Modal
            className="my-confirm-modal"
            visible={submit.show}
            centered={true}
            closable={false}
            footer={<div className="my-modal-footer">
                <Button className="cancel-btn" onClick={handleCancel}>取消</Button>
                <Button
                    type="primary"
                    loading={loading}
                    onClick={handleOk}>确认</Button>
            </div>}
        >
            <Row className="line">
                <div className="title-icon">
                    <QuestionOutlined className="del-icon" />
                </div>
            </Row>
            <Row className="line">
                <div className="title">确认提交{resourceName || '资源'}</div>
            </Row>
        </Modal>
    )
}

export default App