import React,{useEffect,useState} from 'react';
import {CloseOutlined} from '@ant-design/icons';

function Retrieval(param){
    useEffect(()=>{
        document.body.style.overflow="hidden";
        return ()=>{
            document.body.style.overflow="auto"
        }
    },[]);
    const [initData]=useState(
        {
            count:param.count,
            show:true
        }
    )
    return (
        <div className="retrieval-box">
            <div className="content">
                <div className="title">
                    通过
                    {param.name}
                    检索是否存在
                    <CloseOutlined  onClick={param.close}/>
                </div>
                <div className="retrieval-search">
                    {
                        initData.show&&
                        <p>检索到相同
                            {param.name}
                        <b>{initData.count}</b>条</p>
                    }
                </div>
            </div>
        </div>
    )
}
export default Retrieval