import React, { useEffect, useState } from 'react';
import Close from '../../images/close-apply-box.png';
import { Input, Select } from 'antd';
const { Option } = Select;
function ApplyBox(param) {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        }
    });
    const [grade] = useState([
        {
            name: "启航班"
        },
        {
            name: "基础强化班"
        },
        {
            name: "攻防进阶班"
        },
        {
            name: "攻防提高班"
        },
        {
            name: "其他"
        },
    ]);
    const [initData, setInitData] = useState(
        {
            grade: "启航班",
            name: "",
            unit: "",
        }
    )
    let handleChange = (value) => {
        initData.grade = value;
        setInitData({ ...initData })
    }
    return (
        <div className="audit-bounced">
            <div className="apply-box">
                <div className="title">
                    <img src={Close} alt="" onClick={param.close} />
                </div>
                <div className="inline-box info">
                    <div className='line'>
                        <p>真实姓名</p>
                        <Input
                            value={initData.name}
                            placeholder="请输入真实姓名"
                            onChange={(e) => {
                                initData.name = e.target.value;
                                setInitData({ ...initData })
                            }}
                            onFocus={() => {
                                param.errChange('user_name')
                            }}
                        />
                        {
                            param.applyErr.user_name && <div>{param.applyErr.user_name[0]}</div>
                        }
                    </div>
                    <div className='line'>
                        <p>单位</p>
                        <Input
                            value={initData.unit}
                            placeholder="请输入单位名称"
                            onChange={(e) => {
                                initData.unit = e.target.value;
                                setInitData({ ...initData })
                            }}
                            onFocus={() => {
                                param.errChange('unit')
                            }}
                        />
                        {
                            param.applyErr.unit && <div>{param.applyErr.unit[0]}</div>
                        }
                    </div>

                    <p>来源班级</p>
                    <Select defaultValue={initData.grade} style={{ width: "100%" }} onChange={(value) => handleChange(value)}>
                        {
                            grade.map((val, key) => {
                                return <Option key={"key" + key} value={val.name}>{val.name}</Option>
                            })
                        }
                    </Select>
                    <button onClick={() => param.submit(initData)}>报名</button>
                </div>
                <div className="inline-box contact">
                    <img src={param.applyData.qrCode} alt="" />
                    <p>添加项目负责人</p>
                    <p>了解更多项目信息和要求</p>
                </div>
            </div>
        </div>
    )
}
export default ApplyBox;