/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import instance from '../../../Communal/instance';
import Added from '../../../../images/added.png';
import AuditStatus from '../../../../images/audit-status.png';
import Denied from '../../../../images/denied.png';
import Pass from '../../../../images/pass.png';
import { Row, Col } from 'antd';
import Reminder from '../../../Communal/reminder';
import history from '../../../Communal/history';
import './index.less';

function FingerPrintDetail({ param }) {
    const [detail, setDetail] = useState({});
    const [reminderShow, setReminderShow] = useState(false)
    const [classify, setClassify] = useState([])

    useEffect(() => {
        getDetail()
        getClassifyList()
    }, []);

    let getDetail = () => {
        instance.get(`/vulninte/api/mark/${param.match.params.id}/`).then(data => {
            setDetail(data);
        })
    }

    const getClassifyList = () => {
        instance.get("/vulninte/api/static/make/").then(data => {
            if (Array.isArray(data?.mark)) {
                let list = []
                for (let i in data.mark[0]) {
                    list[i] = data.mark[0][i]
                }
                setClassify(list)
            }
        })
    }

    return (
        <div className="activity-ctf-detail submit-ctf">
            <div className="banner"></div>
            <div className="content-container">
                <div className="submit-ctf-name submit-ctf-div">
                    {
                        param.match.params.where === "0" &&
                        <div style={{ display: "inline-block" }} className='ctf-name'>
                            <span onClick={() => window.location.href = "/#/resource"}>资源中心</span> ＞ <span onClick={() => {
                                history.push({ pathname: '/resource/vulninfo', state: { tabKey: "2" } })
                            }}>指纹库</span> ＞ {detail.name}
                        </div>
                    }
                    {
                        detail.is_show_opin &&
                        <div className="status">
                            {
                                detail.status === 0 &&
                                <img src={AuditStatus} alt="" />
                            }
                            {
                                detail.status === 1 &&
                                <img src={Pass} alt="" />
                            }
                            {
                                detail.status === 2 &&
                                <img src={Added} alt="" />
                            }
                            {
                                detail.status === 3 &&
                                <img src={Denied} alt="" />
                            }
                        </div>
                    }
                </div>
                {
                    detail.is_show_opin && detail.opin && detail.opin.length > 0 &&
                    <div className="submit-ctf-div audit-opinion">
                        <div className="title" style={{ borderBottom: "1px solid #f4f4f4" }}>审核意见</div>
                        <div className="content">
                            <div>
                                {
                                    detail.opin &&
                                    detail.opin.map((val, key) => {
                                        return <div key={key + "key"}>
                                            <div className="time">[{val.time}]</div>
                                            <div className="details">
                                                <pre style={{ whiteSpace: "pre-line" }}>{val.msg}</pre>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="submit-ctf-div">
                    <div className="award">
                        <div>
                            <span>{detail?.coin}</span>
                            <span>奖励</span>
                        </div>
                        <p onClick={() => setReminderShow(true)}>赚取更多奖励＞</p>
                    </div>
                    <div className="title">指纹信息</div>
                    <div className="content">
                        <div>
                            <span>指纹名称</span>
                            <div>{detail?.name}</div>
                        </div>
                        <div>
                            <span>指纹类型</span>
                            <div>{classify[detail?.vulntype]}</div>
                        </div>
                        <div>
                            <span>所属厂商</span>
                            <div>{detail?.module?.company}</div>
                        </div>
                        <div>
                            <span>所属组件</span>
                            <div>{detail?.module?.name}</div>
                        </div>
                    </div>
                </div>
                <div className="submit-ctf-div flex-submit">
                    <div className="title">指纹内容</div>
                    <div className="content list-box">
                        <div className='content-box'>
                            <span className='content-title'>指纹内容</span>
                            <div className='content-box-detail'>
                                {
                                    (detail?.content || []).map((x, i) => {
                                        return <Row key={i} className='list-line'>
                                            <Col span={6} className="retrieval-div flex-retrieval-div">
                                                <span className='name'>
                                                    url：
                                                </span>
                                                <div
                                                    className='flex-auto detail'
                                                    title={x?.url}
                                                >
                                                    {x?.url}
                                                </div>
                                            </Col>
                                            <Col span={6} className="retrieval-div flex-retrieval-div">
                                                <span className='name'>识别区域：</span>
                                                <div
                                                    className='flex-auto detail'
                                                    title={x?.zone}
                                                >
                                                    {x?.zone}
                                                </div>

                                            </Col>
                                            <Col span={6} className="retrieval-div flex-retrieval-div">
                                                <span className='name'>识别方式：</span>
                                                <div
                                                    className='flex-auto detail'
                                                    title={x?.way}
                                                >
                                                    {x?.way}
                                                </div>
                                            </Col>
                                            <Col span={6} className="retrieval-div flex-retrieval-div">
                                                <span className='name'>指纹特征：</span>
                                                <div
                                                    className='flex-auto detail'
                                                    title={x?.trait}
                                                >
                                                    {x?.trait}
                                                </div>
                                            </Col>
                                        </Row>
                                    })
                                }
                            </div>
                        </div>
                        <div className='content-box'>
                            <span className='content-title'>指纹搜索</span>
                            <div className='content-box-detail'>
                                {
                                    (detail?.dork || []).map((x, i) => {
                                        return <Row key={i} className='list-line'>
                                            <Col span={7} className="retrieval-div flex-retrieval-div">
                                                <span className='name'>
                                                    搜索引擎：
                                                </span>
                                                <div
                                                    className='flex-auto detail'
                                                    title={x?.engine}
                                                >
                                                    {x?.engine || '--'}
                                                </div>
                                            </Col>
                                            <Col span={16} className="retrieval-div flex-retrieval-div">
                                                <span className='name'>Dork语法：</span>
                                                <div
                                                    className='flex-auto detail'
                                                    title={x?.quake}
                                                >
                                                    {x?.quake || '--'}
                                                </div>
                                            </Col>
                                        </Row>
                                    })
                                }
                            </div>
                        </div>
                        <div className='content-box'>
                            <span className='content-title'>验证地址</span>
                            <div className='content-box-detail'>
                                {
                                    (detail?.verify || []).map((x, i) => {
                                        return <Row key={i} className='list-line'>
                                            <Col span={24} className="retrieval-div flex-retrieval-div">
                                                <div
                                                    className='flex-auto detail'
                                                    title={x}
                                                >
                                                    {x || '--'}
                                                </div>
                                            </Col>
                                        </Row>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                reminderShow &&
                <Reminder
                    close={() => setReminderShow(false)}
                />
            }
        </div>
    )
}

export default FingerPrintDetail;