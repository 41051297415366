import React,{useEffect,useState} from 'react';
import CloseBox from '../../images/close-box.png';
import { Input } from 'antd';
const { TextArea } = Input;
function AddedBox(param){
    useEffect(()=>{
        document.body.style.overflow="hidden"
    },[])
    useEffect(()=>{
        return ()=>{
            document.body.style.overflow="auto"
        }
    },[])
    const [text,setText]=useState("")
    return (
        <div className="audit-bounced">
            <div className="content added-box">
                <div className="header">
                    <img src={CloseBox} onClick={param.close} alt=""/>
                </div>
                <div className="detail">
                    <div>提交人提交资源待补充</div>
                    <div>请填写审核意见</div>
                    <div>
                        <b>*</b>
                        <TextArea 
                            value={text}
                            rows={6}
                            onChange={(e)=>setText(e.target.value)}
                        />
                    </div>
                    <div>
                        <button 
                            onClick={()=>{
                                param.click({status:2,data:text})
                            }}
                        >
                            待补充
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddedBox;